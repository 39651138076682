import React, {useEffect, useState } from "react";
import { Row, Col, Card, Avatar, Button, Form, Input, Upload } from "antd";
import { Icon } from "components/util-components/Icon";
import {
	UploadOutlined,
  MailOutlined,
} from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import Flex from "components/shared-components/Flex";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "redux/actions";
import ProfileForm from "./ProfileForm";

const ProfileInfo = (props) => (

  <Card>
    <Row justify="center">
      <Col sm={24} md={23}>
        <div className="d-md-flex">
          <div
            className="rounded p-2 bg-white shadow-sm mx-auto"
            style={{
              marginTop: "-3.5rem",
              maxWidth: `${props.avatarSize + 16}px`,
            }}
          >
            <Avatar
              shape="square"
              size={props.avatarSize}
              src={props.img}
            />
          </div>
          <div className="ml-md-4 w-100">
            <Flex
              alignItems="center"
              mobileFlex={false}
              className="mb-3 text-md-left text-center"
            >
              <h2 className="mb-0 ml-4">{props.username}</h2>
              {/* <div className="ml-md-3 mt-3 mt-md-0">
								<Button size="small" type="primary">Follow</Button>
								<Button size="small" className="ml-2">Message</Button>
							</div> */}
            </Flex>
            <Row gutter="16" className="ml-4">
              {/* <Col sm={24} md={8}>
								<p className="mt-0 mr-3 text-muted text-md-left text-center">
									It is a long established fact that a reader will be distracted.
								</p>
							</Col> */}
              <Col xs={24} sm={24} md={9}>
                {/* <Row> 
									<Col xs={12} sm={12} md={9}>
									
										<Icon type={UserOutlined} className="text-primary font-size-md"/>
										<span className="text-muted ml-2">Username:</span>
									</Col>
									<Col xs={12} sm={12} md={15}>
										<span className="font-weight-semibold">+12 123 1234</span>
									</Col>
								</Row> */}

                <Row className="mb-2">
                  <Col xs={12} sm={12} md={8}>
                    <Icon
                      type={MailOutlined}
                      className="text-primary font-size-md"
                    />
                    <span className="text-muted ml-2">Email:</span>
                  </Col>
                  <Col xs={12} sm={12} md={8}>
                    <span className="font-weight-semibold">{props.email}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  </Card>
);

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const avatarSize = 150;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};


export const Profile = () => {
  const data = useSelector((state) => state.AuthStore?.user);
  const user = data?.user;
  const dispatch = useDispatch();

  const [username, setUserName] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
	 if(user !== undefined){
		setUserName(user?.username)
    setId(user?.id)
	 }
    
  }, [user, data]);

  const onFinishForm = (values) => {
   
    let data = values;
    data.id = user.id;
    dispatch(updateUser(data));
  };

  //  useEffect(() => {
  //   if (user_success === true) {
  //     dispatch(getUser());
  //   }
  // }, [user_success]);
 
  return (
    <>
      <PageHeaderAlt
        background="/img/others/img-12.jpg"
        cssClass="bg-primary"
        overlap
      >
        <div className="container text-center">
          <div className="py-5 my-md-5"></div>
        </div>
      </PageHeaderAlt>
      <div className="container my-4">
        <ProfileInfo
          avatarSize={avatarSize}
          username={user?.username}
          email={user?.email}
		  img={user?.photo}
        />
        <Row gutter="16">
          <Col xs={24} sm={24} md={24}>
			  <Row gutter="16">
			  	<Col xs={24} sm={24} md={12}>
				  <Card>
              <h4>Edit Profile Settings</h4>
			 <ProfileForm username={user?.username} email={user?.email} id={user?.id}/>
             
            </Card>

				  </Col>


				  <Col xs={24} sm={24} md={12}>
				  <Card>
              <h4 className="mb-4">Security Settings</h4>
              <Form
			     {...layout}
                name="security"
                onFinish={onFinishForm}
                onFinishFailed={onFinishFailed}
              >
                 
                <Row gutter="30">
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Old Password"
                      name="old_password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                     
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="New Password"
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your new password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="float-right"
                      >
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>


				  </Col>
			  </Row>
           
                    </Col>
          {/* <Col xs={24} sm={24} md={16}>
							<Experiences />
							<Interested />
						</Col> */}
        </Row>
      </div>
    </>
  );
};

export default React.memo(Profile);
