import { message } from 'antd';

const durationErrorMessage = "The duration must be at least one minute.";
const teamMemberErrorMessage = "Please select a team member.";
const descriptionErrorMessage = "Please add a description.";

export const handleTimeError = (errorCount, timeUser, timeTitle , startTime, endTime) => {

    var errorMsg = '';
 
     if(timeUser === ""){
       errorCount = errorCount + 1;
       errorMsg = teamMemberErrorMessage;
     }
 
     if(timeTitle === ""){
       errorCount = errorCount + 1;
       errorMsg =  errorMsg + ' ' + descriptionErrorMessage;
      
     }
 
     if(startTime >= endTime){
       errorCount = errorCount + 1;
       errorMsg = errorMsg + ' ' + durationErrorMessage;
     }

     if(errorCount > 0){
       message.error(errorMsg); 
     }
    
    return errorCount;
  }

  export default handleTimeError;