import React, { useContext, useState } from "react";
import { ScrumboardProvider, ScrumboardContext } from "./ScrumboardContext";
import Board from "./Board";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  PlusOutlined,
  RetweetOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Tooltip, } from "antd";
import { modalModeTypes } from "./utils";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import {
  createCard,
  createBoard,
  getBoard,
  updateCard,
  updateCardNextIndex,
  showScheduleItem,
  updateScheduleItem,
  showCard,
} from "redux/actions";
import Modal from "antd/lib/modal/Modal";
import { useParams } from "react-router-dom";
import moment from "moment";
import AddCard from "./actions/AddCard";
import AvatarStatus from "components/shared-components/AvatarStatus";
import EditCard from "./actions/EditCard";
import EditTravelEvent from "./actions/EditTravelEvent";
import Draggable from 'react-draggable';

const { Option } = Select;

const ScrumboardWrapper = (props) => {
  
  const { updateModal } = useContext(ScrumboardContext);

  var { id } = props;

  const state = useSelector((state) => state.ScheduleItemStore);
  const success = useSelector((state) => state.ScheduleStore?.success);
  //   const cardList = useSelector((state) => state.CardStore);
  const datas = state?.[id]?.card;
  const drag_success = useSelector((state) => state.CardStore?.success);

  const [board, setBoard] = useState([]);
  const [startOfWeek, setStartOfWeek] = useState(moment().startOf("week"));
  const [endOfWeek, setEndOfWeek] = useState(moment().endOf("week"));

  var days = [];
  var day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.toDate());
    day = day.clone().add(1, "day");
  }

  const changeNextWeek = () => {
    const getLastDate = board[board.length - 1];
    var new_date = moment(getLastDate.board_date).add(1, "days");
    setStartOfWeek(moment(new_date).startOf("week"));
    setEndOfWeek(moment(new_date).endOf("week"));
  };

  const changePreviousWeek = () => {
    const getFirstDate = board.find((e) => !!e);
    var newFirstDate = moment(getFirstDate.board_date).subtract(1, "days");
    setStartOfWeek(moment(newFirstDate).startOf("week"));
    setEndOfWeek(moment(newFirstDate).endOf("week"));
  };

  const todayWeek = () => {
    setStartOfWeek(moment().startOf("week"));
    setEndOfWeek(moment().endOf("week"));
  };

  React.useEffect(() => {
    let boardObj = [];
    const getBoard = days.forEach((x, index) => {
      const board_data = {
        id: (index + 1).toString(),
        scheduler_year_id: 1,
        board_date: moment(x).format("D MMMM YYYY"),
        title: moment(x).format("ddd"),
        card:
          datas === undefined
            ? []
            : datas?.filter(
                (card) =>
                  moment(card?.date).format("l") === moment(x).format("l")
              ),
      };

      boardObj.push(board_data);
      setBoard(boardObj);
    });
  }, [datas, endOfWeek, startOfWeek]);

  let param = useParams();

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props?.id !== undefined) {
      dispatch(showScheduleItem(props?.id));
    }
  }, [dispatch, props?.id]);

  React.useEffect(() => {
    if (drag_success === true) {
      dispatch(showScheduleItem(props?.id));
    }
  }, [drag_success]);

  const list_item = state?.[id]?.schedule;
  const list_success = state?.success;

  const [lists, setLists] = useState([]);

  React.useEffect(() => {
    setLists(board);
  }, [board]);

  const [cardContent, setCardContent] = useState({});
  React.useEffect(() => {
    const convertArrayToObject = (array, key) =>
      array?.reduce(
        (obj, item) => ({
          ...obj,
          [item["title"]]: item?.card,
        }),
        {}
      );
    const item = convertArrayToObject(board);

    setCardContent(item);
  }, [board]);

  const onDragEnd = (results) => {
    const { destination, source, type } = results;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "COLUMN") {
      const result = Array.from(lists);

      const [removed] = result.splice(source.index, 1);

      result.splice(destination.index, 0, removed);

      let order = [];
      result.forEach((x, index) => {
        const item = { id: x.id, index: index };
        order.push(item);
      });

      // dispatch(updateBoardIndex({order:order}))
      setLists(result);

      return;
    }

    const home = cardContent[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      //   const card = Array.from(home);
      //   const [removed] = card.splice(source.index, 1);
      //   card.splice(destination.index, 0, removed);

      // let order = [];
      // card.forEach((x, index) => {
      //    const item = {id:x.id, index:index};
      //    order.push(item);
      // })

      // dispatch(updateCardIndex({order:order}, "schedule-cards"))
      //   setCardContent({...cardContent, [destination.droppableId]:card});
      return;
    }

    const current = [...cardContent[source.droppableId]];
    const next = [...cardContent[destination.droppableId]];
    const target = current[source.index];

    current.splice(source.index, 1);
    next.splice(destination.index, 0, target);

    const result = {
      ...cardContent,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };

    let order = [];
    next.forEach((x, index) => {
      const item = { id: x.id, index: index };
      order.push(item);
    });

    const getDate = board?.filter((x) => x.title === destination.droppableId);
    const boardDate = getDate?.[0]?.board_date;
    const data = {
      date: moment(boardDate),
      card_id: target.id,
    };

    dispatch(updateCardNextIndex(data, "schedule-cards"));

    setCardContent(result);
    return;
  };

  const onCloseModal = () => {
    updateModal(false);
  };

  const onModalSubmit = (values, mode) => {
    if (mode === modalModeTypes(2)) {
      updateModal(false);
      dispatch(createBoard(values));
    }

    if (mode === modalModeTypes(1)) {
      dispatch(updateCard(values));
      // console.log(values);
      updateModal(false);
    }

    if (mode === modalModeTypes(0)) {
      updateModal(false);
      dispatch(createCard(values));
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {props.containerHeight ? (
          <div className="scrumboard">
            <BoardWrapper
              cards={cardContent}
              lists={lists}
              success={success}
              id={props?.id}
              listItem={list_item}
              list_success={list_success}
              changeNextWeek={changeNextWeek}
              {...props}
              changePreviousWeek={changePreviousWeek}
              todayWeek={todayWeek}
              drag_success={drag_success}
              // handleOnScroll={handleOnScroll}
            />
          </div>
        ) : (
          <BoardWrapper
            {...props}
            cards={cardContent}
            lists={lists}
            success={success}
            id={props?.id}
            listItem={list_item}
            changeNextWeek={changeNextWeek}
            list_success={list_success}
            changePreviousWeek={changePreviousWeek}
            todayWeek={todayWeek}
            drag_success={drag_success}
            // handleOnScroll={handleOnScroll}
          />
        )}
      </DragDropContext>
    </>
  );
};

const BoardWrapper = ({
  lists,
  cards,
  success,
  containerHeight,
  useClone,
  isCombineEnabled,
  withScrollableColumns,
  list_success,
  listItem,
  id,
  changeNextWeek,
  changePreviousWeek,
  todayWeek,
  handleOnScroll,
  drag_success,
}) => {

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (success === true) {
      dispatch(getBoard());
    }
  }, [dispatch, success]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEventVisible, setIsModalEventVisible] = useState(false);
  const [isEditModalVisible, setEditIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const showEditModal = () => {
    setEditIsModalVisible(true);
  };


  const handleOk = () => {
    setIsModalVisible(false);
    setEditIsModalVisible(false)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditIsModalVisible(false)
  };

  const showEventModal = () => {
    setIsModalEventVisible(true);
  };

  const handleEventOk = () => {
    setIsModalEventVisible(false);
  };

  const handleEventCancel = () => {
    setIsModalEventVisible(false);
  };

  const onFinish = (values) => {
    dispatch(updateScheduleItem(values));
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        break;
      case 2:
        showModal();
        break;
      case 3:
        break;
      default:
    }
  };
  const team_item = useSelector((state) => state.TeamStore?.teams);
  const teams = team_item?.team;
  const user = useSelector((state) => state.AuthStore?.user?.id);

  React.useEffect(() => {
    if (success === true || list_success === true) {
      dispatch(showScheduleItem(id));
    }
  }, [dispatch, success, list_success]);


    // set model display to false
    const [eventDetails, setEventDetails] = useState({});

    React.useEffect(() => {
      if (eventDetails !== undefined) {
        dispatch(showCard(eventDetails?.id, "schedule-cards"));
      }
    }, [eventDetails]);
  
    const appointments = useSelector((state) => state.CardStore);
    const data = appointments?.single_card;
    const card_success = appointments?.success;
  
    React.useEffect(() => {
      if (card_success === true) {
        dispatch(showCard(eventDetails?.id, "schedule-cards"));
      }
    }, [card_success]);


    
    const scrollRef = React.useRef(null);
    // const handleScroll = (values) => {
    //    console.log(scrollRef.current);
    //    return 
    // }
  
    const [scroll, setScroll] = React.useState(100);
  
    React.useEffect(() => {
      scrollRef.current.scrollLeft(scroll);
    }, [scrollRef, lists, cards, scroll])


    const [disable, setDisable] = React.useState(true);
    const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = React.createRef();
  
    const onStart = (event, uiData) => {
      const { clientWidth, clientHeight } = window.document.documentElement;
      const targetRect = draggleRef.current?.getBoundingClientRect();
      if (!targetRect) {
        return;
      }
      setBounds({
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      });
    };
 
    
  return (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping={containerHeight}
      isCombineEnabled={isCombineEnabled}
    >
      {(provided) => (
        <div
          className="scrumboard"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >

{eventDetails?.event_type === "Regular Event" ? 
       <EditCard
       handleOk={handleOk}
       handleCancel={handleCancel}
       isModalVisible={isEditModalVisible}
       setIsModalVisible={setEditIsModalVisible}
       data={data}
     />
      : 
    
      <EditTravelEvent
       handleOk={handleOk}
       handleCancel={handleCancel}
       isModalVisible={isEditModalVisible}
       setIsModalVisible={setEditIsModalVisible}
       data={data}
     />

      }

          <div className="scrumboard-header">
            <Modal
              width={1000}
              maskClosable={false}
              footer={null}
              visible={isModalEventVisible}
              onOk={handleEventOk}
              onCancel={handleEventCancel}
              className="draggable__modal_item"
              title={
                <div
                  style={{
                    width: '100%',
                    cursor: 'move',
                  }}
                  onMouseOver={() => {
                    if (disable) {
                      setDisable(false);
                    }
                  }}
                  onMouseOut={() => {
                   setDisable(true);
                  }}
                  // fix eslintjsx-a11y/mouse-events-have-key-events
                  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                  onFocus={() => {}}
                  onBlur={() => {}}
                  // end
                >
               New Appointment
                </div>
              }

              modalRender={modal => (
                <Draggable
                  disabled={disable}
                  bounds={bounds}
                 
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable>
              )}
            >
              <AddCard
                onCancel={handleEventCancel}
                setIsModalVisible={setIsModalEventVisible}
                listId={id}
                listItem={listItem}
              />
            </Modal>

            <Modal
              title="Edit Appointment"
              footer={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form
                name="header-form"
                initialValues={{
                  id: listItem?.id,
                  team_id: listItem?.team_id,
                  title: listItem?.title,
                  user_id: user,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item label="Title" name="title">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="user_id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Team" name="team_id" className="mt-1">
                  <Select className="select-box" placeholder="Select Team">
                    {teams?.map((elm, i) => (
                      <Option key={i} value={elm?.id}>
                        {elm?.team_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item className="mb-0">
                  <Button
                    type="default"
                    htmlType="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="default"
                    className="bg-success text-white float-right"
                    htmlType="submit"
                  >
                    Save Changes
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            {/* <div classsName="schedule__headng_item___view">
          <h3 onClick={handleClick}>{listItem?.title}</h3>
         
          </div> */}
          <Tooltip
                  placement="top"
                  title={`New Appointment`}
                  onClick={() => showEventModal()}
                >
                  <div className="avatar__box__view">
                    <AvatarStatus
                      icon={<PlusOutlined />}
                      name=""
                      type="blue"
                      subTitle=""
                      size={30}
                    />
                  </div>
                </Tooltip>
             {/* <Button
              className="new__event__btn"
              icon={<PlusOutlined style={{ fontWeight: "bold" }} />}
              onClick={() => showEventModal()}
            >
              New Appointment
            </Button> */}
            <div className="schedule_button">
              <Button
                icon={<DoubleLeftOutlined />}
                onClick={() => changePreviousWeek()}
              >
                Previous Week
              </Button>

              <Button
                type="default"
                className="today-btn"
                icon={<RetweetOutlined />}
                onClick={() => todayWeek()}
              >
                This Week
              </Button>
              <Button onClick={() => changeNextWeek()}>
                Next Week <DoubleRightOutlined />
              </Button>
            </div>

            <div>{/**/}</div>
            {/* 
          <div className="text-right">
            <div className="d-flex align-items-center">
              {memberIds.map((member, i) =>
                i < 4 ? (
                  <AssigneeAvatar key={member} id={member} size={30} chain />
                ) : null
              )}
              <Avatar className="ml-n2" size={30}>
                <span className="text-gray font-weight-semibold font-size-base">
                  +9
                </span>
              </Avatar>
            </div>
          </div> */}
          </div>

          <Scrollbars
            className="scrumboard-body"
            ref={scrollRef}
            // onScroll={(e) => handleOnScroll(e)}
          >
            {lists?.map((item, index) => (
              <Board
                key={item?.id}
                id={item?.id}
                dateItem={item?.board_date}
                index={index}
                title={item?.title}
                contents={cards[item?.title]}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                useClone={useClone}
                listItem={listItem}
                setEventDetails={setEventDetails}
                showEModal={showEditModal}
                setScroll={setScroll}
              />
            ))}
            {provided.placeholder}
            {/* <div className="board-column add">
              <div className="board-title" onClick={() => onAddBoardModal()}>
                <h4 className="mb-0">
                  <PlusOutlined />
                  <span>Add List</span>
                </h4>
              </div>
            </div> */}
          </Scrollbars>
        </div>
      )}
    </Droppable>
  );
};

const Scrumboard = (props) => {
  return (
    <ScrumboardProvider>
      <ScrumboardWrapper {...props} />
    </ScrumboardProvider>
  );
};

export default Scrumboard;
