
import axios from "axios";
import { authAxios, hostAxios } from "configs/EnvironmentConfig";
import { message } from "antd";
import { openNotificationWithIcon } from "utils/notification";

export const api = {

    register(data) {
        axios.defaults.withCredentials = false;
        return hostAxios
          .get("/sanctum/csrf-cookie")
          .then((res) => {
            return hostAxios.post("api/v1/register", data.user).then((response) => {
              if (res.status === 200 || res.status === 204) {
                openNotificationWithIcon('success', response?.data?.message?.message, `Please verify the email address for  ${response.data.email}`);
              }
              return { response };
            });
          })
          .catch((error) => {
            message.error(error.response?.data?.message, 2);
            return { error };
          });
      },

  login(data) {
    axios.defaults.withCredentials = false;
    return hostAxios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        return hostAxios.post("api/v1/login", data.user).then((response) => {
          localStorage.setItem("scrumboard_token", response.data.token);
          if (res.status === 200 || res.status === 204) {
          setTimeout(function(){
            window.location = '/app/home';
        }, 1000);
          }
          return { response };
        });
      })
      .catch((error) => {
        message.error(error.response?.data?.message, 2);
        return { error };
      });
  },

  forgetPassword(data) {
    axios.defaults.withCredentials = false;
    return hostAxios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        return hostAxios.post("api/v1/forget-password", data).then((response) => {
          if (res.status === 200 || res.status === 204) {
            openNotificationWithIcon('success', response.data.message, `check your email account`);
          }
          return { response };
        });
      })
      .catch((error) => {
        return { error };
      });
  },

  resetPassword(data) {
    axios.defaults.withCredentials = false;
    return hostAxios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        return hostAxios.post("api/v1/reset-password", data).then((response) => {
          if (res.status === 200 || res.status === 204) {
            openNotificationWithIcon('success', response.data.message, 'Please you have reset your password now, login with your new password');
          }
          return { response };
        });
      })
      .catch((error) => {
        return { error };
      });
  },

  user(data){
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/user`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        message.error(error.response.data.message, 2);
        if(error.response.data.message === "Unauthenticated."){
          localStorage.clear();
         window.location = "/auth/login";
        }    
        return { error };
      });
},

show(data){
  axios.defaults.withCredentials = false;
  return authAxios
    .get(`/single-user/${data}`)
    .then((response) => {
      return { response };
    })
    .catch((error) => {
      message.error(error.response.data.message, 2);
      if(error.response.data.message === "Unauthenticated."){
        localStorage.clear();
       window.location = "/auth/login";
      }    
      return { error };
    });
},

allUsers(data){
  axios.defaults.withCredentials = false;
  return authAxios
    .get(`/all-users`)
    .then((response) => {
      return { response };
    })
    .catch((error) => {
      // message.error(error.response.data.message, 2);
      // if(error.response.data.message === "Unauthenticated."){
      //   localStorage.clear();
      //  window.location = "/auth/login";
      // }    
      return { error };
    });
},

  logout(history) {
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/logout`)
      .then((response) => {
        localStorage.clear();
        window.location = "/auth/login";
        return { response };
      })
      .catch((error) => {
        
       
        return { error };
      });
  },

  update(data) {
    axios.defaults.withCredentials = false;
    return authAxios
      .put(`/profiles/${data.id}`, data)
      .then((response) => {
        message.success(response.data.message, 2);
        return { response };
      })
      .catch((error) => {
        message.error(error.response.data.message, 2);
        return { error };
      });
  },
  destroy(data){
    axios.defaults.withCredentials = false;
    return authAxios
      .delete(`/users/${data}`)
      .then((response) => {
        message.success(response.data.message, 2);
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},
};
