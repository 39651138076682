import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showTeam, updateTeam } from "redux/actions";

function AddTeam(props) {
  const [form] = Form.useForm();
  const { handleOk, data } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state.TeamStore);
  const team_success = state?.success;

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
}

  const onFinish = (values) => {
    dispatch(updateTeam(values));
    handleOk();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const getSingleTeam = React.useMemo(() => {
    if(team_success !== true) return;
    dispatch(showTeam(data?.id));
  }, [team_success])

  React.useEffect(() => {
    if(!getSingleTeam) return;
    getSingleTeam();
  }, [getSingleTeam])
  
  React.useEffect(() => {
    form.resetFields();
  }, [form, data]);

  return (
    <div>
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ id: data?.id, team_name: data?.team_name }}
        form={form}
      >
        <Form.Item
          label="Team Name"
          name="team_name"
          rules={[{ required: true, message: "Please input your team name !" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Team id" name="id" style={{ display: "none" }}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddTeam;
