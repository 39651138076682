import * as types from "./constants"

export const getComment = (comments, url, query) => {
    return {
        type:types.GET_COMMENTS,
        payload:{comments, url, query}
    }
}

export const showComment = (single_comment , url) => {
    return {
        type:types.SHOW_COMMENTS,
        payload:{single_comment, url}
    }
}

export const createComment = (comments, url) => {
    return {
        type:types.CREATE_COMMENTS,
        payload:{comments, url}
    }
}

export const updateComment = (comments, url) => {
    return {
        type:types.UPDATE_COMMENTS,
        payload:{comments, url}
    }
}

export const deleteComment = (comments, url) => {
    return {
        type:types.DELETE_COMMENTS,
        payload:{comments, url}
    }
}
