import React from "react";
import scheduleIcon from "../../../assets/icon/schedule.png";
import calendarIcon from "../../../assets/icon/calendar.png";
import teamIcon from "../../../assets/icon/team.png";
import profileIcon from "../../../assets/icon/profile.png";
import tasklistIcon from "../../../assets/icon/tasklist.png";
import projectIcon from "../../../assets/icon/project.png";
import journalIcon from "../../../assets/icon/paperwork.png";
import notebookIcon from "../../../assets/icon/notebook.png";
import listIcon from "../../../assets/icon/list.png";
import emailIcon from "../../../assets/icon/email.png";
import teamView from "../../../assets/icon/teamView.png";
import textMessageIcon from "../../../assets/icon/text-message.png";
import { textTruncate } from "../../../hooks/useTruncate";

function FooterView(props) {
  const {
    minimumData,
    HandleOpenModal,
    modalData,
    setMinimumData,
    setModalData,
    MinimizeModal,
    dispatch
  } = props;
  return (
    <div>
      {minimumData?.length > 0 ? (
        <div className="bar__main__box">
          <div className="minimize_bar">
            {minimumData?.map((item, index) => (
              <div
                className="bar__cards"
                onClick={() =>
                  HandleOpenModal.handleOpenModal(
                    {
                      id: item?.id,
                      visible: true,
                      object_type: item?.object_type,
                      title: item?.title,
                      user_id: item?.user_id,
                      team_id: item?.team_id,
                    },
                    item?.id,
                    modalData,
                    minimumData,
                    setMinimumData,
                    setModalData,
                    dispatch,
                    MinimizeModal
                  )
                }
              >
                {item?.object_type === 3 ? (
                  <img
                    src={scheduleIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === "team" ? (
                  <img
                    src={teamIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}

                {item?.object_type === "profile" ? (
                  <img
                    src={profileIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 9 ? (
                  <img
                    src={tasklistIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 2 ? (
                  <img
                    src={projectIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 1 ? (
                  <img
                    src={projectIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 4 ? (
                  <img
                    src={journalIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 5 ? (
                  <img
                    src={listIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 6 ? (
                  <img
                    src={calendarIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                  {item?.object_type === 8 ? (
                  <img
                    src={notebookIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 10 ? (
                  <img
                    src={calendarIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                {item?.object_type === 11 ? (
                  <img
                    src={emailIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                   {item?.object_type === 12 ? (
                  <img
                    src={textMessageIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                  {item?.object_type === 13 ? (
                  <img
                    src={teamView}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "20px" }}
                  />
                ) : (
                  ""
                )}
                <div>{textTruncate(item?.title, 12)}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default FooterView;
