import * as types from "./constants"

export const getTime = (time, name, url) => {
    return {
        type:types.GET_TIME,
        payload:{time, name, url}
    }
}

export const showTime = (single_time, url) => {
    return {
        type:types.SHOW_TIME,
        payload:{single_time, url}
    }
}

export const createTime = (time, url) => {
    return {
        type:types.CREATE_TIME,
        payload:{time, url
    }}
}

export const updateTime = (time, url) => {
    return {
        type:types.UPDATE_TIME,
        payload:{time, url}
    }
}

export const deleteTime = (time, url) => {
    return {
        type:types.DELETE_TIME,
        payload:{time, url}
    }
}
