import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createCard } from "redux/actions";
import TitleInput from "./TitleInput";
import Description from "./Description";
import Attachment from "./Attachment";
import DueDate from "./DueDate";
import WorkStatus from "./WorkStatus";
import Priority from "./Priority";

function AddCardModal({
  cardData = {},
  title,
  listItem,
  onCancel,
  listId,
  setEnableFocus,
  enableFocus,
  textInput,
  hasTitle,
  hasDescription,
  hasAttachment,
  hasDueDate,
  hasPriority,
  hasWorkStatus,
  url
}) {

  // form
  const [form] = Form.useForm();

  // create priority box state
  const [priorityBox, setPriorityBox] = useState(false);
  //   create work status box state
  const [workStatusBox, setWorkStatusBox] = useState(false);

  //   create priority state
  const [priority, setPriority] = useState("Normal");

  //   create work status state
  const [workStatus, setWorkStatus] = useState(2);

  // create end date state
  const [endDate, setEndDate] = useState("");

  // create priority data state
  const [priorityData] = useState([
    { value: "Low" },
    { value: "Normal" },
    { value: "High" },
    { value: "Urgent" },
  ]);

  //   create handle date change.
  const changeEndDate = (date) => {
    setEndDate(date);
  };

  //   create handle priority change - this will handle priority change
  const changePiriority = (priority) => {
    setPriority(priority);
  };

  //   create handle priority change work status - this will swithc work status data
  const changeWorkStatus = (status) => {
    setWorkStatus(status);
  };

  //   create dispatch work status
  const dispatch = useDispatch();

  //   const user = useSelector((state) => state.AuthStore?.user);
  //   const team_item = useSelector((state) => state.TeamStore?.teams);
  //   const active = team_item?.active;

  //   select work status from redux store.
  const work_status = useSelector(
    (state) => state.WorkStatusStore?.work_status
  );

  //   create base64 state- this state store the image data that is transform to base64
  const [base64, setBase64] = useState([]);

  // create text editor state.
  const [editorState, setDescription] = useState("");

  //   create handle description function - this will enable description state.
  const handleDescription = (editorState) => {
    setDescription(editorState);
  };

  let lists = [];
  cardData?.card_labels?.forEach((r) => {
    const item = r?.name;
    lists.push(item);
  });

  //   handle submit update
  const submitUpdate = (values) => {
    let data = values;
    // data.start_date = startDate;
    data.due_date = endDate;
    data.priority = priority;
    data.description = editorState;
    data.attachments = base64;
    data.team_id = listItem?.team_id === 0 ? 0 : listItem?.team_id;
    data.work_status_id = workStatus;
    data.type = listItem?.type;
    dispatch(createCard(data, url));
    setDescription("");
    form.resetFields();
    onCancel();
    setEndDate("");
    setBase64([]);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    // Accept 5 files only
    fileList = fileList.slice(-5);
    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });
    setBase64(fileList);
  };

  React.useEffect(() => {
    setEnableFocus(true);
    if (enableFocus === true) {
      textInput.current?.focus();
    }
  }, [enableFocus, textInput]);

  return (
    <>
      <Row>
        <Col md={18} className="pr-4 pb-4 pt-3">
          <Form
            name="edit-card-ref"
            form={form}
            layout="vertical"
            onFinish={submitUpdate}
            initialValues={{ board_id: listId }}
          >
            {hasTitle ? (
              <>
                {/* title component start */}
                <TitleInput Form={Form} textInput={textInput} Input={Input} />
                {/* title component end */}
              </>
            ) : (
              ""
            )}

            <Form.Item
              style={{ display: "none" }}
              name="board_id"
              className="mb-0"
            >
              <Input className="board-card-modal input" />
            </Form.Item>
            <Form.Item className="mb-3 mt-2">
              <p>
                List: <span className="font-weight-semibold">{title}</span>
              </p>
            </Form.Item>
            <Divider className="mt-0" />
            {hasDescription ? (
              <>
                {/*description component start */}
                <Description handleDescription={handleDescription} editorState={editorState}/>
                {/* description component end */}
              </>
            ) : (
              ""
            )}

            {hasAttachment ? (
              <>
                {/*  Attactment Component start*/}
                <Attachment
                  Form={Form}
                  normFile={normFile}
                  handleUpload={handleUpload}
                />
                {/* Attachment component end */}
              </>
            ) : (
              ""
            )}

            <Form.Item className="mb-0">
              <Button type="default" htmlType="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="default"
                className="bg-success text-white float-right"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col md={6} className="card__right p-3">
          <div>
            <h5>Settings</h5>
            {hasDueDate ? (
              <>
                {/* due date component start */}
                {/* <DueDate changeEndDate={changeEndDate} endDate={endDate} /> */}
                {/* due date component end */}
              </>
            ) : (
              ""
            )}

            {hasWorkStatus ? (
              <>
                {/* work status component start */}
                <WorkStatus
                  setWorkStatusBox={setWorkStatusBox}
                  work_status={work_status}
                  workStatus={workStatus}
                  workStatusBox={workStatusBox}
                  setWorkStatus={setWorkStatus}
                  changeWorkStatus={changeWorkStatus}
                />
                {/* work status component end */}
              </>
            ) : (
              ""
            )}

            {hasPriority ? (
              <>
                {/* priority component start */}
                <Priority
                  setPriorityBox={setPriorityBox}
                  priority={priority}
                  priorityBox={priorityBox}
                  priorityData={priorityData}
                  setPriority={setPriority}
                  changePiriority={changePiriority}
                />
                {/* priority component end */}
              </>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default AddCardModal;
