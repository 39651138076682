export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAILED = "GET_PROJECTS_FAILED"

export const SHOW_PROJECTS = "SHOW_PROJECTS"
export const SHOW_PROJECTS_SUCCESS = "SHOW_PROJECTS_SUCCESS"
export const SHOW_PROJECTS_FAILED = "SHOW_PROJECTS_FAILED"

export const SHOW_PROJECT_CARD = "SHOW_PROJECT_CARD"
export const SHOW_PROJECT_CARD_SUCCESS = "SHOW_PROJECT_CARD_SUCCESS"
export const SHOW_PROJECT_CARD_FAILED = "SHOW_PROJECT_CARD_FAILED"

export const CREATE_PROJECTS = "CREATE_PROJECTS"
export const CREATE_PROJECTS_SUCCESS = "CREATE_PROJECTS_SUCCESS"
export const CREATE_PROJECTS_FAILED = "CREATE_PROJECTS_FAILED"

export const UPDATE_PROJECTS = "UPDATE_PROJECTS"
export const UPDATE_PROJECTS_SUCCESS = "UPDATE_PROJECTS_SUCCESS"
export const UPDATE_PROJECTS_FAILED = "UPDATE_PROJECTS_FAILED"

export const DELETE_PROJECTS = "DELETE_PROJECTS"
export const DELETE_PROJECTS_SUCCESS = "DELETE_PROJECTS_SUCCESS"
export const DELETE_PROJECTS_FAILED = "DELETE_PROJECTS_FAILED"

