import * as types from "./constants"

const initialStates = {
    journal_board:[],
    single_journal_board:{},
    journal_entries:[],
    single_journal_entry:{},
    loading:false,
    error:null,
    success:false,
}

export const JournalBoardStore = (state = initialStates, action) => {
    //setup journal_board reducers
   switch (action.type) {
       case types.GET_JOURNAL_BOARD :
       return {...state, loading:true}
       case types.GET_JOURNAL_BOARD_SUCCESS :
       return {...state, loading:false, journal_board:action.journal_board}
       case types.GET_JOURNAL_BOARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_JOURNAL_BOARD :
       return {...state}
       case types.SHOW_JOURNAL_BOARD_SUCCESS :
       return {...state, single_journal_board:action.single_journal_board}
       case types.SHOW_JOURNAL_BOARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_JOURNAL_BOARD :
       return {...state, loading:true, success:false}
       case types.CREATE_JOURNAL_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_JOURNAL_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_JOURNAL_BOARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_JOURNAL_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_JOURNAL_BOARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_JOURNAL_BOARD_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_JOURNAL_BOARD_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_JOURNAL_BOARD_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_JOURNAL_BOARD :
       return {...state, loading:true, success:false}
       case types.DELETE_JOURNAL_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_JOURNAL_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}


//setup journal_entries reducers
       case types.GET_JOURNAL_ENTRIES :
       return {...state, loading:true}
       case types.GET_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, journal_entries:action.journal_entries}
       case types.GET_JOURNAL_ENTRIES_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_JOURNAL_ENTRIES :
       return {...state}
       case types.SHOW_JOURNAL_ENTRIES_SUCCESS :
       return {...state, single_journal_entry:action.single_journal_entry}
       case types.SHOW_JOURNAL_ENTRIES_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_JOURNAL_ENTRIES :
       return {...state, loading:true, success:false}
       case types.CREATE_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_JOURNAL_ENTRIES_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_JOURNAL_ENTRIES :
       return {...state, loading:true, success:false}
       case types.UPDATE_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_JOURNAL_ENTRIES_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_JOURNAL_ENTRIES :
       return {...state, loading:true, success:false}
       case types.DELETE_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_JOURNAL_ENTRIES_FAILED :
       return {...state, success:true, loading:false, error:action.error}

           
          
       default:
           return state;
   }
}


export const JournalEntryStore = (state = initialStates, action) => {
    //setup journal_board reducers
   switch (action.type) {
//setup journal_entries reducers
       case types.GET_JOURNAL_ENTRIES :
       return {...state, loading:true}
       case types.GET_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, journal_entries:action.journal_entries}
       case types.GET_JOURNAL_ENTRIES_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_JOURNAL_ENTRIES :
       return {...state}
       case types.SHOW_JOURNAL_ENTRIES_SUCCESS :
       return {...state, single_journal_entry:action.single_journal_entry}
       case types.SHOW_JOURNAL_ENTRIES_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_JOURNAL_ENTRIES :
       return {...state, loading:true, success:false}
       case types.CREATE_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_JOURNAL_ENTRIES_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_JOURNAL_ENTRIES :
       return {...state, loading:true, success:false}
       case types.UPDATE_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_JOURNAL_ENTRIES_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_JOURNAL_ENTRIES_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_JOURNAL_ENTRIES_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_JOURNAL_ENTRIES_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_JOURNAL_ENTRIES_NEXT_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_JOURNAL_ENTRIES :
       return {...state, loading:true, success:false}
       case types.DELETE_JOURNAL_ENTRIES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_JOURNAL_ENTRIES_FAILED :
       return {...state, success:true, loading:false, error:action.error}

           
          
       default:
           return state;
   }
}
