import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { list_api, card_api } from 'services/JournalEntryService';


// Board Redux States
import * as types from './constants';

function* getJournalBoard(action)
{
    const {response, error} = yield call(list_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_JOURNAL_BOARD_SUCCESS, journal_board:response.data});
    } else {
        yield put({type: types.GET_JOURNAL_BOARD_FAILED, error:error});
    }
}

function* showJournalBoard(action)
{
    const {response, error} = yield call(list_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_JOURNAL_BOARD_SUCCESS, single_journal_board:response.data});
    } else {
        yield put({type: types.SHOW_JOURNAL_BOARD_FAILED, error:error});
    }
}

function* createJournalBoard(action)
{
    const {response, error} = yield call(list_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_JOURNAL_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.CREATE_JOURNAL_BOARD_FAILED, error:error});
    }
}

function* updateJournalBoard(action)
{
    const {response, error} = yield call(list_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_JOURNAL_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_JOURNAL_BOARD_FAILED, error:error});
    }
}

function* updateJouranlBoardIndex(action)
{
    const {response, error} = yield call(list_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_JOURNAL_BOARD_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_JOURNAL_BOARD_INDEX_FAILED, error:error});
    }
}

function* deleteJournalBoard(action)
{
    const {response, error} = yield call(list_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_JOURNAL_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_JOURNAL_BOARD_FAILED, error:error});
    }
}


function* getJournalEntry(action)
{
    const {response, error} = yield call(card_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_JOURNAL_ENTRIES_SUCCESS, card:response.data});
    } else {
        yield put({type: types.GET_JOURNAL_ENTRIES_FAILED, error:error});
    }
}

function* showJournalEntry(action)
{
    const {response, error} = yield call(card_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_JOURNAL_ENTRIES_SUCCESS, single_card:response.data});
    } else {
        yield put({type: types.SHOW_JOURNAL_ENTRIES_FAILED, error:error});
    }
}

function* createJournalEntry(action)
{
    const {response, error} = yield call(card_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_JOURNAL_ENTRIES_SUCCESS, card:response.data});
    } else {
        yield put({type: types.CREATE_JOURNAL_ENTRIES_FAILED, error:error});
    }
}

function* updateJournalEntry(action)
{
    const {response, error} = yield call(card_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_JOURNAL_ENTRIES_SUCCESS, card:response.data});
    } else {
        yield put({type: types.UPDATE_JOURNAL_ENTRIES_FAILED, error:error});
    }
}

function* updateJournalEntryIndex(action)
{
    const {response, error} = yield call(card_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_JOURNAL_ENTRIES_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_JOURNAL_ENTRIES_INDEX_FAILED, error:error});
    }
}

function* updateJournalEntryNextIndex(action)
{
    const {response, error} = yield call(card_api.updateNextIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_FAILED, error:error});
    }
}

function* deleteJournalEntry(action)
{
    const {response, error} = yield call(card_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_JOURNAL_ENTRIES_SUCCESS, card:response.data});
    } else {
        yield put({type: types.DELETE_JOURNAL_ENTRIES_FAILED, error:error});
    }
}


function* watchGetJournalBoard() 
{
    yield takeEvery(types.GET_JOURNAL_BOARD, getJournalBoard)
}

function* watchShowJournalBoard() 
{
    yield takeEvery(types.SHOW_JOURNAL_BOARD, showJournalBoard)
}

function* watchCreateJournalBoard() 
{
    yield takeEvery(types.CREATE_JOURNAL_BOARD, createJournalBoard)
}

function* watchUpdateJournalBoard() 
{
    yield takeEvery(types.UPDATE_JOURNAL_BOARD, updateJournalBoard)
}

function* watchUpdateJournalBoardIndex() 
{
    yield takeEvery(types.UPDATE_JOURNAL_BOARD_INDEX, updateJouranlBoardIndex)
}

function* watchDeleteJournalBoard() 
{
    yield takeEvery(types.DELETE_JOURNAL_BOARD, deleteJournalBoard)
}

function* watchUpdateJournalEntryIndex() 
{
    yield takeEvery(types.UPDATE_JOURNAL_ENTRIES_INDEX, updateJournalEntryIndex)
}

function* watchUpdateJournalEntryNextIndex() 
{
    yield takeEvery(types.UPDATE_JOURNAL_ENTRIES_NEXT_INDEX, updateJournalEntryNextIndex)
}


function* watchGetJournalEntry() 
{
    yield takeEvery(types.GET_JOURNAL_ENTRIES, getJournalEntry)
}

function* watchShowJournalEntry() 
{
    yield takeEvery(types.SHOW_JOURNAL_ENTRIES, showJournalEntry)
}

function* watchCreateJournalEntry() 
{
    yield takeEvery(types.CREATE_JOURNAL_ENTRIES, createJournalEntry)
}

function* watchUpdateJournalEntry() 
{
    yield takeEvery(types.UPDATE_JOURNAL_ENTRIES, updateJournalEntry)
}

function* watchDeleteJournalEntry() 
{
    yield takeEvery(types.DELETE_JOURNAL_ENTRIES, deleteJournalEntry)
}


function* JournalBoardSaga(){
    yield all([
        fork(watchGetJournalBoard),
        fork(watchShowJournalBoard),
        fork(watchCreateJournalBoard),
        fork(watchUpdateJournalBoard),
        fork(watchUpdateJournalBoardIndex),
        fork(watchDeleteJournalBoard),
        fork(watchGetJournalEntry),
        fork(watchShowJournalEntry),
        fork(watchCreateJournalEntry),
        fork(watchUpdateJournalEntry),
        fork(watchDeleteJournalEntry),
        fork(watchUpdateJournalEntryIndex),
        fork(watchUpdateJournalEntryNextIndex),
    ])
}

export default JournalBoardSaga;