import React from 'react'
import { Avatar, Tooltip } from 'antd';
import { labels, membersDetail } from './ScrumboardData';
import { useSelector } from 'react-redux';
import AvatarStatus from 'components/shared-components/AvatarStatus';

const createUID = len => {
	const buf = [],
		chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
		charlen = chars.length,
		length = len || 32;
			
	for (let i = 0; i < length; i++) {
		buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
	}
	return buf.join('');
}

export const modalModeTypes = number => {
	switch (number) {
		case 0:
			return 'ADD_CARD';
		case 1:
			return 'EDIT_CARD';
		case 2:
			return 'ADD_BOARD';
		default:
			return '';
	}
}

export const getLabelsColor = label => {
	if(label) {
		const color = labels.filter(elm => label === elm.label)[0].color
		return color
	}
	return
}

export const getMember = id => {
	if(id) {
		const member = membersDetail.filter(elm => id === elm.id)[0]
		if(member) {
			return <Avatar src={member.img}></Avatar>
		}
	}
	return
}

export const getCover = (id, attachments) => {
	if(id) {
		const cover = attachments.filter(elm => id === elm.id)[0].name
		return cover
	}
	return
}

export const AssigneeUserAvatar = ({id, size = 20, name, chain}) => {
	const state = useSelector(state => state.AuthStore?.user);
	const user = state?.user;
	const teams = state?.all_teams;
	const active = state?.active_workspace;

	const team_members = teams.filter((x) => x.id === active?.team_id)?.[0]?.team_members;


	if(id) {
		const member = team_members?.filter(elm => id === elm.user_id)[0]
		
		if(member) {
			return (
				<div className={`d-flex ${chain? 'ml-n2': ''}`}> 
					<Tooltip title={name? '' : member?.fullname}>
						{member?.photo !== null ?
							<Avatar 
							className="cursor-pointer mt-3" 
							size={size} 
							src={member?.photo}
							style={chain?{border: '2px solid #fff'}: {}}
						>
						</Avatar>
						:
							<AvatarStatus 
							className="cursor-pointer" 
							size={size} 
							type="blue"
							text={member?.initials}
							style={chain?{border: '2px solid #fff'}: {}}
						>
						</AvatarStatus>
						}
					
					</Tooltip>
					{name? <span className="mb-0 ml-2 font-weight-semibold">{member?.fullname}</span> : null}
				</div>
			)
		}
	}
	return null
}


export const AssigneeActiveUserAvatar = ({id, size = 20, name, chain}) => {
	const state = useSelector(state => state.AuthStore?.user);
	const user = state?.user;

		if(user) {
			return (
				<div className={`d-flex ${chain? 'ml-n2': ''}`}> 
					<Tooltip title={name? '' : user?.fullname}>
						{user?.photo !== null ?
							<Avatar 
							className="cursor-pointer mt-3" 
							size={size} 
							src={user?.photo}
							style={chain?{border: '2px solid #fff'}: {}}
						>
						</Avatar>
						:
							<AvatarStatus 
							className="cursor-pointer" 
							size={size} 
							type="blue"
							text={user?.initials}
							style={chain?{border: '2px solid #fff'}: {}}
						>
						</AvatarStatus>
						}
					
					</Tooltip>
					{name? <span className="mb-0 ml-2 font-weight-semibold">{user?.fullname}</span> : null}
				</div>
			)
		}
	
	return null
}


export const AssigneeMembersAvatar = ({id, size = 20, name, chain, teamUsers}) => {
	
	if(id) {
		const member = teamUsers?.filter(elm => id === elm.id)[0]
		
		if(member) {
			return (
				<div className={`d-flex ${chain? 'ml-n2': ''}`}> 
					<Tooltip title={name? '' : member?.fullname}>
						{member?.photo !== null ?
							<Avatar 
							className="cursor-pointer" 
							size={size} 
							src={member?.photo}
							style={chain?{border: '2px solid #fff'}: {}}
						>
						</Avatar>
						:
							<AvatarStatus 
							className="cursor-pointer" 
							size={size} 
							type="blue"
							text={`${member?.initials}`}
							style={chain?{border: '2px solid #fff'}: {}}
						>
						</AvatarStatus>
						}
					
					</Tooltip>
					{name? <span className="mb-0 ml-2 font-weight-semibold">{member?.fullname}</span> : null}
				</div>
			)
		}
	}
	return null
}


export const AssigneeAvatar = ({id, size = 20, name, chain}) => {
	if(id) {
		const member = membersDetail.filter(elm => id === elm.id)[0]
		if(member) {
			return (
				<div className={`d-flex ${chain? 'ml-n2': ''}`}> 
					<Tooltip title={name? '' : member.name}>
						<Avatar 
							className="cursor-pointer" 
							size={size} 
							src={member.img}
							style={chain?{border: '2px solid #fff'}: {}}
						>
						</Avatar>
					</Tooltip>
					{name? <span className="mb-0 ml-2 font-weight-semibold">{member.name}</span> : null}
				</div>
			)
		}
	}
	return null
}

export const createCardObject = () => {
	return {
		id: createUID(10),
		name: '',
		description: '',
		cover: '',
		members: [],
		labels: [],
		attachments: [],
		checklists: [],
		comments: [],
		'dueDate': null
	}
}

export const createCommentObject = () => {
	return {
		id: createUID(10),
		name: 'Eileen Horton',
		src: "/img/avatars/thumb-1.jpg",
		message: '',
		date: new Date()
	}
}