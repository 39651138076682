import React, { useCallback, useContext, useState } from "react";
import { ScrumboardProvider, ScrumboardContext } from "./ScrumboardContext";
import Board from "./Board";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PlusOutlined } from "@ant-design/icons";
import {  Button, Form, Input, Select } from "antd";
import { modalModeTypes } from "./utils";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import {
  createCard,
  createBoard,
  getBoard,
  updateBoardIndex,
  updateCard,
  updateCardIndex,
  updateCardNextIndex,
  showProject,
  updateProject,
  getWorkStatus,
  getTeam,
} from "redux/actions";
import Modal from "antd/lib/modal/Modal";
import { useParams } from "react-router-dom";
import AddBoard from "./actions/AddBoard";
import ModalForm from "./DraggableCard/ModalForm";
import SimpleBar from 'simplebar-react';

const { Option } = Select;

const ScrumboardWrapper = (props) => {
  const { modal, cardData, currentListId, modalMode, updateModal } =
    useContext(ScrumboardContext);

    const [isCancelled, setIsCancelled] = useState(false);

    const {id } = props;

  let param = useParams();

  const dispatch = useDispatch();

  const assign_user_success = useSelector((state) => state.AssignUserStore?.success);
  const comments_success = useSelector((state) => state.CommentStore?.success);
  const card_success = useSelector((state) => state.CardStore?.success)
  const attachment_success = useSelector((state) => state.AttachmentStore?.success);


  React.useEffect(() => { 
    if(!isCancelled){
      if (props?.id !== undefined) {
        const fetchProject = async() => {
          await  dispatch(showProject(props?.id));
        }
        fetchProject();
      }
    }   

    return () => {
      setIsCancelled(true);
    };

  }, [props?.id]);


  React.useEffect(() => { 

    if(!isCancelled){
      if (assign_user_success === true || comments_success === true || card_success === true || attachment_success === true) {
        const fetchProject = async() => {
           await  dispatch(showProject(props?.id));
        }
        fetchProject();
      }
    }else{
      if (assign_user_success === true || comments_success === true || card_success === true || attachment_success === true) {
        const fetchProject = async() => {
           await  dispatch(showProject(props?.id));
        }
        fetchProject();
      }
    }   

    return () => {
      setIsCancelled(true);
    };
   
}, [assign_user_success, card_success, comments_success, attachment_success]);


  const state = useSelector((state) => state.ProjectStore);
  const success = useSelector((state) => state.BoardStore?.success);
  //   const cardList = useSelector((state) => state.CardStore);
  const datas = state?.[id]?.board;
  const project = state?.[id]?.project;
  const team_members = state?.[id]?.team_members;
  const project_success = state?.success;
  const [lists, setLists] = useState([]);

  React.useEffect(() => {
    setLists(datas);
  }, [datas]);

  const [cardContent, setCardContent] = useState({});

  const changeArrayData = useCallback(
    () => {
      const convertArrayToObject = (array, key) =>
      array?.reduce(
        (obj, item) => ({
          ...obj,
          [item["title"]]: item?.card,
        }),
        {}
      );
    const item = convertArrayToObject(datas);
    setCardContent(item);
    },
    [datas],
  )
  React.useEffect(() => {
    changeArrayData();
  }, [datas]);

  const onDragEnd = (results) => {
    const { destination, source, type } = results;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "COLUMN") {
      const result = Array.from(lists);

      const [removed] = result.splice(source.index, 1);

      result.splice(destination.index, 0, removed);

      let order = [];
      result.forEach((x, index) => {
        const item = { id: x.id, index: index };
        order.push(item);
      });

      dispatch(updateBoardIndex({ order: order }, "project-board"));
      setLists(result);

      return;
    }

    const home = cardContent[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      const card = Array.from(home);
      const [removed] = card.splice(source.index, 1);
      card.splice(destination.index, 0, removed);

      let order = [];
      card.forEach((x, index) => {
        const item = { id: x.id, index: index };
        order.push(item);
      });

      dispatch(updateCardIndex({ order: order }, "project-card"));
      setCardContent({ ...cardContent, [destination.droppableId]: card });
      return;
    }

    const current = [...cardContent[source.droppableId]];
    const next = [...cardContent[destination.droppableId]];
    const target = current[source.index];

    current.splice(source.index, 1);
    next.splice(destination.index, 0, target);

    const result = {
      ...cardContent,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };

    let order = [];
    next.forEach((x, index) => {
      const item = { id: x.id, index: index };
      order.push(item);
    });

    const data = {
      title: destination.droppableId,
      order: order,
      card_id: target.id,
      object_id:props?.id
    };

    dispatch(updateCardNextIndex(data, "project-card"));

    setCardContent(result);
    return;
  };

  const onCloseModal = () => {
    updateModal(false);
  };

  const onModalSubmit = (values, mode) => {
    if (mode === modalModeTypes(2)) {
      updateModal(false);
      dispatch(createBoard(values, "project-boards"));
    }

    if (mode === modalModeTypes(1)) {
      dispatch(updateCard(values, "project-cards"));
      // console.log(values);
      updateModal(true);
    }

    if (mode === modalModeTypes(0)) {
      updateModal(false);
      dispatch(createCard(values, "project-cards"));
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {props.containerHeight ? (
          <div className="scrumboard">
            <BoardWrapper
              cards={cardContent}
              lists={lists}
              success={success}
              id={props?.id}
              listItem={project}
              list_success={project_success}
              {...props}
            />
          </div>
        ) : (
          <BoardWrapper
            {...props}
            cards={cardContent}
            lists={lists}
            success={success}
            id={props?.id}
            listItem={project}
            list_success={project_success}
          />
        )}
      </DragDropContext>
      <ModalForm
        visible={modal}
        onClose={() => onCloseModal()}
        onModalSubmit={(values, modalMode) => onModalSubmit(values, modalMode)}
        modalMode={modalMode}
        cardData={cardData}
        listId={currentListId}
        listItem={project}
        object_team_members={team_members}
      />
    </>
  );
};

const BoardWrapper = ({
  lists,
  cards,
  success,
  containerHeight,
  useClone,
  isCombineEnabled,
  withScrollableColumns,
  list_success,
  listItem,
  id,
}) => {

  const dispatch = useDispatch();
  const [isCancelled, setIsCancelled] = useState(false);
  React.useEffect(() => {
    if(!isCancelled) {
      if (success === true) {
        const getBoardData = async()=> {
            await dispatch(getBoard("project-boards"));
        }
        getBoardData();
      }
    }else{
      if (success === true) {
        const getBoardData = async()=> {
            await dispatch(getBoard("project-boards"));
        }
        getBoardData();
      }
    }
    
    return () => {
      setIsCancelled(true);
    }
  }, [success]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  


  const [isBoardModalVisible, setIsBoardModalVisible] = useState(false);

  const showBoardModal = () => {
    setIsBoardModalVisible(true);
  };

  const handleBoardOk = () => {
    setIsBoardModalVisible(false);
  };

  const handleBoardCancel = () => {
    setIsBoardModalVisible(false);
  };

  const onAddBoardModal = () => {
    showBoardModal();
    // dispatch(createBoard(data, "project-boards"));
    // console.log(data);
  };

  const onFinish = (values) => {
    dispatch(updateProject(values));
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        break;
      case 2:
        showModal();
        break;
      case 3:
        break;
      default:
    }
  };

  const user_state = useSelector((state) => state.AuthStore?.user);
  const teams = user_state?.all_teams;
  const user = user_state;

  React.useEffect(() => {
    if(!isCancelled){
      if (success === true || list_success === true) {
        const projectData = async() => {
         await dispatch(showProject(id));
        }
        projectData();
      }
    }else{
      if (success === true || list_success === true) {
        const projectData = async() => {
         await dispatch(showProject(id));
        }
        projectData();
      }
    }
    
    return () => {
      setIsCancelled(true);
    }
  }, [success, list_success]);

  const team_id = teams?.filter((x) => x.id === listItem?.team_id);

  const scrollRef = React.useRef(null);
  // const handleScroll = (values) => {
  //    console.log(scrollRef.current);
  //    return 
  // }

  const [scroll, setScroll] = React.useState(100);

  React.useEffect(() => {
    scrollRef.current.scrollLeft(scroll);
  }, [scrollRef, team_id, lists, cards, scroll])

  return (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping={containerHeight}
      isCombineEnabled={isCombineEnabled}
    >
      {(provided) => (
        <div
          className="scrumboard"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="scrumboard-header">
          <Modal
              title="New Objective"
              footer={null}
              visible={isBoardModalVisible}
              onOk={handleBoardOk}
              onCancel={handleBoardCancel}
            >
              <AddBoard id={id}  onCancel={handleBoardCancel}/>
              </Modal>
            
            <Modal
              title="Edit List"
              footer={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form
                name="header-form"
                initialValues={{
                  id: listItem?.id,
                  team_id: listItem?.team_id,
                  title: listItem?.title,
                  user_id: user?.id,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item label="List Title" name="title">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="user_id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Select Team" name="team_id" className="mt-1">
                  <Select className="select-box" placeholder="Select Team">
                    {teams?.map((elm, i) => (
                      <Option key={i} value={elm?.id}>
                       {elm?.team_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item className="mb-0">
                  <Button
                    type="default"
                    htmlType="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="default"
                    className="bg-success text-white float-right"
                    htmlType="submit"
                  >
                    Save Changes
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            {/* <h3 onClick={handleClick}>{listItem?.title}</h3> */}
            {/* <h4 className="d-flex justify-content-between">
              <span className="mr-2 d-block">
                <span>Team: </span>{" "}
                <span className="text-success">
                {team_id?.[0]?.team_name} / {listItem?.creator}
                </span>
              </span>
            </h4> */}
            {/* <div className="text-right">
              <div className="d-flex align-items-center">
                {memberIds.map((member, i) =>
                  i < 4 ? (
                    <AssigneeAvatar key={member} id={member} size={30} chain />
                  ) : null
                )}
                <Avatar className="ml-n2" size={30}>
                  <span className="text-gray font-weight-semibold font-size-base">
                    +9
                  </span>
                </Avatar>
              </div>
            </div> */}
          </div>

          <Scrollbars className="scrumboard-body"
           ref={scrollRef}
          //  onScroll={handleScroll}
          >
            {lists?.map((item, index) => (
              <Board
                key={item?.title}
                id={item?.id}
                idString={item?.id?.toString()}
                index={index}
                title={item?.title}
                contents={cards[item?.title]}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                useClone={useClone}
                listItem={listItem}
                setScroll={setScroll}
              />
            ))}
            {provided.placeholder}

            {listItem && listItem?.object_type === 1 ? 
            ""
            : 
            <div className="board-column add">
            <div className="board-title" onClick={() => onAddBoardModal()}>
              <h4 className="mb-0">
                <PlusOutlined />
                <span>Add Objective</span>
              </h4>
            </div>
          </div>
            }
           
          </Scrollbars>
        </div>
      )}
    </Droppable>
  );
};

const Scrumboard = (props) => {
  return (
    <ScrumboardProvider>
      <ScrumboardWrapper {...props} />
    </ScrumboardProvider>
  );
};

export default React.memo(Scrumboard);
