export const GET_BOARD = "GET_BOARD"
export const GET_BOARD_SUCCESS = "GET_BOARD_SUCCESS"
export const GET_BOARD_FAILED = "GET_BOARD_FAILED"

export const SHOW_BOARD = "SHOW_BOARD"
export const SHOW_BOARD_SUCCESS = "SHOW_BOARD_SUCCESS"
export const SHOW_BOARD_FAILED = "SHOW_BOARD_FAILED"

export const CREATE_BOARD = "CREATE_BOARD"
export const CREATE_BOARD_SUCCESS = "CREATE_BOARD_SUCCESS"
export const CREATE_BOARD_FAILED = "CREATE_BOARD_FAILED"

export const UPDATE_BOARD = "UPDATE_BOARD"
export const UPDATE_BOARD_SUCCESS = "UPDATE_BOARD_SUCCESS"
export const UPDATE_BOARD_FAILED = "UPDATE_BOARD_FAILED"

export const UPDATE_BOARD_INDEX = "UPDATE_BOARD_INDEX"
export const UPDATE_BOARD_INDEX_SUCCESS = "UPDATE_BOARD_INDEX_SUCCESS"
export const UPDATE_BOARD_INDEX_FAILED = "UPDATE_BOARD_INDEX_FAILED"


export const DELETE_BOARD = "DELETE_BOARD"
export const DELETE_BOARD_SUCCESS = "DELETE_BOARD_SUCCESS"
export const DELETE_BOARD_FAILED = "DELETE_BOARD_FAILED"


export const GET_CARD = "GET_CARD"
export const GET_CARD_SUCCESS = "GET_CARD_SUCCESS"
export const GET_CARD_FAILED = "GET_CARD_FAILED"

export const SHOW_CARD = "SHOW_CARD"
export const SHOW_CARD_SUCCESS = "SHOW_CARD_SUCCESS"
export const SHOW_CARD_FAILED = "SHOW_CARD_FAILED"

export const CREATE_CARD = "CREATE_CARD"
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS"
export const CREATE_CARD_FAILED = "CREATE_CARD_FAILED"

export const UPDATE_CARD = "UPDATE_CARD"
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS"
export const UPDATE_CARD_FAILED = "UPDATE_CARD_FAILED"

export const DELETE_CARD = "DELETE_CARD"
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS"
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED"


export const UPDATE_CARD_INDEX = "UPDATE_CARD_INDEX"
export const UPDATE_CARD_INDEX_SUCCESS = "UPDATE_CARD_INDEX_SUCCESS"
export const UPDATE_CARD_INDEX_FAILED = "UPDATE_CARD_INDEX_FAILED"

export const UPDATE_CARD_NEXT_INDEX = "UPDATE_CARD_NEXT_INDEX"
export const UPDATE_CARD_NEXT_INDEX_SUCCESS = "UPDATE_CARD_NEXT_INDEX_SUCCESS"
export const UPDATE_CARD_NEXT_INDEX_FAILED = "UPDATE_CARD_NEXT_INDEX_FAILED"