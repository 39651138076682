import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/projects?${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message);
            // if(error.response.data.message === "Unauthenticated."){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear(); 
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/projects/${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },

    showProjectCard(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .get(`/project-cards/${data}`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },

    store(data){
     
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/projects",data.projects)
          .then((response) => {
            // successNotify(response.data.message);
      
            message.success(response.data.message.message, 4);
            // window.open(`/project/${response.data.data.id}`, "_blank", "titlebar=no,toolbar=no,fullscreen=yes")
            // data.history.push(``);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/projects/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    destroy(data){
    
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/projects/${data.project}`)
          .then((response) => {
            message.success(response.data.message, 2);
            
            if(response.status === 200){
              data.history.push('/app/project');
            }
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
}

