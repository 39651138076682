export const REGISTER_DEVICE = "REGISTER_DEVICE"
export const REGISTER_DEVICE_SUCCESS = "REGISTER_DEVICE_SUCCESS"
export const REGISTER_DEVICE_FAILED = "REGISTER_DEVICE_FAILED"

export const SHOW_DEVICE = "SHOW_DEVICE"
export const SHOW_DEVICE_SUCCESS = "SHOW_DEVICE_SUCCESS"
export const SHOW_DEVICE_FAILED = "SHOW_DEVICE_FAILED"

export const GET_SMS_MESSAGES = "GET_SMS_MESSAGES"
export const GET_SMS_MESSAGES_SUCCESS = "GET_SMS_MESSAGES_SUCCESS"
export const GET_SMS_MESSAGES_FAILED = "GET_SMS_MESSAGES_FAILED"

export const SHOW_SMS_MESSAGES = "SHOW_SMS_MESSAGES"
export const SHOW_SMS_MESSAGES_SUCCESS = "SHOW_SMS_MESSAGES_SUCCESS"
export const SHOW_SMS_MESSAGES_FAILED = "SHOW_SMS_MESSAGES_FAILED"

export const SEND_SMS_MESSAGES = "SEND_SMS_MESSAGES"
export const SEND_SMS_MESSAGES_SUCCESS = "SEND_SMS_MESSAGES_SUCCESS"
export const SEND_SMS_MESSAGES_FAILED = "SEND_SMS_MESSAGES_FAILED"

