import { Divider, Modal, Card, Tag, Row, Col } from "antd";
import AvatarStatus from "components/shared-components/AvatarStatus";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showUser, MinimizeModal } from "redux/actions";
import tasklistIcon from "../../../assets/icon/tasklist.png";
import calendarIcon from "../../../assets/icon/calendar.png";
import Draggable from 'react-draggable';
import useMinimizeData from "hooks/useMinimizeData";
import useModelData from "hooks/useModelData";
import { HandleOpenModal } from "components/util-components/DragableModel/HandleOpenModal";
import DragModal from "components/util-components/DragableModel/DragModal";

function TeamMembers() {
  const state = useSelector((state) => state.TeamStore?.teams);
  const user_data = useSelector((state) => state.AuthStore?.single_user);
  const user = user_data?.user;
  const objects = user_data?.object;

  const dispatch = useDispatch();

  
  const [teamMembers, setTeamMembers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { modalData, setModalData } = useModelData();
  const { minimumData, setMinimumData } = useMinimizeData();
  const minimizeData = useSelector((state) => state.MinimizeModalStore?.modal);

  const showModal = (id) => {
    setIsModalVisible(true);
    dispatch(showUser(id));
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  React.useEffect(() => {
    if(state !== undefined){
      const active_team = state?.active_workspace;
      const teams = state?.all_teams;
     
      const team_members = teams?.filter(
        item=> active_team?.team_id === item.id
      )?.[0]?.team_members;
      console.log(team_members)
      setTeamMembers(team_members);
    }else{
      return;
    }

  }, [state, objects, modalData])

  const [disable, setDisable] = React.useState(true);
  const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <div className="container">
          <DragModal
        modalData={modalData}
        setModalData={setModalData}
        minimumData={minimumData}
        setMinimumData={setMinimumData}
        HandleOpenModal={HandleOpenModal}
        zIndex={999}
      />
      <Modal
    //    width={}
       maskClosable={false}
       mask={false}
       footer={null}
       visible={isModalVisible}
       onOk={handleOk}
       onCancel={handleCancel}
       className="draggable__modal_item"
       title={
         <div
           style={{
             width: '100%',
             cursor: 'move',
           }}
           onMouseOver={() => {
             if (disable) {
               setDisable(false);
             }
           }}
           onMouseOut={() => {
            setDisable(true);
           }}
           onFocus={() => {}}
           onBlur={() => {}}
           // end
         >
       User Profile
         </div>
       }

       modalRender={modal => (
         <Draggable
           disabled={disable}
           bounds={bounds}
          
           onStart={(event, uiData) => onStart(event, uiData)}
         >
           <div ref={draggleRef}>{modal}</div>
         </Draggable>
       )}
      >
        <div className="user-profile-box">
          {user?.photo !== null ? (
            <AvatarStatus src={user?.photo} size={100} />
          ) : (
            <AvatarStatus text={user.initials} type="blue" size={100} />
          )}

          <h4>
            {user?.firstname} {user?.lastname}
          </h4>
          <div className="tag-view-box">
            <Tag style={{ fontSize: "12px" }}>Email: {user?.email}</Tag>
            <Tag style={{ fontSize: "12px" }}>
              Phone Number:{" "}
              {user?.phone_number !== null ? user?.phone_number : " - "}
            </Tag>
          </div>
        </div>

        <Divider />
        <Row gutter={20}>
         
            {
                objects?.map((item, index) => 
                <Col md={12} key={index} >
                <Card
                className="home__card"
                bordered={true}
                style={{ width: "100%", textAlign: "center" }}
                onClick={(e) => {
                    e.preventDefault();
                    handleCancel();
                    HandleOpenModal.handleOpenModal(
                      {
                        id: item?.id,
                        visible: true,
                        object_type: item?.object_type,
                        title: item?.title,
                        user_id: item?.user_id,
                        team_id: item?.team_id,
                      },
                      item?.id,
                      modalData,
                      minimumData,
                      setMinimumData,
                      setModalData,
                      dispatch,
                      MinimizeModal
                    );
                  }}
              >
                  {item?.object_type === 9 ? (
                        <img
                        src={tasklistIcon}
                        alt="icon"
                        className="img-fluid"
                        style={{ width: "80px" }}
                      />
                  ) : (
                    <img
                    src={calendarIcon}
                    alt="icon"
                    className="img-fluid"
                    style={{ width: "80px" }}
                  />
                  )}
           
                <h5 className="mt-2">{item?.title === "Calendar" ? <>{item?.title} View</>: item?.title}</h5>
              </Card>
              </Col>
                )
            }
         
         
{/* 
          <Col md={12}>
            <Card
              className="home__card"
              bordered={true}
              style={{ width: "100%", textAlign: "center" }}
              // onClick={handleCreateList}
            >
              <img
                src={calendarIcon}
                alt="icon"
                className="img-fluid"
                style={{ width: "80px" }}
              />
              <h5 className="mt-2">Calendar View</h5>
            </Card>
          </Col> */}
        </Row>
      </Modal>
      <div className="team-members-view-box">
        {teamMembers?.map((data, index) => (
          <div
            className="team-members-card"
            key={index}
            onClick={() => showModal(data?.user_id)}
          >
            {data?.photo !== null ? (
              <AvatarStatus src={data?.photo} size={100} />
            ) : (
              <AvatarStatus text={data.initials} type="blue" size={100} />
            )}

            <h4>
              {data?.firstname} {data?.lastname}
            </h4>
            {data?.team_role === 1 ? (
              <Tag color="blue">Team Admin</Tag>
            ) : data?.team_role === 2 ? (
              <Tag color="gold">Team Manager</Tag>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeamMembers;
