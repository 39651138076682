import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/TaskListService';


// Board Redux States
import * as types from './constants';

function* getTaskList(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_TASK_LISTS_SUCCESS, task_list:response.data});
    } else {
        yield put({type: types.GET_TASK_LISTS_FAILED, error:error});
    }
}

function* showTaskList(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_TASK_LISTS_SUCCESS, single_task_list:response.data});
    } else {
        yield put({type: types.SHOW_TASK_LISTS_FAILED, error:error});
    }
}


function* showTaskListCard(action)
{
    const {response, error} = yield call(api.showTaskListCard, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_TASK_LIST_CARD_SUCCESS, task_list_card:response.data});
    } else {
        yield put({type: types.SHOW_TASK_LIST_CARD_FAILED, error:error});
    }
}

function* createTaskList(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_TASK_LISTS_SUCCESS,});
    } else {
        yield put({type: types.CREATE_TASK_LISTS_FAILED, error:error});
    }
}

function* updateTaskList(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_TASK_LISTS_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_TASK_LISTS_FAILED, error:error});
    }
}

function* deleteTaskList(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_TASK_LISTS_SUCCESS, });
    } else {
        yield put({type: types.DELETE_TASK_LISTS_FAILED, error:error});
    }
}



function* getTaskListCheckList(action)
{
    const {response, error} = yield call(api.checkListIndex, action.payload)
    if(response)
    {
        yield put({type: types.GET_TASK_LIST_CHECK_LIST_SUCCESS, project_check_list:response.data});
    } else {
        yield put({type: types.GET_TASK_LIST_CHECK_LIST_FAILED, error:error});
    }
}

function* showTaskListCheckList(action)
{
    const {response, error} = yield call(api.checkListShow, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_TASK_LIST_CHECK_LIST_SUCCESS, single_project:response.data});
    } else {
        yield put({type: types.SHOW_TASK_LIST_CHECK_LIST_FAILED, error:error});
    }
}

function* createTaskListCheckList(action)
{
    const {response, error} = yield call(api.checkListStore, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_TASK_LIST_CHECK_LIST_SUCCESS,});
    } else {
        yield put({type: types.CREATE_TASK_LIST_CHECK_LIST_FAILED, error:error});
    }
}

function* updateTaskListCheckList(action)
{
    const {response, error} = yield call(api.checkListUpdate, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_TASK_LIST_CHECK_LIST_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_TASK_LIST_CHECK_LIST_FAILED, error:error});
    }
}

function* deleteTaskListCheckList(action)
{
    const {response, error} = yield call(api.checkListDestroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_TASK_LIST_CHECK_LIST_SUCCESS, });
    } else {
        yield put({type: types.DELETE_TASK_LIST_CHECK_LIST_FAILED, error:error});
    }
}


function* watchGetTaskListCheckList() 
{
    yield takeEvery(types.GET_TASK_LIST_CHECK_LIST, getTaskListCheckList)
}

function* watchShowTaskListCheckList() 
{
    yield takeEvery(types.SHOW_TASK_LIST_CHECK_LIST, showTaskListCheckList)
}

function* watchShowTaskListCard() 
{
    yield takeEvery(types.SHOW_TASK_LIST_CARD, showTaskListCard)
}

function* watchCreateTaskListCheckList() 
{
    yield takeEvery(types.CREATE_TASK_LIST_CHECK_LIST, createTaskListCheckList)
}

function* watchUpdateTaskListCheckList() 
{
    yield takeEvery(types.UPDATE_TASK_LIST_CHECK_LIST, updateTaskListCheckList)
}

function* watchDeleteTaskListCheckList() 
{
    yield takeEvery(types.DELETE_TASK_LIST_CHECK_LIST, deleteTaskListCheckList)
}



function* watchGetTaskList() 
{
    yield takeLatest(types.GET_TASK_LISTS, getTaskList)
}

function* watchShowTaskList() 
{
    yield takeEvery(types.SHOW_TASK_LISTS, showTaskList)
}

function* watchCreateTaskList() 
{
    yield takeEvery(types.CREATE_TASK_LISTS, createTaskList)
}

function* watchUpdateTaskList() 
{
    yield takeEvery(types.UPDATE_TASK_LISTS, updateTaskList)
}

function* watchDeleteTaskList() 
{
    yield takeEvery(types.DELETE_TASK_LISTS, deleteTaskList)
}




function* taskListSaga(){
    yield all([
        fork(watchGetTaskList),
        fork(watchShowTaskList),
        fork(watchCreateTaskList),
        fork(watchUpdateTaskList),
        fork(watchDeleteTaskList),
         fork(watchShowTaskListCard),

        fork(watchGetTaskListCheckList),
        fork(watchShowTaskListCheckList),
        fork(watchCreateTaskListCheckList),
        fork(watchUpdateTaskListCheckList),
        fork(watchDeleteTaskListCheckList),

    ])
}

export default taskListSaga;