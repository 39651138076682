import * as types from "./constants"

const initialStates = {
    work_status:[],
    single_work_status:{},
    loading:false,
    error:null,
    success:false,
}

 const workStatusStore = (state = initialStates, action) => {
    //setup list_items reducers
   switch (action.type) {
       case types.GET_WORK_STATUS :
       return {...state, loading:true}
       case types.GET_WORK_STATUS_SUCCESS :
       return {...state, loading:false, work_status:action.work_status}
       case types.GET_WORK_STATUS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_WORK_STATUS :
       return {...state}
       case types.SHOW_WORK_STATUS_SUCCESS :
       return {...state, single_work_status:action.single_work_status}
       case types.SHOW_WORK_STATUS_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_WORK_STATUS :
       return {...state, loading:true, success:false}
       case types.CREATE_WORK_STATUS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_WORK_STATUS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_WORK_STATUS :
       return {...state, loading:true, success:false}
       case types.UPDATE_WORK_STATUS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_WORK_STATUS_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_WORK_STATUS :
       return {...state, loading:true, success:false}
       case types.DELETE_WORK_STATUS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_WORK_STATUS_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default workStatusStore
