import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/AttachmentService';

import * as types from './constants';

function* updateAttachment(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_ATTACHMENTS_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_ATTACHMENTS_FAILED, error:error});
    }
}

function* updateAttachmentFromCard(action)
{
    const {response, error} = yield call(api.updateAttachmentFromCard, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_ATTACHMENT_FROM_CARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_ATTACHMENT_FROM_CARD_FAILED, error:error});
    }
}

function* deleteAttachment(action)
{
    const {response, error} = yield call(api.delete, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_ATTACHMENTS_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_ATTACHMENTS_FAILED, error:error});
    }
}


function* watchUpdateAttachment() 
{
    yield takeEvery(types.UPDATE_ATTACHMENTS, updateAttachment)
}

function* watchDeleteAttachment() 
{
    yield takeEvery(types.DELETE_ATTACHMENTS, deleteAttachment)
}

function* watchUpdateAttachmentFromCard() 
{
    yield takeEvery(types.UPDATE_ATTACHMENT_FROM_CARD, updateAttachmentFromCard)
}

function* attachmentSaga(){
    yield all([
        fork(watchUpdateAttachment),
        fork(watchDeleteAttachment),
        fork(watchUpdateAttachmentFromCard),
    ])
}

export default attachmentSaga;