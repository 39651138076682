import * as types from "./constants"

export const getProjectCheckList = (project_check_list) => {
    return {
        type:types.GET_PROJECT_CHECK_LIST,
        payload:project_check_list
    }
}

export const showProjectCheckList = (single_project_check_list) => {
    return {
        type:types.SHOW_PROJECT_CHECK_LIST,
        payload:single_project_check_list
    }
}

export const createProjectCheckList = (project_check_list, history) => {
    return {
        type:types.CREATE_PROJECT_CHECK_LIST,
        payload:{project_check_list, history
    }}
}

export const updateProjectCheckList = (project_check_list) => {
    return {
        type:types.UPDATE_PROJECT_CHECK_LIST,
        payload:project_check_list
    }
}

export const deleteProjectCheckList = (project_check_list, history) => {
    return {
        type:types.DELETE_PROJECT_CHECK_LIST,
        payload:{project_check_list, history}
    }
}