import * as types from "./constants"

export const  allNotification = (notification) => 
{
    return {
        type:types.GET_NOTIFICATION,
        payload:notification
    }
}

export const readNotification = (notification) => 
{
    return {
        type:types.READ_NOTIFICATION,
        payload:notification
    }
}

export const readAllNotification = (notification) => 
{
    return {
        type:types.READ_ALL_NOTIFICATION,
        payload:notification
    }
}