import React, { useState, useEffect, useCallback } from "react";
import {
  EditOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  createProjectCheckList,
  deleteProjectCheckList,
  showProjectCard,
  updateProjectCheckList,
} from "redux/actions";

import AvatarStatus from "../AvatarStatus";

import { useDispatch, useSelector } from "react-redux";

import { Input, Button, Row, Col, Checkbox, Popconfirm, Switch } from "antd";

function Checklist(props) {
  // call dispatch function
  const dispatch = useDispatch();

  //   destructure props data
  const { cardData } = props;

  //   create checklist number to enable the checklist switch
  const [checkNumber, setCheckNumber] = useState(0);

  //   create checklist title state
  const [checkListTitle, setCheckListTilte] = useState("");

  // create a enable checklist state
  const [enableCheckList, setEnableCheckList] = useState(true);

  // create oncancel popover function
  const cancel = useCallback((e) => {}, []);

  //checklist store
  const checklist_success = useSelector((state) => state.ProjectChecklistStore.success);

  // create  on switch checklist change function
  const onChangeCheckListSwitch = useCallback(
    (checked) => setEnableCheckList(checked),
    []
  );

  const handleEnableChecklistForm = (id) => {
    setCheckNumber(id);
  };

  //   create on  enable create checklist state
  const [enableCreateCheckList, setEnableCreateCheckList] = useState(false);

  // create checklist title state
  const [createChecklistTitle, setCreateChecklistTilte] = useState("");

  // create checklist content states
  const [checklistContent, setCheckListContent] = useState([]);

  // create add checklist function
  const addCheckList = useCallback(() => {
    setCheckListContent((oldChecklist) => [
      ...oldChecklist,
      { id: 7022, title: createChecklistTitle },
    ]);
    dispatch(
      createProjectCheckList({
        title: createChecklistTitle,
        project_card_id: cardData?.id,
      })
    );

  }, [cardData?.id, createChecklistTitle, dispatch]);

  //   create onchange event for checklist content

  const onChange = useCallback(
    (e) => {
      setCheckListContent(
        checklistContent?.map((item) =>
          item.id === e.target.value
            ? {
                ...item,
                id: e.target.value,
                checked: e.target.checked === true ? true : false,
              }
            : item
        )
      );
      const data = { id: e.target.value, checked: e.target.checked };
      dispatch(updateProjectCheckList(data));
    },
    [checklistContent, dispatch]
  );

  //   render default checklist from card data
  useEffect(() => {
    setCheckListContent(cardData?.checklist);
  }, [cardData?.checklist]);

  // delete checklist
  const deleteChecklist = (id) => {
    dispatch(deleteProjectCheckList(id));
  };

  // update checklist
  const updateCheckList = (id, title) => {
    setCheckListContent(
      checklistContent?.map((item) =>
        item.id === id ? { ...item, title } : item
      )
    );
    dispatch(updateProjectCheckList({ id, title }));
    setCheckNumber(0);
  };

  const getCard = React.useMemo(() => {
       if(!checklist_success) return;

       dispatch(showProjectCard(cardData?.id));

  }, [checklist_success]);


  useEffect(() => {
      if(!getCard) return;
      (async() => {
        await getCard();
      })()
  }, [getCard])
  return (
    <React.Fragment>
      <div className="mb-4 mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h4 classsName="">
              <UnorderedListOutlined />
              <span className="ml-3">Checklist</span>
            </h4>

            {enableCheckList ? (
              <div
                className="ml-2"
                style={{ marginTop: "-5px", cursor: "pointer" }}
                onClick={() => {
                  setEnableCreateCheckList(true);
                  onChangeCheckListSwitch(true);
                }}
              >
                <AvatarStatus
                  icon={<PlusOutlined />}
                  name=""
                  type="blue"
                  subTitle=""
                  size={20}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          {/* <Switch
            defaultChecked={enableCheckList}
            onChange={onChangeCheckListSwitch}
          /> */}
        </div>

        {/* <Checkbox.Group style={{ width: "100%" }}  onChange={onChange}> */}
        {enableCheckList === true ? (
          <>
            <Row gutter={15}>
              {checklistContent?.map((item, index) => (
                <Col span={24} key={index}>
                  {checkNumber === item?.id ? (
                    <div className="checklist_form">
                      <Input
                        defaultValue={item?.title}
                        onChange={(e) => setCheckListTilte(e.target.value)}
                        className="mr-2"
                        size="small"
                      />
                      <Button
                        className="mr-1"
                        type="default"
                        htmlType="button"
                        size="small"
                        onClick={() => handleEnableChecklistForm(0)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="button"
                        size="small"
                        onClick={() =>
                          updateCheckList(item?.id, checkListTitle)
                        }
                        className="bg-success text-white"
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <div className="checklist-main">
                      <Checkbox
                        value={item?.id}
                        style={{
                          width: "100%",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                        defaultChecked={item?.checked === 1 ? true : false}
                        onChange={onChange}
                      >
                        <div className="check-list-box">
                          <span>
                            {item?.checked === 1 ? (
                              <strike> {item?.title} </strike>
                            ) : (
                              item?.title
                            )}
                          </span>
                        </div>
                      </Checkbox>

                      <div className="check-list-icon">
                        <EditOutlined
                          className="edit"
                          onClick={() => handleEnableChecklistForm(item?.id)}
                        />{" "}
                        <Popconfirm
                          title="Are you sure you want to delete this checklist item?"
                          onConfirm={() => deleteChecklist(item?.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined className="delete text-danger" />
                        </Popconfirm>
                      </div>
                    </div>
                  )}
                </Col>
              ))}
            </Row>
            {/* </Checkbox.Group> */}

            {enableCreateCheckList === true ? (
              <div className="checklist_form mt-4">
                <Input
                  size="small"
                  onChange={(e) => setCreateChecklistTilte(e.target.value)}
                  className="mr-2"
                  placeholder="Enter checklist here"
                  value={createChecklistTitle}
                />
                <Button
                  className="mr-1"
                  type="default"
                  htmlType="button"
                  size="small"
                  onClick={() => setEnableCreateCheckList(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="button"
                  size="small"
                  className="bg-success text-white"
                  onClick={() => {
                    addCheckList();
                    setEnableCreateCheckList(false);
                    setCreateChecklistTilte("");
                  }}
                >
                  Save
                </Button>
              </div>
            ) : (
              ""
            )}
            {/* 
            <div
              className="edit-description"
              onClick={() => setEnableCreateCheckList(true)}
            >
              <EditOutlined /> Add checklist item
            </div> */}
          </>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

export default Checklist;
