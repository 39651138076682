import * as type from '../constants/Menu'

export const enableMenu= (menu) => {
    return {
        type:type.ENABLE_MENU,
        payload:menu
    }
}

export const disableMenu= (menu) => {
    return {
        type:type.DISABLE_MENU,
        payload:menu
    }
}