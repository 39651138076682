import React, {useState, useEffect} from 'react'
import {SafetyOutlined, CloseOutlined} from "@ant-design/icons";
import {Divider} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { showProjectCard, updateCard } from 'redux/actions';

function WorkStatus(props) {
    const {cardData, card_data, url} = props;
    const dispatch = useDispatch();

    const work_status = useSelector(
        (state) => state.WorkStatusStore?.work_status
      );
      const [workStatusBox, setWorkStatusBox] = useState(false);
      const [workStatus, setWorkStatus] = useState(2);
    
      const changeWorkStatus = (status) => {
        let data = card_data;
        data.status_update = true;
        data.work_status_id = status;
        data.attachments = [];
        data.isWorkStatusChange = true;
        dispatch(updateCard(data, url));
        setWorkStatus(status);
      };
    
      useEffect(() => {
        setWorkStatus(card_data?.work_status_id);
      }, [card_data]);

      const card_success = useSelector((state) => state.CardStore?.success)
      
      const getSingleCard = React.useMemo(() => {
        if(card_success === false) return;
        dispatch(showProjectCard(cardData?.id));

      }, [card_success])

      useEffect(() =>{
        if(!getSingleCard) return;
        getSingleCard();
      }, [getSingleCard])

    return (
        <React.Fragment>
            
            <div style={{ position: "relative" }}>
              <div className="item__box" onClick={() => setWorkStatusBox(true)}>
                <span>
                  <SafetyOutlined />{" "}
                  <span className="icon__text">Work Status:</span>{" "}
                  <span>
                    {
                      work_status?.filter((elm) => elm.id === workStatus)?.[0]
                        ?.title
                    }
                  </span>
                </span>
              </div>

              {workStatusBox === true ? (
                <div className="dropdown__box">
                  <div className="heading__box">
                    <span>Works Status</span>
                    <CloseOutlined
                      onClick={() => {
                        setWorkStatusBox(false);
                      }}
                    />
                  </div>
                  <Divider className="dropdown__divider" />
                  {work_status?.map((elm, index) => (
                    <div
                      className="dropdown__box_menu"
                      key={index}
                      onClick={() => {
                        setWorkStatus(elm?.id);
                        setWorkStatusBox(false);
                        changeWorkStatus(elm?.id);
                      }}
                    >
                      {elm?.title}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
        </React.Fragment>
    )
}

export default WorkStatus
