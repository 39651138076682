import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Menu,
} from "antd";
import { DeleteOutlined, CopyOutlined } from "@ant-design/icons";

import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  createSchedule,
  deleteSchedule,
  showScheduleItem,
  updateSchedule,
} from "redux/actions";
import Flex from "components/shared-components/Flex";
import { getTimeInterval } from "../../../../../utils/duration";
import Checkbox from "antd/lib/checkbox/Checkbox";

import DatePickers from "react-datepicker";
import Draggable from 'react-draggable';
import AddTravelEvent from "./AddTravelEvent";
import { AppointmentUtiles } from "./utils";
const { TextArea } = Input;
const { confirm } = Modal;

function EditCard(props) {
  
  // select ant design form data to handle form events
  const [form] = Form.useForm();
  
  // destructure props datas.
  const { isModalVisible, data, handleOk, handleCancel, setIsModalVisible } = props;

  // create event type state
  const [eventType, setEventType] = useState("");
  const [duplicate, setDuplicate] = useState(false);

  // create start time and end time state
  const [endTime, setEndTime] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  // create personal state - This will use to handle the event type. Whether the user select personal or private
  const [personal, setPersonal] = useState(false);
  const [privates, setPrivates] = useState(false);


  const team_item = useSelector((state) => state.TeamStore?.teams);
  const teams = team_item?.all_teams;
  const active_team = team_item?.active_workspace;
  const user_state = useSelector((state) => state.AuthStore?.user);
  const user = user_state?.user;

  const dispatch = useDispatch();

  useEffect(() => {
    setStartTime(moment.tz(data?.start_time, user?.timezone).toDate());
    setEndTime(moment.tz(data?.end_time, user?.timezone).toDate());
  }, [data]);

  const onFinish = (values) => {
    let items = values;
    items.duration = getTimeInterval(startTime, endTime);
    items.start_time = startTime;
    items.end_time = endTime;
    items.board_id = data?.schedule_board_id;
    items.private = privates;
    items.personal = personal;
    items.events_id = data?.calander_event_id;
    items.color = data?.color;
    items.object_type = data?.object_type;
    data.team_id = active_team?.team_id;
    const success = dispatch(updateSchedule(items));
    if (success) {
      dispatch(showScheduleItem(data?.object_id));
    }
    handleOk();
  };

  const onDuplicateFinish = (values) => {
    let datas = values;
    datas.duration = getTimeInterval(startTime, endTime);
    datas.start_time = startTime;
    datas.end_time = endTime;
    datas.board_id = datas?.schedule_board_id;
    datas.private = privates;
    datas.personal = personal;
    datas.team_id = active_team?.team_id;
    datas.color = data?.color;
    datas.object_type = data?.object_type;
    const success = dispatch(createSchedule(datas));
    setDuplicate(false);
    if (success) {
      dispatch(showScheduleItem(data?.object_id));
    }
    handleOk();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChangeStartTime(time) {
    setStartTime(time);
  }

  function onChangeEndTime(time) {
    setEndTime(time);
  }

  const defaultValues = {
    id: data?.id,
    date: moment(data?.date),
    time: [moment(data?.start_time), moment(data?.end_time)],
    duration: moment(data?.duration),
    team_id: data?.team_id === 0 ? 1 : data?.team_id,
    event_type: data?.event_type,
    description: data?.description,
    what: data?.what,
    why: data?.why,
    who: data?.who,
    where: data?.where,
    travel: data?.travel === null ? "" : data?.travel,
    notes: data?.notes === null ? "" : data?.notes,
    schedule_board_id: data?.schedule_board_id,
    schedule_id: data?.object_id,
  };

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  useEffect(() => {
    setEventType(data?.event_type || data?.private === true);
    if (data?.personal === 1) {
      setPersonal(true);
    }else{
      setPersonal(true);
    }

    if (data?.private === 1 || data?.private === true) {
      setPrivates(true);
    }else{
      setPrivates(false);
    }
  }, [data]);
  const deleteRow = (id) => {
    confirm({
      title: "Are you sure?",
      content: "You will not restore this event data again !",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(deleteSchedule(id));
        handleOk();
      },
      onCancel() {},
    });
  };

  const duplicateRow = (id) => {
    confirm({
      title: "Are you sure?",
      content: "",
      okText: "Yes",
      okType: "warning",
      cancelText: "No",
      onOk() {
        setDuplicate(true);
      },
      onCancel() {
        setDuplicate(false);
      },
    });
  };
  const dropdownMenu = (id) => (
    <Menu>
      <Menu.Item onClick={() => duplicateRow(id)}>
        <Flex alignItems="center">
          <CopyOutlined />
          <span className="ml-2">Duplicate Event</span>
        </Flex>
      </Menu.Item>

      <Menu.Item onClick={() => deleteRow(id)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">Delete Event</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const [isTravelModalVisible, setIsTravelModalVisible] = useState(false);

  const handleTravelOk = () => {
    setIsTravelModalVisible(false);
  };

  const handleTravelCancel = () => {
    setIsTravelModalVisible(false);
  };

  const [disable, setDisable] = React.useState(true);
  const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <Modal
        footer={false}
        className="draggable__modal_item"
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disable) {
                setDisable(false);
              }
            }}
            onMouseOut={() => {
             setDisable(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
         {`Add travel for event : ${data?.what} from ${moment.tz(data?.start_time, user?.timezone).format("h:mm a z")} to ${moment.tz(data?.end_time, user?.timezone).format("h:mm a z")} `}
          </div>
        }
        
        visible={isTravelModalVisible}
        onOk={handleTravelOk}
        onCancel={handleTravelCancel}
        width={1000}
        maskClosable={false}
        modalRender={modal => (
          <Draggable
            disabled={disable}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <AddTravelEvent
          data={data}
          setIsRegularModalVisible={setIsModalVisible}
          onCancel={handleTravelCancel}
          setIsModalVisible={setIsTravelModalVisible}
          
        />
      </Modal>

      <Modal
        footer={false}
        width={1000}
 
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        className="draggable__modal_item"
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disable) {
                setDisable(false);
              }
            }}
            onMouseOut={() => {
             setDisable(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
         {
          duplicate === false
            ? "Edit Details"
            : data?.event_type === "Travel Event"
            ? `Duplicate ${data?.travel}`
            : `Duplicate ${data?.what}`
        }
          </div>
        }
        

        modalRender={modal => (
          <Draggable
            disabled={disable}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {duplicate === false ? (
          <>
            <div
              className="ml-3"
              style={{ marginTop: "-15px", float: "right" }}
            >
              <EllipsisDropdown
                menu={dropdownMenu(data?.id)}
                className="ml-5"
              />
            </div>

            <Form
              name="update_form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={defaultValues}
              form={form}
              className="mt-4"
            >
              <Form.Item
                style={{ display: "none" }}
                label="Date"
                name="id"
                className="blockform-col col-12"
              >
                <Input placeholder="The amount of time for the appointment" />
              </Form.Item>
              <Form.Item
                style={{ display: "none" }}
                label="schedule_board_id"
                name="schedule_board_id"
                className="blockform-col col-12"
              >
                <Input placeholder="The amount of time for the appointment" />
              </Form.Item>
              {/* form container start */}
              <div className="form-box">
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>
                      {eventType === "Travel Event" ? "Start Date" : "Date"}
                    </span>

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select event date !",
                        },
                      ]}
                      label=""
                      name="date"
                      className="main_form__item"
                    >
                      <DatePicker
                        placeholder="Select event date"
                        className="date-picker w-100"
                        format={"dddd D-MMM-YYYY"}
                      />
                    </Form.Item>
                  </div>

                  <div className="double-form-box">
                    <span>
                      {eventType === "Travel Event" ? "Time" : "Time"}
                    </span>
                    <div className="time__box">
                      <DatePickers
                        selected={startTime}
                        onChange={(date) => onChangeStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      {/* <SwapOutlined /> */}
                      <h5>To</h5>
                      <DatePickers
                        selected={endTime}
                        onChange={(date) => onChangeEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                  </div>
                </div>
                {/* form container start */}

                {/* form container start */}
                <div className="main-flex">

                  <div className="double-form-box">
                    <span>
                      {/* {eventType === "Travel Event" ? "Travel Details" : "Why"} */}
                    </span>
                    
                    <Checkbox
                     onChange={() => AppointmentUtiles.onChangePersonal(personal, setPersonal, setPrivates)}
                      checked={personal ? true : false}
                    >
                      Personal
                    </Checkbox>

                    {personal ? 
                         <Checkbox
                         onChange={() => AppointmentUtiles.onChangePrivate(privates, setPrivates, setPersonal)}
                         checked={privates ? true : false}
                       >
                         Private
                       </Checkbox>
                    : ""}
               
                  </div>

                  {/* form container end */}

                  <div className="double-form-box">
                    <span>Duration</span>
                    {endTime === moment()
                      ? ""
                      : getTimeInterval(startTime, endTime)}
                  </div>
                </div>
                {/* form container end */}

                <div className="main-flex">
                  <div className="double-form-box">
                    <span>What</span>
                    <Form.Item
                      label=""
                      name="what"
                      className="main_form__item"
                      rules={[
                        {
                          required: eventType === "Travel Event" ? false : true,
                          message: "Input your event appointment title !",
                        },
                      ]}
                    >
                      <Input placeholder="subject of the appointment" />
                    </Form.Item>
                  </div>
                </div>
                {/* form container end */}

                {/* form container start */}
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Why</span>
                    <Form.Item
                      label=""
                      name="why"
                      className="main_form__item"
                      rules={[
                        {
                          required: eventType === "Travel Event" ? false : true,
                          message: "Input the reason for the event !",
                        },
                      ]}
                    >
                      <Input placeholder="Reason for the appointment" />
                    </Form.Item>
                  </div>
                </div>
                {/* form container end */}

                {/* form container start */}
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Who</span>

                    <Form.Item label="" name="who" className="main_form__item">
                      <Input placeholder="who is the appointment with?" />
                    </Form.Item>
                  </div>
                  <div className="double-form-box">
                    <span></span>

                    <Button
                      type=""
                      onClick={() => {
                        setIsTravelModalVisible(true);
                        handleOk();
                      }}
                      className="float-right"
                      style={{ width: "150px" }}
                      htmlType="button"
                    >
                      Add Travel
                    </Button>
                    {/* <Button
                      type=""
                      onClick={() => {
                        setIsTravelModalVisible(true);
                        handleOk();
                      }}
                      className="float-right"
                      style={{ width: "120px" }}
                      htmlType="button"
                    >
                      Travel From
                    </Button> */}
                  </div>
                </div>
                {/* form container end */}

                {/* form container start */}

                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Where</span>

                    <Form.Item
                      className="main_form__item"
                      label=""
                      name="where"
                      rules={[
                        {
                          required: eventType === "Travel Event" ? false : true,
                          message: "Input the location for the event !",
                        },
                      ]}
                    >
                      <Input placeholder="Location of the meeting" />
                    </Form.Item>
                  </div>

                  <div className="double-form-box">
                    <span>Team</span>
                    <Form.Item
                      label=""
                      name="team_id"
                      className="main_form__item"
                      rules={[
                        {
                          required:
                            eventType === "Travel Event" ? false : false,
                          message: "Select team!",
                        },
                      ]}
                    >
                      <Select placeholder="Select team">
                        {teams?.map((elm, i) => (
                          <Select.Option key={i} value={elm?.id === 0 ? 1: elm?.id }>
                            {elm?.team_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {/* form container end */}

                {/* form container start */}
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Note</span>
                    <Form.Item
                      label=""
                      name="notes"
                      className="main_form__item"
                    >
                      <TextArea
                        placeholder="Add event not here"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </div>
                </div>
                {/* form container end */}
              </div>
              {/* form box end */}

              <Form.Item className="blockform-col col-12 mt-5 mb-3">
                <Button
                  type=""
                  className="float-left bg-gray-lighter"
                  htmlType="button"
                  style={{ width: "120px" }}
                  onClick={handleOk}
                >
                  Cancel
                </Button>
                <Button
                  type="success"
                  className="float-right bg-success text-white"
                  style={{ width: "120px" }}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <Form
              name="duplicate_form"
              onFinish={onDuplicateFinish}
              onFinishFailed={onFinishFailed}
              initialValues={defaultValues}
              form={form}
            >
              <Form.Item
                style={{ display: "none" }}
                label="schedule_board_id"
                name="schedule_board_id"
                className="blockform-col col-12"
              >
                <Input placeholder="The amount of time for the appointment" />
              </Form.Item>
              <Form.Item
                style={{ display: "none" }}
                label="schedule_id"
                name="schedule_id"
                className="blockform-col col-12"
              >
                <Input placeholder="The amount of time for the appointment" />
              </Form.Item>

              <Form.Item
                style={{ display: "none" }}
                label="Date"
                name="id"
                className="blockform-col col-12"
              >
                <Input placeholder="The amount of time for the appointment" />
              </Form.Item>

              {/* form container start */}
              <div className="form-box">
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>
                      {eventType === "Travel Event" ? "Start Date" : "Date"}
                    </span>

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select event date !",
                        },
                      ]}
                      label=""
                      name="date"
                      className="main_form__item"
                    >
                      <DatePicker
                        placeholder="Select event date"
                        className="date-picker w-100"
                        format={"dddd D-MMM-YYYY"}
                      />
                    </Form.Item>
                  </div>

                  <div className="double-form-box">
                    <span>
                      {eventType === "Travel Event" ? "Time" : "Time"}
                    </span>
                    <div className="time__box">
                      <DatePickers
                        selected={startTime}
                        onChange={(date) => onChangeStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        // dateFormat="h:mm aa"
                      />
                      {/* <SwapOutlined /> */}
                      <h5>To</h5>
                      <DatePickers
                        selected={endTime}
                        onChange={(date) => onChangeEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        // dateFormat="h:mm aa"
                      />
                    </div>
                  </div>
                </div>
                {/* form container start */}

                {/* form container start */}
                <div className="main-flex">

                  <div className="double-form-box">
                    <span>
                      {/* {eventType === "Travel Event" ? "Travel Details" : "Why"} */}
                    </span>
                    <Checkbox
                     onChange={() => AppointmentUtiles.onChangePersonal(personal, setPersonal)}
                      checked={personal ? true : false}
                    >
                      Personal
                    </Checkbox>
                    <Checkbox
                      onChange={() => AppointmentUtiles.onChangePrivate(privates, setPrivates)}
                      checked={privates ? true : false}
                    >
                      Private
                    </Checkbox>
                  </div>

                  {/* form container end */}

                  <div className="double-form-box">
                    <span>Duration</span>
                    {endTime === moment()
                      ? ""
                      : getTimeInterval(startTime, endTime)}
                  </div>
                </div>
                {/* form container end */}

                <div className="main-flex">
                  <div className="double-form-box">
                    <span>What</span>
                    <Form.Item
                      label=""
                      name="what"
                      className="main_form__item"
                      rules={[
                        {
                          required: eventType === "Travel Event" ? false : true,
                          message: "Input your event appointment title !",
                        },
                      ]}
                    >
                      <Input placeholder="subject of the appointment" />
                    </Form.Item>
                  </div>
                </div>
                {/* form container end */}

                {/* form container start */}
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Why</span>
                    <Form.Item
                      label=""
                      name="why"
                      className="main_form__item"
                      rules={[
                        {
                          required: eventType === "Travel Event" ? false : true,
                          message: "Input the reason for the event !",
                        },
                      ]}
                    >
                      <Input placeholder="Reason for the appointment" />
                    </Form.Item>
                  </div>
                </div>
                {/* form container end */}

                {/* form container start */}
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Who</span>

                    <Form.Item label="" name="who" className="main_form__item">
                      <Input placeholder="who is the appointment with?" />
                    </Form.Item>
                  </div>
                  <div className="double-form-box">
                    <span></span>

                    <Button
                      type=""
                      onClick={() => {
                        setIsTravelModalVisible(true);
                        handleOk();
                      }}
                      className="float-right"
                      style={{ width: "120px" }}
                      htmlType="button"
                    >
                      Travel To
                    </Button>
                    <Button
                      type=""
                      onClick={() => {
                        setIsTravelModalVisible(true);
                        handleOk();
                      }}
                      className="float-right"
                      style={{ width: "120px" }}
                      htmlType="button"
                    >
                      Travel From
                    </Button>
                  </div>
                </div>
                {/* form container end */}

                {/* form container start */}

                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Where</span>

                    <Form.Item
                      className="main_form__item"
                      label=""
                      name="where"
                      rules={[
                        {
                          required: eventType === "Travel Event" ? false : true,
                          message: "Input the location for the event !",
                        },
                      ]}
                    >
                      <Input placeholder="Location of the meeting" />
                    </Form.Item>
                  </div>

                  <div className="double-form-box">
                    <span>Team</span>
                    <Form.Item
                      label=""
                      name="team_id"
                      className="main_form__item"
                      rules={[
                        {
                          required:
                            eventType === "Travel Event" ? false : false,
                          message: "Select team!",
                        },
                      ]}
                    >
                      <Select placeholder="Select team">
                        {teams?.map((elm, i) => (
                          <Select.Option key={i} value={elm?.id === 0 ? 1 : elm?.id}>
                            {elm?.team_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {/* form container end */}

                {/* form container start */}
                <div className="main-flex">
                  <div className="double-form-box">
                    <span>Note</span>
                    <Form.Item
                      label=""
                      name="notes"
                      className="main_form__item"
                    >
                      <TextArea
                        placeholder="Add event note here"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </div>
                </div>
                {/* form container end */}
              </div>
              {/* form box end */}
              <Form.Item className="blockform-col col-12 ">
                <Button
                  type="danger"
                  className="float-left"
                  htmlType="button"
                  onClick={() => setDuplicate(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="float-right"
                  htmlType="submit"
                >
                  Duplicate Event
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
}

export default EditCard;
