import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/DatabaseBackupService';

import * as types from "./action_types";


function* getDatabaseBackup(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_DATABASE_BACKUP_SUCCESS, backups:response.data});
    } else {
        yield put({type: types.GET_DATABASE_BACKUP_FAILED, error:error});
    }
}

function* getRestoreBackup(action)
{
    const {response, error} = yield call(api.getRestoreBackup, action.payload)
    if(response)
    {
        yield put({type: types.GET_RESTORE_DATABASE_BACKUP_SUCCESS, restore_backups:response.data});
    } else {
        yield put({type: types.GET_RESTORE_DATABASE_BACKUP_FAILED, error:error});
    }
}

function* restoreDatabaseBackup(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.RESTORE_DATABASE_BACKUP_SUCCESS});
    } else {
        yield put({type: types.RESTORE_DATABASE_BACKUP_FAILED, error:error});
    }
}

function* makeDatabaseBackup(action)
{
    const {response, error} = yield call(api.makeBackup, action.payload)
    if(response)
    {
        yield put({type: types.MAKE_DATABASE_BACKUP_SUCCESS});
    } else {
        yield put({type: types.MAKE_DATABASE_BACKUP_FAILED, error:error});
    }
}

function* enableMaintainance(action)
{
    const {response, error} = yield call(api.enableMaintainance, action.payload)
    if(response)
    {
        yield put({type: types.ENABLE_MAINTENANCE_SUCCESS});
    } else {
        yield put({type: types.ENABLE_MAINTENANCE_FAILED, error:error});
    }
}


function* watchGetDatabaseBackup() 
{
    yield takeEvery(types.GET_DATABASE_BACKUP, getDatabaseBackup)
}

function* watchGetRestoreBackup() 
{
    yield takeEvery(types.GET_RESTORE_DATABASE_BACKUP, getRestoreBackup)
}

function* watchRestoreDatabaseBackup() 
{
    yield takeEvery(types.RESTORE_DATABASE_BACKUP, restoreDatabaseBackup)
}

function* watchMakeDatabaseBackup() 
{
    yield takeEvery(types.MAKE_DATABASE_BACKUP, makeDatabaseBackup)
}

function* watchEnableMaintainace() 
{
    yield takeEvery(types.ENABLE_MAINTENANCE, enableMaintainance)
}

function* databaseBackupSaga(){
    yield all([
        fork(watchGetDatabaseBackup),
        fork(watchRestoreDatabaseBackup),
        fork(watchMakeDatabaseBackup),
        fork(watchEnableMaintainace),
        fork(watchGetRestoreBackup)
    ])
}

export default databaseBackupSaga;