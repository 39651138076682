import moment from "moment";
import React, { useEffect, useState } from "react";
// import SwitchCheckbox from "../../GlobalComponents/SwitchCheckbox";
import { Button } from "antd";
import { LeftOutlined, RightOutlined, SyncOutlined } from "@ant-design/icons";
import { list } from "postcss";
import { useDispatch, useSelector } from "react-redux";
import { filterCalendarEvent, getWorkStatus, showCalendarTeamView } from "redux/actions";
import { useParams } from "react-router";

function RBCToolbar(props) {
  const {id} = props;
  const [viewState, setViewState] = useState("list");
  const [active, setActive] = useState(4);
  const dispatch = useDispatch();
  const [startOfWeek, setStartOfWeek] = useState(moment().startOf("week"));
  const [endOfWeek, setEndOfWeek] = useState(moment().endOf("week"));
  const [days, setDays] = useState([]);
  const [dayData, setDayData] = useState(moment());
  const [monthData, setMonthData] = useState(moment());

  let param = useParams();

    // const toolbarDate = props.date;
    const goToDayView = () => {
      setViewState("day");
      setActive(3);
      goToToday("day");
    };

    // handle to week date view 
    const goToWeekView = () => {
      setViewState("week");
      setActive(2);
      goToToday("week");
    };

    // handle to month date view 
    const goToMonthView = () => {
      setViewState("month");
      setActive(1);
      goToToday("month");
    };

    // handle to list date view 
    const goToAgendaView = () => {
      setViewState("list");
      setActive(4);
      goToToday("list");
    };

    // handle to go back date view 
    const goToBack = () => {
      if (viewState === "list") {
      } else if (viewState === "day") {
        let startdate = dayData.subtract(1, "days");
        setDayData(moment(startdate)); 
        dispatch(filterCalendarEvent("filter-calendar-by-day", {data:moment(startdate), calendar_id:id }));

      } else if (viewState === "week") {

        const getFirstDate = days.find((e) => !!e);
        var newFirstDate = moment(getFirstDate).subtract(1, "days");
        setStartOfWeek(moment(newFirstDate).startOf("week"));
        setEndOfWeek(moment(newFirstDate).endOf("week"));

        let days_data = [];
        var day = moment(newFirstDate).startOf("week");
    
        while (day <= newFirstDate) {
          days_data.push(day.toDate());
          day = day.clone().add(1, "day");
        }
    
        setDays(days_data);
        return dispatch(filterCalendarEvent("filter-calendar-by-week", {data:days_data, calendar_id:id }));  
         
      } else if (viewState === "month") {

        let date = monthData.subtract(1, "month");
        setMonthData(moment(date));
        dispatch(filterCalendarEvent("filter-calendar-by-month", {data:moment(date), calendar_id:id }));
      }


    };

 // load days, week, and month view when page load. 
    useEffect(() => {
      let days_data = [];
      var day = startOfWeek;
  
      while (day <= endOfWeek) {
        days_data.push(day.toDate());
        day = day.clone().add(1, "day");
      }
  
      setDays(days_data);
    }, [endOfWeek, startOfWeek]);

    // handle go next date view 
    const goToNext = () => {
      if (viewState === "list") {
       
      } else if (viewState === "day") {

        let date = dayData.add(1, "days");
        setDayData(moment(date));
        dispatch(filterCalendarEvent("filter-calendar-by-day", {data:moment(date), calendar_id:id }));
      } 
      else if (viewState === "week") {

        const getLastDate = days[days.length - 1];
        var new_date = moment(getLastDate).add(1, "days");
        setStartOfWeek(moment(new_date).startOf("week"));
        setEndOfWeek(moment(new_date).endOf("week"));

        let days_data = [];
        var day = moment(new_date).startOf("week");
    
        while (day <= moment(new_date).endOf("week")) {
          days_data.push(day.toDate());
          day = day.clone().add(1, "day");
        }
    
        setDays(days_data);

       dispatch(filterCalendarEvent("filter-calendar-by-week", {data:days_data, calendar_id:id }));

      } 
      
      else if (viewState === "month") {
        let date = monthData.add(1, "month");
        setMonthData(moment(date));
        dispatch(filterCalendarEvent("filter-calendar-by-month", {data:moment(date), calendar_id:id }));
      }
    };

    // handle today date view.
    const goToToday = (viewStates) => {
      if(viewStates === "list") {
        dispatch(showCalendarTeamView(id));
        dispatch(getWorkStatus());
      }
      else if(viewStates === "day") {
        setDayData(moment());
        dispatch(filterCalendarEvent("filter-calendar-by-day", {data:moment(), calendar_id:id }));
        dispatch(getWorkStatus());
      }else if (viewStates === "week"){
          setStartOfWeek(moment().startOf("week"));
          setEndOfWeek(moment().endOf("week"));

          let days_data = [];
          var day = moment().startOf("week");
      
          while (day <= moment().endOf("week")) {
            days_data.push(day.toDate());
            day = day.clone().add(1, "day");
          }
      
          setDays(days_data);
          dispatch(filterCalendarEvent("filter-calendar-by-week", {data:days_data, calendar_id:id }));
        dispatch(getWorkStatus());
      }
      else if (viewStates === "month"){
        setMonthData(moment());
        dispatch(filterCalendarEvent("filter-calendar-by-month", {data:moment(), calendar_id:id }));
        dispatch(getWorkStatus());
      }
    };

    // render calendar view.
    return (
      <div className="rbc-toolbar">
        <div className="d-flex align-items-center">
          {viewState === 'list' ?"" : 
             <Button
             style={{ minWidth: "100px" }}
             type="primary"
             size="small"
             ghost
             onClick={goToBack}
             className="mb-3"
           >
             <LeftOutlined style={{ fontSize: "18px" }} />
           </Button>
          }
       
          <Button
            ghost
            style={{ minWidth: "100px" }}
            size="small"
            className="ml-2 mr-2 mb-3"
            onClick={() => goToToday(viewState)}
            
          >
            <SyncOutlined /> {viewState === "list" ? "All List": ""}
            {viewState === "day" ? "Today": ""}
            {viewState === "week" ? "This Week": ""}
            {viewState === "month" ? "This month": ""}
          </Button>

          {viewState === 'list' ?"" : 
           <Button
           style={{ minWidth: "100px" }}
           type="primary"
           size="small"
           ghost
           onClick={goToNext}
           className="mb-3"
         >
           <RightOutlined style={{ fontSize: "18px" }} />
         </Button>
          }
         
        </div>
        <h4 className="mb-3"><span className="rbc-toolbar-label rbc-date">
          <i className="far fa-calendar"></i>
           <span>{viewState === "list" ? moment().format("D-MMMM-YYYY") : 
           viewState === "day" ? moment(dayData).format("dddd, D-MMMM-YYYY ") : 
           viewState === "week" ? `${moment(startOfWeek).format("D-MMMM-YYYY")} - ${moment(endOfWeek).format("D-MMMM-YYYY")}` : 
           viewState === "month" ? moment(monthData).format("MMMM-YYYY"):""
           }</span>
        </span></h4>

        <div className="d-flex align-items-center">
          <Button
            size="small"
            type="primary"
            style={{ minWidth: "100px" }}
            className={`ml-2 mb-3 ${
              active === 4 ? "bg-primary text-white border-0" : ""
            }`}
            onClick={() => goToAgendaView()}
          >
            List
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ minWidth: "100px" }}
            className={`ml-2 mb-3 ${
              active === 3 ? "bg-primary text-white border-0" : ""
            }`}
            onClick={() => goToDayView()}
          >
            Day
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ minWidth: "100px" }}
            className={`ml-2 mb-3 ${
              active === 2 ? "bg-primary text-white border-0" : ""
            }`}
            onClick={() => goToWeekView()}
          >
            Week
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ minWidth: "100px" }}
            className={`ml-2 mb-3 ${
              active === 1 ? "bg-primary text-white border-0" : ""
            }`}
            onClick={goToMonthView}
          >
            Month
          </Button>
        </div>
      </div>
    );
}

export default RBCToolbar
