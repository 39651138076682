export const GET_NOTEBOOK = "GET_NOTEBOOK"
export const GET_NOTEBOOK_SUCCESS = "GET_NOTEBOOK_SUCCESS"
export const GET_NOTEBOOK_FAILED = "GET_NOTEBOOK_FAILED"

export const SHOW_NOTEBOOK = "SHOW_NOTEBOOK"
export const SHOW_NOTEBOOK_SUCCESS = "SHOW_NOTEBOOK_SUCCESS"
export const SHOW_NOTEBOOK_FAILED = "SHOW_NOTEBOOK_FAILED"

export const CREATE_NOTEBOOK = "CREATE_NOTEBOOK"
export const CREATE_NOTEBOOK_SUCCESS = "CREATE_NOTEBOOK_SUCCESS"
export const CREATE_NOTEBOOK_FAILED = "CREATE_NOTEBOOK_FAILED"

export const UPDATE_NOTEBOOK = "UPDATE_NOTEBOOK"
export const UPDATE_NOTEBOOK_SUCCESS = "UPDATE_NOTEBOOK_SUCCESS"
export const UPDATE_NOTEBOOK_FAILED = "UPDATE_NOTEBOOK_FAILED"

export const DELETE_NOTEBOOK = "DELETE_NOTEBOOK"
export const DELETE_NOTEBOOK_SUCCESS = "DELETE_NOTEBOOK_SUCCESS"
export const DELETE_NOTEBOOK_FAILED = "DELETE_NOTEBOOK_FAILED"
