import * as types from "./constants"

const initialStates = {
    task_list:[],
    task_list_check_list:[],
    single_task_list_check_list:{},
    task_list_card: {},
    single_task_list:{},
    loading:false,
    error:null,
    success:false,
}

 const TaskListStore = (state = initialStates, action) => {
    //setup task_list reducers
   switch (action.type) {
       case types.GET_TASK_LISTS :
       return {...state, loading:true}
       case types.GET_TASK_LISTS_SUCCESS :
       return {...state, loading:false, task_list:action.task_list}
       case types.GET_TASK_LISTS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_TASK_LISTS :
       return {...state}
       case types.SHOW_TASK_LISTS_SUCCESS :
       return {...state, [action.single_task_list.task_list.id]:action.single_task_list}
       case types.SHOW_TASK_LISTS_FAILED :
       return {...state, error:action.error, loading:false}

       case types.SHOW_TASK_LIST_CARD :
       return {...state}
       case types.SHOW_TASK_LIST_CARD_SUCCESS :
       return {...state, task_list_card:action.task_list_card}
       case types.SHOW_TASK_LIST_CARD_FAILED :
       return {...state, error:action.error, loading:false}


       case types.CREATE_TASK_LISTS :
       return {...state, loading:true, success:false}
       case types.CREATE_TASK_LISTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_TASK_LISTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_TASK_LISTS :
       return {...state, loading:true, success:false}
       case types.UPDATE_TASK_LISTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_TASK_LISTS_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_TASK_LISTS :
       return {...state, loading:true, success:false}
       case types.DELETE_TASK_LISTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_TASK_LISTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          

       case types.GET_TASK_LIST_CHECK_LIST :
       return {...state, loading:true}
       case types.GET_TASK_LIST_CHECK_LIST_SUCCESS :
       return {...state, loading:false, task_list_check_list:action.task_list_check_list}
       case types.GET_TASK_LIST_CHECK_LIST_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_TASK_LIST_CHECK_LIST :
       return {...state}
       case types.SHOW_TASK_LIST_CHECK_LIST_SUCCESS :
       return {...state, single_task_list_check_list:action.single_task_list_check_list}
       case types.SHOW_TASK_LIST_CHECK_LIST_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_TASK_LIST_CHECK_LIST :
       return {...state, loading:true, success:false}
       case types.CREATE_TASK_LIST_CHECK_LIST_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_TASK_LIST_CHECK_LIST_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_TASK_LIST_CHECK_LIST :
       return {...state, loading:true, success:false}
       case types.UPDATE_TASK_LIST_CHECK_LIST_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_TASK_LIST_CHECK_LIST_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_TASK_LIST_CHECK_LIST :
       return {...state, loading:true, success:false}
       case types.DELETE_TASK_LIST_CHECK_LIST_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_TASK_LIST_CHECK_LIST_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       default:
           return state;
   }

   
}

export default TaskListStore
