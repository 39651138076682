import * as types from "./constants"

export const showAssignedMembers = (single_assigned_member) => {
    return {
        type:types.SHOW_ASSIGNED_MEMBERS,
        payload:single_assigned_member
    }
}

export const getAssignedMembers = (query) => {
    return {
        type:types.GET_ASSIGNED_MEMBERS,
        payload:query
    }
}

export const getAllAssignedMembers = (query) => {
    return {
        type:types.GET_ALL_ASSIGNED_MEMBERS,
        payload:query
    }
}


export const createAssignedMembers = (assigned_member) => {
    return {
        type:types.CREATE_ASSIGNED_MEMBERS,
        payload:assigned_member
    }
}

export const updateAssignedMembers = (assigned_member) => {
    return {
        type:types.UPDATE_ASSIGNED_MEMBERS,
        payload:assigned_member
    }
}

export const deleteAssignedMembers = (assigned_member) => {
    return {
        type:types.DELETE_ASSIGNED_MEMBERS,
        payload:assigned_member
    }
}
