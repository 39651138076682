import React, { useState, useCallback, useEffect } from "react";
import { ClockCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { Switch, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import AvatarStatus from "../AvatarStatus";
import TimeTable from "./Timer/TimeTable";
import AddTime from "./Timer/AddTime";

function WorkTime(props) {
  // create dispatch function
  const dispatch = useDispatch();

  // destructure props
  const {
    cardData,
    time,
    project_item,
    team,
    user,
    createScheduleTime, 
    title, updateTime, deleteTime, url, work_status, 
    enableTime,
    setEnableTime,
    message
  } = props;


  // create time model visibility models
  const [timerScheduleCreateVisible, setTimerScheduleCreateVisible] =
    useState(false);

  // create on change schedule time  switch function
  const onChangeScheduleTimeSwwitch = useCallback(
    (checked) => setEnableTime(checked),
    []
  );

  // create edit schedule time view state
  const [editScheduleTimeView, setEditScheduleTimeView] = useState(true);

  return (
    <React.Fragment>
      {/* Add time model start */}
      <AddTime url={url} createTime={createScheduleTime} cardData={cardData} project_item={project_item} setTimerScheduleCreateVisible={setTimerScheduleCreateVisible} title={title} user={user} teams={team} timerScheduleCreateVisible={timerScheduleCreateVisible} />
      {/* Add time model end */}

      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h4 className="mb-3">
            <ClockCircleOutlined />{" "}
            <span className="ml-3">{title} work time</span>
          </h4>

          {enableTime ? (
            <div
              className="ml-2"
              style={{ marginTop: "-12px", cursor: "pointer" }}
              onClick={() =>
                cardData?.work_status_id > work_status
                  ? setTimerScheduleCreateVisible(true)
                  : ""
              }
            >
              <AvatarStatus
                icon={<PlusOutlined />}
                name=""
                type="blue"
                subTitle=""
                size={20}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        {/* <Switch
          defaultChecked={enableTime}
          onChange={onChangeScheduleTimeSwwitch}
        /> */}
      </div>

      {enableTime === true ? (
        <>
          {cardData?.work_status_id > work_status ? (
            <>
              {editScheduleTimeView === true ? <div></div> : ""}
              <div>
                {time?.length > 0 ? <TimeTable
                  time={time}
                  user={user}
                  teams={team}
                  updateTime={updateTime}
                  url={url}
                  deleteTime={deleteTime}
                  cardData={cardData}
                /> : ""
                
                }
               
              </div>
              {/* <div
                  className="add-description mt-3 mb-3"
                  onClick={() => setTimerScheduleCreateVisible(true)}
                >
                  <EditOutlined /> Schedule Time
                </div> */}
            </>
          ) : (
            <Alert
              message={message}
              type="info"
              showIcon
              closable
            />
          )}
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default WorkTime;
