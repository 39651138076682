import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  DatePicker,
  Col
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePickers from "react-datepicker";
import { getTimeInterval } from "../../../../../utils/duration";
import { updateSchedule, updateCard } from "redux/actions";
import { UploadOutlined } from "@ant-design/icons";
import "../../styles.css";
import { AssigneeUserAvatar } from 'views/app-views/project/scrumboard/utils';
import Attachments from "../../../../../components/shared-components/DraggableCard/Attachments";
import Draggable from 'react-draggable';

const { TextArea } = Input;
const { Option } = Select;

const memberTagRender = (props) => (
    <AssigneeUserAvatar id={props.value} size={25} />
  );

function EditTravelEvent(props) {
  const [form] = Form.useForm();
  const {
    onCancel,
    listId,
    setIsRegularModalVisible,
    setIsModalVisible,
    isModalVisible,
    data,
    handleOk,
    handleCancel,
  } = props;
  const [eventType, setEventType] = useState("");
  const [duration, setDuration] = useState("");
  const [base64, setBase64] = useState([]);
  const [id, setId] = useState("");
  const [endTime, setEndTime] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [date, setDate] = useState(moment());
  const item = useSelector((state) => state.ScheduleItemStore);
  const schedule_item = item?.single_schedule_item?.schedule;
  const dispatch = useDispatch();
  const team_item = useSelector((state) => state.TeamStore?.teams);
  const teams = team_item?.all_teams;
  const active_team = team_item?.active_workspace;
  const team_name = teams?.filter((item) => item.id === active_team?.team_id)?.[0].team_name
  const [teamMember, setTeamMembers] = useState("");
  const handleUserChange = (value) => {
    setTeamMembers(value);
  };




  const onChangeEvent = (value) => {
    setEventType(value);
  };

  const onChangePersonal = (value) => {};

  const onChangePrivate = (value) => {};

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    // Accept 5 files only
    fileList = fileList.slice(-5);
    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });
    setBase64(fileList);
  };

  useEffect(() => {
    setStartTime(moment(data?.start_time).toDate());
    setEndTime(moment(data?.end_time).toDate());
    setDate(moment(data?.date));
    setId(data?.id);
  }, [data]);



  const onFinish = (values) => {
    let datas = values;
    datas.id = id;
    datas.schedule_id = data?.object_id;
    datas.duration = getTimeInterval(startTime, endTime);
    datas.start_time = startTime;
    datas.end_time = endTime;
    datas.event_type = "Travel Event";
    datas.events_id = data?.calander_event_id;
    datas.color = "#51067E";
    datas.object_type = 2;
    data.team_id = active_team?.team_id;
    dispatch(updateSchedule(datas));
    // form.resetFields();
    // console.log(data);
    // setIsRegularModalVisible(true);
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function validatePrimeNumber() {
    if (moment(endTime) < moment(startTime)) {
      return {
        validateStatus: "success",
        errorMsg: null,
      };
    }

    return {
      validateStatus: "error",
      errorMsg: "End time must be after the start time",
    };
  }

  // function onChangeDuration(time, timeString) {
  //   setEndTime(moment(time));
  // }

  function onChangeStartTime(time) {
    setStartTime(time);
  }

  function onChangeEndTime(time) {
    setEndTime(time);
  }

  const defaultValues = {
    date: date,
    time: [moment(data?.start_time), moment(data?.end_time)],
    duration: duration,
    schedule_id: data?.object_id,
    event_type: "Travel Event",
    team_id: active_team?.team_id === 0 ? "" : active_team?.team_id,
    travel: data?.travel,
    description: data?.description,
    notes: data?.notes,
    board_id: data?.schedule_board_id,
    owner: data?.id,
  };

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  useEffect(() => {
    setTeamMembers(data?.team_member);
    
 }, [data?.team_member])


 const [disable, setDisable] = React.useState(true);
 const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
 const draggleRef = React.createRef();

 const onStart = (event, uiData) => {
   const { clientWidth, clientHeight } = window.document.documentElement;
   const targetRect = draggleRef.current?.getBoundingClientRect();
   if (!targetRect) {
     return;
   }
   setBounds({
     left: -targetRect.left + uiData.x,
     right: clientWidth - (targetRect.right - uiData.x),
     top: -targetRect.top + uiData.y,
     bottom: clientHeight - (targetRect.bottom - uiData.y),
   });
 };

  return (
    <>
      <Modal
 footer={false}
 className="draggable__modal_item"
 title={
   <div
     style={{
       width: '100%',
       cursor: 'move',
     }}
     onMouseOver={() => {
       if (disable) {
         setDisable(false);
       }
     }}
     onMouseOut={() => {
      setDisable(true);
     }}
     // fix eslintjsx-a11y/mouse-events-have-key-events
     // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
     onFocus={() => {}}
     onBlur={() => {}}
     // end
   >
  {`Edit travel for event : ${data?.what} from ${moment(data?.start_time).format("h:mm a")} to ${moment(data?.end_time).format("h:mm a")} `}
   </div>
 }
 
 visible={isModalVisible}
 onOk={handleOk}
 onCancel={handleCancel}
 width={1000}
 maskClosable={false}
 modalRender={modal => (
   <Draggable
     disabled={disable}
     bounds={bounds}
     onStart={(event, uiData) => onStart(event, uiData)}
   >
     <div ref={draggleRef}>{modal}</div>
   </Draggable>
 )}
      >
        {console.log(data)}
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={defaultValues}
        >
          <Form.Item
            className="main_form__item d-none"
            label=""
            name="board_id"
            rules={[
              {
                required: eventType === "Travel Event" ? false : false,
                message: "Input your event appointment title !",
              },
            ]}
          >
            <Input placeholder="The amount of time necessary to reach the location." />
          </Form.Item>

          <Form.Item
            className="main_form__item d-none"
            label=""
            name="owner"
            rules={[
              {
                required: eventType === "Travel Event" ? false : false,
                message: "Input your event appointment title !",
              },
            ]}
          >
            <Input placeholder="The amount of time necessary to reach the location." />
          </Form.Item>
          {/* form container start */}
          <div className="form-box">
            <div className="main-flex">
              <div className="double-form-box">
                <span>Date</span>

                <Form.Item
                  rules={[
                    { required: true, message: "Please select event date !" },
                  ]}
                  label=""
                  name="date"
                  className="main_form__item"
                >
                  <DatePicker
                    placeholder="Select event date"
                    className="date-picker w-100"
                    format={"dddd D-MMM-YYYY"}
                  />
                </Form.Item>
              </div>

              <div className="double-form-box">
                <span>Time</span>
                <div className="time__box">
                  <DatePickers
                    selected={startTime}
                    onChange={(date) => onChangeStartTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                  {/* <SwapOutlined /> */}

                  <h5>To</h5>
                  <DatePickers
                    selected={endTime}
                    onChange={(date) => onChangeEndTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </div>
              </div>
            </div>
            {/* form container start */}

            {/* form container start */}
            <div className="main-flex">
              <div className="double-form-box">
                
                <span>Destination</span>
                <Form.Item
                  className="main_form__item"
                  label=""
                  name="travel"
                  rules={[
                    {
                      required: eventType === "Travel Event" ? true : false,
                      message: "Input your event appointment title !",
                    },
                  ]}
                >
                  <Input placeholder="The amount of time necessary to reach the location." />
                </Form.Item>
             
              </div>
              <div className="double-form-box">
                <span>Duration</span>

                {endTime === moment()
                  ? ""
                  : getTimeInterval(startTime, endTime)}
              </div>
            </div>
            {/* form container end */}
{/* form container end */}

<div className="main-flex">

<div className="double-form-box">
  <span>Team</span>
    <h4 className="mt-2">{team_name}</h4>
  
</div>
<div className="double-form-box">
<span>Team Member</span>
<Form.Item
                      label=""
                      name="team_member"
                      className="main_form__item"
                      style={{ width: "100%" }}
                    >
<Select
    onChange={handleUserChange}
    filterOption={false}
    tagRender={memberTagRender}
    // mode="tags"
    removeIcon={null}
    placeholder="None"
    className="board-card-modal"
    // style={{ width: "80%" }}
    defaultValue={teamMember}
  >
     { teams?.filter((x) => x.id === active_team?.team_id)?.[0]
        ?.team_members.map((elm) => (
          <Option key={elm?.user_id} value={elm?.user_id}>
            <AssigneeUserAvatar
              id={elm?.user_id}
              name={`${elm?.firstname} ${elm?.lastname}`}
            />
          </Option>
        ))}
   
  </Select>
  </Form.Item>
</div>
</div>
{/* form container end */}
          
            {/* form container end */}

            {/* <div className="main-flex d-none" >
            <div className="double-form-box">
              <span>{eventType === "Travel Event" ? "Team" : ""}</span>
              {eventType === "Travel Event" ? (
                		  <Form.Item
						  label=""
						  name="team_id"
						  className="main_form__item"
						  rules={[
							{
							  required: eventType === "Travel Event" ? false : true,
							  message: "Select team!",
							},
						  ]}
						>
						  <Select placeholder="Select team">
							{teams?.map((elm, i) => (
							  <Select.Option key={i} value={elm?.id}>
								{elm?.team_name}
							  </Select.Option>
							))}
						  </Select>
						</Form.Item>
              ) : (
                null
              )}
            </div>
          </div>  */}
            {/* form container end */}

            {/* form container start */}
            <div className="main-flex">
              <div className="double-form-box">
                <span>Travel Details</span>
                <Form.Item
                  label=""
                  name="description"
                  className="main_form__item"
                  rules={[
                    {
                      required: eventType === "Travel Event" ? true : false,
                      message: "Input the reason for the event !",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Reason for the appointment"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>
            </div>
            {/* form container end */}

            {/* form container end */}

            {/* form container start */}
            <div className="main-flex">
              <div className="double-form-box">
                <span>Note</span>
                <Form.Item label="" name="notes" className="main_form__item">
                  <TextArea
                    placeholder="Add event not here"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>
            </div>
            {/* form container end */}
            <Row>

 <Col md={24}>
              <Attachments
        cardData={data}
        updateCard={updateCard}
        url="edit-appointment-attachments"
        atttachment_url="appointment-attachments"
        Form={Form}
        isAppointment
      />
      </Col>
            </Row>

           
            {/* <div className="main-flex">
              <div className="double-form-box">
                <span>Attachment</span>
                <Form.Item
                  name="attachments"
                  label=""
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  extra=""
                  className="mt-3"
                >
                  <Upload
                    name="logo"
                    listType="picture"
                    onChange={handleUpload}
                  >
                    <Button>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div> */}
           
          </div>
       

          <Form.Item className="blockform-col col-12 mt-5 mb-3">
            <Button
              type=""
              className="float-left bg-gray-lighter"
              htmlType="button"
              style={{ width: "120px" }}
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="success"
              className="float-right bg-success text-white"
              style={{ width: "120px" }}
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditTravelEvent;
