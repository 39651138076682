import React from "react";
import { Divider } from "antd";
import { CloseOutlined, SafetyOutlined } from "@ant-design/icons";

function WorkStatus(props) {
  // destructure work status props data
  const {
    setWorkStatusBox,
    work_status,
    workStatus,
    workStatusBox,
    setWorkStatus,
    changeWorkStatus,
  } = props;
  return (
    <>
      <div className="item__box">
        <span onClick={() => setWorkStatusBox(true)}>
          <SafetyOutlined /> <span className="icon__text">Work Status:</span>{" "}
          <span>
            {work_status?.filter((elm) => elm.id === workStatus)?.[0]?.title}
          </span>
        </span>
        {workStatusBox === true ? (
          <div className="dropdown__box">
            <div className="heading__box">
              <span>Works Status</span>
              <CloseOutlined
                onClick={() => {
                  setWorkStatusBox(false);
                }}
              />
            </div>
            <Divider className="dropdown__divider" />
            {work_status?.map((elm, index) => (
              <div
                className="dropdown__box_menu"
                key={index}
                onClick={() => {
                  setWorkStatus(elm?.id);
                  setWorkStatusBox(false);
                  changeWorkStatus(elm?.id);
                }}
              >
                {elm?.title}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default WorkStatus;
