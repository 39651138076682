import moment from "moment";
import React, { useState } from "react";
// import SwitchCheckbox from "../../GlobalComponents/SwitchCheckbox";
import { Button } from 'antd';
import {LeftOutlined, RightOutlined, SyncOutlined} from "@ant-design/icons";

function RBCToolbar(props) {
  const [viewState, setViewState] = useState("month");
  const [active, setActive] = useState(1);
  
  const getCustomToolbar = () => {
    // const toolbarDate = props.date;
    const goToDayView = () => {
      props.onView("day");
      setViewState("day");
      setActive(3)
    };
    const goToWeekView = () => {
      props.onView("week");
      setViewState("week");
      setActive(2)
    };
    const goToMonthView = () => {
      props.onView("month");
      setViewState("month");
      setActive(1)
    };
    const goToAgendaView = () => {
      props.onView("agenda");
      setViewState("agenda");
      setActive(4)
    };
    const goToBack = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "month") {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      } else if (view === "week") {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 1,
          1
        );
      }
      props.onNavigate("prev", newDate);
    };
    const goToNext = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "month") {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      } else if (view === "week") {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 1,
          1
        );
      }
      props.onNavigate("next", newDate);
    };

    const goToToday = () => {
      const now = new Date();
      props.date.setMonth(now.getMonth());
      props.date.setYear(now.getFullYear());
      props.date.setDate(now.getDate());
      props.onNavigate("current");
    };

    const goToBackYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() - 1, 1);
      props.onNavigate("prev", newDate);
    };

    const goToNextYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() + 1, 1);
      props.onNavigate("next", newDate);
    };

    const month = () => {
      const date = moment(props.date);
      let month = date.format("MMMM");
      let day = date.format("D");

      return (
        <span className="rbc-toolbar-label rbc-date">
          <i className="far fa-calendar"></i> <span>{`${month} ${day}`}</span>
        </span>
      );
    };
    const year = () => {
      const date = moment(props.date);
      let year = date.format("YYYY");

      return (
        <span className="rbc-btn-group">
          {viewState === "month" && (
            <button type="button" onClick={goToBackYear}>
              <span className="prev-icon">&#8249;&#8249;</span>
            </button>
          )}
          <span className="rbc-toolbar-label">{year}</span>
          {viewState === "month" && (
            <button type="button" onClick={goToNextYear}>
              <span className="prev-icon">&#8250;&#8250;</span>
            </button>
          )}
        </span>
      );
    };

    const day = () => {
      let view = viewState;
      const date = moment(props.date);
      let day;
      if (view === "day") {
        day = date.format("ddd") + " " + date.format("Do");
      }
      return <span className="rbc-toolbar-label">{day}</span>;
    };

    return (
      <div className="rbc-toolbar">
        {/* <div className="rbc-toolbar-item-1">
          <div className="flex-row">
           
            <p className="text-muted" style={{ margin: "0" }}>              
              Study Time
            </p>
          </div>
        </div> */}
        
          <div className="d-flex align-items-center">
          <Button style={{ minWidth:"100px" }} type="primary" size="small" ghost onClick={goToBack}>
          <LeftOutlined style={{ fontSize:"18px" }}/>
          
    </Button>
    <Button ghost style={{ minWidth:"100px" }} size="small" className="ml-2 mr-2" onClick={goToToday}><SyncOutlined /> Today</Button>
    <Button style={{ minWidth:"100px" }} type="primary" size="small" ghost onClick={goToNext}>
    <RightOutlined style={{ fontSize:"18px" }}/>
    </Button>
     
          </div>
          <h4>{month()}  </h4>
          
          <div className="d-flex align-items-center">
          
          <Button  type="primary" size="small" style={{ minWidth:"100px" }} className={`ml-2 ${active === 1 ? "bg-primary text-white border-0" : ""}`} onClick={goToMonthView}>Month</Button>
          <Button size="small" type="primary" style={{ minWidth:"100px" }} className={`ml-2 ${active === 2 ? "bg-primary text-white border-0" : ""}`} onClick={goToWeekView} >Week</Button>
          <Button size="small" type="primary" style={{ minWidth:"100px" }} className={`ml-2 ${active === 3 ? "bg-primary text-white border-0" : ""}`} onClick={goToDayView}>Day</Button>
          <Button size="small" type="primary" style={{ minWidth:"100px" }} className={`ml-2  ${active === 4 ? "bg-primary text-white border-0" : ""}`} onClick={goToAgendaView}>List</Button>
          </div>
          {/* <span className="rbc-btn-group">
            <button className="" onClick={goToMonthView}>
              <span className="label-filter-off">Month</span>
            </button>
            <button className="" onClick={goToDayView}>
              <span className="label-filter-off">Day</span>
            </button>
            <button className="" onClick={goToWeekView}>
              <span className="label-filter-off">Week</span>
            </button>
            <button className="" onClick={goToAgendaView}>
              <span className="label-filter-off">Agenda</span>
            </button>
          </span> */}
    
      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
}

export default RBCToolbar;