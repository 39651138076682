export const GET_OBJECTS = "GET_OBJECTS"
export const GET_OBJECTS_SUCCESS = "GET_OBJECTS_SUCCESS"
export const GET_OBJECTS_FAILED = "GET_OBJECTS_FAILED"

export const SHOW_OBJECTS = "SHOW_OBJECTS"
export const SHOW_OBJECTS_SUCCESS = "SHOW_OBJECTS_SUCCESS"
export const SHOW_OBJECTS_FAILED = "SHOW_OBJECTS_FAILED"

export const CREATE_OBJECTS = "CREATE_OBJECTS"
export const CREATE_OBJECTS_SUCCESS = "CREATE_OBJECTS_SUCCESS"
export const CREATE_OBJECTS_FAILED = "CREATE_OBJECTS_FAILED"

export const UPDATE_OBJECTS = "UPDATE_OBJECTS"
export const UPDATE_OBJECTS_SUCCESS = "UPDATE_OBJECTS_SUCCESS"
export const UPDATE_OBJECTS_FAILED = "UPDATE_OBJECTS_FAILED"

export const DELETE_OBJECTS = "DELETE_OBJECTS"
export const DELETE_OBJECTS_SUCCESS = "DELETE_OBJECTS_SUCCESS"
export const DELETE_OBJECTS_FAILED = "DELETE_OBJECTS_FAILED"
