import React, {useState, useCallback, useEffect} from 'react'
import { Switch, Spin, Row, Col, Input, Button, Tooltip, Popconfirm } from "antd";
import {
    PlusOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    StarFilled,
    DownloadOutlined,
    PlusCircleOutlined,
    CloseCircleOutlined,
    TagsOutlined,
  } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import AvatarStatus from '../AvatarStatus';
import AttachmentModal from './AttachmentModal';
import zip from "../../../assets/attachments/zip.png";
import pdf from "../../../assets/attachments/pdf.jpg";
import { deleteAttachments, updateAttachments } from 'redux/actions';

function Attachments(props) {
  const dispatch = useDispatch();
  
    const {cardData,updateCard, url, atttachment_url, isAppointment} = props;
    // create enable attachments states 
    const [enableAttachment, setEnableAttachment] = useState(false);

    // create on change attachments switch function
    const onChangeAttachmentSwitch = useCallback((checked) => setEnableAttachment(checked), [])

    // create attachments visibility state 
    const [attachmentVisible, setAttachmentVisible] = useState(false);

    const [attachmentsList, setAttachmentsList] = useState(cardData?.attachments);
  
    useEffect(() => {
      setAttachmentsList(cardData?.attachments);
    }, [cardData?.attachments, cardData?.cover]);

    const onCoverChange = async(id) => {
      let data = { id: id, cover: 1, card_id: cardData?.id };
      await dispatch(updateAttachments(data, atttachment_url));
    };
  
    const removeCover = async (id) => {
      let data = { id: id, cover: 0 };
      await dispatch(updateAttachments(data, atttachment_url));
    };
  
    const confirm = async(id) => {
      await dispatch(deleteAttachments(id, atttachment_url));
    }

    const [enableLabel, setEnableLabel] = useState(0);
  
    const onEnableLabel = (id) => {
      setEnableLabel(id);
    };
  
    const [attachmentLabel, setAttachmentLabel] = useState("");
  
    const onChangeAttachmentLable = async(id) => {
      let data =  { id: id, label: attachmentLabel, card_id: cardData?.id };
      await dispatch(updateAttachments(data, atttachment_url));
      setEnableLabel(0);
    };
  
    function cancel(e) {}

    return (
        <React.Fragment>
             <div className="mt-2 mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex  align-items-center">
            <h4 className="mb-1">
              <CloudUploadOutlined /> <span className="ml-3">Attachments</span>
            </h4>

            {enableAttachment ?
               <div
               className="ml-2"
               style={{ marginTop: "-5px", cursor: "pointer" }}
               onClick={() => setAttachmentVisible(true)}
             >
               <AvatarStatus
                 icon={<PlusOutlined />}
                 name=""
                 type="blue"
                 subTitle=""
                 size={20}
               />
             </div>
            : ""}
         
          </div>

          <Switch
            defaultChecked={enableAttachment}
            onChange={onChangeAttachmentSwitch}
          />
        </div>


        {enableAttachment === true ? (
        <>
          <div className="mb-4 mt-3">
            {attachmentsList?.length > 0 ? (
              <div className="d-flex mb-3">
                <div className="mr-3 font-size-md">
                  {/* <PaperClipOutlined /> */}
                </div>
                <div className="w-100">
                  {attachmentsList === undefined ? (
                    <div className="text-center mt-4">
                      <Spin />
                    </div>
                  ) : (
                    <>
                      <Row gutter={16}>
                        {attachmentsList?.map((elm) => (
                          <Col sm={24} md={24} key={elm?.id}>
                            <div className="attachment__view mb-2">
                              <div className="attachment__image__label">
                                <div className="p-0 image-cover">
                                  {elm?.name?.split(".").pop() === "jpeg" ||
                                  elm?.name?.split(".").pop() === "png" ||
                                  elm?.name?.split(".").pop() === "gif" ? (
                                    <img
                                      className="img-fluid"
                                      style={{ height: "40px", width: "56px" }}
                                      alt="example"
                                      src={elm?.name}
                                    />
                                  ) : elm?.name?.split(".").pop() === "pdf" ? (
                                    <img
                                      className="img-fluid"
                                      style={{ height: "40px", width: "56px" }}
                                      alt="example"
                                      src={pdf}
                                    />
                                  ) : elm?.name?.split(".").pop() === "zip" ? (
                                    <img
                                      className="img-fluid"
                                      style={{ height: "40px", width: "56px" }}
                                      alt="example"
                                      src={zip}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="attachment__input_box">
                                  <div className="checklist_form">
                                    <Input
                                      className={`attachment__input mr-2`}
                                      bordered={
                                        enableLabel === elm?.id ? true : false
                                      }
                                      defaultValue={
                                        elm?.label === null ? "" : elm?.label
                                      }
                                      onChange={(e) =>
                                        setAttachmentLabel(e.target.value)
                                      }
                                    />

                                    {enableLabel === elm?.id ? (
                                      <>
                                        <Button
                                          className="mr-1 attachment_btn"
                                          type="danger"
                                          htmlType="button"
                                          size="small"
                                          onClick={() => onEnableLabel(0)}
                                        >
                                          <CloseCircleOutlined />
                                        </Button>
                                        <Button
                                          size="small"
                                          className="attachment_btn"
                                          type="primary"
                                          htmlType="button"
                                          onClick={() =>
                                            onChangeAttachmentLable(elm?.id)
                                          }
                                        >
                                          <PlusCircleOutlined />
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="attachment__icon__box">
                                <div className="attachment-icons">
                                  <Tooltip title="add label">
                                    <TagsOutlined
                                      className="font-size-md"
                                      onClick={() => onEnableLabel(elm?.id)}
                                    />
                                  </Tooltip>
                                </div>

                                <div className="attachment-icons">
                                  <Tooltip title="download attachment">
                                    <DownloadOutlined className="font-size-md" />
                                  </Tooltip>
                                </div>
                                {!isAppointment ? 
                                     <div className="attachment-icons">
                                     {elm?.cover === 1 ? (
                                       <div className=" text-warning">
                                         <Tooltip title="remove cover">
                                           {" "}
                                           <StarFilled
                                             className="font-size-md"
                                             onClick={() => {removeCover(elm?.id)}}
                                           />
                                         </Tooltip>
                                       </div>
                                     ) : (
                                       <div>
                                        <Tooltip title="set cover">
                                         
                                         <StarFilled
                                           className="font-size-md"
                                           onClick={() => {onCoverChange(elm?.id);}}
                                         />
                                       </Tooltip>
                                       </div>
                                   
                                     )}
                                   </div>
                                : "" }
                           
                                {/* <div className="attachment-icons">
                        <Tooltip title="remove cover">
                        <RollbackOutlined  onClick={() => removeCover(elm?.id)}/>
                        </Tooltip>
                        </div> */}
                                <div className="attachment-icons">
                                  <Tooltip title="delete attachment">
                                    <Popconfirm
                                      title="Are you sure you want to delete this attachment?"
                                      onConfirm={() => confirm(elm?.id)}
                                      onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined className="font-size-md text-danger" />
                                    </Popconfirm>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {/* <h4>Attachments</h4> */}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        ""
      )}
      </div>

      <AttachmentModal cardData={cardData} updateCard={updateCard} url={url} attachmentVisible={attachmentVisible} setAttachmentVisible={setAttachmentVisible}/>
        </React.Fragment>
    )
}
 
export default React.memo(Attachments);
