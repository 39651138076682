import {
  Card,
} from "antd";
import React, { useEffect } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeam,
  getTeamMembers,
  getTeamUsers,
} from "redux/actions";
import MyTeam from "./MyTeam";

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.AuthStore?.user?.user);
  const [isCancelled, setIsCancelled]= React.useState(false);
  useEffect(() => {
    if(!isCancelled) {
      const getTeamData = async() => {
        await  dispatch(getTeam());
      }

      getTeamData();
    }else{
      const getTeamData = async() => {
        await  dispatch(getTeam());
      }

      getTeamData();
    }

    return () => {
      setIsCancelled(true);
    }
   
  }, []);

  useEffect(() => {
    if(!isCancelled) {
      const getTeamUserData = async() => {
       await dispatch(getTeamUsers());
      }

      getTeamUserData();
    }else{
      const getTeamUserData = async() => {
        await dispatch(getTeamUsers());
       }
 
       getTeamUserData();
    };

   return () => {
    setIsCancelled(true);
   }
    
  }, []);

  const state = useSelector((state) => state.TeamStore);
  const team_success =  state?.success;

  useEffect(() => {
    if(!isCancelled) {
      if (team_success === true) {
        const getTeamData = async() => {
          await  dispatch(getTeam());
        }
        getTeamData();
        
      }

     
     
    }else{
      if (team_success === true) {
        const getTeamData = async() => {
          await  dispatch(getTeam());
        }
        getTeamData();
        
      }
    }

    return () => {
      setIsCancelled(true);
    }

   
  }, [team_success]);



  return (
    <div className="container">
      <div className="heading__box mb-5 d-flex justify-content-between align-items-center ">
        <h2> Manage Teams</h2>
      </div>

      <Card>
      <MyTeam />
      </Card>
    </div>
  );
};

export default Home;
