import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {  createTeam } from 'redux/actions';

function AddTeam(props) {
    const {visible, handleOk, handleCancel} = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      };

        const onFinish = values => {
          dispatch(createTeam(values));
          form.resetFields();
          handleOk();
        };
      
        const onFinishFailed = errorInfo => {
          console.log('Failed:', errorInfo);
        };

    return (
        <div>
        
          <Form
          form={form}
      {...layout}
      name="team-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Team Name"
        name="team_name"
        rules={[{ required: true, message: 'Please input your team name !' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  
        </div>
    )
}

export default React.memo(AddTeam)
