import * as types from "./constants"

const initialStates = {
    schedules_item:[],
    // schedules_range:[],
    single_schedule_item:{},
    loading:false,
    error:null,
    success:false,
}

 const ScheduleItemStore = (state = initialStates, action) => {
    //setup schedules_item reducers
   switch (action.type) {
       case types.GET_SCHEDULE_ITEM :
       return {...state, loading:true}
       case types.GET_SCHEDULE_ITEM_SUCCESS :
       return {...state, loading:false, schedules_item:action.schedules_item}
       case types.GET_SCHEDULE_ITEM_FAILED :
       return {...state, loading:false, error:action.error, }

    //    case types.GET_SCHEDULE_RANGE :
    //    return {...state, loading:true}
    //    case types.GET_SCHEDULE_RANGE_SUCCESS :
    //    return {...state, loading:false, schedules_range:action.schedules_range}
    //    case types.GET_SCHEDULE_RANGE_FAILED :
    //    return {...state, loading:false, error:action.error, }

       case types.SHOW_SCHEDULE_ITEM :
       return {...state}
       case types.SHOW_SCHEDULE_ITEM_SUCCESS :
      
       return {...state, [action.single_schedule_item?.schedule?.id]:action.single_schedule_item}
       case types.SHOW_SCHEDULE_ITEM_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_SCHEDULE_ITEM :
       return {...state, loading:true, success:false}
       case types.CREATE_SCHEDULE_ITEM_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_SCHEDULE_ITEM_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_SCHEDULE_ITEM :
       return {...state, loading:true, success:false}
       case types.UPDATE_SCHEDULE_ITEM_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_SCHEDULE_ITEM_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_SCHEDULE_ITEM :
       return {...state, loading:true, success:false}
       case types.DELETE_SCHEDULE_ITEM_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_SCHEDULE_ITEM_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default ScheduleItemStore
