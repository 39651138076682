import React from "react";

function TitleInput(props) {
  // destructure props data.
  const { Form, textInput, Input } = props;

  return (
    <React.Fragment>
      <Form.Item
        name="title"
        className="mb-0"
        rules={[{ required: true, message: "Please enter task title" }]}
      >
        <Input className=" input" placeholder="Enter  Title" ref={textInput} />
      </Form.Item>
    </React.Fragment>
  );
}

export default TitleInput;
