import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";

export const  api = {
  index(data){
      axios.defaults.withCredentials = false;
      console.log(data);
      return authAxios
        .get(`/project-assign-user?${data !== undefined ? data : ""}`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message);
          // if(error.response.data.message === "Unauthenticated."){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear(); 
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },

  allAssignMembers(data){
    axios.defaults.withCredentials = false;
    
    return authAxios
      .get(`/project-assign-user?${data !== undefined ? data : ""}`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message);
        // if(error.response.data.message === "Unauthenticated."){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear(); 
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},

  
  store(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .post("/project-assign-user", data)
        .then((response) => {
          // successNotify(response.data.message);
    
          // message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
         
          if(error.response.status === 404){
            message.error(error.response.data.error);
          }
          
          return { error };
        });
  },
  update(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .put(`/project-assign-user/${data.id}`, data)
        .then((response) => {
          message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  destroy(data){
    
      axios.defaults.withCredentials = false;
      return authAxios
        .delete(`/project-assign-user/${data}`)
        .then((response) => {
          message.success(response.data.message, 2);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
}

