import { Button, Card, Col, DatePicker, Divider, Dropdown, Form, Input, Menu, Row, Select, Tag, Upload } from 'antd';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { memberIds } from '../ScrumboardData';
import { AssigneeAvatar, AssigneeUserAvatar,  } from '../utils';
import { 
	SaveOutlined,
	FileTextOutlined,
	PaperClipOutlined,
	EllipsisOutlined,
	StarOutlined,
	DeleteOutlined,
	StarFilled,
	CommentOutlined,
	UserOutlined,
	UploadOutlined,
	PlayCircleOutlined,
	PauseCircleOutlined,
	CloseOutlined,
	FileDoneOutlined,
	SafetyOutlined,
	ReloadOutlined,
	CalendarOutlined,
	ContainerOutlined,
	UnorderedListOutlined,
	CloudUploadOutlined,
	EditOutlined,
} from '@ant-design/icons';

import SunEditor from 'suneditor-react';
import {
	align,
	font,
	fontColor,
	fontSize,
	formatBlock,
	hiliteColor,
	horizontalRule,
	lineHeight,
	list,
	paragraphStyle,
	table,
	template,
	textStyle,
	image,
	link
  } from "suneditor/src/plugins";
import { createCard } from 'redux/actions';
import moment from 'moment';
const { Option } = Select;

const memberTagRender = (props) => <AssigneeAvatar id={props.value} size={25} />

function labelTagRender(props) {
  const { value } = props;
  return (
		<Tag className="my-1">
			<div className="d-flex align-items-center">
				{/* <Badge color={getLabelsColor(value)} /> */}
				{/* <Badge color="green" /> */}
				<span>{value}</span>
				
			</div>
		</Tag>
  );
}

function AddCard({cardData = {},title, onCancel, listId, form, setEnableFocus, enableFocus, textInput}) {
	const [priorityBox, setPriorityBox] = useState(false);
	
	const [priority, setPriority] = useState("Normal");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [priorityData] = useState([
		{value:"Low"},
		{value:"Normal"},
		{value:"High"},
		{value:"Urgent"},
	])


const changeStartDate = (startDate) => {
   setStartDate(startDate)
}

const changeEndDate = (endDate) => {
  setEndDate(endDate);
   
}

const changePiriority = (priority) => {
   setPriority(priority);
}

    const dispatch = useDispatch();

	const [uploadImage, setUploadImage] = useState([]);

	function uploadCallback(file) {
		let uploadedImages = uploadImage;
		const imageObject = {
			file: file,
			localSrc: URL.createObjectURL(file),
		  }

		  uploadedImages.push(imageObject);


		return new Promise(
		  (resolve, reject) => {
			resolve({ data: { link: imageObject.localSrc } });
		  }
		);
	  }

	const user = useSelector(state => state.AuthStore?.user);
	const team_item = useSelector((state) => state.TeamStore?.teams);
	const active = team_item?.active;
	const [base64, setBase64] = useState([]);
	const [editorState, setDescription] = useState("");
	
	  const handleDescription = (editorState) => {
		setDescription(editorState);
	  }
	

    let lists = [];
	cardData?.card_labels?.forEach(r => {
		const item = r?.name;
		lists.push(item);
	})


    const submitUpdate = values => {
		let data = values;
		data.start_date = startDate;
		data.end_date = endDate;
		data.priority = priority;
		data.description = editorState;
		data.team_id = active?.team_id;
		data.attachments = base64
	    dispatch(createCard(data, "tasklist-cards"));
		onCancel();
		form.resetFields();
       
	}

    const normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };
    
      const handleUpload = (info) => {
        let fileList = [...info.fileList];
        // Accept 5 files only
        fileList = fileList.slice(-5);
        fileList.forEach(function (file, index) {
          let reader = new FileReader();
          reader.onload = (e) => {
            file.base64 = e.target.result;
          };
          reader.readAsDataURL(file.originFileObj);
        });
        setBase64(fileList );
      };

	  React.useEffect(() => {
		setEnableFocus(true);
		if(enableFocus === true) {
			textInput.current?.focus()
		}
		
	}, [enableFocus, textInput])
	  
    return (
        <>
		<Row>
			<Col md={18} className="pr-4 pb-4 pt-3">
			<Form name="edit-card-ref" form={form} layout="vertical" onFinish={submitUpdate} initialValues={{ board_id:listId }}>
			
			<Form.Item name="title" className="mb-0"
			
			rules={[
				{ required: true, message: "Please enter task title" },
			  ]}
			  >
				<Input className=" input" placeholder="Enter Task Title" ref={textInput}/>
			</Form.Item>
			<Form.Item style={{ display:"none" }} name="board_id" className="mb-0">
				<Input className="board-card-modal input"/>
			</Form.Item>
			<Form.Item className="mb-3 mt-2">
				<p>List Item: <span className="font-weight-semibold">{title}</span></p>
			</Form.Item>
			{/* <Form.Item label="Assigned to" name="members" className="blockform-col col-3">
				<Select 
					filterOption={false} 
					tagRender={memberTagRender} 
					mode="tags" 
					removeIcon={null}
					placeholder="None"
					className="board-card-modal select"
				>
					{
						memberIds.map(elm => (
							<Option key={elm} value={elm}>
								<AssigneeAvatar id={elm} name/>
							</Option>
						))
					}
				</Select>
			</Form.Item> */}
			{/* <Form.Item label="Start Date" name="start_date" className="blockform-col col-3">
				<DatePicker placeholder="Due date unset" className="board-card-modal date-picker w-100" format={'L'} />
			</Form.Item>
            <Form.Item label="Due Date" name="end_date" className="blockform-col col-3">
				<DatePicker placeholder="Due date unset" className="board-card-modal date-picker w-100" format={'L'} />
			</Form.Item> */}
			{/* <Form.Item label="Labels" name="labels" className="blockform-col col-3">
				<Select 
					filterOption={false} 
					tagRender={labelTagRender} 
					mode="tags" 
					removeIcon={null}
					placeholder="None"
					className="board-card-modal select"
				>
					{
						lists?.map((elm, index) => (
							<Option key={index} value={elm}>
								<div className="d-flex align-items-center">
								
									<span>{elm}</span>
									
								</div>
							</Option>
						))
					}
				</Select>
			</Form.Item> */}
			<Divider className="mt-0"/>
			<div className="d-flex">
				<div className="mr-3 font-size-md">
					<FileTextOutlined />	
				</div>
				<div className="w-100">
					<h4>Description</h4>
					<SunEditor   width="100%"
			onChange={handleDescription}
            // autoFocus={true}
            lang="en"
             setOptions={{
              showPathLabel: false,
              minHeight: "30vh",
              maxHeight: "40vh",
              placeholder: "Enter your text here!!!",
			  popupDisplay : 'local',
			  resizingBar : true,
              plugins: [
                align,
                // font,
                // fontColor,
                // fontSize,
                formatBlock,
                hiliteColor,
                horizontalRule,
                // lineHeight,
                list,
                // paragraphStyle,
                table,
                template,
                textStyle,
                image,
                link
              ],
              buttonList: [
                ["undo", "redo"],
                // ["font", "fontSize", "formatBlock"],
                // ["paragraphStyle"],
                [
                  "bold",
                //   "underline",
                //   "italic",
                //   "strike",
                //   "subscript",
                //   "superscript"
                ],
                // ["fontColor", "hiliteColor"],
                ["removeFormat"],
        // Line break
                ["outdent", "indent"],
                [
					"align", 
					"horizontalRule", 
					"list",
				//  "lineHeight"
				],
                ["table", "link", "image"]
              ],
              formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
              // font: [
              //   "Arial",
              //   "Calibri",
              //   "Comic Sans",
              //   "Courier",
              //   "Garamond",
              //   "Georgia",
              //   "Impact",
              //   "Lucida Console",
              //   "Palatino Linotype",
              //   "Segoe UI",
              //   "Tahoma",
              //   "Times New Roman",
              //   "Trebuchet MS"
              // ]
            }}
			/>
					{/* <Form.Item name="description">
						<Input.TextArea className="board-card-modal text-area"/>
					</Form.Item> */}
				</div>
			</div>
          <div className="mt-5 mb-5">
			  <h4 className="mb-3"><CloudUploadOutlined /> Attachments</h4>
		   <Form.Item
        name="attachments"
        label=""
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
		
      >
        <Upload name="logo" listType="picture" onChange={handleUpload}>
          <Button>
            <UploadOutlined /> Click to upload
          </Button>
        </Upload>
      </Form.Item>

		  </div>
	
			<Form.Item className="mb-0">
                <Button type="default" htmlType="button" onClick={onCancel}>Cancel</Button>
				<Button type="default" className="bg-success text-white float-right" htmlType="submit">Save</Button>
			</Form.Item>
		</Form>  
       

			</Col>

			<Col md={6} className="card__right p-3">
			<div>
			  <h5>Settings</h5>
			  <div className="item__box">
				<div class="icon__box">
				  <CalendarOutlined /> <span className="icon__text">Start Date:</span>
				</div>
				<div>
				 <DatePicker bordered={false} defaultValue={moment()} format="Do MMM YY"  onChange={changeStartDate}/>
			  </div>
			  </div>
			  <div className="item__box">
				<div class="icon__box">
				  <FileDoneOutlined /> <span className="icon__text">Due Date: </span>
				</div>
				<div>
				 <DatePicker bordered={false} defaultValue={moment()} format="Do MMM YY"  onChange={changeEndDate}/>
			  </div>
			  </div>
			  <div className="item__box" >
				<span onClick={() => setPriorityBox(true)}>
				  <SafetyOutlined /> <span className="icon__text">Priority:</span> <span>{priority}</span>
				</span>
				{priorityBox === true ? 
			   <div className="dropdown__box">
			   <div className="heading__box"><span>Priority</span><CloseOutlined onClick={() => {setPriorityBox(false)}}/></div>
			   <Divider className="dropdown__divider"/>
			   {priorityData.map((elm, index) => 
				<div className="dropdown__box_menu" key={index} onClick={() => {setPriority(elm?.value); setPriorityBox(false); changePiriority(elm?.value)}}>
					{elm?.value}
				</div>
			   )}
			  
		   </div> 
				: ""}
				
			  </div>
			  {/* <div className="item__box">
							  <span>Client: <span>Visible</span></span>
						  </div> */}
			</div>
			</Col>
		</Row>
         	    </>
    )
}


export default AddCard
