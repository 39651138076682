import * as types from "./constants"

const initialStates = {
    comments:[],
    single_comment:{},
    loading:false,
    error:null,
    success:false,
}

 const CommentStore = (state = initialStates, action) => {
    //setup comments reducers
   switch (action.type) {
       case types.GET_COMMENTS :
       return {...state, loading:true}
       case types.GET_COMMENTS_SUCCESS :
       return {...state, loading:false, comments:action.comments}
       case types.GET_COMMENTS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_COMMENTS :
       return {...state}
       case types.SHOW_COMMENTS_SUCCESS :
       return {...state, single_comment:action.single_comment}
       case types.SHOW_COMMENTS_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_COMMENTS :
       return {...state, loading:true, success:false}
       case types.CREATE_COMMENTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_COMMENTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_COMMENTS :
       return {...state, loading:true, success:false}
       case types.UPDATE_COMMENTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_COMMENTS_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_COMMENTS :
       return {...state, loading:true, success:false}
       case types.DELETE_COMMENTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_COMMENTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default CommentStore
