import * as types from "./constants"

export const getObjects = (objects) => {
    return {
        type:types.GET_OBJECTS,
        payload:objects
    }
}

export const showObjects = (single_objects) => {
    return {
        type:types.SHOW_OBJECTS,
        payload:single_objects
    }
}

export const createObjects = (objects, object_type, history) => {
    return {
        type:types.CREATE_OBJECTS,
        payload:{objects, object_type, history}
    }
}

export const updateObjects = (objects) => {
    return {
        type:types.UPDATE_OBJECTS,
        payload:objects
    }
}

export const deleteObjects = (objects) => {
    return {
        type:types.DELETE_OBJECTS,
        payload:objects
    }
}
