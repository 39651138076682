export const GET_ASSIGNED_MEMBERS = "GET_ASSIGNED_MEMBERS"
export const GET_ASSIGNED_MEMBERS_SUCCESS = "GET_ASSIGNED_MEMBERS_SUCCESS"
export const GET_ASSIGNED_MEMBERS_FAILED = "GET_ASSIGNED_MEMBERS_FAILED"

export const GET_ALL_ASSIGNED_MEMBERS = "GET_ALL_ASSIGNED_MEMBERS"
export const GET_ALL_ASSIGNED_MEMBERS_SUCCESS = "GET_ALL_ASSIGNED_MEMBERS_SUCCESS"
export const GET_ALL_ASSIGNED_MEMBERS_FAILED = "GET_ALL_ASSIGNED_MEMBERS_FAILED"

export const SHOW_ASSIGNED_MEMBERS = "SHOW_ASSIGNED_MEMBERS"
export const SHOW_ASSIGNED_MEMBERS_SUCCESS = "SHOW_ASSIGNED_MEMBERS_SUCCESS"
export const SHOW_ASSIGNED_MEMBERS_FAILED = "SHOW_ASSIGNED_MEMBERS_FAILED"

export const CREATE_ASSIGNED_MEMBERS = "CREATE_ASSIGNED_MEMBERS"
export const CREATE_ASSIGNED_MEMBERS_SUCCESS = "CREATE_ASSIGNED_MEMBERS_SUCCESS"
export const CREATE_ASSIGNED_MEMBERS_FAILED = "CREATE_ASSIGNED_MEMBERS_FAILED"

export const UPDATE_ASSIGNED_MEMBERS = "UPDATE_ASSIGNED_MEMBERS"
export const UPDATE_ASSIGNED_MEMBERS_SUCCESS = "UPDATE_ASSIGNED_MEMBERS_SUCCESS"
export const UPDATE_ASSIGNED_MEMBERS_FAILED = "UPDATE_ASSIGNED_MEMBERS_FAILED"

export const DELETE_ASSIGNED_MEMBERS = "DELETE_ASSIGNED_MEMBERS"
export const DELETE_ASSIGNED_MEMBERS_SUCCESS = "DELETE_ASSIGNED_MEMBERS_SUCCESS"
export const DELETE_ASSIGNED_MEMBERS_FAILED = "DELETE_ASSIGNED_MEMBERS_FAILED"
