import React from 'react'
import {FileDoneOutlined} from "@ant-design/icons";
import {DatePicker} from "antd";
import moment from "moment";
import { updateCard } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

function DueDate(props) {
  const dispatch = useDispatch();

  const {card_data, url} = props;
 
  const user_state = useSelector((state) => state.AuthStore?.user);
  const user = user_state?.user;
  
  const [deadline, setDeadline] = React.useState(moment());
  const changeEndDate = (dateTime, dateString) => {
      let data = card_data;
      data.deadline = dateString;
      setDeadline(card_data?.deadline);
      data.attachments = [];
      data.isDueDateChange = true;
      dispatch(updateCard(data, url));
    };
 
  React.useEffect(() => {
    setDeadline(card_data?.deadline);
   
  }, [card_data?.deadline])


    return (
        <React.Fragment>
            
            <div className="item__box mb-4">
              <div class="icon__box">
                <FileDoneOutlined />{" "}
                <span className="icon__text">Deadline: </span>
              </div>
              <div className="deadline_date_box">
              <DatePicker
                 value={moment(deadline).tz(user?.timezone)}
                 format={`DD-MMM-YYYY hh:mm a z`}
                 onChange={changeEndDate}
                 bordered={false}
                 showTime={true}
                 minuteStep={5}
               />
               
              </div>
            </div>
        </React.Fragment>
    )
}

export default DueDate
