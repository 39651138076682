import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";

function Header(props) {
  //const create dispatch function
  const dispatch = useDispatch();

  // destructure props data;
  const { cardData, updateCard, url } = props;

  // create title state
  const [title, setTitle] = useState("");
  

  // create enable title state
  const [enableTitle, setEnableTitle] = useState(false);

  // load default data
  useEffect(() => {
    setTitle(cardData?.title);
  }, [cardData]);

  // handle enable title function
  const handleEnableTitle = useCallback(() => setEnableTitle(true), []);



  // store update title
  const submitUpdate = useCallback((e) => {

    let data = cardData;
    data.attachments = [];
    data.title = title;
    data.isTitleUpdate = true;
    
    // save the title changes
    dispatch(updateCard(data, url));
    
    setEnableTitle(false);
    setTitle(title);
  }, [cardData?.id, title, url]);


  return (
    <React.Fragment>
      {enableTitle === false ? (
        <h3 className="card__title" onClick={handleEnableTitle}>
          {title}
        </h3>
      ) : (
        <div className="card__title__form__box">
          <div className="title__form__box"  style={{ width: "85%" }}>
            <Form.Item name="title" className="mb-0 flex">
              <Input
                className="input"
                onChange={(e) => setTitle(e.target.value)}
               
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item className="text-right mb-0">
              <Button
                type="default"
                className=""
                htmlType="button"
                onClick={() => setEnableTitle(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>

          <div>
            <Form.Item className="text-right mb-0">
              <Button onClick={submitUpdate} className="bg-success text-white" type="primary" htmlType="button">
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Header;
