import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { list_api, card_api } from 'services/NotebookNoteService';


// Board Redux States
import * as types from './constants';

function* getNotebookBoard(action)
{
    const {response, error} = yield call(list_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_NOTEBOOK_BOARD_SUCCESS, journal_board:response.data});
    } else {
        yield put({type: types.GET_NOTEBOOK_BOARD_FAILED, error:error});
    }
}

function* showNotebookBoard(action)
{
    const {response, error} = yield call(list_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_NOTEBOOK_BOARD_SUCCESS, single_journal_board:response.data});
    } else {
        yield put({type: types.SHOW_NOTEBOOK_BOARD_FAILED, error:error});
    }
}

function* createNotebookBoard(action)
{
    const {response, error} = yield call(list_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_NOTEBOOK_BOARD_SUCCESS, notebook_board:response.data});
    } else {
        yield put({type: types.CREATE_NOTEBOOK_BOARD_FAILED, error:error});
    }
}

function* updateNotebookBoard(action)
{
    const {response, error} = yield call(list_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_NOTEBOOK_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_NOTEBOOK_BOARD_FAILED, error:error});
    }
}

function* updateNotebookBoardIndex(action)
{
    const {response, error} = yield call(list_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_NOTEBOOK_BOARD_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_NOTEBOOK_BOARD_INDEX_FAILED, error:error});
    }
}

function* deleteNotebookBoard(action)
{
    const {response, error} = yield call(list_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_NOTEBOOK_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_NOTEBOOK_BOARD_FAILED, error:error});
    }
}


function* getNotebookNote(action)
{
    const {response, error} = yield call(card_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_NOTEBOOK_NOTE_SUCCESS, card:response.data});
    } else {
        yield put({type: types.GET_NOTEBOOK_NOTE_FAILED, error:error});
    }
}

function* showNotebookNote(action)
{
    const {response, error} = yield call(card_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_NOTEBOOK_NOTE_SUCCESS, single_card:response.data});
    } else {
        yield put({type: types.SHOW_NOTEBOOK_NOTE_FAILED, error:error});
    }
}

function* createNotebookNote(action)
{
    const {response, error} = yield call(card_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_NOTEBOOK_NOTE_SUCCESS, card:response.data});
    } else {
        yield put({type: types.CREATE_NOTEBOOK_NOTE_FAILED, error:error});
    }
}

function* updateNotebookNote(action)
{
    const {response, error} = yield call(card_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_NOTEBOOK_NOTE_SUCCESS, card:response.data});
    } else {
        yield put({type: types.UPDATE_NOTEBOOK_NOTE_FAILED, error:error});
    }
}

function* updateNotebookNoteIndex(action)
{
    const {response, error} = yield call(card_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_NOTEBOOK_NOTE_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_NOTEBOOK_NOTE_INDEX_FAILED, error:error});
    }
}

function* updateNotebookNoteNextIndex(action)
{
    const {response, error} = yield call(card_api.updateNextIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_FAILED, error:error});
    }
}

function* deleteNotebookNote(action)
{
    const {response, error} = yield call(card_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_NOTEBOOK_NOTE_SUCCESS, card:response.data});
    } else {
        yield put({type: types.DELETE_NOTEBOOK_NOTE_FAILED, error:error});
    }
}


function* watchGetNotebookBoard() 
{
    yield takeEvery(types.GET_NOTEBOOK_BOARD, getNotebookBoard)
}

function* watchShowNotebookBoard() 
{
    yield takeEvery(types.SHOW_NOTEBOOK_BOARD, showNotebookBoard)
}

function* watchCreateNotebookBoard() 
{
    yield takeEvery(types.CREATE_NOTEBOOK_BOARD, createNotebookBoard)
}

function* watchUpdateNotebookBoard() 
{
    yield takeEvery(types.UPDATE_NOTEBOOK_BOARD, updateNotebookBoard)
}

function* watchUpdateNotebookBoardIndex() 
{
    yield takeEvery(types.UPDATE_NOTEBOOK_BOARD_INDEX, updateNotebookBoardIndex)
}

function* watchDeleteNotebookBoard() 
{
    yield takeEvery(types.DELETE_NOTEBOOK_BOARD, deleteNotebookBoard)
}

function* watchUpdateNotebookNoteIndex() 
{
    yield takeEvery(types.UPDATE_NOTEBOOK_NOTE_INDEX, updateNotebookNoteIndex)
}

function* watchUpdateNotebookNoteNextIndex() 
{
    yield takeEvery(types.UPDATE_NOTEBOOK_NOTE_NEXT_INDEX, updateNotebookNoteNextIndex)
}


function* watchGetNotebookNote() 
{
    yield takeEvery(types.GET_NOTEBOOK_NOTE, getNotebookNote)
}

function* watchShowNotebookNote() 
{
    yield takeEvery(types.SHOW_NOTEBOOK_NOTE, showNotebookNote)
}

function* watchCreateNotebookNote() 
{
    yield takeEvery(types.CREATE_NOTEBOOK_NOTE, createNotebookNote)
}

function* watchUpdateNotebookNote() 
{
    yield takeEvery(types.UPDATE_NOTEBOOK_NOTE, updateNotebookNote)
}

function* watchDeleteNotebookNote() 
{
    yield takeEvery(types.DELETE_NOTEBOOK_NOTE, deleteNotebookNote)
}


function* NotebookBoardSaga(){
    yield all([
        fork(watchGetNotebookBoard),
        fork(watchShowNotebookBoard),
        fork(watchCreateNotebookBoard),
        fork(watchUpdateNotebookBoard),
        fork(watchUpdateNotebookBoardIndex),
        fork(watchDeleteNotebookBoard),
        fork(watchGetNotebookNote),
        fork(watchShowNotebookNote),
        fork(watchCreateNotebookNote),
        fork(watchUpdateNotebookNote),
        fork(watchDeleteNotebookNote),
        fork(watchUpdateNotebookNoteIndex),
        fork(watchUpdateNotebookNoteNextIndex),
    ])
}

export default NotebookBoardSaga;