import { Button, Form, Card, Col, DatePicker, Divider, Dropdown, Input, Menu, Row, Select, Tag, Upload } from 'antd';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createBoard } from 'redux/actions';


function AddBoard({id, onCancel}) {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

    const submitUpdate = values => {
		dispatch(createBoard(values, "project-boards"));
		form.resetFields();
		onCancel();
	}


    return (
        <>
          <Form form={form} name="edit-card-ref"  onFinish={submitUpdate} initialValues={{project_id: id  }}>
			
			<Form.Item name="title" label="Objective" className="mb-3"
			 rules={[
				{ required: true, message: "Please enter list item title" },
			  ]}
			>
				<Input  className="input"/>
			</Form.Item>
			<Form.Item style={{ display:"none" }} name="project_id" className="mb-3">
				<Input className="board-card-modal input"/>
			</Form.Item>
		
			<Form.Item className="text-right mb-0">
				<Button type="primary" htmlType="submit">Save Changes</Button>
			</Form.Item>
		</Form>  
        </>
    )
}

export default AddBoard
