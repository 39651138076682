import React from 'react'
import {
    DatePicker,
  } from "antd";

  import {
    FileDoneOutlined,
  } from "@ant-design/icons";

function DueDate(props) {

    const {changeEndDate, endDate} = props;

    return (
        <>
          <div className="item__box mb-4">
                <div className="icon__box">
                  <FileDoneOutlined />{" "}
                  <span className="icon__text">Entry Date: </span>
                </div>
                <div>
                  <DatePicker
                    bordered={false}
                    value={endDate}
                    format="DD-MMM-YYYY"
                    onChange={changeEndDate}
                  />
                </div>
              </div>   
        </>
    )
}

export default DueDate
