import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/TimeService';


// Board Redux States
import * as types from './constants';

function* getTime(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_TIME_SUCCESS, time:response.data});
    } else {
        yield put({type: types.GET_TIME_FAILED, error:error});
    }
}

function* showTime(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_TIME_SUCCESS, single_time:response.data});
    } else {
        yield put({type: types.SHOW_TIME_FAILED, error:error});
    }
}

function* createTime(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_TIME_SUCCESS,});
    } else {
        yield put({type: types.CREATE_TIME_FAILED, error:error});
    }
}

function* updateTime(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_TIME_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_TIME_FAILED, error:error});
    }
}

function* deleteTime(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_TIME_SUCCESS, });
    } else {
        yield put({type: types.DELETE_TIME_FAILED, error:error});
    }
}




function* watchGetTime() 
{
    yield takeEvery(types.GET_TIME, getTime)
}

function* watchShowTime() 
{
    yield takeEvery(types.SHOW_TIME, showTime)
}

function* watchCreateTime() 
{
    yield takeEvery(types.CREATE_TIME, createTime)
}

function* watchUpdateTime() 
{
    yield takeEvery(types.UPDATE_TIME, updateTime)
}

function* watchDeleteTime() 
{
    yield takeEvery(types.DELETE_TIME, deleteTime)
}

function* timeSaga(){
    yield all([
        fork(watchGetTime),
        fork(watchShowTime),
        fork(watchCreateTime),
        fork(watchUpdateTime),
        fork(watchDeleteTime),
    ])
}

export default timeSaga;