import React, { Component } from 'react'
import { Route, Switch, } from 'react-router-dom';
import MailItem from './MailItem';
import MailDetail from './MaiDetail';
import MailCompose from './MailCompose';
import SentEmails from './SentEmails';
import SentEmailDetail from './SentEmailDetail';
import DraftEmails from './DraftEmails';
import EditDraft from './EditDraft';

const MailContent = (props) => {
	
		const { page, id, handleSwitchPage } = props

		const showPage = () => {
			if(page === 'inbox')
			{
				return <MailItem id={id} handleSwitchPage={handleSwitchPage}/>;
			}

			if(page === 'compose'){
				return <MailCompose id={id} handleSwitchPage={handleSwitchPage}/> 
			}

			if(page === 'page_details')
			{
				return <MailDetail id={id} handleSwitchPage={handleSwitchPage}/>;
			}

			if(page === 'sent'){
				return <SentEmails id={id} handleSwitchPage={handleSwitchPage}/>;
			}

			if(page === 'sent_details'){
				return <SentEmailDetail id={id} handleSwitchPage={handleSwitchPage}/>;
			}

			if(page === 'draft')
			{
				return <DraftEmails id={id} handleSwitchPage={handleSwitchPage}/>;
			}

			if(page === 'show_draft'){
				return <EditDraft id={id} handleSwitchPage={handleSwitchPage}/>;
			}
		}
		return (
			<React.Fragment>
				{showPage()}
				{/* <Route path={`${match?.url}/compose`} component={MailCompose} />
				<Route path={`${match?.url}/:category/:id`} component={MailDetail} />
				<Route exact path={`${match?.url}/:category`} component={MailItem} /> */}
			</React.Fragment>
		)
	
}

export default MailContent
