import React, { useState, useCallback } from "react";
import { Modal, Input, Button, Select, Tag, Row, Col, Popconfirm } from "antd";
import {
  AssigneeActiveUserAvatar,
  AssigneeUserAvatar,
} from "views/app-views/project/scrumboard/utils";
import DatePickers from "react-datepicker";
import { getTimeInterval } from "utils/duration";
import { useDispatch, useSelector } from "react-redux";
import handleTimeError from "utils/TimeError";
import { getTimeConflict } from "redux/actions";
import moment from "moment";
const { Option } = Select;

const memberTagRender = (props) => (
  <AssigneeUserAvatar id={props.value} size={25} />
);

function EditTime(props) {
  // create the dispatch variable to dispatch all actions
  const dispatch = useDispatch();

  // destructure props data
  const {
    timerScheduleEditVisible,
    setTimerScheduleEditVisible,
    setEditScheduleTimeUser,
    editScheduleTimeUser,
    cardData,
    user,
    teams,
    editScheduleTimeTitle,
    setEditScheduleTimeTitle,
    editScheduleTimeDate,
    editScheduleStatus,
    editScheduleTimeId,
    setEditStartScheduleTime,
    setEditEndScheduleTime,
    editStartScheduleTime,
    editEndScheduleTime,
    deleteTime,
    url,
    updateTime,
  } = props;

  const user_state = useSelector((state) => state.AuthStore?.user);
  const calendar = user_state?.calendar;
  const calendar_store = useSelector((state) => state.CalendarStore);
  const time_conflict = calendar_store?.is_conflict;
  const calendar_success = calendar_store?.success;
  const [isTimeConflict, setIsTimeConflict] = useState(null);

  React.useEffect(() => {
    if(calendar_success === true || isTimeConflict) {
      setIsTimeConflict(time_conflict);
    }

}, [calendar_success])

  //   handle show edit time
  const handleEditScheduleTimerOk = useCallback(() => {
    setTimerScheduleEditVisible(false);
  }, [setTimerScheduleEditVisible]);

  // handle edit time on modal cancel
  const handleEditScheduleTimerCancel = useCallback(() => {
    setTimerScheduleEditVisible(false);
  }, [setTimerScheduleEditVisible]);

  // handle eit time on user change
  const handleEditUserChange = useCallback((value) => {
    setEditScheduleTimeUser(value);
  }, [setEditScheduleTimeUser]);

  // handle edit modal  on start time change
  const onChangeEditStartScheduleTime = useCallback((time) => {
    setEditStartScheduleTime(time);
  }, [setEditStartScheduleTime]);

  // handle edit modal on end time change.
  const onChangeEditEndScheduleTime = useCallback((time) => {
    setEditEndScheduleTime(time);
    dispatch(getTimeConflict({start_time:moment(editStartScheduleTime).format('YYYY-MM-DD HH:mm:ss'), end_time:moment(time).format('YYYY-MM-DD HH:mm:ss'), calender_id:calendar?.id}))    
  }, [setEditEndScheduleTime]);

  // handle edit modal on confirm delete time
  const confirmDeleteScheduleTimer = useCallback(
    (id) => {
      dispatch(deleteTime(id, url));
      handleEditScheduleTimerCancel();
    },
    [deleteTime, dispatch, handleEditScheduleTimerCancel, url]
  );

  // set varable object for edit time data
  const editTimeScheduleData = {
    id: editScheduleTimeId,
    user_id: editScheduleTimeUser,
    team_id: cardData?.team_id === undefined ? null : cardData?.team_id,
    card_id: cardData?.id,
    title: editScheduleTimeTitle,
    date: editScheduleTimeDate,
    start_time: editStartScheduleTime,
    end_time: editEndScheduleTime,
    status: editScheduleStatus,
    object_type: 1,
    object_id: cardData?.id,
    color: "#1ccb9e",
    duration: getTimeInterval(editStartScheduleTime, editEndScheduleTime),
  };

  //   update change time
  const editScheduleTime = () => {
    var errorCount = 0;

    let errorNum = handleTimeError(
      errorCount,
      editScheduleTimeUser,
      editScheduleTimeTitle,
      editStartScheduleTime,
      editEndScheduleTime
    );

    if (errorNum === 0) {
      if(isTimeConflict === false)
      {
      dispatch(updateTime(editTimeScheduleData, url));
      handleEditScheduleTimerCancel();
      }
    }
  };

  function cancel(e) {}

  return (
    <React.Fragment>
      {/* edit schedule timer modal  */}
      <Modal
        title="Edit Scheduled Time"
        visible={timerScheduleEditVisible}
        onOk={handleEditScheduleTimerOk}
        onCancel={handleEditScheduleTimerCancel}
        footer={null}
        width={700}
      >
        <Row gutter={16}>
          <Col span={12} className="mb-3">
            <div>
              <h5>User</h5>
              <Select
                onChange={handleEditUserChange}
                filterOption={false}
                tagRender={memberTagRender}
                // mode="tags"
                removeIcon={null}
                placeholder="None"
                className="board-card-modal"
                style={{ width: "100%" }}
                defaultValue={editScheduleTimeUser}
              >
                {cardData?.team_id === 0 ? (
                  <Option key={user?.id} value={user?.id}>
                    <AssigneeActiveUserAvatar
                      id={user?.id}
                      name={user?.fullname}
                    />
                  </Option>
                ) : (
                  teams
                    ?.filter((x) => x.id === cardData?.team_id)?.[0]
                    ?.team_members.map((elm) => (
                      <Option key={elm?.user_id} value={elm?.user_id}>
                        <AssigneeUserAvatar
                          id={elm?.user_id}
                          name={`${elm?.firstname} ${elm?.lastname}`}
                        />
                      </Option>
                    ))
                )}
              </Select>
              {/* <DatePicker
                  value={editScheduleTimeDate}
                  style={{ width: "100%" }}
                  onChange={getEditScheduleTimeDate}
                  placeholder="start date unset"
                  className="timepicker-date-input"
                  format={"DD-MMM-YYYY"}
                /> */}
            </div>
          </Col>

          <Col span={12} className="mb-3">
            <div>
              <h5>Description</h5>
              <Input
                value={editScheduleTimeTitle}
                onChange={(e) => setEditScheduleTimeTitle(e.target.value)}
              />
            </div>
          </Col>

          {/* <Col span={12} className="mb-3">
              <h5>Status</h5>
              <Select
                defaultValue={editScheduleStatus}
                style={{ width: "100%" }}
                onChange={handleEditScheduleStatus}
              >
                <Option value="Planned">Planned</Option>
                <Option value="Done">Done</Option>
              </Select>
            </Col> */}

          <Col span={12} className="mb-3">
            <div>
              <h5>Start time</h5>
              <DatePickers
                selected={editStartScheduleTime}
                onChange={(date) => onChangeEditStartScheduleTime(date)}
                showTimeSelect
                // showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="dd-MMMM-yyyy h:mm aa"
              />
            </div>
          </Col>
          <Col span={12} className="mb-3">
            <div>
              <h5>End time</h5>
              <DatePickers
                selected={editEndScheduleTime}
                onChange={(date) => onChangeEditEndScheduleTime(date)}
                showTimeSelect
                // showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="dd-MMMM-yyyy h:mm aa"
              />
            </div>
        
          </Col>
          {isTimeConflict? <p className="text-danger conflict__message">
           time conflict - please check the calendar
                </p>: ""}
          <Col span={8}>
            <h5>
              Duration:{" "}
              <Tag color="green">
                {getTimeInterval(editStartScheduleTime, editEndScheduleTime)}
              </Tag>
            </h5>
          </Col>
          {/* <Col span={8}>
              <div className="">
               
                  <DeleteOutlined className="text-danger" />
                
              </div>
            </Col> */}
        </Row>

        <div className="mt-4 d-flex justify-content-between">
          <div className="">
            <Button
              type="default"
              htmlType="button"
              onClick={() => handleEditScheduleTimerCancel()}
            >
              Cancel
            </Button>
          </div>

          <div className="">
            <Popconfirm
              title="Are you sure you want to delete this time?"
              onConfirm={() => confirmDeleteScheduleTimer(editScheduleTimeId)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" htmlType="button">
                Delete
              </Button>
            </Popconfirm>
          </div>

          <div className="">
            <Button type="default" className="bg-success text-white" htmlType="button" onClick={editScheduleTime}>
              Save
            </Button>
          </div>
        </div>
      </Modal>

      {/* end of schedule timer modal */}
    </React.Fragment>
  );
}

export default EditTime;
