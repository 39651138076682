import Echo from "laravel-echo";
  
const token = localStorage.getItem("scrumboard_token");

let host = process.env.REACT_APP_WEBSOCKET_ENDPOINT;
let tls = process.env.REACT_APP_WEBSOCKET_TLS;

// if( process.env.NODE_ENV === "development"  ){
//    host = "mrfriday.luntano.net:6005";
//    tls = false
// }else if( process.env.NODE_ENV === "production"){
//    host = "mrfriday.luntano.net:6005";
//    tls = true;
// }

const echo_config = new Echo({
   broadcaster: 'socket.io',
   host: host,
   // client: Socketio,
//    transports: ['websocket', 'polling', 'flashsocket'],
   forceTLS: tls, 
   auth: {
       headers: {
           Authorization: "Bearer " + token,
           Accept: "application/json"
       }
   }
 });
 

export const echo = echo_config;

