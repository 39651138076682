import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const api = {
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          
          .put(`/${data.url}/${data.attachment.id}`, data.attachment)
          .then((response) => {
            message.success("cover updated", 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    updateAttachmentFromCard(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .put(`/${data.url}/${data.attachment.id}`, data.attachment)
        .then((response) => {
          message.success(response.data.message, 2);
          return { response };
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
          return { error };
        });
  },
    
    delete(data){
      
      axios.defaults.withCredentials = false;
      return authAxios
      .delete(`/${data.url}/${data.attachment}`)
        .then((response) => {
          message.success(response.data.message, 2);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  
}