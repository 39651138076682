export const GET_TASK_LISTS = "GET_TASK_LISTS"
export const GET_TASK_LISTS_SUCCESS = "GET_TASK_LISTS_SUCCESS"
export const GET_TASK_LISTS_FAILED = "GET_TASK_LISTS_FAILED"

export const SHOW_TASK_LISTS = "SHOW_TASK_LISTS"
export const SHOW_TASK_LISTS_SUCCESS = "SHOW_TASK_LISTS_SUCCESS"
export const SHOW_TASK_LISTS_FAILED = "SHOW_TASK_LISTS_FAILED"

export const SHOW_TASK_LIST_CARD = "SHOW_TASK_LIST_CARD"
export const SHOW_TASK_LIST_CARD_SUCCESS = "SHOW_TASK_LIST_CARD_SUCCESS"
export const SHOW_TASK_LIST_CARD_FAILED = "SHOW_TASK_LIST_CARD_FAILED"

export const CREATE_TASK_LISTS = "CREATE_TASK_LISTS"
export const CREATE_TASK_LISTS_SUCCESS = "CREATE_TASK_LISTS_SUCCESS"
export const CREATE_TASK_LISTS_FAILED = "CREATE_TASK_LISTS_FAILED"

export const UPDATE_TASK_LISTS = "UPDATE_TASK_LISTS"
export const UPDATE_TASK_LISTS_SUCCESS = "UPDATE_TASK_LISTS_SUCCESS"
export const UPDATE_TASK_LISTS_FAILED = "UPDATE_TASK_LISTS_FAILED"

export const DELETE_TASK_LISTS = "DELETE_TASK_LISTS"
export const DELETE_TASK_LISTS_SUCCESS = "DELETE_TASK_LISTS_SUCCESS"
export const DELETE_TASK_LISTS_FAILED = "DELETE_TASK_LISTS_FAILED"

export const GET_TASK_LIST_CHECK_LIST = "GET_TASK_LIST_CHECK_LIST"
export const GET_TASK_LIST_CHECK_LIST_SUCCESS = "GET_TASK_LIST_CHECK_LIST_SUCCESS"
export const GET_TASK_LIST_CHECK_LIST_FAILED = "GET_TASK_LIST_CHECK_LIST_FAILED"

export const SHOW_TASK_LIST_CHECK_LIST = "SHOW_TASK_LIST_CHECK_LIST"
export const SHOW_TASK_LIST_CHECK_LIST_SUCCESS = "SHOW_TASK_LIST_CHECK_LIST_SUCCESS"
export const SHOW_TASK_LIST_CHECK_LIST_FAILED = "SHOW_TASK_LIST_CHECK_LIST_FAILED"

export const CREATE_TASK_LIST_CHECK_LIST = "CREATE_TASK_LIST_CHECK_LIST"
export const CREATE_TASK_LIST_CHECK_LIST_SUCCESS = "CREATE_TASK_LIST_CHECK_LIST_SUCCESS"
export const CREATE_TASK_LIST_CHECK_LIST_FAILED = "CREATE_TASK_LIST_CHECK_LIST_FAILED"

export const UPDATE_TASK_LIST_CHECK_LIST = "UPDATE_TASK_LIST_CHECK_LIST"
export const UPDATE_TASK_LIST_CHECK_LIST_SUCCESS = "UPDATE_TASK_LIST_CHECK_LIST_SUCCESS"
export const UPDATE_TASK_LIST_CHECK_LIST_FAILED = "UPDATE_TASK_LIST_CHECK_LIST_FAILED"

export const DELETE_TASK_LIST_CHECK_LIST = "DELETE_TASK_LIST_CHECK_LIST"
export const DELETE_TASK_LIST_CHECK_LIST_SUCCESS = "DELETE_TASK_LIST_CHECK_LIST_SUCCESS"
export const DELETE_TASK_LIST_CHECK_LIST_FAILED = "DELETE_TASK_LIST_CHECK_LIST_FAILED"