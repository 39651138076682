import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/ProjectChecklistService';


// Board Redux States
import * as types from './constants';

function* getProjectCheckList(action)
{
    const {response, error} = yield call(api.checkListIndex, action.payload)
    if(response)
    {
        yield put({type: types.GET_PROJECT_CHECK_LIST_SUCCESS, project_check_list:response.data});
    } else {
        yield put({type: types.GET_PROJECT_CHECK_LIST_FAILED, error:error});
    }
}

function* showProjectCheckList(action)
{
    const {response, error} = yield call(api.checkListShow, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_PROJECT_CHECK_LIST_SUCCESS, single_project:response.data});
    } else {
        yield put({type: types.SHOW_PROJECT_CHECK_LIST_FAILED, error:error});
    }
}

function* createProjectCheckList(action)
{
    const {response, error} = yield call(api.checkListStore, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_PROJECT_CHECK_LIST_SUCCESS, });
    } else {
        yield put({type: types.CREATE_PROJECT_CHECK_LIST_FAILED, error:error});
    }
}

function* updateProjectCheckList(action)
{
    const {response, error} = yield call(api.checkListUpdate, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_PROJECT_CHECK_LIST_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_PROJECT_CHECK_LIST_FAILED, error:error});
    }
}

function* deleteProjectCheckList(action)
{
    const {response, error} = yield call(api.checkListDestroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_PROJECT_CHECK_LIST_SUCCESS, });
    } else {
        yield put({type: types.DELETE_PROJECT_CHECK_LIST_FAILED, error:error});
    }
}



function* watchGetProjectCheckList() 
{
    yield takeEvery(types.GET_PROJECT_CHECK_LIST, getProjectCheckList)
}

function* watchShowProjectCheckList() 
{
    yield takeEvery(types.SHOW_PROJECT_CHECK_LIST, showProjectCheckList)
}

function* watchCreateProjectCheckList() 
{
    yield takeEvery(types.CREATE_PROJECT_CHECK_LIST, createProjectCheckList)
}

function* watchUpdateProjectCheckList() 
{
    yield takeEvery(types.UPDATE_PROJECT_CHECK_LIST, updateProjectCheckList)
}

function* watchDeleteProjectCheckList() 
{
    yield takeEvery(types.DELETE_PROJECT_CHECK_LIST, deleteProjectCheckList)
}


function* projectChecklistSaga(){
    yield all([
        fork(watchGetProjectCheckList),
        fork(watchShowProjectCheckList),
        fork(watchCreateProjectCheckList),
        fork(watchUpdateProjectCheckList),
        fork(watchDeleteProjectCheckList),
    ])
}

export default projectChecklistSaga;