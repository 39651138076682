import * as types from "./constants"

export const getCalendarTeamView = (calendar_team_view) => {
    return {
        type:types.GET_CALENDAR_TEAM_VIEW,
        payload:calendar_team_view
    }
}

export const showCalendarTeamView = (single_calendar_team_view) => {
    return {
        type:types.SHOW_CALENDAR_TEAM_VIEW,
        payload:single_calendar_team_view
    }
}

export const createCalendarTeamView = (calendar_team_view) => {
    return {
        type:types.CREATE_CALENDAR_TEAM_VIEW,
        payload:calendar_team_view}
}

export const updateCalendarTeamView = (calendar_team_view) => {
    return {
        type:types.UPDATE_CALENDAR_TEAM_VIEW,
        payload:calendar_team_view
    }
}

export const deleteCalendarTeamView = (calendar_team_view) => {
    return {
        type:types.DELETE_CALENDAR_TEAM_VIEW,
        payload:calendar_team_view
    }
}

export const filterCalendarEvent = (url, filter_calendar_event) => {
    return {
        type:types.FILTER_CALENDAR_EVENT,
        payload:{url, filter_calendar_event}
    }
}
