import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Card, Tooltip, Tag } from "antd";
import { getLabelsColor, AssigneeAvatar } from "./utils";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.css";
import zip from "../../../../assets/attachments/zip.png";
import pdf from "../../../../assets/attachments/pdf.jpg";
import EditCard from "./actions/EditCard";
import EditTravelEvent from "./actions/EditTravelEvent";
import { useDispatch, useSelector } from "react-redux";
import { showCard } from "redux/actions";
const InnerCardList = React.memo(function InnerCardList(props) {
  const {setEventDetails, contents} = props;
  const handleItem = (item) => {
    setEventDetails(item);
  };
  return contents?.map((item, index) => (
    <Draggable key={item?.id} draggableId={item?.id?.toString()} index={index}>
      {(dragProvided, dragSnapshot) => (
        <div
          className="mb-3"
          key={item?.id}
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          <Card
            hoverable
            className={`board-card ${
              moment(item?.end_time) <= moment() &&
              moment(item?.date) <= moment()
                ? "expire-bg"
                : ""
            }`}
           
            cover={null}
            onClick={() => {
              handleItem(item);
              props.showModal();
            }}
          >
            {item?.attactments?.map((elm, x) => (
              <React.Fragment key={x}>
                {elm?.cover === 1 ? (
                  <div
                    className="cover__box"
                    style={{
                      backgroundImage: `url(${
                        elm?.name?.split(".").pop() === "jpeg"
                          ? elm?.name
                          : elm?.name?.split(".").pop() === "jpeg"
                          ? elm?.name
                          : elm?.name?.split(".").pop() === "png"
                          ? elm?.name
                          : elm?.name?.split(".").pop() === "zip"
                          ? zip
                          : elm?.name?.split(".").pop() === "pdf"
                          ? pdf
                          : ""
                      })`,
                    }}
                  >
                    {/* <img src={elm?.name} className="rounded img-fluid" alt="cover"/>  */}
                  </div>
                ) : null}
              </React.Fragment>
            ))}

            {/* {item?.card_labels?.map((label, index) => (
              <Tooltip title={label?.name} key={index}>
                Travel
              </Tooltip>
            ))} */}
            <Tooltip title={item?.event_type !== "Travel Event" ? "Regular" : "Travel"} key={index}>
            <h4 className="mb-2">{ item?.event_type !== "Travel Event" ? item.what  : <>
             Travel to { item.travel}
            </>
           
            }</h4> </Tooltip>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-wrap align-items-center">
                {/* {item?.date?
                  <Tag className="bg-gray-lightest" > 
                    <CalendarOutlined />
                    <span className="ml-1 font-weight-semibold">{moment(item?.date).format('DD MMMM')}</span>
                  </Tag>
                  :
                  null
                } */}

                {item?.start_time ? (
                  <Tag color="volcano">
                    <ClockCircleOutlined />
                    <span className="ml-1 font-weight-semibold">
                      {moment(item?.start_time).format("LT")}
                    </span>
                  </Tag>
                ) : null}
         <p className="mr-1 mt-2">to</p>
                
{item?.end_time ? (
                  <Tag color="volcano">
                    {/* <ClockCircleOutlined /> */}
                    <span className="font-weight-semibold">
                      {moment(item?.end_time).format("LT")}
                    </span>
                  </Tag>
                ) : null}

                {item?.duration ? (
                  <Tag className="bg-gray-lightest">
                    <HourglassOutlined />
                    <span className="ml-1 font-weight-semibold">
                      {item?.duration}
                    </span>
                  </Tag>
                ) : null}
              </div>
              <div className="d-flex">
                {item?.members?.map((member) => (
                  <AssigneeAvatar key={member} id={member} size={25} chain />
                ))}
              </div>
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  ));
});

function InnerList(props) {
  const { contents, dropProvided, setEventDetails, showModal, listId } = props;

  return (
    <div className="board-dropzone" ref={dropProvided.innerRef}>
      <InnerCardList
        showModal={showModal}
        setEventDetails={setEventDetails}
        // cardData={cardData}
        contents={contents}
        listId={listId}
      />
      {dropProvided.placeholder}
    </div>
  );
}

function SubIndicator(props) {
  if (props.counts) {
    return (
      <p className="mb-0 mr-2">
        {props.icon}
        <span className="ml-1">{props.counts}</span>
      </p>
    );
  }
  return null;
}

export default function BoardCard(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = "LIST",
    listType,
    style,
    contents,
    useClone,
    cardData,
    setEventDetails,
    showModal
  } = props;


  return (
    <>
      <Droppable
        droppableId={listId}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
        renderClone={useClone}
      >
        {(dropProvided, dropSnapshot) => (
          <Scrollbars
            style={style}
            className="board-wrapper"
            autoHide
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <div
                className="board-scrollContainer"
                style={scrollContainerStyle}
              >
                <InnerList
                  contents={contents}
                  listId={listId}
                  cardData={cardData}
                  dropProvided={dropProvided}
                  showModal={showModal}
                  setEventDetails={setEventDetails}
                />
              </div>
            ) : (
              <InnerList
                contents={contents}
                listId={listId}
                cardData={cardData}
                dropProvided={dropProvided}
                showModal={showModal}
                setEventDetails={setEventDetails}
              />
            )}
          </Scrollbars>
        )}
      </Droppable>
    </>
  );
}
