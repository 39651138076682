import React from 'react'
import { Droppable } from 'react-beautiful-dnd';
import { Scrollbars } from 'react-custom-scrollbars';
import './styles.css';
import { InnerCardList } from './DraggableCard/InnerCardList';

function InnerList(props) {
  const { contents, dropProvided, cardData, listId } = props;

  return (
    <div className="board-dropzone" ref={dropProvided.innerRef}>
      <InnerCardList cardData={cardData} contents={contents} listId={listId}/>
      {dropProvided.placeholder}
    </div>
  );
}


export default function BoardCard(props) {
	const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    contents,
    useClone,
    cardData
  } = props;
	return (
    <>
      <Droppable
        droppableId={listId}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
        renderClone={useClone}
      >
        {(
          dropProvided,
          dropSnapshot,
        ) => (
          <Scrollbars style={style} className="board-wrapper" autoHide {...dropProvided.droppableProps}>
            {internalScroll ? (
              <div className="board-scrollContainer" style={scrollContainerStyle}>
                <InnerList
                  contents={contents}
                  listId={listId}
                  cardData={cardData}
                  dropProvided={dropProvided}
                />
              </div>
            ) : (
              <InnerList
                contents={contents}
                listId={listId}
                cardData={cardData}
                dropProvided={dropProvided}
              />
            )}
          </Scrollbars>
        )}
      </Droppable>
    </>
  );
}