import * as types from "./constants"

export const getJournal = (journal) => {
    return {
        type:types.GET_JOURNAL,
        payload:journal
    }
}

export const showJournal = (single_journal) => {
    return {
        type:types.SHOW_JOURNAL,
        payload:single_journal
    }
}

export const createJournal = (journal, history) => {
    return {
        type:types.CREATE_JOURNAL,
        payload:{journal, history}
    }
}

export const updateJournal = (journal) => {
    return {
        type:types.UPDATE_JOURNAL,
        payload:journal
    }
}

export const deleteJournal = (journal, history) => {
    return {
        type:types.DELETE_JOURNAL,
        payload:{journal, history}
    }
}
