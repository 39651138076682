import * as types from "./constants"

const initialStates = {
    projects:[],
    project_card: {},
    single_project:{},
    loading:false,
    error:null,
    success:false,
}

 const ProjectStore = (state = initialStates, action) => {
    //setup projects reducers
   switch (action.type) {
       case types.GET_PROJECTS :
       return {...state, loading:true}
       case types.GET_PROJECTS_SUCCESS :
       return {...state, loading:false, projects:action.projects}
       case types.GET_PROJECTS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_PROJECTS :
       return {...state}
       case types.SHOW_PROJECTS_SUCCESS :
       return {...state, [action.single_project?.project?.id]:action.single_project}
       case types.SHOW_PROJECTS_FAILED :
       return {...state, error:action.error, loading:false}

           case types.SHOW_PROJECT_CARD :
       return {...state}
       case types.SHOW_PROJECT_CARD_SUCCESS :
       return {...state, project_card:action.project_card}
       case types.SHOW_PROJECT_CARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_PROJECTS :
       return {...state, loading:true, success:false}
       case types.CREATE_PROJECTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_PROJECTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_PROJECTS :
       return {...state, loading:true, success:false}
       case types.UPDATE_PROJECTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_PROJECTS_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_PROJECTS :
       return {...state, loading:true, success:false}
       case types.DELETE_PROJECTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_PROJECTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          

      
       default:
           return state;
   }
}

export default ProjectStore
