import React, { useState, useEffect, useCallback } from 'react'
import {
    createTime,
    getTime,
    showProjectCard,
    updateCard,
    deleteTime,
    updateTime,
    updateScheduleTime,
    createScheduleTime,
    getScheduleTime,
    deleteScheduleTime,
  } from "redux/actions";

  import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Divider,
    Spin,
    Popconfirm,
    Tooltip,
  } from "antd";

  import Header from "../../DraggableCard/Header";
  import Description from "../../DraggableCard/Description";
  import Attachments from "../../DraggableCard/Attachments";
  import Comments from "../../DraggableCard/Comments";
  import { useDispatch, useSelector } from "react-redux";
function UpdateCardForm({ listId, listItem, showCard, cardData, url, comment_url, attachment_url, cardIdString, hasComment }) {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
  

    const user_item = useSelector((state) => state.AuthStore?.user);
    const user = user_item?.user;
  
    let lists = [];
    cardData?.card_labels?.forEach((r) => {
      const item = r?.name;
      lists.push(item);
    });
  
    const initialValues = {
      id: cardData?.id,
      title: cardData?.title,
      members: cardData?.members,
      labels: lists,
      attachments: [],
    };

    // reset form
    useEffect(() => {
      form.resetFields();
    }, [cardData, form]);
  

    const team_state = useSelector((state) => state.TeamStore);
    const team_users = team_state?.users;


    return (
        <React.Fragment>
                <Form
      name="edit-card-ref"
      layout="vertical"
      onFinish={""}
      initialValues={initialValues}
      form={form}
    >
      <div className="text-center mt-2">
        <h4 className="text-gray-light">Page Details</h4>
      </div>
      {/* edit for component start */}
      <Header cardData={cardData} updateCard={updateCard} url={url} />
      {/* edit for component start */}

      {/* display object component start */}
      <Form.Item style={{ display: "none" }} name="id" className="mb-0">
        <Input className="board-card-modal input" />
      </Form.Item>
      <Form.Item className="mb-3 mt-2">
        <p>
          Page Number: <span className="font-weight-semibold">{cardData?.objective   === undefined || cardData?.objective  === null ? listId : cardData?.objective}</span>
        </p>
      </Form.Item>

      {/* display objctive component end */}

      <Divider className="mt-0" />

      {/* display description text editor start */}
      <Description
        cardData={cardData}
        updateCard={updateCard}
        url={url}
      />
      {/*display description text editor end  */}

      {/* attachment header */}
      <Divider className="mt-4" />
      {/* end of attachment header */}
      <Attachments
        cardData={cardData}
        updateCard={updateCard}
        url={url}
        atttachment_url={attachment_url}
      />


      {/* end of attachment list view */}
      {/* time tracker start */}

      <Divider className="mt-0" />
      {/* Schedule time */}

      {/* end of time tracker */}
   {hasComment ?
        <Comments
        user={user}
        cardData={cardData}
        team_users={team_users}
        url={comment_url}
        cardIdString={cardIdString}
      />
   : ""}
 
    </Form>
        </React.Fragment>
    )
}

export default UpdateCardForm
