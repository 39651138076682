export const GET_COMMENTS = "GET_COMMENTS"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_FAILED = "GET_COMMENTS_FAILED"

export const SHOW_COMMENTS = "SHOW_COMMENTS"
export const SHOW_COMMENTS_SUCCESS = "SHOW_COMMENTS_SUCCESS"
export const SHOW_COMMENTS_FAILED = "SHOW_COMMENTS_FAILED"

export const CREATE_COMMENTS = "CREATE_COMMENTS"
export const CREATE_COMMENTS_SUCCESS = "CREATE_COMMENTS_SUCCESS"
export const CREATE_COMMENTS_FAILED = "CREATE_COMMENTS_FAILED"

export const UPDATE_COMMENTS = "UPDATE_COMMENTS"
export const UPDATE_COMMENTS_SUCCESS = "UPDATE_COMMENTS_SUCCESS"
export const UPDATE_COMMENTS_FAILED = "UPDATE_COMMENTS_FAILED"

export const DELETE_COMMENTS = "DELETE_COMMENTS"
export const DELETE_COMMENTS_SUCCESS = "DELETE_COMMENTS_SUCCESS"
export const DELETE_COMMENTS_FAILED = "DELETE_COMMENTS_FAILED"
