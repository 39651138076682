import { Card, Tooltip, Col, Divider,Table, Empty, Row, Select, Form } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import RBCToolbar from "./RBCToolbar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkStatus,
  showCalendarTeamView,
  showProjectCard,
  showSchedule,
} from "redux/actions";
import ModalForm from "components/shared-components/DraggableCard/ModalForm";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../../app-views/project/scrumboard/styles.css";
import "./styles.css";
import { modalModeTypes } from "../project/scrumboard/utils";
import EditCard from "../schedule/scrumboard/actions/EditCard";
import AvatarStatus from "components/shared-components/AvatarStatus";
import EditTravelEvent from "../schedule/scrumboard/actions/EditTravelEvent";

const localizer = momentLocalizer(moment_timezone);
const DnDCalendar = withDragAndDrop(Calendar);
const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Events',
    dataIndex: 'event',
    key: 'event',
  },
];

var colors = ["#fd3153", "#1ccb9e", "#3694DF", "#ff8acc", "10c469"];
const bgColors = colors[Math.floor(Math.random() * colors.length)];
const CalendarItem = (props) => {

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props?.id !== undefined) {
      dispatch(showCalendarTeamView(props?.id));
      dispatch(getWorkStatus());
    }
  }, [dispatch, props?.id]);

  const calendar_data = useSelector((state) => state.CalendarTeamViewStore);
  const calendar = calendar_data?.single_calendar_team_view?.calendar;
  const events = calendar_data?.single_calendar_team_view?.event;
  const loading = calendar_data?.loading;
  const project_card = useSelector((state) => state.ProjectStore);
  const card_data = project_card?.project_card;
  const item = useSelector((state) => state.ScheduleStore);
  const schedule_item = item?.single_schedule;
  const user_state = useSelector((state) => state.AuthStore?.user);
  const user = user_state?.user;

  const [event, setEvents] = useState([]);

  const runEvent = React.useCallback(() => {
    let data = [];

    events?.forEach((elm) => {
      const item = {
        id: elm?.id,
        color: elm.color,
        date:moment.tz(elm?.start_time, user?.timezone).format('DD-MMMM-YYYY'),
        time:<div>{moment.tz(elm?.start_time, user?.timezone).format('h:mm a z')} - {moment.tz(elm?.end_time, user?.timezone).format('h:mm a z')} </div>,
        start: moment(elm?.start_time).toDate(),
        end: moment(elm?.end_time).toDate(),
        event: elm?.title,
        object_type: elm?.object_type,
        object_id: elm?.object_id,
        object_item_id: elm?.object_item_id,
      };

      data.push(item);
    });
    setEvents(data);
  }, [events]);

  React?.useEffect(() => {
    if (events !== undefined) {
      runEvent();
    }
  }, [events]);


  const [eventType, setEventType] = useState(0);
  const [enableModal, setEnableModal] = useState(false);

  const handleOk = () => {
    setEnableModal(false);
  };

  const handleCancel = () => {
    setEnableModal(false);
  };

  const onClose = () => {
    setEnableModal(false);
  };

  const showModalView = (item) => {
    dispatch(showProjectCard(item.object_item_id));
  };

  const showScheduleEvent = (item) => {
    dispatch(showSchedule(item.object_item_id));
  };

 
  return (
    <>
    {eventType ===  2 ? (
        <ModalForm
          modalMode="1"
          cardData={card_data}
          visible={enableModal}
          onClose={onClose}
        />
      ) : eventType === 3 ? (
        schedule_item?.event_type === "Regular Event" ?  <EditCard
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={enableModal}
        setIsModalVisible={setEnableModal}
        data={schedule_item}
      />:
      <EditTravelEvent
      handleOk={handleOk}
      handleCancel={handleCancel}
      isModalVisible={enableModal}
      setIsModalVisible={setEnableModal}
      data={schedule_item}
    />
      ) : (
        ""
      )}
      <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
        {/* <h2>{calendar?.title}</h2> */}
        {/* <div className="d-flex align-items-center">
          <h3 className="text-primary mr-5 pt-1">S</h3>
          {user?.photo !== null ? (
            <Tooltip title={user?.fullname}>
              {" "}
              <AvatarStatus src={user?.photo} />
            </Tooltip>
          ) : (
            <Tooltip title={user?.fullname}>
              <AvatarStatus text={user?.initials} type="blue" />
            </Tooltip>
          )}
        </div> */}
      </div>
      <Card>
        <RBCToolbar id={props.id}/>
        <Table dataSource={event} 
        loading={loading}
        columns={columns} 
        onRow={(record) => ({
          onClick: () => {
            
            setEventType(record?.object_type);
            record?.object_type === 2 
              ? showModalView(record)
              : showScheduleEvent(record);
            setEnableModal(true);
          },
        })}
        />
      </Card>
    </>
  );
};

export default CalendarItem;
