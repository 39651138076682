import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import MenuStore from './Menu';
import MinimizeModalStore from './MinimizeModal';
import AuthStore from 'redux/auth/reducers';
import {BoardStore, CardStore} from 'redux/board/reducers';
import {ListBoardStore, ListCardStore} from 'redux/list_board/reducers';
import {JournalBoardStore, JournalEntryStore} from 'redux/journal_entries/reducers';
import CommentStore from 'redux/comments/reducers';
import NotificationStore from 'redux/notification/reducers';
import ProjectStore from 'redux/projects/reducers';
import ProjectChecklistStore from 'redux/project_checklist/reducers';
import TaskListStore from 'redux/tasklists/reducers';
import JournalStore from 'redux/journal/reducers';
import ScheduleStore from 'redux/schedules/reducers';
import ScheduleItemStore from 'redux/schedule_items/reducers';
import TeamStore from 'redux/teams/reducers';
import ListItemStore from 'redux/list/reducers';
import TimeStore from 'redux/time/reducers';
import CalendarStore from 'redux/calendar/reducers';
import ScheduleTimeStore from 'redux/schedule_time/reducers';
import WorkStatusStore from 'redux/work_status/reducers';
import AssignUserStore from 'redux/assign_users/reducers';
import CalendarTeamViewStore from 'redux/calendar_team_view/reducers';
import ObjectStore from 'redux/objects/reducers';
import EmailStore from 'redux/emails/reducers';
import EmailServiceTypeStore from 'redux/email_service_type/reducers';
import TextMessageStore from 'redux/text_message/reducer'
import AttachmentStore from 'redux/attachment/reducers'
import DatabaseBackupStore from 'redux/database_backup/reducer'
import {NotebookBoardStore, NotebookNoteStore} from 'redux/notebook_pages/reducers';
import NotebookStore from 'redux/notebook/reducers';
const reducers = combineReducers({
    theme: Theme,
    MenuStore,
    auth: Auth,
    AuthStore,
    BoardStore,
    ListBoardStore,
    ListCardStore,
    CardStore,
    CommentStore,
    NotificationStore,
    ProjectStore,
    ProjectChecklistStore,
    JournalStore,
    JournalBoardStore,
    JournalEntryStore,
    ScheduleStore,
    ScheduleItemStore,
    TeamStore,
    TaskListStore,
    ListItemStore,
    TimeStore,
    ScheduleTimeStore,
    WorkStatusStore,
    CalendarStore,
    AssignUserStore,
    CalendarTeamViewStore,
    ObjectStore,
    EmailStore,
    EmailServiceTypeStore,
    MinimizeModalStore,
    TextMessageStore, 
    AttachmentStore,
    DatabaseBackupStore,
    NotebookStore,
    NotebookNoteStore,
    NotebookBoardStore,
});

export default reducers;