import * as types from "./constants"

const initialStates = {
    board:[],
    single_board:{},
    card:[],
    single_card:{},
    loading:false,
    error:null,
    success:false,
}

export const BoardStore = (state = initialStates, action) => {
    //setup board reducers
   switch (action.type) {
       case types.GET_BOARD :
       return {...state, loading:true}
       case types.GET_BOARD_SUCCESS :
       return {...state, loading:false, board:action.board}
       case types.GET_BOARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_BOARD :
       return {...state}
       case types.SHOW_BOARD_SUCCESS :
       return {...state, single_board:action.single_board}
       case types.SHOW_BOARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_BOARD :
       return {...state, loading:true, success:false}
       case types.CREATE_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_BOARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_BOARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_BOARD_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_BOARD_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_BOARD_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_BOARD :
       return {...state, loading:true, success:false}
       case types.DELETE_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}


//setup card reducers
       case types.GET_CARD :
       return {...state, loading:true}
       case types.GET_CARD_SUCCESS :
       return {...state, loading:false, card:action.card}
       case types.GET_CARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_CARD :
       return {...state}
       case types.SHOW_CARD_SUCCESS :
       return {...state, single_card:action.single_card}
       case types.SHOW_CARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_CARD :
       return {...state, loading:true, success:false}
       case types.CREATE_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_CARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_CARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_CARD :
       return {...state, loading:true, success:false}
       case types.DELETE_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

           
          
       default:
           return state;
   }
}


export const CardStore = (state = initialStates, action) => {
    //setup board reducers
   switch (action.type) {
//setup card reducers
       case types.GET_CARD :
       return {...state, loading:true}
       case types.GET_CARD_SUCCESS :
       return {...state, loading:false, card:action.card}
       case types.GET_CARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_CARD :
       return {...state}
       case types.SHOW_CARD_SUCCESS :
       return {...state, single_card:action.single_card}
       case types.SHOW_CARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_CARD :
       return {...state, loading:true, success:false}
       case types.CREATE_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_CARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_CARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_CARD_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_CARD_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_CARD_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_CARD_NEXT_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_CARD_NEXT_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_CARD_NEXT_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_CARD :
       return {...state, loading:true, success:false}
       case types.DELETE_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

           
          
       default:
           return state;
   }
}
