import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";


export const  api = {
  checkListIndex(data){
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/project-checklist`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message);
        // if(error.response.data.message === "Unauthenticated."){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear(); 
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},
checkListShow(data){
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/project-checklist/${data}`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},
checkListStore(data){
    axios.defaults.withCredentials = true;
    return authAxios
      .post("/project-checklist",data.project_check_list)
      .then((response) => {
        // successNotify(response.data.message);
  
        // message.success(response.data.message.message, 4);
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},
checkListUpdate(data){
    axios.defaults.withCredentials = false;
    return authAxios
      .put(`/project-checklist/${data.id}`, data)
      .then((response) => {
        // message.success(response.data.message, 4);
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},
checkListDestroy(data){

    axios.defaults.withCredentials = false;
    return authAxios
      .delete(`/project-checklist/${data.project_check_list}`)
      .then((response) => {
        // message.success(response.data.message, 2);
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},
}

