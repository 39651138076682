import React, {useState} from 'react';
import { Modal, Form, Input, Button,  Popconfirm } from 'antd';
import {
    MinusOutlined, 
    BorderOutlined,
    SwitcherOutlined,
    DeleteOutlined
  } from "@ant-design/icons";

  import {
    DraggableModal,
    DraggableModalProvider,
  } from "ant-design-draggable-modal";
  import Project from "../../../views/app-views/project/scrumboard/index";
  import Schedule from "../../../views/app-views/schedule/scrumboard/index";
  import TeamAppointment from "../../../views/app-views/team_appointment/scrumboard/index";
  import Journal from "../../../views/app-views/journal/scrumboard/index";
  import Notebook from "../../../views/app-views/notebook/scrumboard/index";
  import List from "../../../views/app-views/list/index";
  import TextMessage from "../../../views/app-views/text_message/index";
  import Profile from "../../../views/app-views/profile/index";
  import Team from "../../../views/app-views/team/index";
  import PersonalTaskList from "../../../views/app-views/personal_tasklist_view/scrumboard/index";
  import TeamTaskList from "../../../views/app-views/team_tasklist_view/scrumboard/index";
  import TeamCalendar from "../../../views/app-views/calendar_team_view/index";
  import Calendar from "../../../views/app-views/calendar/index";
  import Email from "../../../views/app-views/email/index";
import { useDispatch, useSelector } from 'react-redux';
import FooterView from './FooterView';
import { MinimizeModal, updateObjects, deleteObjects, showUser, getUser, getTeam } from 'redux/actions';
import TeamMembers from 'views/app-views/TeamView/TeamMembers';

function DragModal(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
    const {modalData, setModalData, minimumData, setMinimumData, zIndex} = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleEditOk = () => {
      setIsModalVisible(false);
      setTitle("");
      setItemId("");
    };
  
    const handleEditCancel = () => {
      setIsModalVisible(false);
      setTitle("");
      setItemId("");
    };

    
    const getUserData = React.useMemo(() =>{
      if(props?.modalData[0]?.user_id === undefined) return;
      dispatch(showUser(props?.modalData[0]?.user_id));
    }, [props?.modalData[0]?.user_id])

    React.useEffect(() => {
      if(!getUserData) return;
      getUserData();
    }, [getUserData])


    const user_state = useSelector((state) => state.AuthStore?.user);
    const user = useSelector((state) => state.AuthStore?.single_user?.user);
    const team_state = useSelector((state) => state.TeamStore?.teams);
    const [teamName, setTeamName] = useState("");
    const [teamAdmin, setTeamAdmin] = useState("");


    const [title, setTitle] = useState("");
      const [itemId, setItemId] = useState("");
    React.useEffect(() =>{
      const teams = team_state?.all_teams;
      const active = team_state?.active_workspace;
      const team_name = teams?.filter(x => x.id === active?.team_id)?.[0]?.team_name;
      const team_member = teams?.filter(x => x.id === active?.team_id)?.[0]?.team_members;
      const team_admin_name = team_member?.filter(x => x.team_role === 1)?.[0]?.fullname;
      setTeamName(team_name === undefined ? "" : team_name);
      setTeamAdmin(team_admin_name);
  
    }, [team_state?.active_workspace, team_state?.all_teams])
   
    // create modal date content
    const [modalWidth, setModalWidth] = useState(1300);
    const [modalHeight, setModalHeight] = useState(750);
    const  [isMaximize, setIsMaximize] = useState(false);

    const showModal = (title, id) => {
      setTitle(title);
      setItemId(id);
      setIsModalVisible(true);
    };

    // create handleMinimize data function
    const handleMinimize = React.useCallback(
        (values, id) => {
          let modalItem = minimumData.filter((x) => x.id === id);
    
          if (modalItem?.length > 0) return;
    
          // remove active modal data
          const arrIndex = modalData.filter((x) => x.id !== id);
          setModalData(arrIndex);
    
          setMinimumData((elm) => [...elm, values]);
        },
        [modalData, minimumData]
      );


      const onCancel = (id) => {
        const arrIndex = modalData.filter((x) => x.id !== id);
        setModalData(arrIndex);
        setModalWidth(1300);
        setModalHeight(750);
        setIsMaximize(false);
      };
    
      const removeMaximized = React.useCallback(() => {
        setModalWidth(1300);
        setModalHeight(750);
        setIsMaximize(false);
      }, [])
    
      const onOk = (id) => {
        const arrIndex = modalData.filter((x) => x.id !== id);
        setModalData(arrIndex);
      };

  const handleMaximum = ()=>{
    setIsMaximize(true);
    setModalWidth(window.innerWidth);
    setModalHeight(window.innerHeight);
  }

  React.useEffect(() => {
    if(minimumData.length > 0) {
      dispatch(MinimizeModal(minimumData))
    } 
}, [minimumData])

const onFinish = (values) => {
  let data = values;
  data.id = itemId;
  dispatch(updateObjects(data));
  handleEditOk();
  onOk(itemId)
};

const onFinishFailed = (errorInfo) => {
  // console.log('Failed:', errorInfo);
};

function confirm(id) {
 dispatch(deleteObjects(id));
 onOk(id)
}

function cancel(e) {
  // console.log(e);
 
}

React.useEffect(() => {
  form.resetFields();
}, [form, title])

    return (
       <React.Fragment>
          <Modal title={`Edit ${title}`} visible={isModalVisible} onOk={handleEditOk} onCancel={handleEditCancel}
          footer={null}
          style={{ zIndex:`${zIndex}`, position:"relative" }}
          >
          <Form
      name="basic"
      initialValues={{
        title: title,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
      form={form}
    >
      <Form.Item
        label="Title"
        name="title"
       
      >
        <Input  placeholder="" />
      </Form.Item>
      <Form.Item>
        <div className="d-flex justify-content-between mt-4">
        <Button type="default" htmlType="button" onClick={handleEditCancel}>
          Cancel
        </Button>
        <Popconfirm
    title="Are you sure to delete this object?"
    onConfirm={() => confirm(itemId)}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  ><Button danger htmlType="button" onClick={handleEditCancel}>
  Delete
</Button>
   </Popconfirm>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        </div>
     
      </Form.Item>
    </Form>
         
      </Modal>
        <DraggableModalProvider>
          {modalData?.map((elm, index) => (
            <DraggableModal
              footer={null}
              initialWidth={modalWidth}
              initialHeight={modalHeight}
              title={
                <>
                <span onClick={() => showModal(elm?.title, elm?.id)}>
                  {  elm?.object_type === "profile"
                ? elm?.title :
                elm?.object_type === "team"
                ? elm?.title :
                elm?.object_type === 1
                  ?  `${elm?.title} - ${user?.fullname}`
                  : elm?.object_type === 2
                  ?  `${elm?.title} - ${teamName} / ${teamAdmin}`
                  : elm?.object_type === 3
                  ? elm?.team_id !== 0 ?`${elm?.title} - ${teamName} ` : `${elm?.title} - ${user?.fullname}`
                  : elm?.object_type === 4
                  ? elm?.title
                  : elm?.object_type === 5
                  ? elm?.title
                  : elm?.object_type === 6
                  ? `${elm?.title} - ${user?.fullname}`
                  : elm?.object_type === 7
                  ? ""
                  : elm?.object_type === 8
                  ? `${elm?.title} - ${user?.fullname}`
                  : elm?.object_type === 9
                  ? elm?.team_id === 0 ? `${elm?.title} - ${user?.fullname}` : `${elm?.title} - ${teamName}`
                  : elm?.object_type === 10
                  ? `${elm?.title} - ${teamName}`
                  : elm?.object_type === 11 ? elm?.team_id !== 0 ?`${elm?.title} - ${teamName} ` : `${elm?.title} - ${user?.fullname}` :
                  
                  elm?.object_type === 12 ? `${elm?.title} - ${user?.fullname}` :
                  elm?.object_type === 13 ? elm?.team_id !== 0 ?`${elm?.title} - ${teamName} ` : `${elm?.title} - ${user?.fullname}`:
                   ""}
                </span> 
                <span className="ml-3">
               
   </span>
                
                </>
              }
              key={index}
              visible={elm.visible}
              onOk={() => onOk(elm.id)}
              onCancel={() => onCancel(elm.id)}
            >
                 <div className="min_max__icon">
            <MinusOutlined 
             onClick={() =>
              handleMinimize(
                {
                  id: elm?.id,
                  visible: true,
                  object_type: elm?.object_type,
                  title:elm?.title,
                  user_id: elm?.user_id,
                  team_id:elm?.team_id
                },
                elm?.id
              )
            }
            />
            {isMaximize ?
            <SwitcherOutlined onClick={removeMaximized}/>
            :
            <BorderOutlined onClick={handleMaximum}/>
            }
            
           
          </div>
          
          {
          elm?.object_type === "profile" ? (
            <Profile id={elm?.id} user_id={elm?.user_id}/>
          ) :
          elm?.object_type === "team" ? (
            <Team id={elm?.id} user_id={elm?.user_id}/>
          ) :
          elm?.object_type === 1 ? (
                <Project id={elm?.id} user_id={elm?.user_id}/>
              ) : elm?.object_type === 2 ? (
                <Project id={elm?.id} user_id={elm?.user_id}/>
              ) : elm?.object_type === 3 ? (
                elm?.team_id === 0 ? <Schedule id={elm?.id} user_id={elm?.user_id}/> : <TeamAppointment id={elm?.id} user_id={elm?.user_id}/>
              ) : elm?.object_type === 4 ? (
                <Journal id={elm?.id} user_id={elm?.user_id} />
              ) : elm?.object_type === 5 ? (
                <List id={elm?.id} user_id={elm?.user_id}/>
              ) : elm?.object_type === 6 ? (
                <Calendar id={elm?.id} user_id={elm?.user_id}/>
              ) : elm?.object_type === 7 ? (
                ""
              ) : elm?.object_type === 8 ? (
                <Notebook id={elm?.id} user_id={elm?.user_id}/>
              ) : elm?.object_type === 9 ? (
                elm?.team_id === 0 ? <PersonalTaskList id={elm?.id} user_id={elm?.user_id}/> : <TeamTaskList id={elm?.id} user_id={elm?.user_id}/>
              ) : elm?.object_type === 10 ? (
                <TeamCalendar id={elm?.id} user_id={elm?.user_id}/>
              ) :  elm?.object_type === 11 ? (
                <Email id={elm?.id} user_id={elm?.user_id}/>
              ):elm?.object_type === 12 ? (
                <TextMessage id={elm?.id} user_id={elm?.user_id}/>
              ):elm?.object_type === 13 ? (
                <TeamMembers id={elm?.id} user_id={elm?.user_id}/>
              ) : (
                ""
              )}
            </DraggableModal>
          ))}
        </DraggableModalProvider>

       
        </React.Fragment>
    )
}

export default DragModal
