import {
  DashboardOutlined,
  BulbOutlined,
  SolutionOutlined,
  ScheduleOutlined,
  ReadOutlined,
  UnorderedListOutlined,
  UserOutlined,
  DatabaseOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Users",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },

  {
    key: "database-backup",
    path: `${APP_PREFIX_PATH}/backup`,
    title: "Database Backup",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },

  // {
  //   key: "",
  //   path: `${APP_PREFIX_PATH}/list`,
  //   title: "My Board List",
  //   icon: SolutionOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },

  // {
  //   key: "project",
  //   path: `${APP_PREFIX_PATH}/project`,
  //   title: "My Project",
  //   icon: BulbOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },

  // {
  //   key: "",
  //   path: `${APP_PREFIX_PATH}/journal`,
  //   title: "My Journal",
  //   icon: ReadOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "",
  //   path: `${APP_PREFIX_PATH}/schedule`,
  //   title: "My Schedule",
  //   icon: ScheduleOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
