import * as types from "./action_types"

export const getDatabaseBackup = (backup) => {
    return {
        type:types.GET_DATABASE_BACKUP,
        payload:backup
    }
}

export const getRestoreDatabaseBackup = (restore_backups) => {
    return {
        type:types.GET_RESTORE_DATABASE_BACKUP,
        payload:restore_backups
    }
}

export const restoreDatabaseBackup = (backup) => {
    return {
        type:types.RESTORE_DATABASE_BACKUP,
        payload:backup
    }
}

export const makeDatabaseBackup = (backup) => {
    return {
        type:types.MAKE_DATABASE_BACKUP,
        payload:backup
    }
}

export const enableMaintainance = (maintainance) => {
    return {
        type:types.ENABLE_MAINTENANCE,
        payload:maintainance
    }
}