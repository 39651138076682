import { Card, Tooltip, Col, Divider, Empty, Row, Select, Form } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import RBCToolbar from "./RBCToolbar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkStatus,
  showCalendar,
  showProjectCard,
  showSchedule,
} from "redux/actions";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../../app-views/project/scrumboard/styles.css";
import "./styles.css";
import { modalModeTypes } from "../project/scrumboard/utils";
import EditCard from "../schedule/scrumboard/actions/EditCard";
import AvatarStatus from "components/shared-components/AvatarStatus";
import ModalForm from "components/shared-components/DraggableCard/ModalForm";
import EditTravelEvent from "../schedule/scrumboard/actions/EditTravelEvent";
const localizer = momentLocalizer(moment_timezone);
const DnDCalendar = withDragAndDrop(Calendar);

var colors = ["#fd3153", "#1ccb9e", "#3694DF", "#ff8acc", "10c469"];
const bgColors = colors[Math.floor(Math.random() * colors.length)];


const CalendarItem = ({id}) => {

  // create dispatch variable  - this will use to dispatch all action function.
  const dispatch = useDispatch();

  // create use effect function to handle show calendar and get workstatus action.
  React.useEffect(() => {
    if (id !== undefined) {
      dispatch(showCalendar(id));
      dispatch(getWorkStatus());
    }
  }, [dispatch,id]);

  // select calendar data from redux store. 
  const calendar_data = useSelector((state) => state.CalendarStore);
  const calendar = calendar_data?.single_calendar?.calendar;
  const events = calendar_data?.single_calendar?.event;
  const project_card = useSelector((state) => state.ProjectStore);
  const card_data = project_card?.project_card;
  const user_state = useSelector((state) => state.AuthStore?.user);
  const user = user_state?.user;
  // select team data from redux store
  const item = useSelector((state) => state.ScheduleStore);
  const schedule_item = item?.single_schedule;

  // create event state - this will handle event data. 
  const [event, setEvents] = useState([]);

  // create event function. - this will destructure events   
  const runEvent = React.useCallback(() => {
    let data = [];

    events?.forEach((elm) => {
      const item = {
        id: elm?.id,
        color: elm.color,
        start: moment.tz(elm?.start_time, user?.timezone).toDate(),
        end: moment.tz(elm?.end_time, user?.timezone).toDate(),
        title: elm?.title,
        object_type: elm?.object_type,
        object_item_id: elm?.object_item_id,
      };

      data.push(item);
    });
    setEvents(data);
  }, [events]);

  // run event callback funtion when page load
  React?.useEffect(() => {
    if (events !== undefined) {
      runEvent();
    }
  }, [events]);


  const onEventDrop = (data) => {
    // console.log(data);
    // console.log("eventdaa >> ", events);
  };

  // create event style getter function. 
  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  // create event type state  - this will handle
  const [eventType, setEventType] = useState(0);
  const [enableModal, setEnableModal] = useState(false);
  const schedule_success = useSelector((state) => state.ScheduleStore?.success)

  // handle modal ok function - this will set enable modal state to false.
  const handleOk = () => {
    setEnableModal(false);
    setEventType(0);
  };

  const handleCancel = () => {
    setEnableModal(false);
    setEventType(0);
  };

  const onClose = () => {
    setEnableModal(false);
    setEventType(0);
  };

  const showModalView = (event) => {
    dispatch(showProjectCard(event.object_item_id));
  };

  const showScheduleEvent = (event) => {
    dispatch(showSchedule(event.object_item_id));
    
  };

  useEffect(() => {
    if(schedule_success === true){
      if(schedule_item?.event_type === "Regular Event"){
        setEventType(3);
      }else if(schedule_item?.event_type === "Travel Event"){
        setEventType(4);
      }
    }
  }, [schedule_success])

  return (
    <>
      
      {eventType === 1 || eventType ===  2 ? (
        <ModalForm
          modalMode="1"
          cardData={card_data}
          visible={enableModal}
          onClose={onClose}
        />
      ) : 
      eventType === 3 ?  <EditCard
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={enableModal}
        setIsModalVisible={setEnableModal}
        data={schedule_item}
      />: eventType === 4 ?
      <EditTravelEvent
      handleOk={handleOk}
      handleCancel={handleCancel}
      isModalVisible={enableModal}
      setIsModalVisible={setEnableModal}
      data={schedule_item}
    />
       : (
        ""
      )}
      {/* <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
        <h2>{calendar?.title}</h2>
        <div className="d-flex align-items-center">
          <h3 className="text-primary mr-5 pt-1">S</h3>
          {user?.photo !== null ? (
            <Tooltip title={user?.fullname}>
              {" "}
              <AvatarStatus src={user?.photo} />
            </Tooltip>
          ) : (
            <Tooltip title={user?.fullname}>
              <AvatarStatus text={user?.initials} type="blue" />
            </Tooltip>
          )}
        </div>
      </div> */}
      <Card>
        <DnDCalendar
          defaultDate={moment().toDate()}
          defaultView="month"
          events={event}
          localizer={localizer}
          onEventDrop={onEventDrop}
          // onEventResize={onEventResize}
          // resizable
          selectable
          onSelectEvent={(event) => {
           
            setEventType(event?.object_type);
            event?.object_type === 1
              ? showModalView(event)
              : showScheduleEvent(event);
            setEnableModal(true);
          }}
          // step={90} // default step is 30
          // timeslots={2}
          components={{
            toolbar: RBCToolbar,
          }}
          eventPropGetter={eventStyleGetter}
          style={{ height: "100vh", width: "100%", backgroundColor: "white" }}
        />
      </Card>
    </>
  );
};

export default CalendarItem;
