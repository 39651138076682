import {
  Button,
  Card,
  Input,
  Modal,
  Form,
  Select,
  Table,
  Menu,
  Row,
  Col,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import "./styles.css";
import utils from "utils";
import { PlusOutlined, FormOutlined, DeleteOutlined } from "@ant-design/icons";
import EditTeam from "./action/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  createTeamMembers,
  deleteTeamMembers,
  deleteTeam,
  showTeam,
  updateTeamMembers,
} from "redux/actions";
import AvatarStatus from "components/shared-components/AvatarStatus";
import { AssigneeUserAvatar } from "../scrumboard/utils";
import Flex from "components/shared-components/Flex";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
const { confirm } = Modal;
const { TabPane } = Tabs;

const { Option } = Select;
const memberTagRender = (props) => (
  <AssigneeUserAvatar id={props.value} size={25} />
);
const TeamMember = (props) => {
  const dispatch = useDispatch();
  const { data, user_team_item } = props;
  const user = useSelector((state) => state.AuthStore?.user);

  const [addUser, setAddedUsers] = useState([]);
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalRoleVisible, setIsModalRoleVisible] = useState(false);

  const [memberId, setMemberId] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const showRoleModal = (elm) => {
    setIsModalRoleVisible(true);
    setMemberId(elm.item_id);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsModalRoleVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalRoleVisible(false);
    form.resetFields();
  };

  const state = useSelector((state) => state.TeamStore);
  const team_roles = state?.team_role;
  const team_users = state?.users;
  const [teamMember, setTeamMembers] = useState([]);
  useEffect(() => {
    const team_members = state?.single_team?.team_members;
    setTeamMembers(team_members);
  }, [state?.single_team?.team_members])

  const single_team = state?.single_team;
  const team_success = state?.success;

  React.useEffect(() => {
    // Use map to get a simple array of "val" values. Ex: [1,4]
    let membersFilter = teamMember?.map((itemY) => {
      return itemY?.user_id;
    });

    // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
    let userFilter = team_users?.filter(
      (itemX) => !membersFilter?.includes(itemX.id)
    );

    // Print the result.
    setAddedUsers(userFilter);
  }, [teamMember, team_users, single_team]);

  // const reloadTeam  = React.useCallback(
  //   () => {
  //     if(team_success === true)
  //     {
  //         dispatch(showTeam(data?.id))
  //     }
  //   },
  //   [team_success],
  // )
  // useEffect(() => {
  //     if(team_success === true){
  //       reloadTeam();
  //     }
  // }, [team_success])

  const deleteRow = (row) => {
    confirm({
      title: "Are you sure?",
      content: "Please confirm you want to remove this team member",
      okText: "Remove",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        const deleteMemeber = dispatch(deleteTeamMembers(row.item_id));
        if (deleteMemeber) {
          dispatch(showTeam(data?.id));
        }
      },
      onCancel() {},
    });
  };

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item onClick={() => showRoleModal(row)}>
        <Flex alignItems="center">
          <FormOutlined />
          <span className="ml-2">Assign Roles</span>
        </Flex>
      </Menu.Item>

      <Menu.Item onClick={() => deleteRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">
            {selectedRows.length > 0
              ? `Remove (${selectedRows.length})`
              : "Remove Member"}
          </span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (teamMember !== undefined || team_success === true) {
      let array = [];
      
      teamMember?.forEach((x, index) => {
        const datas = {
          item_id: x?.id,
          id: user?.id,
          user: (
            <div className="d-flex">
              {user?.id === x?.user_id ? (
                <>
                  {user?.photo !== null ? (
                    <AvatarStatus
                      src={user?.photo}
                      name={`${user?.fullname}`}
                    />
                  ) : (
                    <AvatarStatus
                      type="blue"
                      text={`${user?.initials}`}
                      name={`${user?.fullname}`}
                    />
                  )}
                </>
              ) : (
                <>
                  {team_users?.filter((elm) => elm.id === x?.user_id)?.[0]
                    ?.photo !== null ? (
                    <AvatarStatus
                      src={
                        team_users?.filter(
                          (elm) => elm.id === x?.user_id
                        )?.[0]?.photo
                      }
                      name={`${
                        team_users?.filter(
                          (elm) => elm.id === x?.user_id
                        )?.[0]?.fullname
                      }`}
                    />
                  ) : (
                    <AvatarStatus
                      type="blue"
                      text={`${
                        team_users?.filter(
                          (elm) => elm.id === x?.user_id
                        )?.[0]?.initials
                      }`}
                      name={`${
                        team_users?.filter(
                          (elm) => elm.id === x?.user_id
                        )?.[0]?.fullname
                      }`}
                    />
                  )}
                </>
              )}
            </div>
          ),
          // team:data?.team_name,
          role: (
            <Tag
              color={
                x?.team_role === 1
                  ? "processing"
                  : x?.team_role === 2
                  ? "warning"
                  : x?.team_role === 3
                  ? "success"
                  : ""
              }
            >
              {team_roles?.filter((elm) => elm.id === x?.team_role)?.[0]?.name}
            </Tag>
          ),
          //    updated_at : moment(x.updated_at).format("MMM Do YY"),
        };
        array.push(datas);
      });

      setList(array);
    }


  form.resetFields();
  }, [form, teamMember, team_roles, team_users, team_success, user?.id, user?.photo, user?.fullname, user?.initials]);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "User",
      dataIndex: "user",

      sorter: (a, b) => utils.antdTableSorter(a, b, "user"),
    },
    //   {
    //     title: "Team",
    //     dataIndex: "team",

    //     sorter: (a, b) => utils.antdTableSorter(a, b, "team"),
    //   },
    {
      title: "Role",
      dataIndex: "role",

      sorter: (a, b) => utils.antdTableSorter(a, b, "primary_role"),
    },

    //   {
    //     title: "Updated At",
    //     dataIndex: "updated_at",
    //     // sorter: (a, b) => utils.antdTableSorter(a, b, 'updated_at')
    //   },

    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
    },
  };

  // const onSearch = e => {
  // 	const value = e.currentTarget.value
  // 	const searchArray = e.currentTarget.value? list : list
  // 	const data = utils.wildCardSearch(searchArray, value)
  // 	setList(data)
  // 	setSelectedRowKeys([])
  // }
  const layout = {
    wrapperCol: { span: 24 },
  };

  function handleChange(value) {
    // console.log(`selected ${value}`);
  }

  const onFinish = (values) => {
    const team_data = values;
    team_data.team = single_team?.id;
    dispatch(createTeamMembers(team_data));
    form.resetFields();
    handleOk();
  };




  const onRoleFinish = (values) => {
   dispatch(updateTeamMembers(values));
    handleOk();
  };

  const onFinishFailed = (errorInfo) => {
    //   console.log('Failed:', errorInfo);
  };

  function callback(key) {}

  const [isModalTeamVisible, setIsModalTeamVisible] = React.useState(false);
  const [editTeam, setEditTeam] = React.useState();
  const showTeamModal = (elm) => {
    setIsModalTeamVisible(true);
    setEditTeam(elm);
  };
  const handleTeamOk = () => {
    setIsModalTeamVisible(false);
  };

  const handleTeamCancel = () => {
    setIsModalTeamVisible(false);
  };

  const deleteTeamRow = (row) => {
    confirm({
      title: "Are you sure?",
      content: "Please confirm you want to remove this team",
      okText: "Remove",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        dispatch(deleteTeam(row.id));
        props.handleShowTeamCancel();
      },
      onCancel() {},
    });
  };

  const getSingleTeam = React.useMemo(() => {
    if(team_success !== true) return;
    dispatch(showTeam(data?.id));
  }, [team_success])

  React.useEffect(() => {
    if(!getSingleTeam) return;
    getSingleTeam();
  }, [getSingleTeam])
  
  const dropdownTeamMenu = (row) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          showTeamModal(row);
        }}
      >
        <Flex alignItems="center">
          <FormOutlined />
          <span className="ml-2">Edit Team</span>
        </Flex>
      </Menu.Item>

      <Menu.Item onClick={() => deleteTeamRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">
            {selectedRows.length > 0
              ? `Delete (${selectedRows.length})`
              : "Delete Team"}
          </span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => form.resetFields(), [form]);
  
  return (
    <div className="container">
      <Modal
        title="Edit Team"
        visible={isModalTeamVisible}
        onOk={handleTeamOk}
        onCancel={handleTeamCancel}
        footer={[]}
        width={800}
      >
        <EditTeam handleOk={handleTeamOk} data={editTeam} />
      </Modal>
      <Modal
        title="Assign Role To Member"
        footer={null}
        visible={isModalRoleVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <Form
          {...layout}
          name="role form"
          onFinish={onRoleFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ id: memberId }}
          form={form}
        >
          <label>Team Role</label>
          <Form.Item name="role" className="mt-1"
          rules={[{ required: true, message: 'select role' }]}
          >
            <Select className="select-box" placeholder="Select Team Role">
              {team_roles?.map((elm, i) => (
                <Option key={i} value={elm?.id}>
                  {elm?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ display: "none" }} label="Team Name" name="id">
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Button
                type="primary"
                htmlType="button"
                danger
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
   {isModalVisible ?
      <Modal
      width={800}
      title="Add Team Members"
      footer={[]}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        {...layout}
        name="basic"
        width={850}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row gutter={30}>
          <Col md={24}>
            <div>
              <p className="mb-2">Team Members</p>
            </div>
            <Form.Item label="" name="members" className=""
            rules={[{ required: true, message: 'select team members' }]}
            >
              <Select
                onChange={handleChange}
                filterOption={false}
                tagRender={memberTagRender}
                mode="tags"
                removeIcon={null}
                placeholder="None"
                className="board-card-modal"
              >
                {addUser?.map((elm) => (
                  <Option key={elm?.id} value={elm?.id}>
                    <AssigneeUserAvatar
                      id={elm?.id}
                      name={`${elm?.firstname} ${elm?.lastname}`}
                    />
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ display: "none" }} label="Team Name" name="id">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Team Members
          </Button>
        </Form.Item>
      </Form>
    </Modal>
   : null} 
  
      <div className="heading__box mb-5 d-flex justify-content-between">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="mr-3">{single_team?.team_name}</h2>
          <EllipsisDropdown menu={dropdownTeamMenu(data)} />
        </div>

        <div className="team__box mb-4 d-flex justify-content-between align-items-center">
          <></>
          <Button
            className="today-btn"
            onClick={showModal}
            icon={<PlusOutlined />}
          >
            Add Memebers
          </Button>
        </div>
      </div>

      <Card>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Team Members" key="1">
            <div className="table-responsive">
              <Table
                columns={tableColumns}
                dataSource={list}
                rowKey="id"
                onRow={(record) => ({
                  onClick: () => {
                    //   dispatch(showProject(record?.item_id))
                    //   history?.push(`/app/project/${record.item_id}`)
                  },
                })}
                rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  type: "checkbox",
                  preserveSelectedRowKeys: false,
                  ...rowSelection,
                }}
              />
            </div>
          </TabPane>

          {/* <TabPane tab="Member Requests" key="2">
            <OtherTeam />
          </TabPane> */}
        </Tabs>
      </Card>
    </div>
  );
};

export default React.memo(TeamMember);
