import * as type from '../constants/Menu'

const initialState = {
    enable:false,
}

const MenuStore = ( state = initialState, action) => {
   switch (action.type) {

    
    case type.ENABLE_MENU:
    return {
        ...state,
        enable:true
    }
   

   case type.DISABLE_MENU:
   return {
    ...state,
    enable:false
    }

    default:
			return state;
}
}

export default MenuStore;


