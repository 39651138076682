import * as types from "./constants"

export const getWorkStatus = (work_status) => {
    return {
        type:types.GET_WORK_STATUS,
        payload:work_status
    }
}

export const showWorkStatus = (single_work_status) => {
    return {
        type:types.SHOW_WORK_STATUS,
        payload:single_work_status
    }
}

export const createWorkStatus = (work_status) => {
    return {
        type:types.CREATE_WORK_STATUS,
        payload:work_status}
}

export const updateWorkStatus = (work_status) => {
    return {
        type:types.UPDATE_WORK_STATUS,
        payload:work_status
    }
}

export const deleteWorkStatus = (work_status) => {
    return {
        type:types.DELETE_WORK_STATUS,
        payload:work_status
    }
}
