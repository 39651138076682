export const GET_SCHEDULE_TIME = "GET_SCHEDULE_TIME"
export const GET_SCHEDULE_TIME_SUCCESS = "GET_SCHEDULE_TIME_SUCCESS"
export const GET_SCHEDULE_TIME_FAILED = "GET_SCHEDULE_TIME_FAILED"

export const SHOW_SCHEDULE_TIME = "SHOW_SCHEDULE_TIME"
export const SHOW_SCHEDULE_TIME_SUCCESS = "SHOW_SCHEDULE_TIME_SUCCESS"
export const SHOW_SCHEDULE_TIME_FAILED = "SHOW_SCHEDULE_TIME_FAILED"

export const CREATE_SCHEDULE_TIME = "CREATE_SCHEDULE_TIME"
export const CREATE_SCHEDULE_TIME_SUCCESS = "CREATE_SCHEDULE_TIME_SUCCESS"
export const CREATE_SCHEDULE_TIME_FAILED = "CREATE_SCHEDULE_TIME_FAILED"

export const UPDATE_SCHEDULE_TIME = "UPDATE_SCHEDULE_TIME"
export const UPDATE_SCHEDULE_TIME_SUCCESS = "UPDATE_SCHEDULE_TIME_SUCCESS"
export const UPDATE_SCHEDULE_TIME_FAILED = "UPDATE_SCHEDULE_TIME_FAILED"

export const DELETE_SCHEDULE_TIME = "DELETE_SCHEDULE_TIME"
export const DELETE_SCHEDULE_TIME_SUCCESS = "DELETE_SCHEDULE_TIME_SUCCESS"
export const DELETE_SCHEDULE_TIME_FAILED = "DELETE_SCHEDULE_TIME_FAILED"
