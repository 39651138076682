import * as types from  './constants';

export const registerUser = (user) => {
    return {
        type: types.REGISTER_USER,
        payload: { user }
    }
}


export const loginUser = (user, history) => {
    return {
        type: types.LOGIN_USER,
        payload: { user, history }
    }
}

export const getUser = (user) => {
    return {
        type: types.GET_USER,
        payload: user
    }
}

export const showUser = (singel_user) => {
    return {
        type: types.SHOW_USER,
        payload: singel_user
    }
}

export const getAllUsers = (users) => {
    return {
        type: types.GET_USERS,
        payload: users
    }
}


export const logoutUser = (user) => {
    return {
        type: types.LOGOUT_USER,
        payload: user
    }
}




export const forgetPassword = (password) => {
    return {
        type: types.FORGET_PASSWORD,
        payload: password
    }
}


export const resetPassword = (password) => {
    return {
        type: types.RESET_PASSWORD,
        payload: password
    }
}

export const updateUser = (user) => {
    return {
        type: types.UPDATE_USER_PROFILE,
        payload: user
    }
}

export const deleteUser = (user) => {
    return {
        type: types.DELETE_USER,
        payload: user
    }
}

