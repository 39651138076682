import * as types from "./constants"

export const getScheduleItem = (schedule_item) => {
    return {
        type:types.GET_SCHEDULE_ITEM,
        payload:schedule_item
    }
}


// export const getScheduleRange = (schedule_range) => {
//     return {
//         type:types.GET_SCHEDULE_RANGE,
//         payload:schedule_range
//     }
// }

export const showScheduleItem = (single_schedule_item) => {
    return {
        type:types.SHOW_SCHEDULE_ITEM,
        payload:single_schedule_item
    }
}

export const createScheduleItem = (schedule_item, history) => {
    return {
        type:types.CREATE_SCHEDULE_ITEM,
        payload:{schedule_item, history}
    }
}

export const updateScheduleItem = (schedule_item) => {
    return {
        type:types.UPDATE_SCHEDULE_ITEM,
        payload:schedule_item
    }
}

export const deleteScheduleItem = (schedule_item) => {
    return {
        type:types.DELETE_SCHEDULE_ITEM,
        payload:schedule_item
    }
}
