export const GET_SCHEDULE_ITEM = "GET_SCHEDULE_ITEM"
export const GET_SCHEDULE_ITEM_SUCCESS = "GET_SCHEDULE_ITEM_SUCCESS"
export const GET_SCHEDULE_ITEM_FAILED = "GET_SCHEDULE_ITEM_FAILED"

// export const GET_SCHEDULE_RANGE = "GET_SCHEDULE_RANGE"
// export const GET_SCHEDULE_RANGE_SUCCESS = "GET_SCHEDULE_RANGE_SUCCESS"
// export const GET_SCHEDULE_RANGE_FAILED = "GET_SCHEDULE_RANGE_FAILED"

export const SHOW_SCHEDULE_ITEM = "SHOW_SCHEDULE_ITEM"
export const SHOW_SCHEDULE_ITEM_SUCCESS = "SHOW_SCHEDULE_ITEM_SUCCESS"
export const SHOW_SCHEDULE_ITEM_FAILED = "SHOW_SCHEDULE_ITEM_FAILED"

export const CREATE_SCHEDULE_ITEM = "CREATE_SCHEDULE_ITEM"
export const CREATE_SCHEDULE_ITEM_SUCCESS = "CREATE_SCHEDULE_ITEM_SUCCESS"
export const CREATE_SCHEDULE_ITEM_FAILED = "CREATE_SCHEDULE_ITEM_FAILED"

export const UPDATE_SCHEDULE_ITEM = "UPDATE_SCHEDULE_ITEM"
export const UPDATE_SCHEDULE_ITEM_SUCCESS = "UPDATE_SCHEDULE_ITEM_SUCCESS"
export const UPDATE_SCHEDULE_ITEM_FAILED = "UPDATE_SCHEDULE_ITEM_FAILED"

export const DELETE_SCHEDULE_ITEM = "DELETE_SCHEDULE_ITEM"
export const DELETE_SCHEDULE_ITEM_SUCCESS = "DELETE_SCHEDULE_ITEM_SUCCESS"
export const DELETE_SCHEDULE_ITEM_FAILED = "DELETE_SCHEDULE_ITEM_FAILED"
