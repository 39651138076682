import React, { useContext, useState } from "react";
import { ScrumboardProvider, ScrumboardContext } from "./ScrumboardContext";
import Board from "./Board";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PlusOutlined } from "@ant-design/icons";
import {  Button, Form, Input, Select } from "antd";
import { modalModeTypes } from "./utils";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import {
  createNotebookNote,
  createNotebookBoard,
  showNotebook,
  updateNotebookNote,
  updateNotebook,
  getNotebookBoard,
  updateNotebookNoteNextIndex,
  updateNotebookNoteIndex,
  updateNotebookBoardIndex,
  getTeam,
} from "redux/actions";
import Modal from "antd/lib/modal/Modal";
import ModalForm from "../CardItem/EditCardModal/ModalForm";
// import ModalForm from "../CardItem/EditCardModal/ModalForm";
const { Option } = Select;

const ScrumboardWrapper = (props) => {
  const { modal, cardData, currentListId, modalMode, updateModal } =
    useContext(ScrumboardContext);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props?.id !== undefined) {
      dispatch(showNotebook(props?.id));
    }
  }, [dispatch, props?.id]);

  const state = useSelector((state) => state.NotebookStore);
  const success = useSelector((state) => state.NotebookBoardStore.success);
  //   const cardList = useSelector((state) => state.CardStore);

  const datas = state?.single_notebook?.note_board;
  const notebook = state?.single_notebook?.note;
  const notebook_success = state?.success;

  const [lists, setLists] = useState([]);

  React.useEffect(() => {
    setLists(datas);
  }, [datas]);

  const [cardContent, setCardContent] = useState({});
  React.useEffect(() => {
    const convertArrayToObject = (array, key) =>
      array?.reduce(
        (obj, item) => ({
          ...obj,
          [item["title"]]: item?.note_card,
        }),
        {}
      );
    const item = convertArrayToObject(datas);
    setCardContent(item);
  }, [datas]);

  const onDragEnd = (results) => {
    const { destination, source, type } = results;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "COLUMN") {
      const result = Array.from(lists);

      const [removed] = result.splice(source.index, 1);

      result.splice(destination.index, 0, removed);

      let order = [];
      result.forEach((x, index) => {
        const item = { id: x.id, index: index };
        order.push(item);
      });

      dispatch(updateNotebookBoardIndex({ order: order }));
      setLists(result);

      return;
    }

    const home = cardContent[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      const card = Array.from(home);
      const [removed] = card.splice(source.index, 1);
      card.splice(destination.index, 0, removed);

      let order = [];
      card.forEach((x, index) => {
        const item = { id: x.id, index: index };
        order.push(item);
      });

      dispatch(updateNotebookNoteIndex({ order: order }));
      setCardContent({ ...cardContent, [destination.droppableId]: card });
      return;
    }

    const current = [...cardContent[source.droppableId]];
    const next = [...cardContent[destination.droppableId]];
    const target = current[source.index];

    current.splice(source.index, 1);
    next.splice(destination.index, 0, target);

    const result = {
      ...cardContent,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };

    let order = [];
    next.forEach((x, index) => {
      const item = { id: x.id, index: index };
      order.push(item);
    });

    const data = {
      title: destination.droppableId,
      order: order,
      card_id: target.id,
    };

    dispatch(updateNotebookNoteNextIndex(data));

    setCardContent(result);
    return;
  };

  const onCloseModal = () => {
    updateModal(false);
  };

  const onModalSubmit = (values, mode) => {
    if (mode === modalModeTypes(2)) {
      updateModal(false);
      dispatch(createNotebookBoard(values));
    }

    if (mode === modalModeTypes(1)) {
      dispatch(updateNotebookNote(values));
      // console.log(values);
      updateModal(false);
    }

    if (mode === modalModeTypes(0)) {
      updateModal(false);
      dispatch(createNotebookNote(values));
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {props.containerHeight ? (
          <div className="scrumboard">
            <BoardWrapper
              cards={cardContent}
              lists={lists}
              success={success}
              id={props?.id}
              listItem={notebook}
              list_success={notebook_success}
              {...props}
            />
          </div>
        ) : (
          <BoardWrapper
            {...props}
            cards={cardContent}
            lists={lists}
            success={success}
            id={props?.id}
            listItem={notebook}
            list_success={notebook_success}
          />
        )}
      </DragDropContext>
      <ModalForm
        visible={modal}
        onClose={() => onCloseModal()}
        onModalSubmit={(values, modalMode) => onModalSubmit(values, modalMode)}
        modalMode={modalMode}
        cardData={cardData}
        listId={currentListId}
        url="note-card"
        attachment_url="note-attachments"
        cardIdString="note_card_id"
        comment_url="note-comments"
        hasComment={true}
        hasDueDate={true}
      />
    </>
  );
};

const BoardWrapper = ({
  lists,
  cards,
  success,
  containerHeight,
  useClone,
  isCombineEnabled,
  withScrollableColumns,
  list_success,
  listItem,
  id,
}) => {
  const card_success = useSelector((state) => state.CardStore.success);
  const data = {
    object_id: id,
    title: `Page ${Math.floor(Math.random() * (30 - 1 + 1)) + 1}`,
  };
  const onAddBoardModal = () => {
    // updateModal(true);
    // updateModalMode(modalModeTypes(2));
    dispatch(createNotebookBoard(data));
    // console.log(data);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (success === true) {
      dispatch(getNotebookBoard());
    }
  }, [dispatch, success]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    dispatch(updateNotebook(values));
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        break;
      case 2:
        showModal();
        break;
      case 3:
        break;
      default:
    }
  };

  React.useEffect(() => {
    dispatch(getTeam());
  }, [dispatch]);

  const team_item = useSelector((state) => state.TeamStore?.teams);
  const teams = team_item?.team;
  const user = useSelector((state) => state.AuthStore?.user?.id);
  const attachment_success = useSelector((state) => state.AttachmentStore?.success);

  React.useEffect(() => {
    if (success === true || list_success === true || card_success=== true || attachment_success) {
      dispatch(showNotebook(id));
    }
  }, [success, list_success, card_success, attachment_success]);

  return (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping={containerHeight}
      isCombineEnabled={isCombineEnabled}
    >
      {(provided) => (
        <div
          className="scrumboard"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="scrumboard-header">
            <Modal
              title="Edit List"
              footer={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form
                name="header-form"
                initialValues={{
                  id: listItem?.id,
                  team_id: listItem?.team_id,
                  title: listItem?.title,
                  user_id: user,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item label="List Title" name="title">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="user_id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Select Team" name="team_id" className="mt-1">
                  <Select className="select-box" placeholder="Select Team">
                    {teams?.map((elm, i) => (
                      <Option key={i} value={elm?.id}>
                        Team {elm?.team_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item className="mb-0">
                  <Button
                    type="default"
                    htmlType="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="default"
                    className="bg-success text-white float-right"
                    htmlType="submit"
                  >
                    Save Changes
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
        
            {/* <h3 onClick={handleClick}>{listItem?.title}</h3> */}
            {/* <h4 className="d-flex justify-content-between">
              <span className="mr-2 d-block">
                <span>Team: </span>{" "}
                <span className="text-success">
                  {team_id?.[0]?.team_name} / {listItem?.creator}
                </span>
              </span>
            </h4> */}
            {/* <div className="text-right">
              <div className="d-flex align-items-center">
                {memberIds.map((member, i) =>
                  i < 4 ? (
                    <AssigneeAvatar key={member} id={member} size={30} chain />
                  ) : null
                )}
                <Avatar className="ml-n2" size={30}>
                  <span className="text-gray font-weight-semibold font-size-base">
                    +9
                  </span>
                </Avatar>
              </div>
            </div> */}
          </div>

          <Scrollbars className="scrumboard-body">
            {lists?.map((item, index) => (
              <Board
                key={item?.title}
                id={item?.id}
                index={index}
                title={item?.title}
                contents={cards[item?.title]}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                useClone={useClone}
                listItem={listItem}
              />
            ))}
            {provided.placeholder}
            <div className="board-column add">
              <div className="board-title" onClick={() => onAddBoardModal()}>
                <h4 className="mb-0">
                  <PlusOutlined />
                  <span>Add Page</span>
                </h4>
              </div>
            </div>
          </Scrollbars>
        </div>
      )}
    </Droppable>
  );
};

const Scrumboard = (props) => {
  return (
    <ScrumboardProvider>
      <ScrumboardWrapper {...props} />
    </ScrumboardProvider>
  );
};

export default Scrumboard;
