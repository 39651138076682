import React, { useContext, useState } from "react";
import { ScrumboardContext } from "./ScrumboardContext";
import { Draggable } from "react-beautiful-dnd";
import BoardCard from "./BoardCard";
import { modalModeTypes } from "./utils";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import { Menu, Form, Input, Modal, Button, Badge } from "antd";
import {
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteBoard, updateBoard } from "redux/actions";
import AddCard from "./actions/AddCard";
import moment from "moment";
import AddTravelEvent from "./actions/AddTravelEvent";
import ModalDraggable from 'react-draggable';
const RenameForm = ({ title, finish, id, setRenameActive }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onRenameSubmit = (values) => {
    dispatch(updateBoard(values));
  };

  const onClose = () => {
    setRenameActive("");
  };

  return (
    <Form
      form={form}
      name="renameField"
      onFinish={onRenameSubmit}
      layout="vertical"
      autoComplete="off"
      className="w-100"
      initialValues={{
        title: title,
        id: id,
      }}
    >
      <Form.Item name="title" className="mb-0">
        <Input
          autoFocus
          value={title}
          suffix={
            <div className="cursor-pointer" onClick={() => onClose()}>
              <CloseOutlined />
            </div>
          }
        />
      </Form.Item>

      <Form.Item name="id" className="mb-0 mt-3" style={{ display: "none" }}>
        <Input autoFocus value={title} />
      </Form.Item>

      <Form.Item>
        <Button className="mb-0 mt-2" type="primary" htmlType="submit">
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

const Board = ({
  title,
  contents,
  index,
  listItem,
  id,
  dateItem,
  isScrollable,
  isCombineEnabled,
  useClone,
  setEventDetails, 
  showEModal,
  setScroll,
}) => {
  const {
    updateModal,
    updateModalMode,
    updateCurrentListId,
    updateCardData,
    columns,
    updateColumns,
    ordered,
    updateOrdered,
  } = useContext(ScrumboardContext);
  const [renameActive, setRenameActive] = useState("");

  const newCard = (listId) => {
    updateModal(true);
    updateModalMode(modalModeTypes(0));
    updateCurrentListId(listId);
  };

  const onUpdateCardModal = (obj, listId) => {
    updateModal(true);
    updateModalMode(modalModeTypes(1));
    updateCurrentListId(listId);
    updateCardData(obj);
  };

  const onTitleClick = (title) => {
    setRenameActive(title);
  };

  const onFinish = (newTitle) => {
    if (newTitle) {
      const newColumns = {};
      delete Object.assign(newColumns, columns, { [newTitle]: columns[title] })[
        title
      ];
      const newOrder = ordered.map((elm) => {
        if (elm === title) {
          return newTitle;
        }
        return elm;
      });
      updateColumns(newColumns);
      updateOrdered(newOrder);
    }
    setRenameActive("");
  };

  const dispatch = useDispatch();

  const onBoardDelete = (id) => {
    Modal.confirm({
      title: "Do you want to delete this board?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "Cancel",
      onOk() {
        dispatch(deleteBoard(id));
      },
    });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const team_admin = useSelector((state) => state.TeamStore?.team_admin);

  const [disable, setDisable] = React.useState(true);
  const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  let date = moment().format('D-MMM-YYYY');
 
	React.useEffect(() => {
		if(date ===  moment(dateItem).format("D-MMM-YYYY")) {
			let scroll_index = index + 1;
			setScroll(130 * scroll_index);
		}
	}, [date, dateItem]);

  return (
    <React.Fragment>
      <Modal
        footer={false}
        className="draggable__modal_item"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        maskClosable={false}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disable) {
                setDisable(false);
              }
            }}
            onMouseOut={() => {
             setDisable(true);
            }}
            
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
         New Appointment
          </div>
        }

        modalRender={modal => (
          <ModalDraggable
            disabled={disable}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </ModalDraggable>
        )}
      >
        <AddCard
          title={title}
          dateItem={dateItem}
          listItem={listItem}
          listId={id}
          onCancel={handleCancel}
          setIsModalVisible={setIsModalVisible}
        />
        {/* <AddTravelEvent title={title} listId={id} onCancel={handleCancel} setIsModalVisible={setIsModalVisible}/> */}
      </Modal>

      <Draggable draggableId={title} index={index}>
        {(provided, snapshot) => (
          <div
            className="board-column schedule__board__view"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className="board-title" {...provided.dragHandleProps}>
              {renameActive === title ? (
                <RenameForm
                  id={id}
                  title={title}
                  finish={onFinish}
                  setRenameActive={setRenameActive}
                />
              ) : (
                <>
                  <div
                    className="d-flex justify-content-between align-center"
                    style={{ width: "100%" }}
                  >
                       <div className="appointment-date-view">
                    <span className="d-block">{moment(dateItem).format("D-MMM-YYYY")}</span>
                    <h4 className="mb-0"> {title}</h4>
                    </div>
                    <Badge
        className="site-badge-count-109"

        count={contents?.length === 0 ? "" : contents?.length }
        style={{ backgroundColor: '#fff8a2',color:"#455560",fontSize:"10px", border:'1px solid rgba(0, 0, 0, 0.137)' }}
        
      />
                  </div>

                  {team_admin?.primary_role === "Leader" &&
                  team_admin?.team_id === listItem?.team_id ? (
                    <EllipsisDropdown
                      menu={
                        <Menu>
                          <Menu.Item onClick={() => onTitleClick(title)}>
                            <EditOutlined />
                            <span>Rename Board</span>
                          </Menu.Item>
                          <Menu.Item onClick={() => onBoardDelete(id)}>
                            <DeleteOutlined />
                            <span>Delete Board</span>
                          </Menu.Item>
                        </Menu>
                      }
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <BoardCard
              listId={title}
              listType="CONTENT"
              className={snapshot.isDragging ? "is-dragging" : ""}
              contents={contents}
              internalScroll={isScrollable}
              isCombineEnabled={isCombineEnabled}
              useClone={useClone}
              cardData={onUpdateCardModal}
              listItem={listItem}
              setEventDetails={setEventDetails}
              showModal={showEModal}
            />
            <div className="board-add" onClick={() => showModal()}>
              <div><PlusOutlined style={{ fontWeight:"bold"}}/></div>
            </div>
          </div>
        )}
      </Draggable>
    </React.Fragment>
  );
};

export default Board;
