import React, { useEffect, useState } from "react";

import { Button, message as AntMessage, Badge, Avatar, Tabs, Upload } from "antd";
import AvatarStatus from "components/shared-components/AvatarStatus";
import {
  SearchOutlined,
  AudioOutlined,
  FileImageOutlined,
  UserOutlined,
  UploadOutlined,
  LoadingOutlined, 
  PlusOutlined,
  VideoCameraAddOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { textTruncate } from "../../../hooks/useTruncate";
import { useDispatch, useSelector } from "react-redux";
import {
  getSmsMessages,
  sendTextMessage,
  showDevice,
  showSmsMessages,
} from "redux/actions";
import moment from "moment-timezone";
import { echo } from "configs/Socket";
import useSound from "use-sound";
import boopSfx from "../../../assets/sound/alert.mp3";

const { TabPane } = Tabs;

const Chat = (props) => {
  const { id } = props;
  const [playActive] = useSound(boopSfx, { volume: 0.25 });
  const [playbackRate, setPlaybackRate] = React.useState(0.75);
  const [play] = useSound(boopSfx, playbackRate);
  
  const playSound = () => {
    <div onClick={playActive()}></div>;
  };

  const user_state = useSelector((state) => state.AuthStore?.user);
  const user = user_state?.user;

  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useDispatch();

  const handleChatTab = () => {
    setActiveTab("2");
  };
  const getDevice = React.useMemo(() => {
    if (id === undefined) return;
    dispatch(showDevice(id));
  }, [id]);

  React.useEffect(() => {
    if (!getDevice) return;
    (async () => {
      await getDevice();
    })();
  }, [getDevice]);

  const device = useSelector((state) => state.TextMessageStore);
  const contacts = device?.[id]?.contact;
  const device_item = device?.[id]?.device;
  const text_messages = device?.sms_messages;
  const show_text_message = device?.show_sms_messages;
  const [newMessage, setNewMessage] = useState([]);
  const [clientInfo, setClientInfo] = useState({});
  const messageRef = React.useRef(null);
  const [message, setMessage] = useState();
  const [contactId, setContactId] = useState("");
  const message_success = device?.success;
  function callback(key) {}

  const getMessageData = (item) => {
    dispatch(getSmsMessages(item?.id));
    setContactId(item?.id);
    setClientInfo({
      participant_initials: item?.participant_initials,
      participant_name: item?.participant_name,
    });
  };

  const getNewMessageData = (item) => {
    dispatch(getSmsMessages(item?.text_message_contact_id));
    setContactId(item?.text_message_contact_id);
    setClientInfo({
      participant_initials: item?.participant_initials,
      participant_name: item?.participant_name,
    });
  };

  // const handleShowTextMessage = (id) => {
  //   dispatch(showSmsMessages(id));
  // };

  const handleListenToTextMessage = React.useMemo(() => {
    if (device_item === undefined) return;
    echo
      .private(`text-message.${device_item?.imei_number}`)
      .listen("TextMessage", (message) => {
        if (message?.textMessage === undefined) return;
        const message_data = [];
        message_data.push(message?.textMessage);
        setNewMessage(newMessage.concat(message_data).reverse());
        // message_data = [];
        playSound();
        dispatch(showDevice(id));
        if(contactId !== null || contactId !== undefined){
          dispatch(getSmsMessages(contactId));
        }
        
      });
  }, [device_item, contactId]);

  React.useEffect(() => {
    if (!handleListenToTextMessage) return;
    (async () => {
      await handleListenToTextMessage();
    })();
  }, [handleListenToTextMessage]);

  const scrollToBottom = () => {
    messageRef?.current?.scrollToBottom();
  };

  React.useEffect(() => {
    if (text_messages === undefined) return;
    scrollToBottom();
  }, [text_messages]);


  const data = {
    message: message,
    device_id: device_item?.id,
    contact_id: contactId,
  };

  // send form data to the redux store
  const handleSendMessage = () => {
    dispatch(sendTextMessage(data));
    setMessage("");
  };

  
  const handleSendMessageWithEnterKey = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (message_success !== true) return;
    (async () => {
      dispatch(getSmsMessages(contactId));
    })();
  }, [message_success]);

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

 const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        setImageUrl(imageUrl),
        setLoading(false)
      );
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/svg' || file.type === 'application/pdf';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <div className="text__message__main__box">
      <div className="text__message__main__top__bar">
        {/* search bar component start */}
        <div className="text__message__search__bar">
          {/* <Badge dot style={{ backgroundColor: "#52c41a" }}>
            {user?.photo !== null ? (
              <AvatarStatus src={user?.photo} />
            ) : (
              <AvatarStatus
                text={user?.initials}
                // name={clientInfo?.participant_name}
                type="blue"
              />
            )}
          </Badge> */}
          <div className="text__message__search__input__box">
            <SearchOutlined />
            <input placeholder="Search for contacts..." />
          </div>
        </div>
        {/* search bar component end */}

        {/* main component start */}
        <div className="text__message__top__menu">
          <div className="avatal__item">
            <AvatarStatus
              text={clientInfo?.participant_initials
                ?.match(/\b(\w)/g)
                .join("")
                .toUpperCase()}
              name={clientInfo?.participant_name}
              type="yellow"
            />
          </div>

          <div className="chat__icons">
            <VideoCameraAddOutlined />
            <PhoneOutlined />
            <SearchOutlined />
          </div>
        </div>
        {/* main component end */}
      </div>

      <div className="text__message__body">
        {/* side bar component start */}
        <div className="text__message__side__bar">
          <Scrollbars className="side__bar__content">
            <div className="text__message__chat">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Contacts" key="1">
                  {contacts?.map((item, index) => (
                    <div
                      key={index}
                      className="message__contact__box"
                      onClick={() => {
                        getMessageData(item);
                      }}
                    >
                      <AvatarStatus
                        text={item?.participant_initials
                          ?.match(/\b(\w)/g)
                          .join("")
                          .toUpperCase()}
                        name={item?.participant_address}
                        type="yellow"
                        subTitle={textTruncate(item?.participant_name, 27)}
                      />
                    </div>
                  ))}
                </TabPane>

                <TabPane
                  tab={
                    <>
                      <span className="mr-1">New Message</span>
                      <Badge
                        count={newMessage?.length}
                        style={{ backgroundColor: "#52c41a" }}
                      ></Badge>
                    </>
                  }
                  key="3"
                >
                  {newMessage?.length > 0 ? (
                    <>
                      {newMessage?.map((item, index) => (
                        <div
                          key={index}
                          className="message__contact__box"
                          onClick={() => getNewMessageData(item)}
                        >
                          <AvatarStatus
                            text={item?.participant_initials
                              ?.match(/\b(\w)/g)
                              .join("")
                              .toUpperCase()}
                            name={item?.participant_name}
                            type="yellow"
                            subTitle={textTruncate(item?.last_message, 22)}
                          />

                          <span className="message__time">
                            {moment(item?.received_at).format("MMM DD")}
                          </span>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="text-center mt-2">No new message found</p>
                  )}
                </TabPane>
                {/* <TabPane tab="Chat" key="2">
                  {text_messages?.map((item, index) => 
                    <div className="message__contact__box" onClick={() => handleShowTextMessage(item?.id)}>
                    <AvatarStatus
                      text={item?.participant_name
                        ?.match(/\b(\w)/g)
                        .join("")
                        .toUpperCase()}
                      name={item?.participant_name}
                      type="cyan"
                      subTitle={textTruncate(
                        item?.last_message,
                        27
                      )}
                    />

                    <span className="message__time">{moment(item?.received_at).format("MMM DD")}</span>
                  </div>
                  )}
                
                </TabPane> */}
              </Tabs>
            </div>
          </Scrollbars>
        </div>
        {/* sidebar component end */}

        {/* main body component start */}
        <div className="text__message__body__box">
          <Scrollbars className="text__message__area" ref={messageRef}>
            <div className="chats">
              {/* chat left content start */}
              {text_messages?.map((item, index) => (
                <React.Fragment key={index}>
                  {item?.direction === "incoming" ? (
                    <>
                      <div className="chat left__chat">
                        <Avatar
                          className="chat_avatal"
                          style={{
                            color: "#fff",
                            backgroundColor: item?.avatar_bg_color,
                          }}
                        >
                          {item?.participant_initials
                            ?.match(/\b(\w)/g)
                            .join("")
                            .toUpperCase()}
                        </Avatar>
                        <div className="chat__main__body">
                          <p className="chat__content">{item?.last_message}</p>
                        </div>
                      </div>
                      {/* chat left content start */}
                    </>
                  ) : (
                    <>
                      {/* chat left content start */}
                      <div className="chat right__chat">
                        {user?.photo !== null ? (
                          <Avatar shape="circle" src={user?.photo} />
                        ) : (
                          <Avatar
                            className="chat_avatal"
                            style={{
                              color: "#fff",
                              backgroundColor: "#3e82f7",
                            }}
                          >
                            {item?.participant_initials}
                          </Avatar>
                        )}

                        <div className="chat__main__body">
                          <p className="chat__content">{item?.last_message}</p>
                        </div>
                      </div>
                      {/* chat left content start */}
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          </Scrollbars>

          {text_messages?.length === 0 ? (
            ""
          ) : (
            <div className="footer__area">
              <div className="text_message__footer__input__box">
                <AudioOutlined />
                <input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => handleSendMessageWithEnterKey(e)}
                  placeholder="Type your message or use speech to text..."
                />
                <div className="upload__file__box">
                   {/* <div className="upload__main__box">
                   <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
                   </div> */}
                   <FileImageOutlined />
                </div>
                
              </div>
              <Button type="primary" onClick={() => handleSendMessage()}>
                Send
              </Button>
            </div>
          )}
        </div>

        {/* main body component end */}
      </div>
    </div>
  );
};

export default React.memo(Chat);
