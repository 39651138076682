import * as types from "./constants"

const initialStates = {
    calander_team_view:[],
    single_calendar_team_view:{},
    loading:false,
    error:null,
    success:false,
}

 const CalendarTeamViewStore = (state = initialStates, action) => {
    //setup list_items reducers
   switch (action.type) {
       case types.GET_CALENDAR_TEAM_VIEW :
       return {...state, loading:true}
       case types.GET_CALENDAR_TEAM_VIEW_SUCCESS :
       return {...state, loading:false, calendar_team_view:action.calendar_team_view}
       case types.GET_CALENDAR_TEAM_VIEW_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_CALENDAR_TEAM_VIEW :
       return {...state}
       case types.SHOW_CALENDAR_TEAM_VIEW_SUCCESS :
       return {...state, single_calendar_team_view:action.single_calendar_team_view}
       case types.SHOW_CALENDAR_TEAM_VIEW_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_CALENDAR_TEAM_VIEW :
       return {...state, loading:true, success:false}
       case types.CREATE_CALENDAR_TEAM_VIEW_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_CALENDAR_TEAM_VIEW_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_CALENDAR_TEAM_VIEW :
       return {...state, loading:true, success:false}
       case types.UPDATE_CALENDAR_TEAM_VIEW_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_CALENDAR_TEAM_VIEW_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_CALENDAR_TEAM_VIEW :
       return {...state, loading:true, success:false}
       case types.DELETE_CALENDAR_TEAM_VIEW_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_CALENDAR_TEAM_VIEW_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.FILTER_CALENDAR_EVENT:
       return {...state, loading:true}
       case types.FILTER_CALENDAR_EVENT_SUCCESS :
       return {...state, loading:false, single_calendar_team_view:action.calendar_team_view}
       case types.FILTER_CALENDAR_EVENT_FAILED :
       return {...state, loading:false, error:action.error, }
          
       default:
           return state;
   }
}

export default CalendarTeamViewStore
