import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { board_api, card_api } from 'services/ListBoardService';


// Board Redux States
import * as types from './constants';

function* getListBoard(action)
{
    const {response, error} = yield call(board_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_LIST_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.GET_LIST_BOARD_FAILED, error:error});
    }
}

function* showListBoard(action)
{
    const {response, error} = yield call(board_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_LIST_BOARD_SUCCESS, single_list_card:response.data});
    } else {
        yield put({type: types.SHOW_LIST_BOARD_FAILED, error:error});
    }
}

function* createListBoard(action)
{
    const {response, error} = yield call(board_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_LIST_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.CREATE_LIST_BOARD_FAILED, error:error});
    }
}

function* updateListBoard(action)
{
    const {response, error} = yield call(board_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_LIST_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_LIST_BOARD_FAILED, error:error});
    }
}


function* updateBoardIndex(action)
{
    const {response, error} = yield call(board_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_LIST_BOARD_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_LIST_BOARD_INDEX_FAILED, error:error});
    }
}

function* deleteListBoard(action)
{
    const {response, error} = yield call(board_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_LIST_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_LIST_BOARD_FAILED, error:error});
    }
}


function* getListCard(action)
{
    const {response, error} = yield call(card_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_LIST_CARD_SUCCESS, list_card:response.data});
    } else {
        yield put({type: types.GET_LIST_CARD_FAILED, error:error});
    }
}

function* showListCard(action)
{
    const {response, error} = yield call(card_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_LIST_CARD_SUCCESS, single_list_card:response.data});
    } else {
        yield put({type: types.SHOW_LIST_CARD_FAILED, error:error});
    }
}

function* createListCard(action)
{
    const {response, error} = yield call(card_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_LIST_CARD_SUCCESS, list_card:response.data});
    } else {
        yield put({type: types.CREATE_LIST_CARD_FAILED, error:error});
    }
}

function* updateListCard(action)
{
    const {response, error} = yield call(card_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_LIST_CARD_SUCCESS, list_card:response.data});
    } else {
        yield put({type: types.UPDATE_LIST_CARD_FAILED, error:error});
    }
}

function* updateCardIndex(action)
{
    const {response, error} = yield call(card_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_LIST_CARD_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_LIST_CARD_INDEX_FAILED, error:error});
    }
}

function* updateCardNextIndex(action)
{
    const {response, error} = yield call(card_api.updateNextIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_LIST_CARD_NEXT_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_LIST_CARD_NEXT_INDEX_FAILED, error:error});
    }
}

function* deleteListCard(action)
{
    const {response, error} = yield call(card_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_LIST_CARD_SUCCESS, list_card:response.data});
    } else {
        yield put({type: types.DELETE_LIST_CARD_FAILED, error:error});
    }
}


function* watchGetListBoard() 
{
    yield takeEvery(types.GET_LIST_BOARD, getListBoard)
}

function* watchShowListBoard() 
{
    yield takeEvery(types.SHOW_LIST_BOARD, showListBoard)
}

function* watchCreateListBoard() 
{
    yield takeEvery(types.CREATE_LIST_BOARD, createListBoard)
}

function* watchUpdateListBoard() 
{
    yield takeEvery(types.UPDATE_LIST_BOARD, updateListBoard)
}

function* watchUpdateBoardIndex() 
{
    yield takeEvery(types.UPDATE_LIST_BOARD_INDEX, updateBoardIndex)
}

function* watchDeleteListBoard() 
{
    yield takeEvery(types.DELETE_LIST_BOARD, deleteListBoard)
}

function* watchUpdateCardIndex() 
{
    yield takeEvery(types.UPDATE_LIST_CARD_INDEX, updateCardIndex)
}

function* watchUpdateCardNextIndex() 
{
    yield takeEvery(types.UPDATE_LIST_CARD_NEXT_INDEX, updateCardNextIndex)
}


function* watchGetListCard() 
{
    yield takeEvery(types.GET_LIST_CARD, getListCard)
}

function* watchShowListCard() 
{
    yield takeEvery(types.SHOW_LIST_CARD, showListCard)
}

function* watchCreateListCard() 
{
    yield takeEvery(types.CREATE_LIST_CARD, createListCard)
}

function* watchUpdateListCard() 
{
    yield takeEvery(types.UPDATE_LIST_CARD, updateListCard)
}

function* watchDeleteListCard() 
{
    yield takeEvery(types.DELETE_LIST_CARD, deleteListCard)
}


function* listBoardSaga(){
    yield all([
        fork(watchGetListBoard),
        fork(watchShowListBoard),
        fork(watchCreateListBoard),
        fork(watchUpdateListBoard),
        fork(watchUpdateBoardIndex),
        fork(watchDeleteListBoard),
        fork(watchGetListCard),
        fork(watchShowListCard),
        fork(watchCreateListCard),
        fork(watchUpdateListCard),
        fork(watchDeleteListCard),
        fork(watchUpdateCardIndex),
        fork(watchUpdateCardNextIndex),
    ])
}

export default listBoardSaga;