import React, { useRef, useState } from "react";
import { Form, Input, Button, message, Select, Upload } from "antd";
import SunEditor from "suneditor-react";
import axios from "axios";
// import {useIdleTimer } from 'react-idle-timer'
import {convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html'

import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import { 
	UploadOutlined,
	CloudUploadOutlined,

} from '@ant-design/icons';

import { useDispatch, useSelector } from "react-redux";
import { createDraftEmail, createEmail, updateDraftEmail } from "redux/actions";
import moment from "moment";

// remove localstorage first
localStorage.removeItem("draft_id");

function MailCompose(props) {
  // var pathArray = window.location?.pathname.split("/");
  // var object_id = pathArray[3];
  const { id, handleSwitchPage } = props;
  const dispatch = useDispatch();


  const [form] = Form.useForm();
  const back = () => {
    handleSwitchPage("inbox");
    form.resetFields();
  };

  const [cc, setCC] = useState("");
  const [subject, setSubject] = useState("");
  const [bcc, setBcc] = useState("");
  const [to, setTo] = useState([]);

  const [uploadImage, setUploadImage] = React.useState([]);
  const [base64, setBase64] = React.useState([]);
  function uploadCallback(file) {
    let uploadedImages = uploadImage;
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }

 

  const user = useSelector((state) => state.AuthStore?.user);
  const object = useSelector((state) => state.ObjectStore?.single_object)


  const onFinish = (values) => {
 
    console.log(values);
    let datas = values;
    datas.object_id = id;
    datas.account = object?.email_account_id;
    datas.full_name = user?.fullname;
    datas.attachments = base64;
    datas.date = moment();
    setBase64([]);
    form.resetFields();
    dispatch(createEmail(datas))
  };

  function handleChange(value) {
    setTo(value);
  }

  const [editorState, setDescription] = React.useState("");

  const handleDescription = (editorState) => {
    setDescription(editorState)
  };



  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    // Accept 5 files only
    fileList = fileList.slice(-5);
    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });
    setBase64(fileList);
  };


  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
  
    const getId = localStorage.getItem("draft_id");
    if(editorState === "") return;
    let datas = {
      id:getId,
      object_id: id,
      e_account_id:object?.email_account_id,
      e_personal:user?.user?.fullname,
      e_date:moment(),
      attachments:base64,
      e_subject:subject,
      e_cc:cc,
      e_bcc:bcc,
      e_body:editorState,
      //  e_to:to,

    }; 
    if(getId === undefined || getId === null){
    
      dispatch(createDraftEmail(datas));
    }else{
      dispatch(updateDraftEmail(datas));
    }


    // setBase64([]);
    // form.resetFields();
    // dispatch(createEmail(datas))
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  }


  // const idleTimer = useIdleTimer({ onIdle, onActive, timeout:20 * 1000 })
  return (
    <div className="mail-compose">
      <h4 className="mb-4">New Message</h4>
     
      <Form onFinish={onFinish} form={form}>
        <Form.Item name="to" className="email_to">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="To"
            onChange={handleChange}
            className="container-dropdown"
          >
            {/* {emailAddress} */}
          </Select>
        </Form.Item>
        <Form.Item name="cc">
          <Input placeholder="Cc:" onChange={(e) => setCC(e.target.value)}/>
        </Form.Item>
        <Form.Item name="subject">
          <Input placeholder="Subject:" onChange={(e) => setSubject(e.target.value)}/>
        </Form.Item>
        <Form.Item name="message">
          <SunEditor
            width="100%"
            onChange={handleDescription}
            autoFocus={true}
            lang="en"
            setOptions={{
              showPathLabel: false,
              minHeight: "30vh",
              maxHeight: "40vh",
              placeholder: "Enter your text here!!!",
              popupDisplay: "local",
              resizingBar: true,
              plugins: [
                align,
                // font,
                // fontColor,
                // fontSize,
                formatBlock,
                hiliteColor,
                horizontalRule,
                // lineHeight,
                list,
                // paragraphStyle,
                table,
                template,
                textStyle,
                image,
                link,
              ],
              buttonList: [
                ["undo", "redo"],
                // ["font", "fontSize", "formatBlock"],
                // ["paragraphStyle"],
                [
                  "bold",
                  //   "underline",
                  //   "italic",
                  //   "strike",
                  //   "subscript",
                  //   "superscript"
                ],
                // ["fontColor", "hiliteColor"],
                ["removeFormat"],
                // Line break
                ["outdent", "indent"],
                [
                  "align",
                  "horizontalRule",
                  "list",
                  //  "lineHeight"
                ],
                ["table", "link", "image"],
              ],
              formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
              // font: [
              //   "Arial",
              //   "Calibri",
              //   "Comic Sans",
              //   "Courier",
              //   "Garamond",
              //   "Georgia",
              //   "Impact",
              //   "Lucida Console",
              //   "Palatino Linotype",
              //   "Segoe UI",
              //   "Tahoma",
              //   "Times New Roman",
              //   "Trebuchet MS"
              // ]
            }}
          />
        </Form.Item>

        <div className="mt-5 mb-5">
			  <h4 className="mb-3"><CloudUploadOutlined /> Attachments</h4>
		   <Form.Item
        name="file"
        label=""
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
		
      >
        <Upload name="logo" listType="picture" onChange={handleUpload}>
          <Button>
            <UploadOutlined /> Click to upload
          </Button>
        </Upload>
      </Form.Item>

		  </div>
        <Form.Item>
          <div className="mt-5 text-right">
            <Button className="mr-2" onClick={back}>
              Discard
            </Button>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </div>
        </Form.Item>
      </Form>
      
    </div>
  );
}

export default MailCompose;
