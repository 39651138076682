import * as types from "./constants"

export const getTeam = (teams) => {
    return {
        type:types.GET_TEAMS,
        payload:teams
    }
}

export const getTeamAdmin = (team_admin) => {
    return {
        type:types.GET_TEAM_ADMIN,
        payload:team_admin
    }
}

export const getTeamUsers = (users) => {
    return {
        type:types.GET_TEAM_USERS,
        payload:users
    }
}


export const showTeam = (single_team) => {
    return {
        type:types.SHOW_TEAMS,
        payload:single_team
    }
}



export const createTeam = (teams) => {
    return {
        type:types.CREATE_TEAMS,
        payload:{teams
    }}
}

export const updateTeam = (teams) => {
    return {
        type:types.UPDATE_TEAMS,
        payload:teams
    }
}

export const updateActiveTeam = (active_team) => {
    return {
        type:types.UPDATE_ACTIVE_TEAM,
        payload:active_team
    }
}

export const deleteTeam = (teams) => {
    return {
        type:types.DELETE_TEAMS,
        payload:teams
    }
}



export const getTeamMembers = (members) => {
    return {
        type:types.GET_TEAM_MEMBERS,
        payload:members
    }
}


export const createTeamMembers = (members) => {
    return {
        type:types.CREATE_TEAM_MEMBERS,
        payload:members
    }
}

export const updateTeamMembers = (members) => {
    return {
        type:types.UPDATE_TEAM_MEMBERS,
        payload:members
    }
}

export const deleteTeamMembers = (members) => {
    return {
        type:types.DELETE_TEAM_MEMBERS,
        payload:members
    }
}


export const getTeamRole = (team_role) => {
    return {
        type:types.GET_TEAM_ROLES,
        payload:team_role
    }
}


export const createTeamRole = (team_role) => {
    return {
        type:types.CREATE_TEAM_ROLES,
        payload:team_role
    }
}

export const updateTeamRole = (team_role) => {
    return {
        type:types.UPDATE_TEAM_ROLES,
        payload:team_role
    }
}

export const deleteTeamRole = (team_role) => {
    return {
        type:types.DELETE_TEAM_ROLES,
        payload:team_role
    }
}
