import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';

import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
// import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'suneditor/dist/css/suneditor.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './default.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-times/css/classic/default.css';
import 'react-times/css/material/default.css';
import 'ant-design-draggable-modal/dist/index.css';
import 'simplebar/dist/simplebar.min.css';

// setup themes constance viraible
const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

// created app functions. 
function App() {
  
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <Router>
            <Switch>
              <Route path="/" component={Views}/>
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
