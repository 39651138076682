import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/ScheduleTimeService';


// Board Redux States
import * as types from './constants';

function* getScheduleTime(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_SCHEDULE_TIME_SUCCESS, schedule_time:response.data});
    } else {
        yield put({type: types.GET_SCHEDULE_TIME_FAILED, error:error});
    }
}

function* showScheduleTime(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_SCHEDULE_TIME_SUCCESS, single_schedule_time:response.data});
    } else {
        yield put({type: types.SHOW_SCHEDULE_TIME_FAILED, error:error});
    }
}

function* createScheduleTime(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_SCHEDULE_TIME_SUCCESS,});
    } else {
        yield put({type: types.CREATE_SCHEDULE_TIME_FAILED, error:error});
    }
}

function* updateScheduleTime(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_SCHEDULE_TIME_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_SCHEDULE_TIME_FAILED, error:error});
    }
}

function* deleteScheduleTime(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_SCHEDULE_TIME_SUCCESS, });
    } else {
        yield put({type: types.DELETE_SCHEDULE_TIME_FAILED, error:error});
    }
}




function* watchGetScheduleTime() 
{
    yield takeEvery(types.GET_SCHEDULE_TIME, getScheduleTime)
}

function* watchShowScheduleTime() 
{
    yield takeEvery(types.SHOW_SCHEDULE_TIME, showScheduleTime)
}

function* watchCreateScheduleTime() 
{
    yield takeEvery(types.CREATE_SCHEDULE_TIME, createScheduleTime)
}

function* watchUpdateScheduleTime() 
{
    yield takeEvery(types.UPDATE_SCHEDULE_TIME, updateScheduleTime)
}

function* watchDeleteScheduleTime() 
{
    yield takeEvery(types.DELETE_SCHEDULE_TIME, deleteScheduleTime)
}

function* scheduleTimeSaga(){
    yield all([
        fork(watchGetScheduleTime),
        fork(watchShowScheduleTime),
        fork(watchCreateScheduleTime),
        fork(watchUpdateScheduleTime),
        fork(watchDeleteScheduleTime),
    ])
}

export default scheduleTimeSaga;