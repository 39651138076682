import React from 'react'
import SunEditor from "suneditor-react";
import {
  align,
  formatBlock,
  hiliteColor,
  horizontalRule,
  list,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";

import {
    FileTextOutlined,
  } from "@ant-design/icons";

function Description(props) {
    const {handleDescription, editorState} = props;
    return (
        <React.Fragment>
                        <div className="d-flex">
                <div className="mr-3 font-size-md">
                  <FileTextOutlined />
                </div>
                <div className="w-100">
                  <h4>Note</h4>
                  <SunEditor
                    width="100%"
                    setDefaultStyle="font-family: sans-serif; font-size: 15px;"
                    setContents={editorState}
                    onChange={handleDescription}
                    // autoFocus={true}
                    lang="en"
                    setOptions={{
                      showPathLabel: false,
                      minHeight: "30vh",
                      maxHeight: "40vh",
                      placeholder: "Enter your text here!!!",
                      popupDisplay: "local",
                      resizingBar: true,
                      plugins: [
                        align,
                        formatBlock,
                        hiliteColor,
                        horizontalRule,
                        list,
                        table,
                        template,
                        textStyle,
                        image,
                        link,
                      ],
                      buttonList: [
                        ["undo", "redo"],
                        ["bold"],
  
                        ["removeFormat"],
                        // Line break
                        ["outdent", "indent"],
                        [
                          "align",
                          "horizontalRule",
                          "list",
                          //  "lineHeight"
                        ],
                        ["table", "link", "image"],
                      ],
                      formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                    }}
                  />
                </div>
              </div>
        </React.Fragment>
    )
}

export default Description
