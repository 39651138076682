import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/ListService';


// Board Redux States
import * as types from './constants';

function* getListItem(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_LIST_ITEM_SUCCESS, list_items:response.data});
    } else {
        yield put({type: types.GET_LIST_ITEM_FAILED, error:error});
    }
}

function* showListItem(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_LIST_ITEM_SUCCESS, single_list_item:response.data});
    } else {
        yield put({type: types.SHOW_LIST_ITEM_FAILED, error:error});
    }
}

function* createListItem(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_LIST_ITEM_SUCCESS,});
    } else {
        yield put({type: types.CREATE_LIST_ITEM_FAILED, error:error});
    }
}

function* updateListItem(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_LIST_ITEM_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_LIST_ITEM_FAILED, error:error});
    }
}

function* deleteListItem(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_LIST_ITEM_SUCCESS, });
    } else {
        yield put({type: types.DELETE_LIST_ITEM_FAILED, error:error});
    }
}




function* watchGetListItem() 
{
    yield takeEvery(types.GET_LIST_ITEM, getListItem)
}

function* watchShowListItem() 
{
    yield takeEvery(types.SHOW_LIST_ITEM, showListItem)
}

function* watchCreateListItem() 
{
    yield takeEvery(types.CREATE_LIST_ITEM, createListItem)
}

function* watchUpdateListItem() 
{
    yield takeEvery(types.UPDATE_LIST_ITEM, updateListItem)
}

function* watchDeleteListItem() 
{
    yield takeEvery(types.DELETE_LIST_ITEM, deleteListItem)
}

function* listItemSaga(){
    yield all([
        fork(watchGetListItem),
        fork(watchShowListItem),
        fork(watchCreateListItem),
        fork(watchUpdateListItem),
        fork(watchDeleteListItem),

    ])
}

export default listItemSaga;