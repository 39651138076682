import { Button, Modal, Menu, Table } from "antd";
import React, { useState } from "react";
import { PlusOutlined, FormOutlined } from "@ant-design/icons";
import AddTeam from "./action/Add";
import EditTeam from "./action/Edit";
import { useDispatch, useSelector } from "react-redux";
import { getTeam, showTeam } from "redux/actions";
import Utils from "utils";
import AvatarStatus from "components/shared-components/AvatarStatus";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import moment from "moment";
import Flex from "components/shared-components/Flex";
import TeamMember from "./TeamMember";


function MyTeam() {
  // create dispatch variable to dispatch actions. 
  const dispatch = useDispatch();

  // set modal visible state
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  // set show modal visibility state
  const [isModalShowTeamVisible, setIsModalShowTeamVisible] = React.useState(false);

  // handle show modal state
  const showModal = () => {
    setIsModalVisible(true);
  };

  // handel on ok state
  const handleOk = () => {
    setIsModalVisible(false);
  };

  // handle on cancel modal state
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // handle show modal team state
  const showModalShowTeam = () => {
    setIsModalShowTeamVisible(true);
  };

  // handle show team ok state 
  const handleShowTeamOk = () => {
    setIsModalShowTeamVisible(false);
  };

  // handle show modal team on cancel state
  const handleShowTeamCancel = () => {
    setIsModalShowTeamVisible(false);
  };

  // select team date form redux store 
  const team_item = useSelector((state) => state.TeamStore?.teams?.all_teams);

  //select on team success from redux store
  const success = useSelector((state) => state.TeamStore?.success);

  // select single team data from redux store
  const single_team = useSelector((state) => state.TeamStore?.single_team);

  // slect user data from team store
  const user = useSelector((state) => state.AuthStore?.user?.user);

  // select team data from team store
  const state = useSelector((state) => state.TeamStore);
  const team_users = state?.users;

  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const user_team_item = team_item?.filter(
    (elm) => elm.team_owner === user?.id
  );

  // set single team details as object state
  const [teamDetails, setTeamDetails] = useState({});

  // handle on show team modal 
  const showTeamModal = (elm) => {
    setTeamDetails(elm);
  };

  // handle on table row selected
  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
    },
  };

  // handle make a request view. 
  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          showTeamModal(row);
          showModal();
          setEnableModal("Edit");
        }}
      >
        <Flex alignItems="center">
          <FormOutlined />
          <span className="ml-2">Make Request</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  // load team data and push them to the table view. 
  React.useEffect(() => {
    if (team_item !== undefined) {
      let array = [];
      let teams = team_item?.filter(elm => elm.id !== 1);
      teams?.forEach((x, index) => {
        const data = {
          item_id: x.id,
          id: x.id,
          team_owner: x?.team_owner,
          name: x?.team_name,
          team_name: (
            <div className="d-flex">
              {x?.logo !== null ? (
                <AvatarStatus src={x?.logo} name={x?.username} />
              ) : (
                <AvatarStatus
                  type="blue"
                  text={x?.team_name?.substring(0, 2).toUpperCase()}
                  name={x?.team_name}
                />
              )}
            </div>
          ),
          team_leader:
            x?.team_owner === user?.id
              ? `${user?.firstname} ${user?.lastname}`
              : x?.team_owner ===
                team_users?.filter((elm) => elm.id === x?.team_owner)?.[0]?.id
              ? `${
                  team_users?.filter((elm) => elm.id === x?.team_owner)?.[0]
                    ?.firstname
                } ${
                  team_users?.filter((elm) => elm.id === x?.team_owner)?.[0]
                    ?.lastname
                }`
              : "",

          updated_at: moment(x.updated_at).format("MMM Do YY"),
        };
        array.push(data);
      });

      setList(array);
    }
  }, [team_item]);

  // set table header data list. This is set as an array data. This is
  
  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "team_name",

      sorter: (a, b) => Utils.antdTableSorter(a, b, "team_name"),
    },
    {
      title: "Team Leader",
      dataIndex: "team_leader",

      sorter: (a, b) => Utils.antdTableSorter(a, b, "team_name"),
    },

    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) =>
        elm?.team_owner !== user?.id ? (
          <div className="text-right">
            <EllipsisDropdown menu={dropdownMenu(elm)} />
          </div>
        ) : (
          ""
        ),
    },
  ];

  // reload team data
  React.useEffect(() => {
    if (success === true) {
      dispatch(getTeam());
    }
  }, [dispatch, success]);

  const [enableModal, setEnableModal] = useState("Add");

  return (
    <div>
      <Modal
        title={enableModal === "Add" ? "New Team" : `Edit Team`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        {enableModal === "Add" ? (
          <AddTeam handleOk={handleOk} />
        ) : (
          <EditTeam handleOk={handleOk} data={teamDetails} />
        )}
      </Modal>

      <Modal
        title="Team Details"
        width={1300}
        visible={isModalShowTeamVisible}
        onOk={handleShowTeamOk}
        onCancel={handleShowTeamCancel}
        footer={[]}
      >
        <TeamMember
          data={single_team}
          user_team_item={user_team_item}
          handleShowTeamCancel={handleShowTeamCancel}
        />
      </Modal>

      <div className="team__box d-flex justify-content-between align-items-center">
        <h4>My Teams</h4>
        <Button
          className="today-btn new_team_button"
          onClick={() => {
            showModal();
            setEnableModal("Add");
          }}
          icon={<PlusOutlined />}
        >
          New Team
        </Button>
      </div>
      <Table
        columns={tableColumns}
        dataSource={list}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {
            if (record?.team_owner === user?.id) {
              showModalShowTeam();
              dispatch(showTeam(record?.item_id));
            }

            //   history?.push(`/app/project/${record.item_id}`)
          },
        })}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          type: "checkbox",
          preserveSelectedRowKeys: false,
          ...rowSelection,
        }}
      />
    </div>
  );
}

export default MyTeam;
