import React, { useRef, useState } from "react";
import { Form, Input, Popconfirm,  Tooltip, Button, message, Select, Upload } from "antd";
import SunEditor from "suneditor-react";
import axios from "axios";
// import {useIdleTimer } from 'react-idle-timer'
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import { ReplySVG } from 'assets/svg/icon';
import { labels, getFileType } from './MailLabels';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { 
	UploadOutlined,
	CloudUploadOutlined,LeftCircleOutlined, 
	StarOutlined, 
	DeleteOutlined, 
	StarFilled, 
	DownloadOutlined,
  QuestionCircleOutlined ,
	MailOutlined 
} from '@ant-design/icons';

import { useDispatch, useSelector } from "react-redux";
import { createDraftEmail, createEmail, updateDraftEmail, showDraftEmail, deleteDraftEmail } from "redux/actions";
import moment from "moment";
import CustomIcon from 'components/util-components/CustomIcon'

import ReactHtmlParser from "react-html-parser";
import { EditorState, convertFromHTML, ContentState } from "draft-js";

function EditDraft(props) {
  // var pathArray = window.location?.pathname.split("/");
  // var object_id = pathArray[3];
  const { id, handleSwitchPage } = props;
  const dispatch = useDispatch();

  const draft_id = localStorage.getItem('draft_id');

  const getSingleDraftEmail = React.useMemo(() => {
    if(draft_id === undefined || draft_id === null) return;

    dispatch(showDraftEmail(draft_id))
  }, [draft_id])

  React.useEffect(() => {
    if(!getSingleDraftEmail) return;

  (async() => {
    await getSingleDraftEmail();
  })();

  }, [getSingleDraftEmail])


  const [form] = Form.useForm();
  const back = () => {
    handleSwitchPage("draft");
    form.resetFields();
  };

  const [cc, setCC] = useState("");
  const [subject, setSubject] = useState("");
  const [bcc, setBcc] = useState("");
  const [to, setTo] = useState([]);
	const [starred, setStarred] = React.useState(false);

  const [uploadImage, setUploadImage] = React.useState([]);
  const [base64, setBase64] = React.useState([]);
  function uploadCallback(file) {
    let uploadedImages = uploadImage;
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }



  const user = useSelector((state) => state.AuthStore?.user);
  const object = useSelector((state) => state.ObjectStore?.single_object)
  const show_email = useSelector((state) => state.EmailStore?.single_draft_email);

  const onFinish = (values) => {
 
    let datas = values;
    datas.object_id = id;
    datas.account = object?.email_account_id;
    datas.full_name = user?.fullname;
    datas.attachments = base64;
    datas.date = moment();
    setBase64([]);
    form.resetFields();
    dispatch(createEmail(datas))
  };

 //   get default html data from database.
 const html =
 show_email?.e_body === null || show_email?.e_body === null? "<p></p>" : `${show_email?.e_body || show_email?.e_body}`;

// convert html data to array string
const contentBlock = convertFromHTML(html);

//   convert array data to plain text.
const contentState = ContentState.createFromBlockArray(
 contentBlock.contentBlocks
);


//   get new content from the array state.
const newState = EditorState.createWithContent(contentState);


const [editorState, setDescription] = React.useState(show_email?.e_body);

  React.useEffect(() =>{
    if(show_email === undefined) return;
    
    setCC(show_email?.e_cc);
    setSubject(show_email?.e_subject);
    setBcc(show_email?.e_bcc);
    setDescription(show_email?.e_body);
  }, [show_email])


  function handleChange(value) {
    setTo(value);
  }


  const handleDescription = (editorState) => {
    setDescription(editorState);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    // Accept 5 files only
    fileList = fileList.slice(-5);
    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });
    setBase64(fileList);
  };


  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    const getId = localStorage.getItem("draft_id");
    if(editorState === "") return;
    let datas = {
      id:getId,
      object_id: id,
      e_account_id:object?.email_account_id,
      e_personal:user?.user?.fullname,
      e_date:moment(),
      attachments:base64,
      e_subject:subject,
      e_cc:cc,
      e_bcc:bcc,
      e_body:editorState,
      //  e_to:to,

    }; 
    if(getId === undefined || getId === null){
    
      dispatch(createDraftEmail(datas));
    }else{
      dispatch(updateDraftEmail(datas));
    }


    // setBase64([]);
    // form.resetFields();
    // dispatch(createEmail(datas))
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  }


  // const idleTimer = useIdleTimer({ onIdle, onActive, timeout:5 * 1000 })

  const initialValues = {
    cc:show_email?.e_cc,
    subject:show_email?.e_subject,
    bcc:show_email?.e_bcc,
    message:newState,
  };
  

  React.useEffect(() => {

    form.resetFields();
  }, [show_email, form]);

  const tick = () => {
    setStarred(!starred);
    }
  

    function confirm(e) {
      dispatch(deleteDraftEmail(draft_id))
      handleSwitchPage("draft");
      localStorage.removeItem("draft_id");
    }
    
    function cancel(e) {
      // console.log(e);
      // message.error('Click on No');
    }


    
  return (
    <div className="mail-compose">
      {/* <h4 className="mb-4">New Message</h4> */}
    
      <div className="mail-detail">
				<div className="d-lg-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center mb-3">
						<div className="font-size-md mr-3" onClick={()=> {back()}}>
							<LeftCircleOutlined className="mail-detail-action-icon font-size-md ml-0" />
						</div>
						<AvatarStatus icon={<MailOutlined />} name={show_email?.e_personal} />
					</div>
					<div className="mail-detail-action mb-3">
						<span className="mr-2 font-size-md">{moment(show_email?.e_date).format('MMMM Do YYYY, h:mm a')}</span>
						{/* <Tooltip title="Reply">
							<CustomIcon className="mail-detail-action-icon" svg={ReplySVG} />
						</Tooltip> */}
						<Tooltip title="Star" onClick={()=>{tick()}}>
							{starred? <StarFilled className="mail-detail-action-icon star checked" /> : <StarOutlined className="mail-detail-action-icon star" />}
						</Tooltip>
						<Tooltip title="Delete">
            <Popconfirm title="Are you sure？"
            cancelText="No"
            okText="Yes"
            onConfirm={confirm}
            onCancel={cancel}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
							<DeleteOutlined className="mail-detail-action-icon"/>
              </Popconfirm>
						</Tooltip>
					</div>
				</div>
        </div>
      <Form onFinish={onFinish} form={form} initialValues={initialValues}>
        <Form.Item name="to" className="email_to">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="To"
            onChange={handleChange}
            className="container-dropdown"
          >
            {/* {emailAddress} */}
          </Select>
        </Form.Item>
        <Form.Item name="cc">
          <Input placeholder="Cc:" onChange={(e) => setCC(e.target.value)}/>
        </Form.Item>
        <Form.Item name="subject">
          <Input placeholder="Subject:" onChange={(e) => setSubject(e.target.value)}/>
        </Form.Item>
        <Form.Item name="message">
          <SunEditor
            width="100%"
            onChange={handleDescription}
            autoFocus={true}
            defaultValue={html}
            lang="en"
            setOptions={{
              showPathLabel: false,
              minHeight: "30vh",
              maxHeight: "40vh",
              placeholder: "Enter your text here!!!",
              popupDisplay: "local",
              resizingBar: true,
              plugins: [
                align,
                // font,
                // fontColor,
                // fontSize,
                formatBlock,
                hiliteColor,
                horizontalRule,
                // lineHeight,
                list,
                // paragraphStyle,
                table,
                template,
                textStyle,
                image,
                link,
              ],
              buttonList: [
                ["undo", "redo"],
                // ["font", "fontSize", "formatBlock"],
                // ["paragraphStyle"],
                [
                  "bold",
                  //   "underline",
                  //   "italic",
                  //   "strike",
                  //   "subscript",
                  //   "superscript"
                ],
                // ["fontColor", "hiliteColor"],
                ["removeFormat"],
                // Line break
                ["outdent", "indent"],
                [
                  "align",
                  "horizontalRule",
                  "list",
                  //  "lineHeight"
                ],
                ["table", "link", "image"],
              ],
              formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
              // font: [
              //   "Arial",
              //   "Calibri",
              //   "Comic Sans",
              //   "Courier",
              //   "Garamond",
              //   "Georgia",
              //   "Impact",
              //   "Lucida Console",
              //   "Palatino Linotype",
              //   "Segoe UI",
              //   "Tahoma",
              //   "Times New Roman",
              //   "Trebuchet MS"
              // ]
            }}
          />
        </Form.Item>

        <div className="mt-5 mb-5">
			  <h4 className="mb-3"><CloudUploadOutlined /> Attachments</h4>
		   <Form.Item
        name="attachments"
        label=""
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
		
      >
        <Upload name="logo" listType="picture" onChange={handleUpload}>
          <Button>
            <UploadOutlined /> Click to upload
          </Button>
        </Upload>
      </Form.Item>

		  </div>
        <Form.Item>
          <div className="mt-5 text-right">
            <Button className="mr-2" onClick={back}>
              Discard
            </Button>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </div>
        </Form.Item>
      </Form>
      
    </div>
  );
}

export default EditDraft;
