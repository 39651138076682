import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/NotebookService';


// Board Redux States
import * as types from './constants';

function* getNotebook(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_NOTEBOOK_SUCCESS, notebooks:response.data});
    } else {
        yield put({type: types.GET_NOTEBOOK_FAILED, error:error});
    }
}

function* showNotebook(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_NOTEBOOK_SUCCESS, single_notebook:response.data});
    } else {
        yield put({type: types.SHOW_NOTEBOOK_FAILED, error:error});
    }
}

function* createNotebook(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_NOTEBOOK_SUCCESS,});
    } else {
        yield put({type: types.CREATE_NOTEBOOK_FAILED, error:error});
    }
}

function* updateNotebook(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_NOTEBOOK_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_NOTEBOOK_FAILED, error:error});
    }
}

function* deleteNotebook(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_NOTEBOOK_SUCCESS, });
    } else {
        yield put({type: types.DELETE_NOTEBOOK_FAILED, error:error});
    }
}




function* watchGetNotebook() 
{
    yield takeLatest(types.GET_NOTEBOOK, getNotebook)
}

function* watchShowNotebook() 
{
    yield takeEvery(types.SHOW_NOTEBOOK, showNotebook)
}

function* watchCreateNotebook() 
{
    yield takeEvery(types.CREATE_NOTEBOOK, createNotebook)
}

function* watchUpdateNotebook() 
{
    yield takeEvery(types.UPDATE_NOTEBOOK, updateNotebook)
}

function* watchDeleteNotebook() 
{
    yield takeEvery(types.DELETE_NOTEBOOK, deleteNotebook)
}




function* notebookSaga(){
    yield all([
        fork(watchGetNotebook),
        fork(watchShowNotebook),
        fork(watchCreateNotebook),
        fork(watchUpdateNotebook),
        fork(watchDeleteNotebook),

    ])
}

export default notebookSaga;