import * as types from "./constants";
const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: {},
  single_user:{},
  users:[],
  success:false,
};

const AuthStore = (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        success: false,
        registrationError: null,
      };
      break;
    case types.REGISTER_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
        registrationError: null,
      };
      break;
    case types.REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      };
      break;

    case types.LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };

    case types.LOGIN_FAILD:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


      
    case types.UPDATE_USER_PROFILE:
    return {
      ...state,
      loading: true,
      success:false,
    };
  case types.UPDATE_USER_PROFILE_SUCCESS:
    return {
      ...state,
      loading: false,
      success:true,
    };

  case types.UPDATE_USER_PROFILE_FAILED:
    return {
      ...state,
      loading: false,
      success:true,
      error: action.error,
    };


    case types.DELETE_USER:
    return {
      ...state,
      loading: true,
      success:false,
    };
  case types.DELETE_USER_SUCCESS:
    return {
      ...state,
      loading: false,
      success:true,
    };

  case types.DELETE_USER_FAILD:
    return {
      ...state,
      loading: false,
      success:true,
      error: action.error,
    };


      case types.GET_USER:
      return {
        ...state,
        loading: true,
    
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };

    case types.GET_USER_FAILD:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      case types.GET_USERS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };

    case types.GET_USERS_FAILD:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      case types.SHOW_USER:
      return {
        ...state,
        loading: true,
      };
    case types.SHOW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        single_user: action.single_user,
      };

    case types.SHOW_USER_FAILD:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      
    case types.FORGET_PASSWORD:
      return {
        ...state,
        loading: true,
        success:false,
      };
    case types.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success:true,
      };

    case types.FORGET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        success:true,
        error: action.error,
      };

      case types.RESET_PASSWORD:
        return {
            ...state,
            loading: true,
            success:false,
        }
    case types.RESET_PASSWORD_SUCCESS:
       return {
            ...state,
            loading: false,
            success:true,
        }
 
    case types.RESET_PASSWORD_FAILED:
        return  { 
            ...state,
             loading:false,
             success:true,
              error:action.error
             };

    case types.LOGOUT_USER:
      return { ...state };
    case types.LOGOUT_USER_SUCCESS:
      return { ...state };

    case types.LOGOUT_FAILD:
      state = { ...state, error: action.payload, loading: false };
      return;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AuthStore;
