import React, { useContext, useState } from "react";
import { ScrumboardProvider, ScrumboardContext } from "./ScrumboardContext";
import Board from "./Board";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Button, Form, Input, Select } from "antd";
import { modalModeTypes } from "./utils";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import {
  createCard,
  createBoard,
  updateBoardIndex,
  updateCard,
  updateCardIndex,
  updateCardNextIndex,
  showTaskList,
  getTeam,
  updateTaskList,
  getWorkStatus,
  getCard,
} from "redux/actions";
import Modal from "antd/lib/modal/Modal";
import ModalForm from "./DraggableCard/ModalForm";
const { Option } = Select;

const ScrumboardWrapper = (props) => {
  const { modal, cardData, currentListId, modalMode, updateModal } =
    useContext(ScrumboardContext);

  const {id} = props;
  const dispatch = useDispatch();
  const assign_user_success = useSelector((state) => state.AssignUserStore?.success);
  const comments_success = useSelector((state) => state.CommentStore?.success);
  const card_success = useSelector((state) => state.CardStore?.success)
  const user_item = useSelector((state) => state.AuthStore?.user);
  const user = user_item?.user;
  const active_workspace = user_item?.active_workspace;
  const schedule_success = useSelector((state) => state.ScheduleTimeStore.success);
  const attachment_success = useSelector((state) => state.AttachmentStore?.success);

  React.useLayoutEffect(() => {
      dispatch(getWorkStatus());
  }, [dispatch, props?.id]);

  React.useLayoutEffect(() => {
    if (props?.id !== undefined) 
     {
      const data = {object_id:props?.id, user_id:props?.user_id, token_id:active_workspace?.token_id}
      dispatch(showTaskList(data, `team-task-list-view`));
    }
  }, [dispatch, props?.id]);

  React.useEffect(() => {
    if (assign_user_success === true || comments_success === true || card_success === true || schedule_success === true || attachment_success) 
     {
      const data = {object_id:props?.id, user_id:props?.user_id, token_id:active_workspace?.token_id}
      dispatch(showTaskList(data, `team-task-list-view`));
    }
  }, [assign_user_success, card_success, comments_success, schedule_success, attachment_success]);

  const state = useSelector((state) => state.TaskListStore);
  const states = useSelector((state) => state.WorkStatusStore);
  const success = useSelector((state) => state.BoardStore?.success);
  //   const cardList = useSelector((state) => state.CardStore);
 
  const task_list = state?.[id]?.task_list;
  const task_list_success = state?.success;

  const status_datas = states?.work_status;
  const datas = status_datas;

  const [board, setBoard] = useState([]);
  const [lists, setLists] = useState([]);
  const [card, setCard] = useState([]);

  const cards= state?.[id]?.cards;

  React.useLayoutEffect(() => {
    setLists(board);
  }, [board]);


  React.useEffect(() => {
    let boardObj = [];
    if(status_datas !== undefined){
      const getBoard = datas?.forEach((x, index) => {
        const board_data = {
          "id": x?.id,
          "title":x?.title,
          "card" : cards !== undefined ? cards?.filter(elm => elm?.work_status_id === x?.id ) : card,
        }
  
        boardObj.push(board_data);
        setBoard(boardObj);
      })
    }

  }, [card, cards,status_datas])


  const [cardContent, setCardContent] = useState({});
  React.useLayoutEffect(() => {
    const convertArrayToObject = (array, key) =>
      array?.reduce(
        (obj, item) => ({
          ...obj,
          [item["title"]]: item?.card,
        }),
        {}
      );
    const item = convertArrayToObject(board);
    setCardContent(item);
  }, [board, cards]);

  const onDragEnd = (results) => {
    const { destination, source, type } = results;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "COLUMN") {
      const result = Array.from(lists);

      const [removed] = result.splice(source.index, 1);

      result.splice(destination.index, 0, removed);

      let order = [];
      result.forEach((x, index) => {
        const item = { id: x.id, index: index };
        order.push(item);
      });

      dispatch(updateBoardIndex({ order: order }, "project-board"));
      setLists(result);

      return;
    }

    const home = cardContent[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      const card = Array.from(home);
      const [removed] = card.splice(source.index, 1);
      card.splice(destination.index, 0, removed);

      let order = [];
      card.forEach((x, index) => {
        const item = { id: x.id, index: index };
        order.push(item);
      });

      dispatch(updateCardIndex({ order: order }, "project-card"));
      setCardContent({ ...cardContent, [destination.droppableId]: card });
      return;
    }

    const current = [...cardContent[source.droppableId]];
    const next = [...cardContent[destination.droppableId]];
    const target = current[source.index];

    current.splice(source.index, 1);
    next.splice(destination.index, 0, target);

    const result = {
      ...cardContent,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };

    let order = [];
    next.forEach((x, index) => {
      const item = { id: x.id, index: index };
      order.push(item);
    });

    const data = {
      title: destination.droppableId,
      order: order,
      card_id: target.id,
      status:true,
      team_id:task_list?.team_id === null ? null:task_list?.team_id
    };

    dispatch(updateCardNextIndex(data, "project-card"));
    if(task_list?.team_id === 0)
    {
      dispatch(getCard(user?.id, "project-cards", 'user_id'))
    }else if(task_list?.team_id !== null){
      dispatch(getCard(task_list?.team_id, "project-cards", "team_id"))
    }
    setCardContent(result);
    return;
  };

  const onCloseModal = () => {
    updateModal(false);
  };

  const onModalSubmit = (values, mode) => {
    if (mode === modalModeTypes(2)) {
      updateModal(false);
      dispatch(createBoard(values, "project-boards"));
    }

    if (mode === modalModeTypes(1)) {
      dispatch(updateCard(values, "project-cards"));
      updateModal(false);
    }

    if (mode === modalModeTypes(0)) {
      updateModal(false);
      dispatch(createCard(values, "project-cards"));
    }
  };


  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {props.containerHeight ? (
          <div className="scrumboard">
            <BoardWrapper
              cards={cardContent}
              lists={lists}
              success={success}
              id={props?.id}
              task_list={task_list}
              task_list_success={task_list_success}
              user_id={props?.user_id}
              {...props}
            />
          </div>
        ) : (
          <BoardWrapper
            {...props}
            cards={cardContent}
            lists={lists}
            success={success}
            id={props?.id}
            task_list={task_list}
            task_list_success={task_list_success}
            user_id={props?.user_id}
          />
        )}
      </DragDropContext>
      <ModalForm
        visible={modal}
        onClose={() => onCloseModal()}
        onModalSubmit={(values, modalMode) => onModalSubmit(values, modalMode)}
        modalMode={modalMode}
        cardData={cardData}
        listId={currentListId}
      />
    </>
  );
};

const BoardWrapper = ({
  lists,
  cards,
  success,
  containerHeight,
  useClone,
  isCombineEnabled,
  withScrollableColumns,
  task_list_success,
  task_list,
  id,
  user_id,
}) => {
  const { updateModal, updateModalMode } = useContext(ScrumboardContext);

  const data = {
    task_list_id: id,
    title: `New Board ${Math.floor(Math.random() * (30 - 1 + 1)) + 1}`,
  };
  const onAddBoardModal = () => {
    // updateModal(true);
    // updateModalMode(modalModeTypes(2));
    dispatch(createBoard(data));
    // console.log(data);
  };

  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    dispatch(getTeam());
  }, [dispatch]);

  // React.useEffect(() => {
  //   if (success === true) {
  //     dispatch(getBoard("task-lists"));
  //   }
  // }, [dispatch, success]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    dispatch(updateTaskList(values));
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        break;
      case 2:
        showModal();
        break;
      case 3:
        break;
      default:
    }
  };
  const team_item = useSelector((state) => state.TeamStore?.teams);
  const teams = team_item?.team;
  const user = useSelector((state) => state.AuthStore?.user?.id);
  const user_item = useSelector((state) => state.AuthStore?.user);
  const active_workspace = user_item?.active_workspace;
  const board_success = useSelector((state) => state.BoardStore?.success);

  React.useEffect(() => {
    if (success === true || task_list_success === true || board_success === true) {
      const data = {object_id:id, user_id:user_id, token_id:active_workspace?.token_id}
      dispatch(showTaskList(data, `team-task-list-view`));
    }
  }, [dispatch, success, task_list_success, board_success]);


  return (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping={containerHeight}
      isCombineEnabled={isCombineEnabled}
    >
      {(provided) => (
        <div
          className="scrumboard"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="scrumboard-header">
            <Modal
              title="Edit Task List"
              footer={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form
                name="header-form"
                initialValues={{
                  id: task_list?.id,
                  team_id: task_list?.team_id,
                  title: task_list?.title,
                  user_id: user,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item label="Title" name="title">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="user_id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="List Title"
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Team" name="team_id" className="mt-1">
                  <Select className="select-box" placeholder="Select Team">
                    {teams?.map((elm, i) => (
                      <Option key={i} value={elm?.id}>
                        {elm?.team_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item className="mb-0">
                  <Button
                    type="default"
                    htmlType="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="default"
                    className="bg-success text-white float-right"
                    htmlType="submit"
                  >
                    Save Changes
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            {/* <h3 onClick={handleClick}>{task_list?.title}</h3> */}
            {/* <h4 className="d-flex justify-content-between">
              <span className="mr-2 d-block">
                <span>Team: </span>{" "}
                <span className="text-success">
               {team_id?.[0]?.team_name} / {task_list?.creator}
                </span>
              </span>
            </h4> */}

            {/* <div className="text-right">
              <div className="d-flex align-items-center">
                {memberIds.map((member, i) =>
                  i < 4 ? (
                    <AssigneeAvatar key={member} id={member} size={30} chain />
                  ) : null
                )}
                <Avatar className="ml-n2" size={30}>
                  <span className="text-gray font-weight-semibold font-size-base">
                    +9
                  </span>
                </Avatar>
              </div>
            </div> */}
          </div>
      
          <Scrollbars className="scrumboard-body">
            {lists?.map((item, index) => (
              <>
              
              <Board
                key={item?.title}
                id={item?.id}
                index={index}
                title={item?.title}
                contents={cards[item?.title]}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                useClone={useClone}
                listItem={task_list}
              />
              </>
             
            ))}
            {provided.placeholder}
            {/* <div className="board-column add">
              <div className="board-title" onClick={() => onAddBoardModal()}>
                <h4 className="mb-0">
                  <PlusOutlined />
                  <span>Add List</span>
                </h4>
              </div>
            </div> */}
          </Scrollbars>
        </div>
      )}
    </Droppable>
  );
};

const Scrumboard = (props) => {
  return (
    <ScrumboardProvider>
      <ScrumboardWrapper {...props} />
    </ScrumboardProvider>
  );
};

export default Scrumboard;
