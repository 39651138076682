export const GET_WORK_STATUS = "GET_WORK_STATUS"
export const GET_WORK_STATUS_SUCCESS = "GET_WORK_STATUS_SUCCESS"
export const GET_WORK_STATUS_FAILED = "GET_WORK_STATUS_FAILED"

export const SHOW_WORK_STATUS = "SHOW_WORK_STATUS"
export const SHOW_WORK_STATUS_SUCCESS = "SHOW_WORK_STATUS_SUCCESS"
export const SHOW_WORK_STATUS_FAILED = "SHOW_WORK_STATUS_FAILED"

export const CREATE_WORK_STATUS = "CREATE_WORK_STATUS"
export const CREATE_WORK_STATUS_SUCCESS = "CREATE_WORK_STATUS_SUCCESS"
export const CREATE_WORK_STATUS_FAILED = "CREATE_WORK_STATUS_FAILED"

export const UPDATE_WORK_STATUS = "UPDATE_WORK_STATUS"
export const UPDATE_WORK_STATUS_SUCCESS = "UPDATE_WORK_STATUS_SUCCESS"
export const UPDATE_WORK_STATUS_FAILED = "UPDATE_WORK_STATUS_FAILED"

export const DELETE_WORK_STATUS = "DELETE_WORK_STATUS"
export const DELETE_WORK_STATUS_SUCCESS = "DELETE_WORK_STATUS_SUCCESS"
export const DELETE_WORK_STATUS_FAILED = "DELETE_WORK_STATUS_FAILED"
