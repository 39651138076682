import * as types from "./constants"

const initialStates = {
    list_items:[],
    single_list_item:{},
    loading:false,
    error:null,
    success:false,
}

 const ListItemStore = (state = initialStates, action) => {
    //setup list_items reducers
   switch (action.type) {
       case types.GET_LIST_ITEM :
       return {...state, loading:true}
       case types.GET_LIST_ITEM_SUCCESS :
       return {...state, loading:false, list_items:action.list_items}
       case types.GET_LIST_ITEM_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_LIST_ITEM :
       return {...state}
       case types.SHOW_LIST_ITEM_SUCCESS :
       return {...state, single_list_item:action.single_list_item}
       case types.SHOW_LIST_ITEM_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_LIST_ITEM :
       return {...state, loading:true, success:false}
       case types.CREATE_LIST_ITEM_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_LIST_ITEM_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_LIST_ITEM :
       return {...state, loading:true, success:false}
       case types.UPDATE_LIST_ITEM_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_LIST_ITEM_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_LIST_ITEM :
       return {...state, loading:true, success:false}
       case types.DELETE_LIST_ITEM_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_LIST_ITEM_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default ListItemStore
