export const GET_CALENDAR = "GET_CALENDAR"
export const GET_CALENDAR_SUCCESS = "GET_CALENDAR_SUCCESS"
export const GET_CALENDAR_FAILED = "GET_CALENDAR_FAILED"

export const SHOW_CALENDAR = "SHOW_CALENDAR"
export const SHOW_CALENDAR_SUCCESS = "SHOW_CALENDAR_SUCCESS"
export const SHOW_CALENDAR_FAILED = "SHOW_CALENDAR_FAILED"

export const CREATE_CALENDAR = "CREATE_CALENDAR"
export const CREATE_CALENDAR_SUCCESS = "CREATE_CALENDAR_SUCCESS"
export const CREATE_CALENDAR_FAILED = "CREATE_CALENDAR_FAILED"

export const UPDATE_CALENDAR = "UPDATE_CALENDAR"
export const UPDATE_CALENDAR_SUCCESS = "UPDATE_CALENDAR_SUCCESS"
export const UPDATE_CALENDAR_FAILED = "UPDATE_CALENDAR_FAILED"

export const DELETE_CALENDAR = "DELETE_CALENDAR"
export const DELETE_CALENDAR_SUCCESS = "DELETE_CALENDAR_SUCCESS"
export const DELETE_CALENDAR_FAILED = "DELETE_CALENDAR_FAILED"

export const GET_TIME_CONFLICT = "GET_TIME_CONFLICT"
export const GET_TIME_CONFLICT_SUCCESS = "GET_TIME_CONFLICT_SUCCESS"
export const GET_TIME_CONFLICT_FAILED = "GET_TIME_CONFLICT_FAILED"
