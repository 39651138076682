import * as types from "./action_types"

const initialStates = {
    backups:[],
    restore_backups:[],
    backup:{},
    loading:false,
    error:null,
    success:false,
}

const DatabaseBackupStore = (state = initialStates, action) => {

    switch(action.type) {
        case types.GET_DATABASE_BACKUP :
        return {...state, loading:true, success:false}
        case types.GET_DATABASE_BACKUP_SUCCESS :
        return {...state, loading:false, backups:action.backups}
        case types.GET_DATABASE_BACKUP_FAILED :
        return {...state,  loading:false, error:action.error}

        case types.GET_RESTORE_DATABASE_BACKUP :
        return {...state, loading:true, success:false}
        case types.GET_RESTORE_DATABASE_BACKUP_SUCCESS :
        return {...state, loading:false, restore_backups:action.restore_backups}
        case types.GET_RESTORE_DATABASE_BACKUP_FAILED :
        return {...state,  loading:false, error:action.error}

        case types.RESTORE_DATABASE_BACKUP :
        return {...state, loading:true, success:false}
        case types.RESTORE_DATABASE_BACKUP_SUCCESS :
        return {...state, loading:false, success:true}
        case types.RESTORE_DATABASE_BACKUP_FAILED :
        return {...state, success:true, loading:false, error:action.error}

        case types.MAKE_DATABASE_BACKUP :
        return {...state, loading:true, success:false}
        case types.MAKE_DATABASE_BACKUP_SUCCESS :
        return {...state, loading:false, success:true}
        case types.MAKE_DATABASE_BACKUP_FAILED :
        return {...state, success:true, loading:false, error:action.error}

        case types.ENABLE_MAINTENANCE :
        return {...state, loading:true, success:false}
        case types.ENABLE_MAINTENANCE_SUCCESS :
        return {...state, loading:false, success:true}
        case types.ENABLE_MAINTENANCE_FAILED :
        return {...state, success:true, loading:false, error:action.error}


        default:
        return state;
    }

}

export default DatabaseBackupStore;