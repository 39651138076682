import React from 'react'

import {
	FileWordOutlined,
	FileExcelOutlined,
	FileImageOutlined,
	FilePdfOutlined
} from '@ant-design/icons';

export const labels = ['works', 'private', 'important']

export const getLabelColor = label => {
	switch (label) {
		case 'works':
			return 'blue';
		case 'private':
			return 'cyan';
		case 'important':
			return 'red';
		default:
			return '#ececec';
	}
}

export const getFileType = fileType => {
	switch (fileType) {
		case 'DOC':
			return <FileWordOutlined className="text-primary" />
		case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
			return <FileExcelOutlined className="text-success"/>;
		case 'XLS':
				return <FileExcelOutlined className="text-success"/>;
		case 'PDF':
			return <FilePdfOutlined className="text-danger"/>;
		case 'JPG':
			return <FileImageOutlined className="text-warning"/>;
			case 'JPEG':
			return <FileImageOutlined className="text-warning"/>;
		case 'PNG':
			return <FileImageOutlined className="text-warning"/>;
		default:
			return null;
	}
}