export const GET_TIME = "GET_TIME"
export const GET_TIME_SUCCESS = "GET_TIME_SUCCESS"
export const GET_TIME_FAILED = "GET_TIME_FAILED"

export const SHOW_TIME = "SHOW_TIME"
export const SHOW_TIME_SUCCESS = "SHOW_TIME_SUCCESS"
export const SHOW_TIME_FAILED = "SHOW_TIME_FAILED"

export const CREATE_TIME = "CREATE_TIME"
export const CREATE_TIME_SUCCESS = "CREATE_TIME_SUCCESS"
export const CREATE_TIME_FAILED = "CREATE_TIME_FAILED"

export const UPDATE_TIME = "UPDATE_TIME"
export const UPDATE_TIME_SUCCESS = "UPDATE_TIME_SUCCESS"
export const UPDATE_TIME_FAILED = "UPDATE_TIME_FAILED"

export const DELETE_TIME = "DELETE_TIME"
export const DELETE_TIME_SUCCESS = "DELETE_TIME_SUCCESS"
export const DELETE_TIME_FAILED = "DELETE_TIME_FAILED"
