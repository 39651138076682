import React from 'react'
  import draftToHtml from "draftjs-to-html";
  import SunEditor from "suneditor-react";
  import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
  } from "suneditor/src/plugins";


function TextEditor(props) {
    const {handleText, html} = props;
    return (
        <React.Fragment>
              <SunEditor
                    setDefaultStyle="font-family: sans-serif; font-size: 15px;"
                    defaultValue={html}
                    width="100%"
                    onChange={handleText}
                    autoFocus={true}
                    lang="en"
                    setOptions={{
                      showPathLabel: false,
                      minHeight: "30vh",
                      maxHeight: "40vh",
                      placeholder: "Enter your text here!!!",
                      popupDisplay: "local",
                      resizingBar: true,
                      plugins: [
                        align,
                        // font,
                        // fontColor,
                        // fontSize,
                        formatBlock,
                        hiliteColor,
                        horizontalRule,
                        // lineHeight,
                        list,
                        // paragraphStyle,
                        table,
                        template,
                        textStyle,
                        image,
                        link,
                      ],
                      buttonList: [
                        ["undo", "redo"],
                        // ["font", "fontSize", "formatBlock"],
                        // ["paragraphStyle"],
                        [
                          "bold",
                          //   "underline",
                          //   "italic",
                          //   "strike",
                          //   "subscript",
                          //   "superscript"
                        ],
                        // ["fontColor", "hiliteColor"],
                        ["removeFormat"],
                        // Line break
                        ["outdent", "indent"],
                        [
                          "align",
                          "horizontalRule",
                          "list",
                          //  "lineHeight"
                        ],
                        ["table", "link", "image"],
                      ],
                      formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                      // font: [
                      //   "Arial",
                      //   "Calibri",
                      //   "Comic Sans",
                      //   "Courier",
                      //   "Garamond",
                      //   "Georgia",
                      //   "Impact",
                      //   "Lucida Console",
                      //   "Palatino Linotype",
                      //   "Segoe UI",
                      //   "Tahoma",
                      //   "Times New Roman",
                      //   "Trebuchet MS"
                      // ]
                    }}
                  />
        </React.Fragment>
    )
}

export default TextEditor
