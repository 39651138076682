import * as types from "./constants"

export const getCalendar = (calendar) => {
    return {
        type:types.GET_CALENDAR,
        payload:calendar
    }
}

export const showCalendar = (single_calendar) => {
    return {
        type:types.SHOW_CALENDAR,
        payload:single_calendar
    }
}

export const createCalendar = (calendar) => {
    return {
        type:types.CREATE_CALENDAR,
        payload:calendar}
}

export const updateCalendar = (calendar) => {
    return {
        type:types.UPDATE_CALENDAR,
        payload:calendar
    }
}

export const deleteCalendar = (calendar) => {
    return {
        type:types.DELETE_CALENDAR,
        payload:calendar
    }
}

export const getTimeConflict = (is_conflict) => {
    return {
        type:types.GET_TIME_CONFLICT,
        payload:is_conflict
    }
}

