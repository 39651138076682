import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/CommentService';


// Board Redux States
import * as types from './constants';

function* getComment(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_COMMENTS_SUCCESS, comments:response.data});
    } else {
        yield put({type: types.GET_COMMENTS_FAILED, error:error});
    }
}

function* showComment(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_COMMENTS_SUCCESS, single_card:response.data});
    } else {
        yield put({type: types.SHOW_COMMENTS_FAILED, error:error});
    }
}

function* createComment(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_COMMENTS_SUCCESS, list:response.data});
    } else {
        yield put({type: types.CREATE_COMMENTS_FAILED, error:error});
    }
}

function* updateComment(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_COMMENTS_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_COMMENTS_FAILED, error:error});
    }
}

function* deleteComment(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_COMMENTS_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_COMMENTS_FAILED, error:error});
    }
}




function* watchGetComment() 
{
    yield takeEvery(types.GET_COMMENTS, getComment)
}

function* watchShowComment() 
{
    yield takeEvery(types.SHOW_COMMENTS, showComment)
}

function* watchCreateComment() 
{
    yield takeEvery(types.CREATE_COMMENTS, createComment)
}

function* watchUpdateComment() 
{
    yield takeEvery(types.UPDATE_COMMENTS, updateComment)
}

function* watchDeleteComment() 
{
    yield takeEvery(types.DELETE_COMMENTS, deleteComment)
}




function* commentSaga(){
    yield all([
        fork(watchGetComment),
        fork(watchShowComment),
        fork(watchCreateComment),
        fork(watchUpdateComment),
        fork(watchDeleteComment),

    ])
}

export default commentSaga;