import * as types from "./constants"

export const getJournalBoard = (journal_board) => {
    return {
        type:types.GET_JOURNAL_BOARD,
        payload:journal_board
    }
}

export const showJournalBoard = (single_journal_board) => {
    return {
        type:types.SHOW_JOURNAL_BOARD,
        payload:single_journal_board
    }
}

export const createJournalBoard = (journal_board) => {
    return {
        type:types.CREATE_JOURNAL_BOARD,
        payload:journal_board
    }
}

export const updateJournalBoard = (journal_board) => {
    return {
        type:types.UPDATE_JOURNAL_BOARD,
        payload:journal_board
    }
}


export const updateJournalBoardIndex = (index) => {
    return {
        type:types.UPDATE_JOURNAL_BOARD_INDEX,
        payload:index
    }
}

export const updateJournalEntryIndex = (index) => {
    return {
        type:types.UPDATE_JOURNAL_ENTRIES_INDEX,
        payload:index
    }
}

export const updateJournalEntryNextIndex = (index) => {
    return {
        type:types.UPDATE_JOURNAL_ENTRIES_NEXT_INDEX,
        payload:index
    }
}

export const deleteJournalBoard = (journal_board) => {
    return {
        type:types.DELETE_JOURNAL_BOARD,
        payload:journal_board
    }
}



export const getJouranlEntries = (journal_entries) => {
    return {
        type:types.GET_JOURNAL_ENTRIES,
        payload:journal_entries
    }
}

export const showJouranlEntries = (single_journal_entry) => {
    return {
        type:types.SHOW_JOURNAL_ENTRIES,
        payload:single_journal_entry
    }
}

export const createJouranlEntries = (journal_entries) => {
    return {
        type:types.CREATE_JOURNAL_ENTRIES,
        payload:journal_entries
    }
}

export const updateJournalEntries = (journal_entries) => {
    return {
        type:types.UPDATE_JOURNAL_ENTRIES,
        payload:journal_entries
    }
}

export const deleteJouranlEntries = (journal_entries) => {
    return {
        type:types.DELETE_JOURNAL_ENTRIES,
        payload:journal_entries
    }
}