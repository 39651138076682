import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/ObjectService';


// Board Redux States
import * as types from './constants';

function* getObjects(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_OBJECTS_SUCCESS, objects:response.data});
    } else {
        yield put({type: types.GET_OBJECTS_FAILED, error:error});
    }
}

function* showObjects(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_OBJECTS_SUCCESS, single_object:response.data});
    } else {
        yield put({type: types.SHOW_OBJECTS_FAILED, error:error});
    }
}

function* createObjects(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_OBJECTS_SUCCESS,});
    } else {
        yield put({type: types.CREATE_OBJECTS_FAILED, error:error});
    }
}

function* updateObjects(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_OBJECTS_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_OBJECTS_FAILED, error:error});
    }
}

function* deleteObjects(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_OBJECTS_SUCCESS, });
    } else {
        yield put({type: types.DELETE_OBJECTS_FAILED, error:error});
    }
}




function* watchGetObjects() 
{
    yield takeEvery(types.GET_OBJECTS, getObjects)
}

function* watchShowObjects() 
{
    yield takeEvery(types.SHOW_OBJECTS, showObjects)
}

function* watchCreateObjects() 
{
    yield takeEvery(types.CREATE_OBJECTS, createObjects)
}

function* watchUpdateObjects() 
{
    yield takeEvery(types.UPDATE_OBJECTS, updateObjects)
}

function* watchDeleteObjects() 
{
    yield takeEvery(types.DELETE_OBJECTS, deleteObjects)
}

function* objectSaga(){
    yield all([
        fork(watchGetObjects),
        fork(watchShowObjects),
        fork(watchCreateObjects),
        fork(watchUpdateObjects),
        fork(watchDeleteObjects),
    ])
}

export default objectSaga;