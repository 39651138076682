export const GET_TEAMS = "GET_TEAMS"
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS"
export const GET_TEAMS_FAILED = "GET_TEAMS_FAILED"

export const GET_TEAM_USERS = "GET_TEAM_USERS"
export const GET_TEAM_USERS_SUCCESS = "GET_TEAM_USERS_SUCCESS"
export const GET_TEAM_USERS_FAILED = "GET_TEAM_USERS_FAILED"

export const SHOW_TEAMS = "SHOW_TEAMS"
export const SHOW_TEAMS_SUCCESS = "SHOW_TEAMS_SUCCESS"
export const SHOW_TEAMS_FAILED = "SHOW_TEAMS_FAILED"

export const CREATE_TEAMS = "CREATE_TEAMS"
export const CREATE_TEAMS_SUCCESS = "CREATE_TEAMS_SUCCESS"
export const CREATE_TEAMS_FAILED = "CREATE_TEAMS_FAILED"

export const UPDATE_TEAMS = "UPDATE_TEAMS"
export const UPDATE_TEAMS_SUCCESS = "UPDATE_TEAMS_SUCCESS"
export const UPDATE_TEAMS_FAILED = "UPDATE_TEAMS_FAILED"

export const DELETE_TEAMS = "DELETE_TEAMS"
export const DELETE_TEAMS_SUCCESS = "DELETE_TEAMS_SUCCESS"
export const DELETE_TEAMS_FAILED = "DELETE_TEAMS_FAILED"

export const UPDATE_ACTIVE_TEAM = "UPDATE_ACTIVE_TEAM"
export const UPDATE_ACTIVE_TEAM_SUCCESS = "UPDATE_ACTIVE_TEAM_SUCCESS"
export const UPDATE_ACTIVE_TEAM_FAILED = "UPDATE_ACTIVE_TEAM_FAILED"

export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS"
export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS"
export const GET_TEAM_MEMBERS_FAILED = "GET_TEAM_MEMBERS_FAILED"

export const CREATE_TEAM_MEMBERS = "CREATE_TEAM_MEMBERS"
export const CREATE_TEAM_MEMBERS_SUCCESS = "CREATE_TEAM_MEMBERS_SUCCESS"
export const CREATE_TEAM_MEMBERS_FAILED = "CREATE_TEAM_MEMBERS_FAILED"

export const UPDATE_TEAM_MEMBERS = "UPDATE_TEAM_MEMBERS"
export const UPDATE_TEAM_MEMBERS_SUCCESS = "UPDATE_TEAM_MEMBERS_SUCCESS"
export const UPDATE_TEAM_MEMBERS_FAILED = "UPDATE_TEAM_MEMBERS_FAILED"

export const DELETE_TEAM_MEMBERS = "DELETE_TEAM_MEMBERS"
export const DELETE_TEAM_MEMBERS_SUCCESS = "DELETE_TEAM_MEMBERS_SUCCESS"
export const DELETE_TEAM_MEMBERS_FAILED = "DELETE_TEAM_MEMBERS_FAILED"

export const GET_TEAM_ADMIN = "GET_TEAM_ADMIN"
export const GET_TEAM_ADMIN_SUCCESS = "GET_TEAM_ADMIN_SUCCESS"
export const GET_TEAM_ADMIN_FAILED = "GET_TEAM_ADMIN_FAILED"

export const GET_TEAM_ROLES = "GET_TEAM_ROLES"
export const GET_TEAM_ROLES_SUCCESS = "GET_TEAM_ROLES_SUCCESS"
export const GET_TEAM_ROLES_FAILED = "GET_TEAM_ROLES_FAILED"

export const CREATE_TEAM_ROLES = "CREATE_TEAM_ROLES"
export const CREATE_TEAM_ROLES_SUCCESS = "CREATE_TEAM_ROLES_SUCCESS"
export const CREATE_TEAM_ROLES_FAILED = "CREATE_TEAM_ROLES_FAILED"

export const UPDATE_TEAM_ROLES = "UPDATE_TEAM_ROLES"
export const UPDATE_TEAM_ROLES_SUCCESS = "UPDATE_TEAM_ROLES_SUCCESS"
export const UPDATE_TEAM_ROLES_FAILED = "UPDATE_TEAM_ROLES_FAILED"

export const DELETE_TEAM_ROLES = "DELETE_TEAM_ROLES"
export const DELETE_TEAM_ROLES_SUCCESS = "DELETE_TEAM_ROLES_SUCCESS"
export const DELETE_TEAM_ROLES_FAILED = "DELETE_TEAM_ROLES_FAILED"
