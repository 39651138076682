import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/database-backups`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            return { error };
          });
    },

    getRestoreBackup(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .get(`/get-restore-backups`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          return { error };
        });
  },

    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/database-backups`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    store(data){
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/restore-database",data)
          .then((response) => {
            // successNotify(response.data.message);
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            message.error(error.response.data.message, 4);
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/database-backups/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            message.error(error.response.data.message, 4);
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    enableMaintainance(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .put(`/master/${data.id}`, data)
        .then((response) => {
          message.success(`${response.data.message.message}`, 4);
          return { response };
        })
        .catch((error) => {
        message.error(`${error.response.data.message} ${error.response.data.login_users?.map((elm) => ` ${elm.fullname}`)}`);
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
    destroy(data){
     
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/database-backups/${data}`)
          .then((response) => {
            message.success(response.data.message, 2);
            return { response };
          })
          .catch((error) => {
            message.error(error.response.data.message, 2);
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },

    makeBackup(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .post("/make-backups",{})
        .then((response) => {
          // successNotify(response.data.message);
          message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },

  //   search(data){
  //     axios.defaults.withCredentials = true;
  //     return authAxios
  //       .get(`/search-emails?object_id=${data.object_id}&search=${data.search.toString()}`)
  //       .then((response) => {
  //         return { response };
  //       })
  //       .catch((error) => {
  //         // errorNotify(error.response.data.message === "Unauthenticated.");
  //         // if(error.response.status === 401){
  //         //   alert("Session Expire Login Again!")
  //         //   localStorage.clear();
  //         //   window.location = "/auth/login";
  //         // }
          
  //         return { error };
  //       });
  // },
}

