import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api, members_api, team_role_api } from 'services/TeamService';


// Board Redux States
import * as types from './constants';

function* getTeam(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_TEAMS_SUCCESS, teams:response.data});
    } else {
        yield put({type: types.GET_TEAMS_FAILED, error:error});
    }
}

function* getTeamAdmin(action)
{
    const {response, error} = yield call(members_api.teamAdmin, action.payload)
    if(response)
    {
        yield put({type: types.GET_TEAM_ADMIN_SUCCESS, team_admin:response.data});
    } else {
        yield put({type: types.GET_TEAM_ADMIN_FAILED, error:error});
    }
}

function* getTeamUsers(action)
{
    const {response, error} = yield call(api.teamUser, action.payload)
    if(response)
    {
        yield put({type: types.GET_TEAM_USERS_SUCCESS, users:response.data});
    } else {
        yield put({type: types.GET_TEAM_USERS_FAILED, error:error});
    }
}

function* showTeam(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_TEAMS_SUCCESS, single_team:response.data});
    } else {
        yield put({type: types.SHOW_TEAMS_FAILED, error:error});
    }
}

function* createTeam(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_TEAMS_SUCCESS,});
    } else {
        yield put({type: types.CREATE_TEAMS_FAILED, error:error});
    }
}

function* updateTeam(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_TEAMS_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_TEAMS_FAILED, error:error});
    }
}

function* updateActiveTeam(action)
{
    const {response, error} = yield call(api.updateActiveTeam, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_ACTIVE_TEAM_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_ACTIVE_TEAM_FAILED, error:error});
    }
}

function* deleteTeam(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_TEAMS_SUCCESS, });
    } else {
        yield put({type: types.DELETE_TEAMS_FAILED, error:error});
    }
}

// Team members sagas 

function* getTeamMembers(action)
{
    const {response, error} = yield call(members_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_TEAM_MEMBERS_SUCCESS, members:response.data});
    } else {
        yield put({type: types.GET_TEAM_MEMBERS_FAILED, error:error});
    }
}


function* createTeamMembers(action)
{
    const {response, error} = yield call(members_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_TEAM_MEMBERS_SUCCESS,});
    } else {
        yield put({type: types.CREATE_TEAM_MEMBERS_FAILED, error:error});
    }
}

function* updateTeamMembers(action)
{
    const {response, error} = yield call(members_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_TEAM_MEMBERS_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_TEAM_MEMBERS_FAILED, error:error});
    }
}

function* deleteTeamMembers(action)
{
    const {response, error} = yield call(members_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_TEAM_MEMBERS_SUCCESS, });
    } else {
        yield put({type: types.DELETE_TEAM_MEMBERS_FAILED, error:error});
    }
}




// team role sagas
// Team members sagas 

function* getTeamRole(action)
{
    const {response, error} = yield call(team_role_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_TEAM_ROLES_SUCCESS, team_role:response.data});
    } else {
        yield put({type: types.GET_TEAM_ROLES_FAILED, error:error});
    }
}


function* createTeamRole(action)
{
    const {response, error} = yield call(team_role_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_TEAM_ROLES_SUCCESS,});
    } else {
        yield put({type: types.CREATE_TEAM_ROLES_FAILED, error:error});
    }
}

function* updateTeamRole(action)
{
    const {response, error} = yield call(team_role_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_TEAM_ROLES_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_TEAM_ROLES_FAILED, error:error});
    }
}

function* deleteTeamRole(action)
{
    const {response, error} = yield call(team_role_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_TEAM_ROLES_SUCCESS, });
    } else {
        yield put({type: types.DELETE_TEAM_ROLES_FAILED, error:error});
    }
}


function* watchGetTeam() 
{
    yield takeEvery(types.GET_TEAMS, getTeam)
}

function* watchGetTeamAdmin() 
{
    yield takeEvery(types.GET_TEAM_ADMIN, getTeamAdmin)
}

function* watchGetTeamUsers() 
{
    yield takeEvery(types.GET_TEAM_USERS, getTeamUsers)
}

function* watchShowTeam() 
{
    yield takeEvery(types.SHOW_TEAMS, showTeam)
}

function* watchCreateTeam() 
{
    yield takeEvery(types.CREATE_TEAMS, createTeam)
}

function* watchUpdateTeam() 
{
    yield takeEvery(types.UPDATE_TEAMS, updateTeam)
}

function* watchUpdateActiveTeam() 
{
    yield takeEvery(types.UPDATE_ACTIVE_TEAM, updateActiveTeam)
}

function* watchDeleteTeam() 
{
    yield takeEvery(types.DELETE_TEAMS, deleteTeam)
}

// get members watch function

function* watchGetTeamMembers() 
{
    yield takeEvery(types.GET_TEAM_MEMBERS, getTeamMembers)
}

function* watchCreateTeamMembers() 
{
    yield takeEvery(types.CREATE_TEAM_MEMBERS, createTeamMembers)
}

function* watchUpdateTeamMembers() 
{
    yield takeEvery(types.UPDATE_TEAM_MEMBERS, updateTeamMembers)
}

function* watchDeleteTeamMembers() 
{
    yield takeEvery(types.DELETE_TEAM_MEMBERS, deleteTeamMembers)
}

// get members watch function

function* watchGetTeamRole() 
{
    yield takeEvery(types.GET_TEAM_ROLES, getTeamRole)
}

function* watchCreateTeamRole() 
{
    yield takeEvery(types.CREATE_TEAM_ROLES, createTeamRole)
}

function* watchUpdateTeamRole() 
{
    yield takeEvery(types.UPDATE_TEAM_ROLES, updateTeamRole)
}

function* watchDeleteTeamRole() 
{
    yield takeEvery(types.DELETE_TEAM_ROLES, deleteTeamRole)
}

function* teamSaga(){
    yield all([
        fork(watchGetTeam),
        fork(watchShowTeam),
        fork(watchCreateTeam),
        fork(watchUpdateTeam),
        fork(watchDeleteTeam),
        fork(watchGetTeamUsers),
        fork(watchGetTeamAdmin),
        fork(watchUpdateActiveTeam),
        // register members watch functions

        fork(watchGetTeamMembers),
        fork(watchCreateTeamMembers),
        fork(watchUpdateTeamMembers),
        fork(watchDeleteTeamMembers),

        // register team role functions
        fork(watchGetTeamRole),
        fork(watchCreateTeamRole),
        fork(watchUpdateTeamRole),
        fork(watchDeleteTeamRole),

    ])
}

export default teamSaga;