import React from 'react'
import {CheckOutlined} from "@ant-design/icons";
import {Popconfirm} from "antd";
import { useDispatch } from 'react-redux';
import {updateCard} from "redux/actions";

function MarkAsComplete(props) {
    const dispatch = useDispatch();
    const {card_data, cardData, url} = props;

    function cancel(e) {}

    const confirmCompleted = () => {
        let data = card_data;
        data.work_status_id = 7;
        data.is_done = true;
        data.attachments = [];
        data.isConfirmCompleted = true;
        dispatch(updateCard(data, url));
      };

    return (
        <React.Fragment>
            
            {card_data?.work_status_id >= 4 ? (
              <div className="item__box">
                {card_data?.work_status_id === 7 ? (
                  <span>
                    <CheckOutlined />{" "}
                    <span className="icon__text">Task Marked As Done</span>
                  </span>
                ) : (
                  <Popconfirm
                    title="Are you sure you want to mark task as done"
                    onConfirm={confirmCompleted}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span>
                      <span className="icon__text">Mark Task As Done</span>
                    </span>
                  </Popconfirm>
                )}
              </div>
            ) : (
              ""
            )}
        </React.Fragment>
    )
}

export default MarkAsComplete
