import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import { isNull } from "lodash-es";
import Loading from "components/shared-components/Loading";
import {
  getTeam,
  getTeamAdmin,
  getTeamRole,
  getUser,
  allNotification,
  getWorkStatus,
} from "redux/actions";
const token = localStorage.getItem("scrumboard_token");

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  

  const dispatch = useDispatch();

  const [isCancelled, setIsCancelled] = React.useState(false);

  React.useEffect(() => {
    if (!isCancelled) {
      if (token !== null) {
        const getUserData = async () => {
           await dispatch(getUser());
        };

        getUserData();
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, []);

  React.useEffect(() => {
    if (!isCancelled) {
      if (token !== null) {
        const getTeamAdminData = async () => {
          await dispatch(getTeamAdmin());
        };

        getTeamAdminData();
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, []);

  React.useEffect(() => {
    if (!isCancelled) {
      if (token !== null) {
        const getTeamData = async () => {
          await dispatch(getTeam());
        };

        getTeamData();
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, []);

  React.useEffect(() => {
    if (!isCancelled) {
      if (token !== null) {
        const getTeamRoleData = async () => {
         await dispatch(getTeamRole());
        };

        getTeamRoleData();
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, []);

  React.useEffect(() => {
    if (!isCancelled) {
      if (token !== null) {
        const getNotification = async () => {
          await dispatch(allNotification());
        };

        getNotification();
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, []);


  React.useEffect(() => {
    if (!isCancelled) {
      if (token !== null) {
        const getWorkStatusData = async () => {
          await dispatch(getWorkStatus());
        };

        getWorkStatusData();
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, []);


  const success = useSelector((state) => state.AuthStore.success);

  React.useEffect(() => {
    if (!isCancelled) {
      if (success === true) {
        if (token !== null) {
          const getUserData = async () => {
            const object = await dispatch(getUser());
          };

          getUserData();
        }
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, [success]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            {isNull(token) ? (
              <Redirect to={`${AUTH_PREFIX_PATH}/login `} />
            ) : token === undefined ? (
              <Redirect to={`${AUTH_PREFIX_PATH}/login `} />
            ) : (
              <Redirect to={APP_PREFIX_PATH} />
            )}
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location} />
          </Route>

          <Suspense fallback={<Loading cover="content" />}>
            <div style={{ padding: "50px", paddingTop: "20px" }}>
              <Route
                path={`/project/:id`}
                component={lazy(() => import(`./app-views/project/scrumboard`))}
              />
              <Route
                path={`/list/:id`}
                component={lazy(() => import(`./app-views/list`))}
              />
              <Route
                path={`/work-status/:id`}
                component={lazy(() =>
                  import(`./app-views/work-status/scrumboard`)
                )}
              />
              <Route
                path={`/journal/:id`}
                component={lazy(() => import(`./app-views/journal/scrumboard`))}
              />
              <Route
                path={`/schedule/:id`}
                component={lazy(() =>
                  import(`./app-views/schedule/scrumboard`)
                )}
              />
              <Route
                path={`/task-list/:id`}
                component={lazy(() =>
                  import(`./app-views/tasklist/scrumboard`)
                )}
              />
              <Route
                path={`/calendar/:id`}
                component={lazy(() => import(`./app-views/calendar`))}
              />
              <Route
                path={`/team-calendar/:id`}
                component={lazy(() => import(`./app-views/calendar_team_view`))}
              />
            </div>
          </Suspense>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
