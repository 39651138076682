import * as types from "./action_types"

export const registerDevice = (device) => {
    return {
        type:types.REGISTER_DEVICE,
        payload:device
    }
}

export const showDevice = (device) => {
    return {
        type:types.SHOW_DEVICE,
        payload:device
    }
}

export const getSmsMessages = (sms_messages) => {
    return {
        type:types.GET_SMS_MESSAGES,
        payload:sms_messages
    }
}

export const showSmsMessages = (show_sms_messages) => {
    return {
        type:types.SHOW_SMS_MESSAGES,
        payload:show_sms_messages
    }
}

export const sendTextMessage = (send_sms_messages) => {
    return {
        type:types.SEND_SMS_MESSAGES,
        payload:send_sms_messages
    }
}