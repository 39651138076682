import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/EmailServiceType';

import * as types from './constants';

function* getEmailServiceType(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_EMAIL_SERVICE_TYPE_SUCCESS, email_service_types:response.data});
    } else {
        yield put({type: types.GET_EMAIL_SERVICE_TYPE_FAILED, error:error});
    }
}

function* showEmailServiceType(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_EMAIL_SERVICE_TYPE_SUCCESS, single_email_service_type:response.data});
    } else {
        yield put({type: types.SHOW_EMAIL_SERVICE_TYPE_FAILED, error:error});
    }
}

function* createEmailServiceType(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_EMAIL_SERVICE_TYPE_SUCCESS,});
    } else {
        yield put({type: types.CREATE_EMAIL_SERVICE_TYPE_FAILED, error:error});
    }
}

function* updateEmailServiceType(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_EMAIL_SERVICE_TYPE_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_EMAIL_SERVICE_TYPE_FAILED, error:error});
    }
}

function* deleteEmailServiceType(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_EMAIL_SERVICE_TYPE_SUCCESS, });
    } else {
        yield put({type: types.DELETE_EMAIL_SERVICE_TYPE_FAILED, error:error});
    }
}




function* watchGetEmailServiceType() 
{
    yield takeLatest(types.GET_EMAIL_SERVICE_TYPE, getEmailServiceType)
}

function* watchShowEmailServiceType() 
{
    yield takeEvery(types.SHOW_EMAIL_SERVICE_TYPE, showEmailServiceType)
}

function* watchCreateEmailServiceType() 
{
    yield takeEvery(types.CREATE_EMAIL_SERVICE_TYPE, createEmailServiceType)
}

function* watchUpdateEmailServiceType() 
{
    yield takeEvery(types.UPDATE_EMAIL_SERVICE_TYPE, updateEmailServiceType)
}

function* watchDeleteEmailServiceType() 
{
    yield takeEvery(types.DELETE_EMAIL_SERVICE_TYPE, deleteEmailServiceType)
}




function* emailServiceTypeSaga(){
    yield all([
        fork(watchGetEmailServiceType),
        fork(watchShowEmailServiceType),
        fork(watchCreateEmailServiceType),
        fork(watchUpdateEmailServiceType),
        fork(watchDeleteEmailServiceType),

    ])
}

export default emailServiceTypeSaga;