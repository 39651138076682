export const GET_LIST_ITEM = "GET_LIST_ITEM"
export const GET_LIST_ITEM_SUCCESS = "GET_LIST_ITEM_SUCCESS"
export const GET_LIST_ITEM_FAILED = "GET_LIST_ITEM_FAILED"

export const SHOW_LIST_ITEM = "SHOW_LIST_ITEM"
export const SHOW_LIST_ITEM_SUCCESS = "SHOW_LIST_ITEM_SUCCESS"
export const SHOW_LIST_ITEM_FAILED = "SHOW_LIST_ITEM_FAILED"

export const CREATE_LIST_ITEM = "CREATE_LIST_ITEM"
export const CREATE_LIST_ITEM_SUCCESS = "CREATE_LIST_ITEM_SUCCESS"
export const CREATE_LIST_ITEM_FAILED = "CREATE_LIST_ITEM_FAILED"

export const UPDATE_LIST_ITEM = "UPDATE_LIST_ITEM"
export const UPDATE_LIST_ITEM_SUCCESS = "UPDATE_LIST_ITEM_SUCCESS"
export const UPDATE_LIST_ITEM_FAILED = "UPDATE_LIST_ITEM_FAILED"

export const DELETE_LIST_ITEM = "DELETE_LIST_ITEM"
export const DELETE_LIST_ITEM_SUCCESS = "DELETE_LIST_ITEM_SUCCESS"
export const DELETE_LIST_ITEM_FAILED = "DELETE_LIST_ITEM_FAILED"
