import React, {useCallback, useState} from 'react'
import {
    Modal,
    Form,
    Button,
    Upload,
  } from "antd";

  import {
    UploadOutlined,
  } from "@ant-design/icons";

  import { useDispatch } from "react-redux";
import { updateAttachmentFromCard } from 'redux/actions';

function AttachmentModal(props) {
    const dispatch = useDispatch();
    const {attachmentVisible, setAttachmentVisible, url, cardData} = props;
    const [base64, setBase64] = useState([]);
    const handleAttachmentOk = useCallback((e) => setAttachmentVisible(false), [setAttachmentVisible])
    const handleAttachmentCancel = useCallback((e) => setAttachmentVisible(false), [setAttachmentVisible])

    const normFile = useCallback((e) => {
        if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
    }, [])

    const handleUpload = useCallback((info) => {
        let fileList = [...info.fileList];
        // Accept 5 files only
        fileList = fileList.slice(-5);
        fileList.forEach(function (file, index) {
          let reader = new FileReader();
          reader.onload = (e) => {
            file.base64 = e.target.result;
          };
          reader.readAsDataURL(file.originFileObj);
        });
        setBase64(fileList);
    }, [])

    const handleUploadAttacthment = async() => {
     
        await dispatch(
          updateAttachmentFromCard({ id: cardData?.id, team_id:cardData.team_id, due_date:cardData?.due_date, attachments: base64 }, url)
       );
       setBase64([]);
       setAttachmentVisible(false);
     
    }
  
 
    return (
        <React.Fragment>
             {/* attachment input */}
      <Modal
        title="New Attachments"
        visible={attachmentVisible}
        onOk={handleAttachmentOk}
        onCancel={handleAttachmentCancel}
        footer={null}
      >
        <Form.Item
          name="dragger"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra=""
        >
          <Upload.Dragger
            name="files"
            listType=""
            showUploadList={true}
            onChange={handleUpload}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>

        <div className="d-flex justify-content-between align-items-center">
          <Button
            className="mr-1"
            type="default"
            htmlType="button"
            size="small"
            onClick={() => setAttachmentVisible(false)}
          >
            Cancel
          </Button>
          <Button
            type="default"
            htmlType="button"
            className="bg-success text-white"
            size="small"
            onClick={() => handleUploadAttacthment()}
          >
            Save
          </Button>
        </div>
      </Modal>
      {/* end of attachment form */}
        </React.Fragment>
    )
}

export default AttachmentModal
