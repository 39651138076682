// get token with bearer
import axios from 'axios';

// set token hearders
let hostName = process.env.REACT_APP_API_ENDPOINT_URL;

// if (process.env.NODE_ENV === "development") {
//   hostName = "http://localhost:8000/";
// } else if (process.env.NODE_ENV === "production") {
//   hostName = "https://api.mrfriday.luntano.net/";
// }

// get token with bearer
const accessToken = localStorage.getItem('scrumboard_token');
let tokenName =  process.env.REACT_APP_API_TOKEN_ENDPOINT_URL;

// if (process.env.NODE_ENV === "development") {
// 	tokenName = "http://localhost:8000/api/v1";
// } else if (process.env.NODE_ENV === "production") {
// 	tokenName = "https://api.mrfriday.luntano.net/api/v1";
// }

export  const authAxios = axios.create({
    baseURL: tokenName ,
    headers: {
        Authorization: `Bearer ${accessToken}`
    }
});

export  const hostAxios = axios.create({
    baseURL: hostName,
});

const dev = {
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL
  };
  
  const prod = {
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL
  };
  
  const test = {
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL
  };

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
