import * as types from "./constants"

const initialStates = {
    objects:[],
    single_object:{},
    loading:false,
    error:null,
    success:false,
}

 const objectStore = (state = initialStates, action) => {
    //setup object reducers
   switch (action.type) {
       case types.GET_OBJECTS :
       return {...state, loading:true}
       case types.GET_OBJECTS_SUCCESS :
       return {...state, loading:false, objects:action.objects}
       case types.GET_OBJECTS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_OBJECTS :
       return {...state}
       case types.SHOW_OBJECTS_SUCCESS :
       return {...state, single_object:action.single_object}
       case types.SHOW_OBJECTS_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_OBJECTS :
       return {...state, loading:true, success:false}
       case types.CREATE_OBJECTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_OBJECTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_OBJECTS :
       return {...state, loading:true, success:false}
       case types.UPDATE_OBJECTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_OBJECTS_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_OBJECTS :
       return {...state, loading:true, success:false}
       case types.DELETE_OBJECTS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_OBJECTS_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default objectStore
