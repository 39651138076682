import * as types from "./constants"

const initialStates = {
    
    assigned_member:[],
    all_assigned_member:[],
    single_assigned_member:{},
    users:[],
    loading:false,
    error:null,
    success:false,
}

 const AssignUserStore = (state = initialStates, action) => {
    //setup teams reducers
   switch (action.type) {
     
       case types.GET_ASSIGNED_MEMBERS :
       return {...state, loading:true}
       case types.GET_ASSIGNED_MEMBERS_SUCCESS :
       return {...state, loading:false, assigned_member:action.assigned_member}
       case types.GET_ASSIGNED_MEMBERS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.GET_ALL_ASSIGNED_MEMBERS :
       return {...state, loading:true}
       case types.GET_ALL_ASSIGNED_MEMBERS_SUCCESS :
       return {...state, loading:false, all_assigned_member:action.all_assigned_member}
       case types.GET_ALL_ASSIGNED_MEMBERS_FAILED :
       return {...state, loading:false, error:action.error, }

  case types.SHOW_ASSIGNED_MEMBERS :
       return {...state}
       case types.SHOW_ASSIGNED_MEMBERS_SUCCESS :
       return {...state, single_assigned_member:action.single_assigned_member}
       case types.SHOW_ASSIGNED_MEMBERS_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_ASSIGNED_MEMBERS :
       return {...state, loading:true, success:false}
       case types.CREATE_ASSIGNED_MEMBERS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_ASSIGNED_MEMBERS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_ASSIGNED_MEMBERS :
       return {...state, loading:true, success:false}
       case types.UPDATE_ASSIGNED_MEMBERS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_ASSIGNED_MEMBERS_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_ASSIGNED_MEMBERS :
       return {...state, loading:true, success:false}
       case types.DELETE_ASSIGNED_MEMBERS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_ASSIGNED_MEMBERS_FAILED :
       return {...state, success:true, loading:false, error:action.error}
       default:
           return state;
   }
}

export default AssignUserStore
