export const UPDATE_ATTACHMENT_FROM_CARD = "UPDATE_ATTACHMENT_FROM_CARD";
export const UPDATE_ATTACHMENT_FROM_CARD_SUCCESS = "UPDATE_ATTACHMENT_FROM_CARD_SUCCESS";
export const UPDATE_ATTACHMENT_FROM_CARD_FAILED = "UPDATE_ATTACHMENT_FROM_CARD_FAILED";

export const UPDATE_ATTACHMENTS = "UPDATE_ATTACHMENTS"
export const UPDATE_ATTACHMENTS_SUCCESS = "UPDATE_ATTACHMENTS_SUCCESS"
export const UPDATE_ATTACHMENTS_FAILED = "UPDATE_ATTACHMENTS_FAILED"

export const DELETE_ATTACHMENTS = "DELETE_ATTACHMENTS"
export const DELETE_ATTACHMENTS_SUCCESS = "DELETE_ATTACHMENTS_SUCCESS"
export const DELETE_ATTACHMENTS_FAILED = "DELETE_ATTACHMENTS_FAILED"