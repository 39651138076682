import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { list_api, card_api } from 'services/BoardService';


// Board Redux States
import * as types from './constants';

function* getBoard(action)
{
    const {response, error} = yield call(list_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.GET_BOARD_FAILED, error:error});
    }
}

function* showBoard(action)
{
    const {response, error} = yield call(list_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_BOARD_SUCCESS, single_card:response.data});
    } else {
        yield put({type: types.SHOW_BOARD_FAILED, error:error});
    }
}

function* createBoard(action)
{
    const {response, error} = yield call(list_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.CREATE_BOARD_FAILED, error:error});
    }
}

function* updateBoard(action)
{
    const {response, error} = yield call(list_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_BOARD_FAILED, error:error});
    }
}


function* updateBoardIndex(action)
{
    const {response, error} = yield call(list_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_BOARD_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_BOARD_INDEX_FAILED, error:error});
    }
}

function* deleteBoard(action)
{
    const {response, error} = yield call(list_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_BOARD_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_BOARD_FAILED, error:error});
    }
}


function* getCard(action)
{
    const {response, error} = yield call(card_api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_CARD_SUCCESS, card:response.data});
    } else {
        yield put({type: types.GET_CARD_FAILED, error:error});
    }
}

function* showCard(action)
{
    const {response, error} = yield call(card_api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_CARD_SUCCESS, single_card:response.data});
    } else {
        yield put({type: types.SHOW_CARD_FAILED, error:error});
    }
}

function* createCard(action)
{
    const {response, error} = yield call(card_api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_CARD_SUCCESS, card:response.data});
    } else {
        yield put({type: types.CREATE_CARD_FAILED, error:error});
    }
}

function* updateCard(action)
{
    const {response, error} = yield call(card_api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_CARD_SUCCESS, card:response.data});
    } else {
        yield put({type: types.UPDATE_CARD_FAILED, error:error});
    }
}

function* updateCardIndex(action)
{
    const {response, error} = yield call(card_api.updateIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_CARD_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_CARD_INDEX_FAILED, error:error});
    }
}

function* updateCardNextIndex(action)
{
    const {response, error} = yield call(card_api.updateNextIndex, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_CARD_NEXT_INDEX_SUCCESS,});
    } else {
        yield put({type: types.UPDATE_CARD_NEXT_INDEX_FAILED, error:error});
    }
}

function* deleteCard(action)
{
    const {response, error} = yield call(card_api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_CARD_SUCCESS, card:response.data});
    } else {
        yield put({type: types.DELETE_CARD_FAILED, error:error});
    }
}


function* watchGetBoard() 
{
    yield takeEvery(types.GET_BOARD, getBoard)
}

function* watchShowBoard() 
{
    yield takeEvery(types.SHOW_BOARD, showBoard)
}

function* watchCreateBoard() 
{
    yield takeEvery(types.CREATE_BOARD, createBoard)
}

function* watchUpdateBoard() 
{
    yield takeEvery(types.UPDATE_BOARD, updateBoard)
}


function* watchUpdateBoardIndex() 
{
    yield takeEvery(types.UPDATE_BOARD_INDEX, updateBoardIndex)
}

function* watchDeleteBoard() 
{
    yield takeEvery(types.DELETE_BOARD, deleteBoard)
}

function* watchUpdateCardIndex() 
{
    yield takeEvery(types.UPDATE_CARD_INDEX, updateCardIndex)
}

function* watchUpdateCardNextIndex() 
{
    yield takeEvery(types.UPDATE_CARD_NEXT_INDEX, updateCardNextIndex)
}


function* watchGetCard() 
{
    yield takeLatest(types.GET_CARD, getCard)
}

function* watchShowCard() 
{
    yield takeEvery(types.SHOW_CARD, showCard)
}

function* watchCreateCard() 
{
    yield takeEvery(types.CREATE_CARD, createCard)
}

function* watchUpdateCard() 
{
    yield takeEvery(types.UPDATE_CARD, updateCard)
}

function* watchDeleteCard() 
{
    yield takeEvery(types.DELETE_CARD, deleteCard)
}


function* boardCardSaga(){
    yield all([
        fork(watchGetBoard),
        fork(watchShowBoard),
        fork(watchCreateBoard),
        fork(watchUpdateBoard),
        fork(watchUpdateBoardIndex),
        fork(watchDeleteBoard),
        fork(watchGetCard),
        fork(watchShowCard),
        fork(watchCreateCard),
        fork(watchUpdateCard),
        fork(watchDeleteCard),
        fork(watchUpdateCardIndex),
        fork(watchUpdateCardNextIndex),
    ])
}

export default boardCardSaga;