import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";

export const api = {
    /**
    * Get role listings
    *
    * @param {Object} params
    * @returns {Object}
    */
  
   index(){
      axios.defaults.withCredentials = false;
      return authAxios.get("/all")
      .then((response) => ({response}))
      .catch((error) => ({error}));
    },
  
    read(data){
      axios.defaults.withCredentials = false;
      return authAxios.get(`/read/${data}`)
      .then((response) => ({response}))
      .catch((error) => ({error}));
    },
  
    readAll(){
      axios.defaults.withCredentials = false;
      return authAxios.get("/read-all")
      .then((response) => ({response}))
      .catch((error) => ({error}));
    },
  
  }