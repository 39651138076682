import React from 'react'
import { Divider } from "antd";
import { CloseOutlined, SafetyOutlined } from "@ant-design/icons";
function Priority(props) {
    const {setPriorityBox, priority, priorityBox, priorityData, setPriority, changePiriority} = props;
    return (
        <React.Fragment>
              <div className="item__box">
                <span onClick={() => setPriorityBox(true)}>
                  <SafetyOutlined /> <span className="icon__text">Priority:</span>{" "}
                  <span>{priority}</span>
                </span>
                {priorityBox === true ? (
                  <div className="dropdown__box">
                    <div className="heading__box">
                      <span>Priority</span>
                      <CloseOutlined
                        onClick={() => {
                          setPriorityBox(false);
                        }}
                      />
                    </div>
                    <Divider className="dropdown__divider" />
                    {priorityData.map((elm, index) => (
                      <div
                        className="dropdown__box_menu"
                        key={index}
                        onClick={() => {
                          setPriority(elm?.value);
                          setPriorityBox(false);
                          changePiriority(elm?.value);
                        }}
                      >
                        {elm?.value}
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>  
        </React.Fragment>
    )
}

export default Priority
