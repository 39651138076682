import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/AssignMemberService';


// Board Redux States
import * as types from './constants';


function* getAssignedMembers(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_ASSIGNED_MEMBERS_SUCCESS, assigned_member:response.data});
    } else {
        yield put({type: types.GET_ASSIGNED_MEMBERS_FAILED, error:error});
    }
}

function* getAllAssignedMembers(action)
{
    const {response, error} = yield call(api.allAssignMembers, action.payload)
    if(response)
    {
        yield put({type: types.GET_ALL_ASSIGNED_MEMBERS_SUCCESS, all_assigned_member:response.data});
    } else {
        yield put({type: types.GET_ALL_ASSIGNED_MEMBERS_FAILED, error:error});
    }
}

function* showAssignedMembers(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_ASSIGNED_MEMBERS_SUCCESS, single_assigned_member:response.data});
    } else {
        yield put({type: types.SHOW_ASSIGNED_MEMBERS_FAILED, error:error});
    }
}

function* createAssignedMembers(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_ASSIGNED_MEMBERS_SUCCESS,});
    } else {
        yield put({type: types.CREATE_ASSIGNED_MEMBERS_FAILED, error:error});
    }
}
function* updateAssignedMembers(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_ASSIGNED_MEMBERS_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_ASSIGNED_MEMBERS_FAILED, error:error});
    }
}
function* deleteAssignedMembers(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_ASSIGNED_MEMBERS_SUCCESS, });
    } else {
        yield put({type: types.DELETE_ASSIGNED_MEMBERS_FAILED, error:error});
    }
}


// get assigned users watch services
function* watchGetAssignedMembers() 
{
    yield takeEvery(types.GET_ASSIGNED_MEMBERS, getAssignedMembers)
}

function* watchGetAllAssignedMembers() 
{
    yield takeEvery(types.GET_ALL_ASSIGNED_MEMBERS, getAllAssignedMembers)
}

function* watchShowAssignedMembers() 
{
    yield takeEvery(types.SHOW_ASSIGNED_MEMBERS, showAssignedMembers)
}

function* watchCreateAssignedMembers() 
{
    yield takeEvery(types.CREATE_ASSIGNED_MEMBERS, createAssignedMembers)
}
function* watchUpdateAssignedMembers() 
{
    yield takeEvery(types.UPDATE_ASSIGNED_MEMBERS, updateAssignedMembers)
}

function* watchDeleteAssignedMembers() 
{
    yield takeEvery(types.DELETE_ASSIGNED_MEMBERS, deleteAssignedMembers)
}


function* assignMembersSaga(){
    yield all([
       
        // get assigned members
        fork(watchGetAssignedMembers),
        fork(watchGetAllAssignedMembers),
        fork(watchShowAssignedMembers),
        fork(watchCreateAssignedMembers),
        fork(watchUpdateAssignedMembers),
        fork(watchDeleteAssignedMembers),
      

    ])
}

export default assignMembersSaga;