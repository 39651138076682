import React, { Component, useMemo, useState } from 'react'
import InnerAppLayout from 'layouts/inner-app-layout';
import MailMenu from './MailMenu';
import MailContent from './MailContent';
import { useDispatch } from 'react-redux';
import { showObjects } from 'redux/actions';
 
const Mail = (props) => {
	
		const {id} = props;

	    const [page, setPage] = useState("inbox");
        const dispatch = useDispatch();

		const handleSwitchPage = (value) => {
			setPage(value);
		}

		const showObjectData = useMemo(() => {
			if(id === undefined) return;
			dispatch(showObjects(id))
		}, [dispatch, id])

		React.useEffect(() =>{
			if(!showObjectData) return;
			 (async() => {
                showObjectData()
			 })()
		}, [showObjectData])
		
		return (
			<div className="mail">
				<InnerAppLayout 
					sideContent={<MailMenu {...props} handleSwitchPage={handleSwitchPage} />}
					mainContent={<MailContent {...props} page={page} handleSwitchPage={handleSwitchPage}/>}
					border
				/>
			</div>
		)
	
}

export default Mail
