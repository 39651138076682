import * as types from "./constants"

export const getEmail = (object_id, page) => {
    return {
        type:types.GET_EMAIL,
        payload:{object_id, page}
    }
}

export const showEmail = (single_email) => {
    return {
        type:types.SHOW_EMAIL,
        payload:single_email
    }
}

export const createEmail = (email) => {
    return {
        type:types.CREATE_EMAIL,
        payload:email
    }
}

export const updateEmail = (email) => {
    return {
        type:types.UPDATE_EMAIL,
        payload:email
    }
}

export const deleteEmail = (email) => {
    return {
        type:types.DELETE_EMAIL,
        payload:email
    }
}

export const searchEmail = (object_id, search) => {
    return {
        type:types.SEARCH_EMAIL,
        payload:{object_id, search}
    }
}


export const getSendEmail = (object_id, page) => {
    return {
        type:types.GET_SEND_EMAIL,
        payload:{object_id, page}
    }
}

export const showSendEmail = (single_send_email) => {
    return {
        type:types.SHOW_SEND_EMAIL,
        payload:single_send_email
    }
}

export const updateSendEmail = (send_email) => {
    return {
        type:types.UPDATE_SEND_EMAIL,
        payload:send_email
    }
}

export const deleteSendEmail = (send_email) => {
    return {
        type:types.DELETE_SEND_EMAIL,
        payload:send_email
    }
}


export const getDraftEmail = (object_id, page) => {
    return {
        type:types.GET_DRAFT_EMAIL,
        payload:{object_id, page}
    }
}

export const createDraftEmail = (draft_email) => {
    return {
        type:types.CREATE_DRAFT_EMAIL,
        payload:draft_email
    }
}

export const updateDraftEmail = (draft_email) => {
    return {
        type:types.UPDATE_DRAFT_EMAIL,
        payload:draft_email
    }
}

export const showDraftEmail = (single_draft_email) => {
    return {
        type:types.SHOW_DRAFT_EMAIL,
        payload:single_draft_email
    }
}


export const deleteDraftEmail = (draft_email) => {
    return {
        type:types.DELETE_DRAFT_EMAIL,
        payload:draft_email
    }
}


export const deleteReplyEmail = (email) => {
    return {
        type:types.DELETE_REPLY_EMAIL,
        payload:email
    }
}

export const replyEmail = (email) => {
    return {
        type:types.REPLY_EMAIL,
        payload:email
    }
}