import * as types from "./constants"

export const getTaskList = (task_list) => {
    return {
        type:types.GET_TASK_LISTS,
        payload:task_list
    }
}

export const showTaskList = (single_task_list, url) => {
    return {
        type:types.SHOW_TASK_LISTS,
        payload:{single_task_list, url}
    }
}

export const showTaskListCard = (single_task_list_card) => {
    return {
        type:types.SHOW_TASK_LIST_CARD,
        payload:single_task_list_card
    }
}

export const createTaskList = (task_list, history) => {
    return {
        type:types.CREATE_TASK_LISTS,
        payload:{task_list, history
    }}
}

export const updateTaskList = (task_list) => {
    return {
        type:types.UPDATE_TASK_LISTS,
        payload:task_list
    }
}

export const deleteTaskList = (task_list, history) => {
    return {
        type:types.DELETE_TASK_LISTS,
        payload:{task_list, history}
    }
}


export const getTaskListCheckList = (task_list_check_list) => {
    return {
        type:types.GET_TASK_LIST_CHECK_LIST,
        payload:task_list_check_list
    }
}

export const showTaskListCheckList = (single_task_list_check_list) => {
    return {
        type:types.SHOW_TASK_LIST_CHECK_LIST,
        payload:single_task_list_check_list
    }
}

export const createTaskListCheckList = (task_list_check_list, history) => {
    return {
        type:types.CREATE_TASK_LIST_CHECK_LIST,
        payload:{task_list_check_list, history
    }}
}

export const updateTaskListCheckList = (task_list_check_list) => {
    return {
        type:types.UPDATE_TASK_LIST_CHECK_LIST,
        payload:task_list_check_list
    }
}

export const deleteTaskListCheckList = (task_list_check_list, history) => {
    return {
        type:types.DELETE_TASK_LIST_CHECK_LIST,
        payload:{task_list_check_list, history}
    }
}