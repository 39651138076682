export const GET_PROJECT_CHECK_LIST = "GET_PROJECT_CHECK_LIST"
export const GET_PROJECT_CHECK_LIST_SUCCESS = "GET_PROJECT_CHECK_LIST_SUCCESS"
export const GET_PROJECT_CHECK_LIST_FAILED = "GET_PROJECT_CHECK_LIST_FAILED"

export const SHOW_PROJECT_CHECK_LIST = "SHOW_PROJECT_CHECK_LIST"
export const SHOW_PROJECT_CHECK_LIST_SUCCESS = "SHOW_PROJECT_CHECK_LIST_SUCCESS"
export const SHOW_PROJECT_CHECK_LIST_FAILED = "SHOW_PROJECT_CHECK_LIST_FAILED"

export const CREATE_PROJECT_CHECK_LIST = "CREATE_PROJECT_CHECK_LIST"
export const CREATE_PROJECT_CHECK_LIST_SUCCESS = "CREATE_PROJECT_CHECK_LIST_SUCCESS"
export const CREATE_PROJECT_CHECK_LIST_FAILED = "CREATE_PROJECT_CHECK_LIST_FAILED"

export const UPDATE_PROJECT_CHECK_LIST = "UPDATE_PROJECT_CHECK_LIST"
export const UPDATE_PROJECT_CHECK_LIST_SUCCESS = "UPDATE_PROJECT_CHECK_LIST_SUCCESS"
export const UPDATE_PROJECT_CHECK_LIST_FAILED = "UPDATE_PROJECT_CHECK_LIST_FAILED"

export const DELETE_PROJECT_CHECK_LIST = "DELETE_PROJECT_CHECK_LIST"
export const DELETE_PROJECT_CHECK_LIST_SUCCESS = "DELETE_PROJECT_CHECK_LIST_SUCCESS"
export const DELETE_PROJECT_CHECK_LIST_FAILED = "DELETE_PROJECT_CHECK_LIST_FAILED"

