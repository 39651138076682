import React, { Component } from 'react'
import { Menu, Button, Badge } from 'antd';
import { 
	InboxOutlined, 
	FileTextOutlined, 
	MailOutlined, 
	StarOutlined, 
	DeleteOutlined, 
	EditOutlined 
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const MailMenu = (props) => {
	
		const { handleSwitchPage } = props
		return (
			<div className="w-100">
				<div className="p-3">
					
						<Button type="primary" block onClick={() => handleSwitchPage("compose")}>
							<EditOutlined />
							<span>Compose</span>
						</Button>
					
				</div>
				<Menu
					defaultSelectedKeys={`inbox`}
					mode="inline"
					// selectedKeys={["inbox"]}
				>
					<Menu.Item key={`inbox`} onClick={() => handleSwitchPage("inbox")}>
						<InboxOutlined />
						<span>Inbox</span>
						
					</Menu.Item>
					<Menu.Item key={`sent`} onClick={() => handleSwitchPage("sent")}>
						<MailOutlined />
						<span>Sent</span>
						{/* <Link to={`sent`} /> */}
					</Menu.Item>
					<Menu.Item key={`draft`} onClick={() => handleSwitchPage("draft")}>
						<FileTextOutlined />
						<span>Draft</span>
						{/* <Link to={`$draft`} /> */}
					</Menu.Item>
					<Menu.Item  key={`starred`}>
						<StarOutlined />
						<span>Starred</span>
						{/* <Link to={`starred`}/> */}
					</Menu.Item>
					<Menu.Item  key={`deleted`}>
						<DeleteOutlined />
						<span>Deleted</span>
						{/* <Link to={`deleted`}/> */}
					</Menu.Item>
					<Menu.ItemGroup title="Labels">
						<Menu.Item key={`works`}>
							<Badge color="blue" />
							<span>Works</span>
							{/* <Link to={`works`}/> */}
						</Menu.Item>
						<Menu.Item key={`private`}>
							<Badge color="cyan" />
							<span>Private</span>
							{/* <Link to={`private`}/> */}
						</Menu.Item>
						<Menu.Item key={`important`}>
							<Badge color="red" />
							<span>Important</span>
							{/* <Link to={`important`}/> */}
						</Menu.Item>
					</Menu.ItemGroup>
				</Menu>
			</div>
		)
	
}

export default MailMenu
