import React from 'react'
import { Draggable } from 'react-beautiful-dnd';
import { Card, Tooltip, Tag } from 'antd';
import { CalendarOutlined, CommentOutlined, PaperClipOutlined } from '@ant-design/icons';
import moment from 'moment';
import './style.css';
import zip from '../../../../../assets/attachments/zip.png'
import pdf from '../../../../../assets/attachments/pdf.jpg'
import { useDispatch, useSelector } from 'react-redux';
import {showProjectCard } from 'redux/actions';
import AvatarStatus from "components/shared-components/AvatarStatus";



function SubIndicator(props) {
    if(props.counts) {
      return (
        <p className="mb-0 mr-2">
          {props.icon}
          <span className="ml-1">{props.counts}</span>
        </p>
      )
    }
    return null
  }

export const InnerCardList = React.memo(function InnerCardList(props) {
    const user_state = useSelector((state) => state.AuthStore?.user);
    const user = user_state?.user;
    const teams = user_state?.all_teams;
    const team_state = useSelector((state) => state.TeamStore);
    const team_users = team_state?.users;
    const work_status = useSelector(
      (state) => state.WorkStatusStore?.work_status
    );
  
    const dispatch = useDispatch();
  
    const showProjectDetails = (id) => {
       dispatch(showProjectCard(id));
    }
    return props.contents?.map((item, index) => (
      <Draggable key={item?.id} draggableId={item?.id?.toString()} index={index}>
        {( dragProvided, dragSnapshot) => (
          <div
            className='mb-3'
            key={item?.id}
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
          >
            <Card 
              hoverable 
              className='board-card'
              cover={null} 
              onClick={() => {props.cardData(item, props.listId); showProjectDetails(item?.id)}}
            >
                      
            {item.object !== null || item.object !== undefined ? 
              <Tooltip title={`${item.object} /  ${item.objective}`} >
                    <h4 className={`mb-2 ${
              item?.is_backloged === 1
                ? "expire-bg-2"
                : ""
            }`}>{item.title}</h4>
              </Tooltip>
            
            :    <h4 className={`mb-2 ${
              item?.is_backloged === 1
                ? "expire-bg-2"
                : ""
            }`}>{item.title}</h4>}
              <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
              <Tooltip title="deadline" >
                {item?.deadline?
                  <Tag className="bg-gray-lightest" > 
                    <CalendarOutlined />
                    <span className="ml-1 font-weight-semibold">{moment(item?.deadline).format('DD-MMM-YYYY hh:mm a z')}</span>
                  </Tag>
                  :
                  null
                }
                </Tooltip>
              </div>
         </div>
            {item?.attachments?.map((elm, x) => 
            <React.Fragment key={x}>
             {elm?.cover === 1 ?
             <div className="cover__box mt-2" style={{
                 backgroundImage: `url(${
                   elm?.name?.split('.').pop() === "jpeg" ? elm?.name :
                   elm?.name?.split('.').pop() === "gif" ? elm?.name :
                   elm?.name?.split('.').pop() === "jpeg" ? elm?.name :
                   elm?.name?.split('.').pop() === "png" ? elm?.name: 
                   elm?.name?.split('.').pop() === "zip" ? zip :
                   elm?.name?.split('.').pop() === "pdf" ? pdf:""
                  })` 
                 }}>
  {/* <img src={elm?.name} className="rounded img-fluid" alt="cover"/>  */}
             </div>
           : null}
            </React.Fragment>
            )}
           
            <div className="d-flex justify-content-between mt-3">
              <div>
              <Tooltip title="work status" >
                <Tag color="magenta">{work_status?.filter((elm) => elm.id === item?.work_status_id)?.[0]
                      ?.title}</Tag>
              </Tooltip>
              </div>
          

              <div className="mb-3">
              <Tooltip title="priority" >
              {item?.priority === "Low" ?  <Tag color="green">{item?.priority}</Tag>: 
              item?.priority === "Normal" ? <Tag color="geekblue">{item?.priority}</Tag> :
              item?.priority === "High" ?   <Tag color="orange">{item?.priority}</Tag> :
              item?.priority === "Urgent" ?  <Tag color="magenta">{item?.priority}</Tag> :
             ""
              }
              </Tooltip>
            </div>
            </div>
            <div className="d-flex flex-wrap mb-2 mt-1">
              {item?.assign_user?.filter(elm => elm?.project_card_id === item?.id)?.map((elm, index) =>
                user?.id === elm?.user_id ? (
                  <Tooltip placement="top" title={user?.fullname} key={index}>
                    <div
                      className="avatar__box__view mb-2"
                      
                    >
                      {user?.photo !== null ? (
                        <AvatarStatus
                          src={user?.photo}
                          // name={user?.fullname}
                          // subTitle="Kofi Boakye"
                          size={25}
                          chain
                        />
                      ) : (
                        <AvatarStatus
                          // icon={<UserOutlined />}
                          text={user?.initials}
                          // name={user?.fullname}
                          type="blue"
                          // subTitle="Kofi Boakye"
                          size={25}
                          chain
                        />
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="top"
                    title={
                      teams?.filter((m) => m?.id === item?.team_id)?.[0]?.team_members?.filter((x) => x.user_id === elm?.user_id)?.[0]
                            ?.fullname
                    }
                    key={index}
                  >
                    <div
                      className="avatar__box__view mb-2"
                     
                    >
                      {teams?.filter((m) => m?.id === item?.team_id)?.[0]?.team_members?.filter((x) => x.user_id === elm?.user_id)?.[0]
                            ?.photo !== null ? (
                        <AvatarStatus
                          src={
                            teams?.filter((m) => m?.id === item?.team_id)?.[0]?.team_members?.filter((x) => x.user_id === elm?.user_id)?.[0]
                            ?.photo
                          }
                          size={25}
                          chain
                        />
                      ) : (
                        <AvatarStatus
                          text={
                            teams?.filter((m) => m?.id === item?.team_id)?.[0]?.team_members?.filter((x) => x.user_id === elm?.user_id)?.[0]
                            ?.initials
                          }
                          type="blue"
                          size={25}
                          chain
                        />
                      )}

                    </div>
                  </Tooltip>
                )
              )}
              </div>
 
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <SubIndicator counts={item?.comments?.length} icon={<CommentOutlined />}/>
                <SubIndicator counts={item?.attachments?.length} icon={<PaperClipOutlined />}/>
              </div>
         </div>
          </Card>
        </div>
        )}
      </Draggable>
    ));
  });