import * as types from "./constants"

export const getNotebookBoard = (notebook_board) => {
    return {
        type:types.GET_NOTEBOOK_BOARD,
        payload:notebook_board
    }
}

export const showNotebookBoard = (single_notebook_board) => {
    return {
        type:types.SHOW_NOTEBOOK_BOARD,
        payload:single_notebook_board
    }
}

export const createNotebookBoard = (notebook_board) => {
    return {
        type:types.CREATE_NOTEBOOK_BOARD,
        payload:notebook_board
    }
}

export const updateNotebookBoard = (notebook_board) => {
    return {
        type:types.UPDATE_NOTEBOOK_BOARD,
        payload:notebook_board
    }
}


export const updateNotebookBoardIndex = (index) => {
    return {
        type:types.UPDATE_NOTEBOOK_BOARD_INDEX,
        payload:index
    }
}

export const updateNotebookNoteIndex = (index) => {
    return {
        type:types.UPDATE_NOTEBOOK_NOTE_INDEX,
        payload:index
    }
}

export const updateNotebookNoteNextIndex = (index) => {
    return {
        type:types.UPDATE_NOTEBOOK_NOTE_NEXT_INDEX,
        payload:index
    }
}

export const deleteNotebookBoard = (notebook_board) => {
    return {
        type:types.DELETE_NOTEBOOK_BOARD,
        payload:notebook_board
    }
}



export const getNotebookNote = (notebook_note) => {
    return {
        type:types.GET_NOTEBOOK_NOTE,
        payload:notebook_note
    }
}

export const showNotebookNote = (single_notebook_note) => {
    return {
        type:types.SHOW_NOTEBOOK_NOTE,
        payload:single_notebook_note
    }
}

export const createNotebookNote = (notebook_note) => {
    return {
        type:types.CREATE_NOTEBOOK_NOTE,
        payload:notebook_note
    }
}

export const updateNotebookNote = (notebook_note) => {
    return {
        type:types.UPDATE_NOTEBOOK_NOTE,
        payload:notebook_note
    }
}

export const deleteNotebookNote = (notebook_note) => {
    return {
        type:types.DELETE_NOTEBOOK_NOTE,
        payload:notebook_note
    }
}