export const GET_EMAIL = "GET_EMAIL"
export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS"
export const GET_EMAIL_FAILED = "GET_EMAIL_FAILED"

export const GET_SEND_EMAIL = "GET_SEND_EMAIL"
export const GET_SEND_EMAIL_SUCCESS = "GET_SEND_EMAIL_SUCCESS"
export const GET_SEND_EMAIL_FAILED = "GET_SEND_EMAIL_FAILED"

export const SHOW_EMAIL = "SHOW_EMAIL"
export const SHOW_EMAIL_SUCCESS = "SHOW_EMAIL_SUCCESS"
export const SHOW_EMAIL_FAILED = "SHOW_EMAIL_FAILED"

export const CREATE_EMAIL = "CREATE_EMAIL"
export const CREATE_EMAIL_SUCCESS = "CREATE_EMAIL_SUCCESS"
export const CREATE_EMAIL_FAILED = "CREATE_EMAIL_FAILED"

export const UPDATE_EMAIL = "UPDATE_EMAIL"
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS"
export const UPDATE_EMAIL_FAILED = "UPDATE_EMAIL_FAILED"

export const DELETE_EMAIL = "DELETE_EMAIL"
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS"
export const DELETE_EMAIL_FAILED = "DELETE_EMAIL_FAILED"

export const SEARCH_EMAIL = "SEARCH_EMAIL"
export const SEARCH_EMAIL_SUCCESS = "SEARCH_EMAIL_SUCCESS"
export const SEARCH_EMAIL_FAILED = "SEARCH_EMAIL_FAILED"


export const SHOW_SEND_EMAIL = "SHOW_SEND_EMAIL"
export const SHOW_SEND_EMAIL_SUCCESS = "SHOW_SEND_EMAIL_SUCCESS"
export const SHOW_SEND_EMAIL_FAILED = "SHOW_SEND_EMAIL_FAILED"

export const UPDATE_SEND_EMAIL = "UPDATE_SEND_EMAIL"
export const UPDATE_SEND_EMAIL_SUCCESS = "UPDATE_SEND_EMAIL_SUCCESS"
export const UPDATE_SEND_EMAIL_FAILED = "UPDATE_SEND_EMAIL_FAILED"

export const DELETE_SEND_EMAIL = "DELETE_SEND_EMAIL"
export const DELETE_SEND_EMAIL_SUCCESS = "DELETE_SEND_EMAIL_SUCCESS"
export const DELETE_SEND_EMAIL_FAILED = "DELETE_SEND_EMAIL_FAILED"



export const GET_DRAFT_EMAIL = "GET_DRAFT_EMAIL"
export const GET_DRAFT_EMAIL_SUCCESS = "GET_DRAFT_EMAIL_SUCCESS"
export const GET_DRAFT_EMAIL_FAILED = "GET_DRAFT_EMAIL_FAILED"

export const CREATE_DRAFT_EMAIL = "CREATE_DRAFT_EMAIL"
export const CREATE_DRAFT_EMAIL_SUCCESS = "CREATE_DRAFT_EMAIL_SUCCESS"
export const CREATE_DRAFT_EMAIL_FAILED = "CREATE_DRAFT_EMAIL_FAILED"

export const UPDATE_DRAFT_EMAIL = "UPDATE_DRAFT_EMAIL"
export const UPDATE_DRAFT_EMAIL_SUCCESS = "UPDATE_DRAFT_EMAIL_SUCCESS"
export const UPDATE_DRAFT_EMAIL_FAILED = "UPDATE_DRAFT_EMAIL_FAILED"

export const SHOW_DRAFT_EMAIL = "SHOW_DRAFT_EMAIL"
export const SHOW_DRAFT_EMAIL_SUCCESS = "SHOW_DRAFT_EMAIL_SUCCESS"
export const SHOW_DRAFT_EMAIL_FAILED = "SHOW_DRAFT_EMAIL_FAILED"

export const DELETE_DRAFT_EMAIL = "DELETE_DRAFT_EMAIL"
export const DELETE_DRAFT_EMAIL_SUCCESS = "DELETE_DRAFT_EMAIL_SUCCESS"
export const DELETE_DRAFT_EMAIL_FAILED = "DELETE_DRAFT_EMAIL_FAILED"


export const REPLY_EMAIL = "REPLY_EMAIL"
export const REPLY_EMAIL_SUCCESS = "REPLY_EMAIL_SUCCESS"
export const REPLY_EMAIL_FAILED = "REPLY_EMAIL_FAILED"

export const DELETE_REPLY_EMAIL = "DELETE_REPLY_EMAIL"
export const DELETE_REPLY_EMAIL_SUCCESS = "DELETE_REPLY_EMAIL_SUCCESS"
export const DELETE_REPLY_EMAIL_FAILED = "DELETE_REPLY_EMAIL_FAILED"