import React, { useState } from 'react';

const useModelData = () => {
  const [modalData, setModalData] = useState([]);
  // do some stuff
  return { modalData, setModalData };
};

export default useModelData;



