import * as types from "./constants"

export const getScheduleTime = (schedule_time, url) => {
    return {
        type:types.GET_SCHEDULE_TIME,
        payload:{schedule_time, url}
    }
}

export const showScheduleTime = (single_schedule_time, url) => {
    return {
        type:types.SHOW_SCHEDULE_TIME,
        payload:{single_schedule_time, url}
    }
}

export const createScheduleTime = (schedule_time, url) => {
    return {
        type:types.CREATE_SCHEDULE_TIME,
        payload:{schedule_time, url
    }}
}

export const updateScheduleTime = (schedule_time, url) => {
    return {
        type:types.UPDATE_SCHEDULE_TIME,
        payload:{schedule_time, url}
    }
}

export const deleteScheduleTime = (schedule_time, url) => {
    return {
        type:types.DELETE_SCHEDULE_TIME,
        payload:{schedule_time, url}
    }
}
