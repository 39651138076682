import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/objects?${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message);
            // if(error.response.data.message === "Unauthenticated."){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear(); 
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/objects/${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },

    store(data){
       console.log(data);
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/objects",data.objects)
          .then((response) => {
            // successNotify(response.data.message);
      
            message.success(response.data.message.message, 4);
            // data.history.push(`/app/${data.object_type}/${response.data.data.id}${data.object_type === "email" ? "/inbox" : ""}`);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/objects/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    destroy(data){
    
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/objects/${data}`)
          .then((response) => {
            message.success(response.data.message, 2);
            
            if(response.status === 200){
              data.history.push('/app/home');
            }
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
}

