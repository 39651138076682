import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FileTextOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import AvatarStatus from "../AvatarStatus";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import TextEditor from "components/util-components/TextEditor/TextEditor";

function Description(props) {
  //const create dispatch function
  const dispatch = useDispatch();

  // destructure props data;
  const { cardData, updateCard, url } = props;

  //   create enable description state
  const [enableDescription, setEnableDescription] = useState(true);

  //   create show description state
  const [showDescription, setShowDescription] = useState(false);

  //   create switch to show description function
  const onChangeDescriptionSwitch = useCallback(
    (checked) => setEnableDescription(checked),
    []
  );

  //   get default html data from database.
  const html =
    cardData?.description === null || cardData?.journal_entry === null? "<p></p>" : `${cardData?.description || cardData?.journal_entry}`;

  // convert html data to array string
  const contentBlock = convertFromHTML(html);

  //   convert array data to plain text.
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );

  //   get new content from the array state.
  const newState = EditorState.createWithContent(contentState);

  //   set default state for the editor
  const [editorState, setDescription] = useState();

  //   get html data from the api when page loads
  useEffect(() => {
    setDescription(newState);
  }, [cardData]);

  //   handle state change
  const handleDescription = useCallback(
    (editorState) => setDescription(editorState),
    []
  );

  //   send description data to the api.
  const changeDescription = useCallback(() => {
    let data = cardData;
    data.description = editorState;
    data.attachments = [];
    data.isDescriptionChange = true;
    data.id = cardData?.id;
    dispatch(updateCard(data, url));
  }, [cardData, editorState]);

  return (
    <React.Fragment>
      <div className="d-flex mt-4 mb-4">
        <div className="mr-3 font-size-md">
          <FileTextOutlined />
        </div>
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <h4>Description</h4>
              {enableDescription ? (
                <div
                  className="ml-2"
                  style={{ marginTop: "-5px", cursor: "pointer" }}
                  onClick={() => {
                    setShowDescription(true);
                    setEnableDescription(true);
                  }}
                >
                  <AvatarStatus
                    icon={<EditOutlined />}
                    name=""
                    type="blue"
                    subTitle=""
                    size={20}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            {/* <Switch
              defaultChecked={enableDescription}
              onChange={onChangeDescriptionSwitch}
            /> */}
          </div>

          {showDescription ? (
                <>
                  <TextEditor handleText={handleDescription} html={html} />
                  <div
                    className="close-description"
                    onClick={() => setShowDescription(false)}
                  >
                    <CloseOutlined /> Cancel
                  </div>

                  <div
                    className="mr-4 save-description text-success"
                    onClick={() => {
                      changeDescription();
                      setShowDescription(false);
                    }}
                  >
                    <SaveOutlined /> Save
                  </div>
                </>
              ) : (
                <div className="html_data">
                  {html === undefined ? "" : <> {ReactHtmlParser(html)}</>}

                  {/* <div
                    className="edit-description"
                    onClick={() => setShowDescription(true)}
                  >
                    <EditOutlined /> Edit Description
                  </div> */}
                </div>
              )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Description;
