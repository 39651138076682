export const GET_NOTEBOOK_BOARD = "GET_NOTEBOOK_BOARD"
export const GET_NOTEBOOK_BOARD_SUCCESS = "GET_NOTEBOOK_BOARD_SUCCESS"
export const GET_NOTEBOOK_BOARD_FAILED = "GET_NOTEBOOK_BOARD_FAILED"

export const SHOW_NOTEBOOK_BOARD = "SHOW_NOTEBOOK_BOARD"
export const SHOW_NOTEBOOK_BOARD_SUCCESS = "SHOW_NOTEBOOK_BOARD_SUCCESS"
export const SHOW_NOTEBOOK_BOARD_FAILED = "SHOW_NOTEBOOK_BOARD_FAILED"

export const CREATE_NOTEBOOK_BOARD = "CREATE_NOTEBOOK_BOARD"
export const CREATE_NOTEBOOK_BOARD_SUCCESS = "CREATE_NOTEBOOK_BOARD_SUCCESS"
export const CREATE_NOTEBOOK_BOARD_FAILED = "CREATE_NOTEBOOK_BOARD_FAILED"

export const UPDATE_NOTEBOOK_BOARD = "UPDATE_NOTEBOOK_BOARD"
export const UPDATE_NOTEBOOK_BOARD_SUCCESS = "UPDATE_NOTEBOOK_BOARD_SUCCESS"
export const UPDATE_NOTEBOOK_BOARD_FAILED = "UPDATE_NOTEBOOK_BOARD_FAILED"

export const UPDATE_NOTEBOOK_BOARD_INDEX = "UPDATE_NOTEBOOK_BOARD_INDEX"
export const UPDATE_NOTEBOOK_BOARD_INDEX_SUCCESS = "UPDATE_NOTEBOOK_BOARD_INDEX_SUCCESS"
export const UPDATE_NOTEBOOK_BOARD_INDEX_FAILED = "UPDATE_NOTEBOOK_BOARD_INDEX_FAILED"


export const DELETE_NOTEBOOK_BOARD = "DELETE_NOTEBOOK_BOARD"
export const DELETE_NOTEBOOK_BOARD_SUCCESS = "DELETE_NOTEBOOK_BOARD_SUCCESS"
export const DELETE_NOTEBOOK_BOARD_FAILED = "DELETE_NOTEBOOK_BOARD_FAILED"


export const GET_NOTEBOOK_NOTE = "GET_NOTEBOOK_NOTE"
export const GET_NOTEBOOK_NOTE_SUCCESS = "GET_NOTEBOOK_NOTE_SUCCESS"
export const GET_NOTEBOOK_NOTE_FAILED = "GET_NOTEBOOK_NOTE_FAILED"

export const SHOW_NOTEBOOK_NOTE = "SHOW_NOTEBOOK_NOTE"
export const SHOW_NOTEBOOK_NOTE_SUCCESS = "SHOW_NOTEBOOK_NOTE_SUCCESS"
export const SHOW_NOTEBOOK_NOTE_FAILED = "SHOW_NOTEBOOK_NOTE_FAILED"

export const CREATE_NOTEBOOK_NOTE = "CREATE_NOTEBOOK_NOTE"
export const CREATE_NOTEBOOK_NOTE_SUCCESS = "CREATE_NOTEBOOK_NOTE_SUCCESS"
export const CREATE_NOTEBOOK_NOTE_FAILED = "CREATE_NOTEBOOK_NOTE_FAILED"

export const UPDATE_NOTEBOOK_NOTE = "UPDATE_NOTEBOOK_NOTE"
export const UPDATE_NOTEBOOK_NOTE_SUCCESS = "UPDATE_NOTEBOOK_NOTE_SUCCESS"
export const UPDATE_NOTEBOOK_NOTE_FAILED = "UPDATE_NOTEBOOK_NOTE_FAILED"

export const DELETE_NOTEBOOK_NOTE = "DELETE_NOTEBOOK_NOTE"
export const DELETE_NOTEBOOK_NOTE_SUCCESS = "DELETE_NOTEBOOK_NOTE_SUCCESS"
export const DELETE_NOTEBOOK_NOTE_FAILED = "DELETE_NOTEBOOK_NOTE_FAILED"


export const UPDATE_NOTEBOOK_NOTE_INDEX = "UPDATE_NOTEBOOK_NOTE_INDEX"
export const UPDATE_NOTEBOOK_NOTE_INDEX_SUCCESS = "UPDATE_NOTEBOOK_NOTE_INDEX_SUCCESS"
export const UPDATE_NOTEBOOK_NOTE_INDEX_FAILED = "UPDATE_NOTEBOOK_NOTE_INDEX_FAILED"

export const UPDATE_NOTEBOOK_NOTE_NEXT_INDEX = "UPDATE_NOTEBOOK_NOTE_NEXT_INDEX"
export const UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_SUCCESS = "UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_SUCCESS"
export const UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_FAILED = "UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_FAILED"