import * as types from "./constants"

const initialStates = {
    notebooks:[],
    single_notebook:{},
    loading:false,
    error:null,
    success:false,
}

 const NotebookStore = (state = initialStates, action) => {
    //setup notebooks reducers
   switch (action.type) {
       case types.GET_NOTEBOOK :
       return {...state, loading:true}
       case types.GET_NOTEBOOK_SUCCESS :
       return {...state, loading:false, notebooks:action.notebooks}
       case types.GET_NOTEBOOK_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_NOTEBOOK :
       return {...state}
       case types.SHOW_NOTEBOOK_SUCCESS :
       return {...state, single_notebook:action.single_notebook}
       case types.SHOW_NOTEBOOK_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_NOTEBOOK :
       return {...state, loading:true, success:false}
       case types.CREATE_NOTEBOOK_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_NOTEBOOK_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_NOTEBOOK :
       return {...state, loading:true, success:false}
       case types.UPDATE_NOTEBOOK_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_NOTEBOOK_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_NOTEBOOK :
       return {...state, loading:true, success:false}
       case types.DELETE_NOTEBOOK_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_NOTEBOOK_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default NotebookStore
