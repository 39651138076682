import * as types from "./constants"

const initialStates = {
    notification:[],
    loading:false, 
    success:false, 
    error:null
}

const NotificationStore = (state = initialStates, action) => 
{
    switch (action.type) {
        case types.GET_NOTIFICATION:
            return{
                ...state, 
                loading:true,
            }

            case types.GET_NOTIFICATION_SUCCESS:
            return{
                ...state, 
                loading:false,
                notification:action.notification
            }
        
            case types.GET_NOTIFICATION_FAILED:
            return{
                ...state, 
                loading:false,
                error:action.error
            }
        

            case types.READ_NOTIFICATION:
            return{
                ...state, 
                loading:true,
                success:false,
            }
        

            case types.READ_NOTIFICATION_SUCCESS:
            return{
                ...state, 
                loading:false,
                success:true,
            }
        

            case types.READ_NOTIFICATION_FAILED:
            return{
                ...state, 
                loading:true,
                error:action.error,
                success:true
            }
        



            case types.READ_ALL_NOTIFICATION:
            return{
                ...state, 
                loading:true,
            }
        

            case types.READ_ALL_NOTIFICATION_SUCCESS:
            return{
                ...state, 
                loading:false,
                success:true,
            }
        

            case types.READ_ALL_NOTIFICATION_FAILED:
            return{
                ...state, 
                loading:false,
                error:action.error,
                success:true
            }
        
        
    
        default:
            return state;
    }
}

export default NotificationStore;