import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/CalendarTeamViewService';


// Board Redux States
import * as types from './constants';

function* getCalendarTeamView(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_CALENDAR_TEAM_VIEW_SUCCESS, calendar_team_view:response.data});
    } else {
        yield put({type: types.GET_CALENDAR_TEAM_VIEW_FAILED, error:error});
    }
}

function* showCalendarTeamView(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_CALENDAR_TEAM_VIEW_SUCCESS, single_calendar_team_view:response.data});
    } else {
        yield put({type: types.SHOW_CALENDAR_TEAM_VIEW_FAILED, error:error});
    }
}

function* createCalendarTeamView(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_CALENDAR_TEAM_VIEW_SUCCESS,});
    } else {
        yield put({type: types.CREATE_CALENDAR_TEAM_VIEW_FAILED, error:error});
    }
}

function* updateCalendarTeamView(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_CALENDAR_TEAM_VIEW_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_CALENDAR_TEAM_VIEW_FAILED, error:error});
    }
}

function* deleteCalendarTeamView(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_CALENDAR_TEAM_VIEW_SUCCESS, });
    } else {
        yield put({type: types.DELETE_CALENDAR_TEAM_VIEW_FAILED, error:error});
    }
}


function* filteredCalendarEvent(action)
{
    const {response, error} = yield call(api.filterCalendar, action.payload)
    if(response)
    {
        yield put({type: types.FILTER_CALENDAR_EVENT_SUCCESS, calendar_team_view:response.data});
    } else {
        yield put({type: types.FILTER_CALENDAR_EVENT_FAILED, error:error});
    }
}


function* watchGetCalendarTeamView() 
{
    yield takeLatest(types.GET_CALENDAR_TEAM_VIEW, getCalendarTeamView)
}

function* watchShowCalendarTeamView() 
{
    yield takeEvery(types.SHOW_CALENDAR_TEAM_VIEW, showCalendarTeamView)
}

function* watchCreateCalendarTeamView() 
{
    yield takeEvery(types.CREATE_CALENDAR_TEAM_VIEW, createCalendarTeamView)
}

function* watchUpdateCalendarTeamView() 
{
    yield takeEvery(types.UPDATE_CALENDAR_TEAM_VIEW, updateCalendarTeamView)
}

function* watchDeleteCalendarTeamView() 
{
    yield takeEvery(types.DELETE_CALENDAR_TEAM_VIEW, deleteCalendarTeamView)
}

function* watchFilterCalendarEvent()
{
    yield takeEvery(types.FILTER_CALENDAR_EVENT, filteredCalendarEvent)
}

function* calendarTeamViewSaga(){
    yield all([
        fork(watchGetCalendarTeamView),
        fork(watchShowCalendarTeamView),
        fork(watchCreateCalendarTeamView),
        fork(watchUpdateCalendarTeamView),
        fork(watchDeleteCalendarTeamView),
        fork(watchFilterCalendarEvent),
    ])
}

export default calendarTeamViewSaga;