import React, { Component, useEffect } from 'react'
import MailData from 'assets/data/mail.data.json';
import { ReplySVG } from 'assets/svg/icon';
import { BsForward } from "react-icons/bs";
import { labels, getFileType } from './MailLabels';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { Tooltip, Modal, Button, Divider, Popconfirm, Upload, Form} from 'antd';
import { 
	LeftCircleOutlined, 
	StarOutlined, 
	DeleteOutlined, 
	StarFilled, 
	DownloadOutlined,
	MailOutlined,
	QuestionCircleOutlined,
	UploadOutlined,
	SwapRightOutlined
} from '@ant-design/icons';
import CustomIcon from 'components/util-components/CustomIcon'
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { deleteReplyEmail, updateEmail,deleteEmail, replyEmail, showEmail } from "redux/actions";
import Draggable from 'react-draggable';

import SunEditor from "suneditor-react";
import {
	align,
	font,
	fontColor,
	fontSize,
	formatBlock,
	hiliteColor,
	horizontalRule,
	lineHeight,
	list,
	paragraphStyle,
	table,
	template,
	textStyle,
	image,
	link,
  } from "suneditor/src/plugins";
import FowardEmail from './FowardEmail';


const MaiDetail = (props) => {

	// var pathArray = window.location.pathname.split("/");
	// var object_id = pathArray[3];

	const {handleSwitchPage, id} = props;
	const [form] = Form.useForm();
	const [details, setDetail] = React.useState({});
	const [starred, setStarred] = React.useState(false);
	const [attachment, setAttachment] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [description, setDescription] = React.useState("");
	const [enableModal, setEnableModal] = React.useState(false);
	const getId = localStorage.getItem("email_id");

	  //   get dispatch function
	const dispatch = useDispatch();

	  //   create function to get emails from api
	  var getEmails = React.useCallback(() => {
		if(getId === undefined) return;
		dispatch(showEmail(getId));
	  }, [dispatch, getId]);
	
	    // run get email function anytime email component render
	  React.useEffect(() => {
		getEmails();
	  }, [getEmails]);
	
	const emails = useSelector(state => state.EmailStore?.emails?.data);

	React.useEffect(() => {
		if(emails !== undefined) {
			// const { category, id } = props.match.params
			const currentId = parseInt(getId)
			let data = []
			// if(labels.includes(category)) {
			// 	data =emails?.filter(elm => elm.id === currentId)
			// } else {
				
			// }
			data = emails?.filter(elm => elm.id === currentId)
			const res = data[0]
			setDetail(res);
			setStarred(res?.e_star === 0 ? false : true);
			setAttachment(res?.email_message_attachments);
		}
	}, [emails, getId])




	const [enableSaveStar, setEnableStarEmail] = React.useState(false);
	const starEmail = React.useMemo(() => {
		if(enableSaveStar === false) return;
		if(starred === undefined) return;
		let data = details;
			data.e_star = starred;
		dispatch(updateEmail(data));
		
	}, [starred])

	useEffect(() => {
		if(starEmail === undefined) return;
		(async() => {
			
		   await starEmail();
		  
		})()
	}, [starEmail])

	const tick = (item) => {
	setEnableStarEmail(true);
	setStarred(!starred);
	
	}

	const back = () => {
		handleSwitchPage('inbox')
		// props.history.goBack()
	}

	const handleOk  = () => {
		setVisible(false);
	}

	const handleCancel = () => {
		setVisible(false);
	}

	

	const [disable, setDisable] = React.useState(true);
	const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
	const draggleRef = React.createRef();
  
	const onStart = (event, uiData) => {
	  const { clientWidth, clientHeight } = window.document.documentElement;
	  const targetRect = draggleRef.current?.getBoundingClientRect();
	  if (!targetRect) {
		return;
	  }
	  setBounds({
		left: -targetRect.left + uiData.x,
		right: clientWidth - (targetRect.right - uiData.x),
		top: -targetRect.top + uiData.y,
		bottom: clientHeight - (targetRect.bottom - uiData.y),
	  });
	};

	const handleDescription = (editorState) => {
		setDescription(editorState)
	  };

	  const replEmail = () => {
		const data = {
			message_id:details?.id,
			e_body:description, 
			e_header:details?.e_header,
			e_to: details?.e_from,
			e_from: details?.e_to,
			e_subject: details?.e_subject,
			attachments:base64,
		}
		  dispatch(replyEmail(data));
		  setBase64([]);
		  setDescription("");
		  form.resetFields();
		  handleOk();
	  }


	  useEffect(() => {
		form.resetFields();
	  }, [form, details])

	  function confirm(id) {
		dispatch(deleteReplyEmail(id))
		handleSwitchPage("inbox");
	  }

	  function deleteEmailConfirm (id)
	  {
		dispatch(deleteEmail(id))
		handleSwitchPage("inbox");
	  }
	  
	  function cancel(e) {
		// console.log(e);
		// message.error('Click on No');
	  }

	const [isModalVisible, setIsModalVisible] = React.useState(false);

	const showModal = () => {
	setEnableModal(true)
	setIsModalVisible(true);
	};


	const [uploadImage, setUploadImage] = React.useState([]);
	const [base64, setBase64] = React.useState([]);


	const handleUpload = (info) => {
		let fileList = [...info.fileList];
		// Accept 5 files only
		fileList = fileList.slice(-5);
		fileList.forEach(function (file, index) {
		  let reader = new FileReader();
		  reader.onload = (e) => {
			file.base64 = e.target.result;
		  };
		  reader.readAsDataURL(file.originFileObj);
		});
		setBase64(fileList);
	  };

	  const normFile = (e) => {
		if (Array.isArray(e)) {
		  return e;
		}
		return e && e.fileList;
	  };

	const { e_subject, e_date, e_to,e_from,  e_body } = details;
		

	const onFinish = () => {

	}
		return (
			<div className="mail-detail">
			
				<FowardEmail id={id} email={details} isModalVisible={isModalVisible} setEnableModal={setEnableModal} setIsModalVisible={setIsModalVisible}/>
			
				
				 <Modal
        width={700}
		maskClosable={false}
		footer={null}
		visible={visible}
		onOk={handleOk}
		onCancel={handleCancel}
		className="draggable__modal_item"
		title={
		  <div
			style={{
			  width: '100%',
			  cursor: 'move',
			}}
			onMouseOver={() => {
			  if (disable) {
				setDisable(false);
			  }
			}}
			onMouseOut={() => {
			 setDisable(true);
			}}
			onFocus={() => {}}
			onBlur={() => {}}
			// end
		  >
		Reply Email
		  </div>
		}

		modalRender={modal => (
		  <Draggable
			disabled={disable}
			bounds={bounds}
		   
			onStart={(event, uiData) => onStart(event, uiData)}
		  >
			<div ref={draggleRef}>{modal}</div>
		  </Draggable>
		)}
        >
			  <Form onFinish={onFinish} form={form}>
			  <Form.Item name="to" className="attachments">
         <SunEditor
            width="100%"
            onChange={handleDescription}
            autoFocus={true}
            lang="en"
			defaultValue={description}
            setOptions={{
              showPathLabel: false,
              minHeight: "30vh",
              maxHeight: "40vh",
              placeholder: "Enter your text here!!!",
              popupDisplay: "local",
              resizingBar: true,
              plugins: [
                align,
                // font,
                // fontColor,
                // fontSize,
                formatBlock,
                hiliteColor,
                horizontalRule,
                // lineHeight,
                list,
                // paragraphStyle,
                table,
                template,
                textStyle,
                image,
                link,
              ],
              buttonList: [
                ["undo", "redo"],
                // ["font", "fontSize", "formatBlock"],
                // ["paragraphStyle"],
                [
                  "bold",
                  //   "underline",
                  //   "italic",
                  //   "strike",
                  //   "subscript",
                  //   "superscript"
                ],
                // ["fontColor", "hiliteColor"],
                ["removeFormat"],
                // Line break
                ["outdent", "indent"],
                [
                  "align",
                  "horizontalRule",
                  "list",
                  //  "lineHeight"
                ],
                ["table", "link", "image"],
              ],
              formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
              // font: [
              //   "Arial",
              //   "Calibri",
              //   "Comic Sans",
              //   "Courier",
              //   "Garamond",
              //   "Georgia",
              //   "Impact",
              //   "Lucida Console",
              //   "Palatino Linotype",
              //   "Segoe UI",
              //   "Tahoma",
              //   "Times New Roman",
              //   "Trebuchet MS"
              // ]
            }}
          />
		  </Form.Item>
<div className="mt-3">
<Form.Item
        name="file"
        label=""
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
		
      >
		  <Upload name="logo" listType="picture" onChange={handleUpload}>
          <Button>
            <UploadOutlined /> Click to upload Attachment
          </Button>
        </Upload>
		</Form.Item>
		</div>


		  <div className="reply__button">
		  <Button type="primary" danger>
      Cancel
    </Button>

	<Button type="primary" onClick={replEmail}>
      Send Reply
    </Button>
		  </div>

		  </Form>
        </Modal>
				<div className="d-lg-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center mb-3">
						<div className="font-size-md mr-3" onClick={()=> {back()}}>
							<LeftCircleOutlined className="mail-detail-action-icon font-size-md ml-0" />
						</div>
						<AvatarStatus icon={<MailOutlined />} name={details?.e_personal} subTitle={`To: ${e_from}`}/>
					</div>
					<div className="mail-detail-action mb-3">
						<span className="mr-2 font-size-md">{moment(e_date).format('MMMM Do YYYY, h:mm a')}</span>
						<Tooltip title="Reply" onClick={()=>setVisible(true)}>
							<div>
							<CustomIcon className="mail-detail-action-icon" svg={ReplySVG} />
							</div>
							
						</Tooltip>

						<Tooltip title="Star" onClick={()=>{tick()}}>
							{starred? <StarFilled className="mail-detail-action-icon star checked" /> : <StarOutlined className="mail-detail-action-icon star" />}
						</Tooltip>
						{details?.attachments?.length > 0 ? <Tooltip title="Download Attachment"><DownloadOutlined className="mail-detail-action-icon"/></Tooltip> : null}
						
						<Tooltip title="Delete">
						<Popconfirm title="Are you sure？"
            cancelText="No"
            okText="Yes"
            onConfirm={() => deleteEmailConfirm(details?.id)}
            onCancel={cancel}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
							<DeleteOutlined className="mail-detail-action-icon"/>
						</Popconfirm>
						</Tooltip>

						
						<Tooltip title="Forward" onClick={showModal}>
						<div className="mail-detail-action-icon">
						<BsForward />
						</div>	
						</Tooltip>
					</div>
				</div>
				<div className="mail-detail-content">
					<h3 className="mb-4">{e_subject}</h3>

					<div dangerouslySetInnerHTML={{ __html: e_body }} />
					
					<div className="mail-detail-attactment">
						{
							details?.attachments?.map( (elm, i) => (
								<div className="mail-detail-attactment-item" key={`attachment-file-${i}`}>
									<span>{getFileType(elm.a_type)}</span>
									<div className="ml-2">
										<div>{elm.a_name}</div>
										<div className="text-muted font-size-sm">{elm.size}</div>
									</div>
								</div>
							))
						}
					</div>
				</div>



				{details?.reply_email_message?.map((item, index) => 
				<React.Fragment key={index}>
				<Divider />
				<div className="d-lg-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center mb-3">
						{/* <div className="font-size-md mr-3" onClick={()=> {back()}}>
							<LeftCircleOutlined className="mail-detail-action-icon font-size-md ml-0" />
						</div> */}
						<AvatarStatus icon={<MailOutlined />} name={details?.e_to} />
					</div>
					<div className="mail-detail-action mb-3">
						<span className="mr-2 font-size-md">{moment(e_date).format('MMMM Do YYYY, h:mm a')}</span>
						<Tooltip title="Reply" onClick={()=>setVisible(true)}>
							<div>
							<CustomIcon className="mail-detail-action-icon" svg={ReplySVG} />
							</div>
							
						</Tooltip>

						<Tooltip title="Star" onClick={()=>{tick(starred);}}>
							{starred? <StarFilled className="mail-detail-action-icon star checked" /> : <StarOutlined className="mail-detail-action-icon star" />}
						</Tooltip>
						{details?.attachments?.length > 0 ? <Tooltip title="Download Attachment"><DownloadOutlined className="mail-detail-action-icon"/></Tooltip> : null}
						<Tooltip title="Delete">
						<Popconfirm title="Are you sure？"
            cancelText="No"
            okText="Yes"
            onConfirm={() => confirm(item?.id)}
            onCancel={cancel}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
							<DeleteOutlined className="mail-detail-action-icon"/>
							</Popconfirm>
						</Tooltip>
					</div>
				</div>
				<div className="mail-detail-content">
					{/* <h3 className="mb-4">{e_subject}</h3> */}

					<div dangerouslySetInnerHTML={{ __html: item?.e_body }} />
					
					<div className="mail-detail-attactment">
						{
							details?.attachments?.map( (elm, i) => (
								<div className="mail-detail-attactment-item" key={`attachment-file-${i}`}>
									<span>{getFileType(elm.a_type)}</span>
									<div className="ml-2">
										<div>{elm.a_name}</div>
										<div className="text-muted font-size-sm">{elm.size}</div>
									</div>
								</div>
							))
						}
					</div>
				</div>
				</React.Fragment>
				)}
			</div>
		)
	}


export default MaiDetail
