import React, { useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { logoutUser, MinimizeModal } from "redux/actions";
import AvatarStatus from "components/shared-components/AvatarStatus";
import { Link } from "react-router-dom";
import useMinimizeData from "hooks/useMinimizeData";
import useModelData from "hooks/useModelData";
import DragModal from "components/util-components/DragableModel/DragModal";
import { HandleOpenModal } from "components/util-components/DragableModel/HandleOpenModal";

export const NavProfile = (props) => {
  const user = useSelector((state) => state.AuthStore?.user?.user);
  const dispatch = useDispatch();
  const handleLoggOff = () => dispatch(logoutUser());
  const { modalData, setModalData } = useModelData();
  const { minimumData, setMinimumData } = useMinimizeData();
  const minimizeData = useSelector((state) => state.MinimizeModalStore?.modal);

  const [visible, setVisible] = useState();

  const onMenuClick = (e) => {
    setVisible(false);
  }

  const handleVisibleChange = flag => {
    setVisible(flag);
  };

  const profileMenu = (
    <React.Fragment>
      <DragModal
        modalData={modalData}
        setModalData={setModalData}
        minimumData={minimumData}
        setMinimumData={setMinimumData}
        HandleOpenModal={HandleOpenModal}
      />
      <div className="nav-profile nav-dropdown">
        <div className="nav-profile-header">
          <div className="d-flex">
            {user?.photo !== null && user?.photo !== undefined ? (
              <Avatar size={45} src={user?.photo} />
            ) : (
              <AvatarStatus text={user?.initials} type="cyan" />
            )}

            <div className="pl-3">
              <h4 className="mb-0 mt-2">
                {user?.firstname} {user?.lastname}
              </h4>
              {/* <span className="text-muted">Frontend Developer</span> */}
            </div>
          </div>
        </div>
        <div className="nav-profile-body">
          <Menu onClick={onMenuClick}>
            <Menu.Item
              key={1}
              onClick={(e) => {
                HandleOpenModal.handleOpenModal(
                  {
                    id: 100,
                    visible: true,
                    object_type: "profile",
                    title: "Profile",
                    user_id: user?.id,
                    team_id: null,
                  },
                  100,
                  modalData,
                  minimizeData,
                  setMinimumData,
                  setModalData, 
                  dispatch,
                  MinimizeModal
                );
              }}
            >
              <UserOutlined className="mr-3" />
              <span className="font-weight-normal">Profile</span>
            </Menu.Item>
            <Menu.Item key={2} onClick={props.showWorkSpaceModal}>
              <LaptopOutlined className="mr-3" />
              <span className="font-weight-normal">Workspace</span>
            </Menu.Item>

            {user?.user_role_id === 2 ? (
              <Menu.Item
                key={3}
                onClick={(e) => {
                  HandleOpenModal.handleOpenModal(
                    {
                      id: 110,
                      visible: true,
                      object_type: "team",
                      title: "Manage Team",
                      user_id: user?.id,
                      team_id: null,
                    },
                    110,
                    modalData,
                    minimizeData,
                    setMinimumData,
                    setModalData,
                    dispatch,
                    MinimizeModal
                  );
                }}
              >
                <UsergroupAddOutlined className="mr-3" />

                <span className="font-weight-normal">Manage Teams</span>
              </Menu.Item>
            ) : (
              ""
            )}
            <Menu.Item key={4} onClick={handleLoggOff}>
              <span>
                <LogoutOutlined className="mr-3" />
                <span className="font-weight-normal">Sign Out</span>
              </span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <Dropdown placement="bottomRight" 
    onVisibleChange={handleVisibleChange}
        visible={visible}
    overlay={profileMenu} trigger={["click"]}>
      <Menu
        className={`d-flex ${
          user?.photo !== null && user?.photo ? "" : "mt-2"
        } align-item-center`}
        mode="horizontal"
      >
        <Menu.Item>
          {user?.photo !== null && user?.photo ? (
            <Avatar src={user?.photo} />
          ) : (
            <AvatarStatus
              text={user?.initials}
              type="blue"
              style={{ fontSize: "20px !important" }}
            />
          )}
          {/* <Avatar src={profileImg} /> */}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null)(NavProfile);
