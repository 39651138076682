import * as type from '../constants/MinimizeModal';

const initialState = {
    modal:[],
}

const MinimizeModalStore = ( state = initialState, action) => {
   switch (action.type) {
    case type.MINIMIZE_MODAL:
    return {
        modal:action.payload,
    }
    default:
	    return state;
}
}

export default MinimizeModalStore;
