export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED"

export const READ_NOTIFICATION = "READ_NOTIFICATION"
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS"
export const READ_NOTIFICATION_FAILED = "READ_NOTIFICATION_FAILED"

export const READ_ALL_NOTIFICATION = "READ_ALL_NOTIFICATION"
export const READ_ALL_NOTIFICATION_SUCCESS = "READ_ALL_NOTIFICATION_SUCCESS"
export const READ_ALL_NOTIFICATION_FAILED = "READ_ALL_NOTIFICATION_FAILED"
