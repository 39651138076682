import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/TextMessageService';

import * as types from "./action_types";


function* registerDevice(action)
{
    const {response, error} = yield call(api.registerDevice, action.payload)
    if(response)
    {
        yield put({type: types.REGISTER_DEVICE_SUCCESS,});
    } else {
        yield put({type: types.REGISTER_DEVICE_FAILED, error:error});
    }
}

function* showDevice(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_DEVICE_SUCCESS, single_device:response.data});
    } else {
        yield put({type: types.SHOW_DEVICE_FAILED, error:error});
    }
}

function* getSmsMessages(action)
{
    const {response, error} = yield call(api.getTextMessage, action.payload)
    if(response)
    {
        yield put({type: types.GET_SMS_MESSAGES_SUCCESS, sms_messages:response.data});
    } else {
        yield put({type: types.GET_SMS_MESSAGES_FAILED, error:error});
    }
}

function* showSmsMessages(action)
{
    const {response, error} = yield call(api.showTextMessage, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_SMS_MESSAGES_SUCCESS, show_sms_messages:response.data});
    } else {
        yield put({type: types.SHOW_SMS_MESSAGES_FAILED, error:error});
    }
}

function* sendSmsMessages(action)
{
    const {response, error} = yield call(api.sendTextMessage, action.payload)
    if(response)
    {
        yield put({type: types.SEND_SMS_MESSAGES_SUCCESS});
    } else {
        yield put({type: types.SEND_SMS_MESSAGES_FAILED, error:error});
    }
}


function* watchRegisterDevice() 
{
    yield takeEvery(types.REGISTER_DEVICE, registerDevice)
}

function* watchShowDevice() 
{
    yield takeEvery(types.SHOW_DEVICE, showDevice)
}

function* watchGetSMSMessage() 
{
    yield takeEvery(types.GET_SMS_MESSAGES, getSmsMessages)
}

function* watchShowSMSMessage() 
{
    yield takeEvery(types.SHOW_SMS_MESSAGES, showSmsMessages)
}

function* watchSendSMSMessage() 
{
    yield takeEvery(types.SEND_SMS_MESSAGES, sendSmsMessages)
}

function* textMessageSaga(){
    yield all([
        fork(watchRegisterDevice),
        fork(watchShowDevice),
        fork(watchGetSMSMessage),
        fork(watchShowSMSMessage),
        fork(watchSendSMSMessage)
    ])
}

export default textMessageSaga;