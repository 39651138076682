import * as types from "./constants"

const initialStates = {
    schedule_time:[],
    schedule_single_time:{},
    loading:false,
    error:null,
    success:false,
}

 const ScheduleTimeStore = (state = initialStates, action) => {
    //setup list_items reducers
   switch (action.type) {
       case types.GET_SCHEDULE_TIME :
       return {...state, loading:true}
       case types.GET_SCHEDULE_TIME_SUCCESS :
       return {...state, loading:false, schedule_time:action.schedule_time}
       case types.GET_SCHEDULE_TIME_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_SCHEDULE_TIME :
       return {...state}
       case types.SHOW_SCHEDULE_TIME_SUCCESS :
       return {...state, single_time:action.single_schedule_time}
       case types.SHOW_SCHEDULE_TIME_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_SCHEDULE_TIME :
       return {...state, loading:true, success:false}
       case types.CREATE_SCHEDULE_TIME_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_SCHEDULE_TIME_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_SCHEDULE_TIME :
       return {...state, loading:true, success:false}
       case types.UPDATE_SCHEDULE_TIME_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_SCHEDULE_TIME_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_SCHEDULE_TIME :
       return {...state, loading:true, success:false}
       case types.DELETE_SCHEDULE_TIME_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_SCHEDULE_TIME_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default ScheduleTimeStore
