export const GET_LIST_BOARD = "GET_LIST_BOARD"
export const GET_LIST_BOARD_SUCCESS = "GET_LIST_BOARD_SUCCESS"
export const GET_LIST_BOARD_FAILED = "GET_LIST_BOARD_FAILED"

export const SHOW_LIST_BOARD = "SHOW_LIST_BOARD"
export const SHOW_LIST_BOARD_SUCCESS = "SHOW_LIST_BOARD_SUCCESS"
export const SHOW_LIST_BOARD_FAILED = "SHOW_LIST_BOARD_FAILED"

export const CREATE_LIST_BOARD = "CREATE_LIST_BOARD"
export const CREATE_LIST_BOARD_SUCCESS = "CREATE_LIST_BOARD_SUCCESS"
export const CREATE_LIST_BOARD_FAILED = "CREATE_LIST_BOARD_FAILED"

export const UPDATE_LIST_BOARD = "UPDATE_LIST_BOARD"
export const UPDATE_LIST_BOARD_SUCCESS = "UPDATE_LIST_BOARD_SUCCESS"
export const UPDATE_LIST_BOARD_FAILED = "UPDATE_LIST_BOARD_FAILED"

export const UPDATE_LIST_BOARD_INDEX = "UPDATE_LIST_BOARD_INDEX"
export const UPDATE_LIST_BOARD_INDEX_SUCCESS = "UPDATE_LIST_BOARD_INDEX_SUCCESS"
export const UPDATE_LIST_BOARD_INDEX_FAILED = "UPDATE_LIST_BOARD_INDEX_FAILED"


export const DELETE_LIST_BOARD = "DELETE_LIST_BOARD"
export const DELETE_LIST_BOARD_SUCCESS = "DELETE_LIST_BOARD_SUCCESS"
export const DELETE_LIST_BOARD_FAILED = "DELETE_LIST_BOARD_FAILED"


export const GET_LIST_CARD = "GET_LIST_CARD"
export const GET_LIST_CARD_SUCCESS = "GET_LIST_CARD_SUCCESS"
export const GET_LIST_CARD_FAILED = "GET_LIST_CARD_FAILED"

export const SHOW_LIST_CARD = "SHOW_LIST_CARD"
export const SHOW_LIST_CARD_SUCCESS = "SHOW_LIST_CARD_SUCCESS"
export const SHOW_LIST_CARD_FAILED = "SHOW_LIST_CARD_FAILED"

export const CREATE_LIST_CARD = "CREATE_LIST_CARD"
export const CREATE_LIST_CARD_SUCCESS = "CREATE_LIST_CARD_SUCCESS"
export const CREATE_LIST_CARD_FAILED = "CREATE_LIST_CARD_FAILED"

export const UPDATE_LIST_CARD = "UPDATE_LIST_CARD"
export const UPDATE_LIST_CARD_SUCCESS = "UPDATE_LIST_CARD_SUCCESS"
export const UPDATE_LIST_CARD_FAILED = "UPDATE_LIST_CARD_FAILED"

export const DELETE_LIST_CARD = "DELETE_LIST_CARD"
export const DELETE_LIST_CARD_SUCCESS = "DELETE_LIST_CARD_SUCCESS"
export const DELETE_LIST_CARD_FAILED = "DELETE_LIST_CARD_FAILED"


export const UPDATE_LIST_CARD_INDEX = "UPDATE_LIST_CARD_INDEX"
export const UPDATE_LIST_CARD_INDEX_SUCCESS = "UPDATE_LIST_CARD_INDEX_SUCCESS"
export const UPDATE_LIST_CARD_INDEX_FAILED = "UPDATE_LIST_CARD_INDEX_FAILED"

export const UPDATE_LIST_CARD_NEXT_INDEX = "UPDATE_LIST_CARD_NEXT_INDEX"
export const UPDATE_LIST_CARD_NEXT_INDEX_SUCCESS = "UPDATE_LIST_CARD_NEXT_INDEX_SUCCESS"
export const UPDATE_LIST_CARD_NEXT_INDEX_FAILED = "UPDATE_LIST_CARD_NEXT_INDEX_FAILED"