import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  list_api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/note-boards`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message);
            // if(error.response.data.message === "Unauthenticated."){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear(); 
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/note-boards/${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    store(data){
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/note-boards",data)
          .then((response) => {
            // successNotify(response.data.message);
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },

    updateIndex(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .post("/note-boards/order",data)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          return { error };
        });
  },

    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/note-boards/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    destroy(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/note-boards/${data}`)
          .then((response) => {
            message.success(response.data.message, 2);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
}

export const  card_api = {
    index(data){
        axios.defaults.withCredentials = false;
       
        return authAxios
          .get(`/note-card`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message);
            // if(error.response.data.message === "Unauthenticated."){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear(); 
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/note-card/${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    store(data){
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/note-card",data)
          .then((response) => {
            message.success(response.data.message, 2);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/note-card/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 2);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },

    updateIndex(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .post("/note-cards/order",data)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          return { error };
        });
  },

  updateNextIndex(data){
    axios.defaults.withCredentials = true;
    return authAxios
      .post("/note-cards/next-order",data)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
},

    destroy(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/note-card/${data}`)
          .then((response) => {
            message.success(response.data.message, 2);
            return { response };
          })
          .catch((error) => {
            // // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
}