import * as types from "./constants"

export const getNotebook = (notebook) => {
    return {
        type:types.GET_NOTEBOOK,
        payload:notebook
    }
}

export const showNotebook = (single_notebook) => {
    return {
        type:types.SHOW_NOTEBOOK,
        payload:single_notebook
    }
}

export const createNotebook = (notebook, history) => {
    return {
        type:types.CREATE_NOTEBOOK,
        payload:{notebook, history}
    }
}

export const updateNotebook = (notebook) => {
    return {
        type:types.UPDATE_NOTEBOOK,
        payload:notebook
    }
}

export const deleteNotebook = (notebook, history) => {
    return {
        type:types.DELETE_NOTEBOOK,
        payload:{notebook, history}
    }
}
