import React from 'react'
import {
    Button,
    Upload,
  } from "antd";

import {
    UploadOutlined,
    CloudUploadOutlined,
  } from "@ant-design/icons";

function Attachment(props) {
    const {Form, normFile, handleUpload} = props;
    return (
        <React.Fragment>
              <div className="mt-5 mb-5">
                <h4 className="mb-3">
                  <CloudUploadOutlined /> Attachments
                </h4>
                <Form.Item
                  name="attachments"
                  label=""
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  extra=""
                >
                  <Upload name="logo" listType="picture" onChange={handleUpload}>
                    <Button>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
        </React.Fragment>
    )
}

export default Attachment
