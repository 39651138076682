import * as types from "./constants"

const initialStates = {
    teams:[],
    team_admin:{},
    members:[],
    users:[],
    team_role:[],
    single_team:{},
    loading:false,
    error:null,
    success:false,
}

 const TeamStore = (state = initialStates, action) => {
    //setup teams reducers
   switch (action.type) {
       case types.GET_TEAMS :
       return {...state, loading:true}
       case types.GET_TEAMS_SUCCESS :
       return {...state, loading:false, teams:action.teams}
       case types.GET_TEAMS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.GET_TEAM_ADMIN :
       return {...state, loading:true}
       case types.GET_TEAM_ADMIN_SUCCESS :
       return {...state, loading:false, team_admin:action.team_admin}
       case types.GET_TEAM_ADMIN_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.GET_TEAM_USERS :
       return {...state, loading:true}
       case types.GET_TEAM_USERS_SUCCESS :
       return {...state, loading:false, users:action.users}
       case types.GET_TEAM_USERS_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_TEAMS :
       return {...state}
       case types.SHOW_TEAMS_SUCCESS :
       return {...state, single_team:action.single_team}
       case types.SHOW_TEAMS_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_TEAMS :
       return {...state, loading:true, success:false}
       case types.CREATE_TEAMS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_TEAMS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_TEAMS :
       return {...state, loading:true, success:false}
       case types.UPDATE_TEAMS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_TEAMS_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_ACTIVE_TEAM :
       return {...state, loading:true, success:false}
       case types.UPDATE_ACTIVE_TEAM_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_ACTIVE_TEAM_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_TEAMS :
       return {...state, loading:true, success:false}
       case types.DELETE_TEAMS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_TEAMS_FAILED :
       return {...state, success:true, loading:false, error:action.error}



    //    team members reducers

    case types.GET_TEAM_MEMBERS :
    return {...state, loading:true}
    case types.GET_TEAM_MEMBERS_SUCCESS :
    return {...state, loading:false, members:action.members}
    case types.GET_TEAM_MEMBERS_FAILED :
    return {...state, loading:false, error:action.error, }


    case types.CREATE_TEAM_MEMBERS :
       return {...state, loading:true, success:false}
       case types.CREATE_TEAM_MEMBERS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_TEAM_MEMBERS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_TEAM_MEMBERS :
       return {...state, loading:true, success:false}
       case types.UPDATE_TEAM_MEMBERS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_TEAM_MEMBERS_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_TEAM_MEMBERS :
       return {...state, loading:true, success:false}
       case types.DELETE_TEAM_MEMBERS_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_TEAM_MEMBERS_FAILED :
       return {...state, success:true, loading:false, error:action.error}

// team role reducers

case types.GET_TEAM_ROLES :
    return {...state, loading:true}
    case types.GET_TEAM_ROLES_SUCCESS :
    return {...state, loading:false, team_role:action.team_role}
    case types.GET_TEAM_ROLES_FAILED :
    return {...state, loading:false, error:action.error, }

    case types.CREATE_TEAM_ROLES :
       return {...state, loading:true, success:false}
       case types.CREATE_TEAM_ROLES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_TEAM_ROLES_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_TEAM_ROLES :
       return {...state, loading:true, success:false}
       case types.UPDATE_TEAM_ROLES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_TEAM_ROLES_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_TEAM_ROLES :
       return {...state, loading:true, success:false}
       case types.DELETE_TEAM_ROLES_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_TEAM_ROLES_FAILED :
       return {...state, success:true, loading:false, error:action.error}
    
       default:
           return state;
   }
}

export default TeamStore
