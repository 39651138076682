import * as types from "./constants"

export const getEmailServiceType = (email_service_type) => {
    return {
        type:types.GET_EMAIL_SERVICE_TYPE,
        payload:email_service_type
    }
}

export const showEmailServiceType = (singel_email_service_type) => {
    return {
        type:types.SHOW_EMAIL_SERVICE_TYPE,
        payload:singel_email_service_type
    }
}

export const createEmailServiceType = (email_service_type) => {
    return {
        type:types.CREATE_EMAIL_SERVICE_TYPE,
        payload:{email_service_type}
    }
}

export const updateEmailServiceType = (email_service_type) => {
    return {
        type:types.UPDATE_EMAIL_SERVICE_TYPE,
        payload:email_service_type
    }
}

export const deleteEmailServiceType = (email_service_type) => {
    return {
        type:types.DELETE_EMAIL_SERVICE_TYPE,
        payload:{email_service_type}
    }
}
