import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input,Select, Row, Upload } from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "redux/actions";
import moment from "moment";
import momentTimezone from "moment-timezone";
const { Option, OptGroup } = Select;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};


const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

function ProfileForm(props) {
  const { username, email, id } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    dispatch(updateUser(values));
  };

  // React.useEffect(() => {
  //       dispatch(getUser());
  // }, [])

  const renderOptions = React.useCallback(
    () => {
      const timezones = moment.tz.names();
    let mappedValues = {};
    let regions = [];

    timezones.map((timezone) => {
      const splitTimezone = timezone.split("/");
      const region = splitTimezone[0];
      if (!mappedValues[region]) {
        mappedValues[region] = [];
        regions.push(region);
      }
      mappedValues[region].push(timezone);
    });
    return regions.map((region) => {
      const options = mappedValues[region].map((timezone) => {
        return <Option key={timezone}>{timezone}</Option>;
      });
      return (
        <OptGroup
          key={region}
          title={<div style={{ fontSize: 30 }}>{region}</div>}
        >
          {options}
        </OptGroup>
      );
    });
    },
    [],
  )

  const data = useSelector((state) => state.AuthStore?.user);
  const user = data?.user;

  // console.log(user);
  useEffect(() => {
    form.resetFields();
  }, [user]);

 
  return (
    <>
      <Form
        {...layout}
        form={form}
        name="profile-input"
        initialValues={{
          id: user?.id,
          firstname:user?.firstname?.toString(),
          lastname:user?.lastname?.toString(),
          username: user?.username?.toString(),
          email: user?.email?.toString(),
          image: [],
          phone_number: user?.phone_number?.toString(),
          timezone:user?.timezone,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item style={{ display: "none" }} label="id" name="id">
          <Input />
        </Form.Item>
        <Row gutter="20">
        <Col xs={24} sm={24} md={12}>
            <Form.Item label="Firstname" name="firstname">
              <Input value={user?.firstname} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Lastname" name="lastname">
              <Input value={user?.lastname} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Username" name="username">
              <Input value={user?.username} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Email" name="email">
              <Input autoComplete="off" value={user?.email} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
          <Form.Item
                name="timezone"
                label="Timezone"
                // rules={[{ required: true, message: 'Please input your timezone!' }]}
                // hasFeedback
              >
                <Select
                showSearch={true}
                //   style={{ width: 400 }}
                >
                  {renderOptions()}
                </Select>
              </Form.Item>

          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Phone Number" name="phone_number">
              <Input autoComplete="off" value={user?.phone_number} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={16}>
            <Form.Item
              name="image"
              label="Upload Profile Photo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra=""
            >
              <Upload name="logo" listType="picture">
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="float-right">
                Save Changes
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default React.memo(ProfileForm);
