import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/ScheduleItemService';


// schedule item Redux States
import * as types from './constants';

function* getScheduleItem(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_SCHEDULE_ITEM_SUCCESS, schedules_item:response.data});
    } else {
        yield put({type: types.GET_SCHEDULE_ITEM_FAILED, error:error});
    }
}

// function* getRangeSchedule(action)
// {
//     const {response, error} = yield call(api.range, action.payload)
//     if(response)
//     {
//         yield put({type: types.GET_SCHEDULE_RANGE_SUCCESS, schedules_range:response.data});
//     } else {
//         yield put({type: types.GET_SCHEDULE_RANGE_FAILED, error:error});
//     }
// }

function* showScheduleItem(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_SCHEDULE_ITEM_SUCCESS, single_schedule_item:response.data});
    } else {
        yield put({type: types.SHOW_SCHEDULE_ITEM_FAILED, error:error});
    }
}

function* createScheduleItem(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_SCHEDULE_ITEM_SUCCESS, list:response.data});
    } else {
        yield put({type: types.CREATE_SCHEDULE_ITEM_FAILED, error:error});
    }
}

function* updateScheduleItem(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_SCHEDULE_ITEM_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_SCHEDULE_ITEM_FAILED, error:error});
    }
}

function* deleteScheduleItem(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_SCHEDULE_ITEM_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_SCHEDULE_ITEM_FAILED, error:error});
    }
}




function* watchGetScheduleItem() 
{
    yield takeEvery(types.GET_SCHEDULE_ITEM, getScheduleItem)
}

// _ITEM

function* watchShowScheduleItem() 
{
    yield takeEvery(types.SHOW_SCHEDULE_ITEM, showScheduleItem)
}

function* watchCreateScheduleItem() 
{
    yield takeEvery(types.CREATE_SCHEDULE_ITEM, createScheduleItem)
}

function* watchUpdateScheduleItem() 
{
    yield takeEvery(types.UPDATE_SCHEDULE_ITEM, updateScheduleItem)
}

function* watchDeleteScheduleItem() 
{
    yield takeEvery(types.DELETE_SCHEDULE_ITEM, deleteScheduleItem)
}




function* scheduleItemSaga(){
    yield all([
        fork(watchGetScheduleItem),
        fork(watchShowScheduleItem),
        fork(watchCreateScheduleItem),
        fork(watchUpdateScheduleItem),
        fork(watchDeleteScheduleItem),

    ])
}

export default scheduleItemSaga;