export const AppointmentUtiles = {
  // handle on personal change - this function set the event personal state to true or false.
  onChangePersonal(personal, setPersonal) {
    if (personal === false) {
      setPersonal(true);
    } else if (personal === true) {
      setPersonal(false);
    }
  },

  // handle on private change - this function set the event private state to true or false.
  onChangePrivate(privates, setPrivates) {
    if (privates === false) {
      setPrivates(true);
    } else if (privates === true) {
      setPrivates(false);
    }
  },
};
