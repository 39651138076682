import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table } from "antd";
import EditTime from "./EditTime";

const columns = [
  {
    title: "Team Member",
    dataIndex: "team_member",
    key: "team_member",
  },
  {
    title: "Start Time",
    dataIndex: "start_time",
    key: "start_time",
  },
  {
    title: "End Time",
    dataIndex: "end_time",
    key: "end_time",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
];

function TimeTable(props) {
  const { time, user, teams, cardData, deleteTime, updateTime, url } = props;
  const [dataSource, setDataSource] = useState([]);

  const [editStartTime, setEditStartTime] = useState(new Date());
  const [editEndTime, setEditEndTime] = useState(new Date());
  const [timeEditVisible, setTimerEditVisible] = useState(false);
  const [editTimeUser, setEditTimeUser] = useState("");
  // const [editScheduleTimeDate, setEditTimeDate] = useState(moment());
  const [editScheduleTimeId, setEditTimeId] = useState("");
  const [editScheduleStatus, setEditStatus] = useState("");
  const [editScheduleTimeTitle, setEditTimeTitle] = useState("");

  useEffect(() => {
    let data = [];
    time?.forEach((elm) => {
      let item = {
        key: elm?.id,
        team_member:
          elm?.user_id === user?.id
            ? user?.fullname
            : teams
                ?.filter((x) => x.id === cardData?.team_id)?.[0]
                ?.team_members?.filter(
                  (m) => m?.member_id === elm?.user_id
                )?.[0]?.fullname,
        // date: moment(elm?.date).format("dddd DD-MMM-YYYY"),
        start_time: moment(elm?.start_time).format("ddd DD-MMM-YYYY hh:mm a"),
        end_time: moment(elm?.end_time).format("ddd DD-MMM-YYYY hh:mm a"),
        duration: elm?.duration,
        main_data: elm,
      };
      data.push(item);
    });

    setDataSource(data);
  }, [time]);

  return (
    <React.Fragment>
      <EditTime
        timerScheduleEditVisible={timeEditVisible}
        setTimerScheduleEditVisible={setTimerEditVisible}
        setEditScheduleTimeUser={setEditTimeUser}
        editScheduleTimeUser={editTimeUser}
        cardData={cardData}
        user={user}
        teams={teams}
        editScheduleTimeTitle={editScheduleTimeTitle}
        setEditScheduleTimeTitle={setEditTimeTitle}
        editScheduleStatus={editScheduleStatus}
        editScheduleTimeId={editScheduleTimeId}
        editStartScheduleTime={editStartTime}
        editEndScheduleTime={editEndTime}
        deleteTime={deleteTime}
        url={url}
        updateTime={updateTime}
        setEditEndScheduleTime={setEditEndTime}
        setEditStartScheduleTime={setEditStartTime}
      />
      <Table
        dataSource={dataSource}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {
            setTimerEditVisible(true);
            // setEditTimeDate(
            //   moment(record?.main_data?.date)
            // );
            setEditEndTime(moment(record?.main_data?.end_time).toDate());
            setEditStartTime(moment(record?.main_data?.start_time).toDate());
            setEditTimeId(record?.main_data?.id);

            setEditStatus(record?.main_data?.status);
            setEditTimeTitle(record?.main_data?.title);
            setEditTimeUser(record?.main_data?.user_id);
          },
        })}
        columns={columns}
        className="schedule__time__table"
      />
    </React.Fragment>
  );
}

export default TimeTable;
