import * as types from "./action_types"

const initialStates = {
    sms_messages:[],
    show_sms_messages:[],
    device:{},
    single_device:{},
    loading:false,
    error:null,
    success:false,
}

const TextMessageStore = (state = initialStates, action) => {

    switch(action.type) {
        case types.REGISTER_DEVICE :
        return {...state, loading:true, success:false}
        case types.REGISTER_DEVICE_SUCCESS :
        return {...state, loading:false, success:true}
        case types.REGISTER_DEVICE_FAILED :
        return {...state, success:true, loading:false, error:action.error}

        case types.SHOW_DEVICE :
        return {...state}
        case types.SHOW_DEVICE_SUCCESS :
        return {...state, [action.single_device?.object?.id]:action.single_device}
        case types.SHOW_DEVICE_FAILED :
        return {...state, error:action.error, loading:false}

        case types.GET_SMS_MESSAGES :
        return {...state}
        case types.GET_SMS_MESSAGES_SUCCESS :
        return {...state, sms_messages:action.sms_messages}
        case types.GET_SMS_MESSAGES_FAILED :
        return {...state, error:action.error, loading:false}

        case types.SHOW_SMS_MESSAGES :
        return {...state}
        case types.SHOW_SMS_MESSAGES_SUCCESS :
        return {...state, show_sms_messages:action.show_sms_messages}
        case types.SHOW_SMS_MESSAGES_FAILED :
        return {...state, error:action.error, loading:false}

        case types.SEND_SMS_MESSAGES :
        return {...state, success:false}
        case types.SEND_SMS_MESSAGES_SUCCESS :
        return {...state, success:true}
        case types.SEND_SMS_MESSAGES_FAILED :
        return {...state, error:action.error,success:true, loading:false}

        default:
        return state;
    }

}

export default TextMessageStore;