import { takeEvery, takeLatest, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/CalendarService';


// Board Redux States
import * as types from './constants';

function* getCalendar(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_CALENDAR_SUCCESS, calendar:response.data});
    } else {
        yield put({type: types.GET_CALENDAR_FAILED, error:error});
    }
}

function* showCalendar(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_CALENDAR_SUCCESS, single_calendar:response.data});
    } else {
        yield put({type: types.SHOW_CALENDAR_FAILED, error:error});
    }
}

function* createCalendar(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_CALENDAR_SUCCESS,});
    } else {
        yield put({type: types.CREATE_CALENDAR_FAILED, error:error});
    }
}

function* updateCalendar(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_CALENDAR_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_CALENDAR_FAILED, error:error});
    }
}

function* deleteCalendar(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_CALENDAR_SUCCESS, });
    } else {
        yield put({type: types.DELETE_CALENDAR_FAILED, error:error});
    }
}

function* getTimeConflict(action)
{
    const {response, error} = yield call(api.getTimeConflict, action.payload)
    if(response)
    {
        yield put({type: types.GET_TIME_CONFLICT_SUCCESS, is_conflict:response.data});
    } else {
        yield put({type: types.GET_TIME_CONFLICT_FAILED, error:error});
    }
}


function* watchGetCalendar() 
{
    yield takeLatest(types.GET_CALENDAR, getCalendar)
}

function* watchShowCalendar() 
{
    yield takeEvery(types.SHOW_CALENDAR, showCalendar)
}

function* watchCreateCalendar() 
{
    yield takeEvery(types.CREATE_CALENDAR, createCalendar)
}

function* watchUpdateCalendar() 
{
    yield takeEvery(types.UPDATE_CALENDAR, updateCalendar)
}

function* watchDeleteCalendar() 
{
    yield takeEvery(types.DELETE_CALENDAR, deleteCalendar)
}

function* watchGetTimeConflict() 
{
    yield takeLatest(types.GET_TIME_CONFLICT, getTimeConflict)
}

function* calendarSaga(){
    yield all([
        fork(watchGetCalendar),
        fork(watchShowCalendar),
        fork(watchCreateCalendar),
        fork(watchUpdateCalendar),
        fork(watchDeleteCalendar),
        fork(watchGetTimeConflict),
    ])
}

export default calendarSaga;