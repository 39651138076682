import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/JournalService';


// Board Redux States
import * as types from './constants';

function* getJournal(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_JOURNAL_SUCCESS, journals:response.data});
    } else {
        yield put({type: types.GET_JOURNAL_FAILED, error:error});
    }
}

function* showJournal(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_JOURNAL_SUCCESS, single_journal:response.data});
    } else {
        yield put({type: types.SHOW_JOURNAL_FAILED, error:error});
    }
}

function* createJournal(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_JOURNAL_SUCCESS,});
    } else {
        yield put({type: types.CREATE_JOURNAL_FAILED, error:error});
    }
}

function* updateJournal(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_JOURNAL_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_JOURNAL_FAILED, error:error});
    }
}

function* deleteJournal(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_JOURNAL_SUCCESS, });
    } else {
        yield put({type: types.DELETE_JOURNAL_FAILED, error:error});
    }
}




function* watchGetJournal() 
{
    yield takeLatest(types.GET_JOURNAL, getJournal)
}

function* watchShowJournal() 
{
    yield takeEvery(types.SHOW_JOURNAL, showJournal)
}

function* watchCreateJournal() 
{
    yield takeEvery(types.CREATE_JOURNAL, createJournal)
}

function* watchUpdateJournal() 
{
    yield takeEvery(types.UPDATE_JOURNAL, updateJournal)
}

function* watchDeleteJournal() 
{
    yield takeEvery(types.DELETE_JOURNAL, deleteJournal)
}




function* jouranlSaga(){
    yield all([
        fork(watchGetJournal),
        fork(watchShowJournal),
        fork(watchCreateJournal),
        fork(watchUpdateJournal),
        fork(watchDeleteJournal),

    ])
}

export default jouranlSaga;