import * as types from "./constants"

const initialStates = {
    attachments:[],
    single_attachment:{},
    loading:false,
    error:null,
    success:false,
}

const AttachmentStore = (state = initialStates, action) => {
    switch (action.type) {
        
       case types.UPDATE_ATTACHMENTS :
        return {...state, loading:true, success:false}
        case types.UPDATE_ATTACHMENTS_SUCCESS :
        return {...state, loading:false, success:true}
        case types.UPDATE_ATTACHMENTS_FAILED:
        return {...state, success:true, loading:false, error:action.error}
 
        case types.UPDATE_ATTACHMENT_FROM_CARD :
        return {...state, loading:true, success:false}
        case types.UPDATE_ATTACHMENT_FROM_CARD_SUCCESS :
        return {...state, loading:false, success:true}
        case types.UPDATE_ATTACHMENT_FROM_CARD_FAILED:
        return {...state, success:true, loading:false, error:action.error}
        
        
        case types.DELETE_ATTACHMENTS :
        return {...state, loading:true, success:false}
        case types.DELETE_ATTACHMENTS_SUCCESS :
        return {...state, loading:false, success:true}
        case types.DELETE_ATTACHMENTS_FAILED:
        return {...state, success:true, loading:false, error:action.error}
       
        default:
            return state;
 
    }
}

export default AttachmentStore;