import React, { useEffect } from "react";
import { Modal, Form, Row, Col } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { showCard } from "redux/actions";
import DueDate from "../../DraggableCard/DueDate";
import DeleteCard from "components/shared-components/DraggableCard/DeleteCard";
import UpdateCardForm from "./UpdateCardForm";
import { modalModeTypes } from "views/app-views/project/scrumboard/utils";
import Draggable from 'react-draggable';
const getModalTitle = (type) => {
  switch (type) {
    case modalModeTypes(0):
      return "New card";
    case modalModeTypes(2):
      return "New board";
    default:
      return;
  }
};

function ModalForm(props) {
  const [form] = Form.useForm();

  const {
    cardData,
    modalMode,
    visible,
    listId,
    onClose,
    onModalSubmit,
    listItem,
    url,
    attachment_url,
    comment_url,
    cardIdString, 
    hasComment, 
    hasDueDate
  } = props;
  const card_item = useSelector((state) => state.CardStore);
  const [isCancelled, setIsCancelled] = React.useState(false);
  const board_success = useSelector((state) => state.BoardStore.success);
  const card_data = card_item?.single_card;
  const card_success = card_item?.success;
  const attachment_success = useSelector((state) => state.AttachmentStore?.success);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if(!isCancelled){
      
      if (cardData?.id !== undefined) {
        const cardDataItem = async() => {
         
          await dispatch(showCard(cardData?.id, url));
          
        }
        
        cardDataItem();
        
      }
    } else {
     
      if (cardData?.id !== undefined) {
        const cardDataItem = async() => {
         
          await dispatch(showCard(cardData?.id, url));
        }
        cardDataItem();
        
      }
    }

    return () => {
      setIsCancelled(true);
    }
   
  }, [cardData?.id]);

  React.useEffect(() => {
    if(!isCancelled) {
      if (card_success === true || board_success || attachment_success) {
        const cardDataItem = async() => {
          await dispatch(showCard(cardData?.id, url));
        }
        cardDataItem();
      }
    }else{
      if (card_success === true || board_success || attachment_success) {
        const cardDataItem = async() => {
          await dispatch(showCard(cardData?.id, url));
        }
        cardDataItem();
      }
    }

    return () => {
      setIsCancelled(true);
    }
 
  }, [card_success, board_success, attachment_success]);

  const submit = (values, mode) => {
    onModalSubmit(values, mode);
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [cardData, form]);

  const [disable, setDisable] = React.useState(true);
  const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <React.Fragment>
      <Modal
         title={
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (disable) {
            setDisable(false);
          }
        }}
        onMouseOut={() => {
         setDisable(true);
        }}
        // fix eslintjsx-a11y/mouse-events-have-key-events
        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
        onFocus={() => {}}
        onBlur={() => {}}
        // end
      >
     Edit
      </div>
    }
        visible={visible}
        footer={null}
        width={1220}
        style={modalMode === modalModeTypes(1) ? { top: 20 } : null}
        // destroyOnClose
        maskClosable={false}
        onCancel={() => onClose()}
        className="editModal"
        modalRender={modal => (
          <Draggable
            disabled={disable}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Row>
          <Col md={17}>
            <div
              className="pt-2 pb-4 pr-4"
              style={
                modalMode === modalModeTypes(1)
                  ? {
                      maxHeight: "85vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }
                  : null
              }
            >
              <div
                className={modalMode === modalModeTypes(1) ? "mr-2 ml-2" : null}
              >
                <UpdateCardForm
                  cardData={card_data}
                  listId={listId}
                  onSubmit={(values) => submit(values, modalModeTypes(1))}
                  listItem={listItem}
                  url={url}
                  attachment_url={attachment_url}
                  cardIdString={cardIdString}
                  comment_url={comment_url}
                  hasComment={hasComment}
                  // object_team_members={object_team_members}
                />
              </div>
            </div>
          </Col>

          <Col md={7} className="card__right p-3 ">
            <div className="pt-3">
              <h5>Settings</h5>
                {hasDueDate ? <>
                {/* due date component start */}
              {/* <DueDate card_data={card_data} url={url} /> */}
              {/* due date component end */}
                </> : ""}
              
            </div>

            <div>
              <h5 className="mt-5">Action</h5>

              {/* Delete card component start */}
              <DeleteCard url={url} cardData={cardData} onClose={onClose} />
              {/* Delete card component end */}
            </div>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
}

export default ModalForm;
