import React from 'react'
import {Popconfirm} from "antd";
import { useDispatch } from 'react-redux';
import {deleteCard} from "redux/actions";
import {DeleteOutlined} from "@ant-design/icons";

function DeleteCard(props) {

    const {cardData, onClose, url} = props;

    const dispatch = useDispatch();

    function cancel(e) {}

    function confirm(id) {
        let data = cardData?.id;
        dispatch(deleteCard(data, url));
        onClose();
      }

    return (
        <React.Fragment>
            
            <Popconfirm
              title="Are you sure you want to delete this task?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <div className="item__box">
                <span>
                  <DeleteOutlined /> <span className="icon__text">Delete</span>
                </span>
              </div>
            </Popconfirm>
        </React.Fragment>
    )
}

export default DeleteCard
