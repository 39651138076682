import { all } from 'redux-saga/effects';
import AuthSaga from 'redux/auth/sagas';
import boardCardSaga from 'redux/board/sagas';
import listBoardSaga from 'redux/list_board/sagas';
import commentSaga from 'redux/comments/sagas';
import NotificationSaga from 'redux/notification/sagas';
import projectSaga from 'redux/projects/sagas';
import projectChecklistSaga from 'redux/project_checklist/sagas';
import Auth from './Auth';
import journalSaga from 'redux/journal/sagas';
import JournalBoardSaga from 'redux/journal_entries/sagas';
import scheduleSaga from 'redux/schedules/sagas';
import scheduleItemSaga from 'redux/schedule_items/sagas';
import teamSaga from 'redux/teams/sagas';
import taskListSaga from 'redux/tasklists/sagas';
import listItemSaga from 'redux/list/sagas';
import timeSaga from 'redux/time/sagas';
import scheduleTimeSaga from 'redux/schedule_time/sagas';
import workStatusSaga from 'redux/work_status/sagas';
import calendarSaga from 'redux/calendar/sagas';
import assignMembersSaga from 'redux/assign_users/sagas';
import calendarTeamViewSaga from 'redux/calendar_team_view/sagas';
import objectSaga from 'redux/objects/sagas';
import emailSaga from 'redux/emails/sagas';
import emailServiceTypeSaga from 'redux/email_service_type/sagas';
import textMessageSaga from 'redux/text_message/sagas';
import attachmentSaga from 'redux/attachment/sagas';
import databaseBackupSaga from 'redux/database_backup/sagas';
import notebookSaga from 'redux/notebook/sagas';
import notebookBoardSaga from 'redux/notebook_pages/sagas';
export default function* rootSaga(getState) {
  yield all([
    Auth(),
    AuthSaga(), 
    boardCardSaga(),
    listBoardSaga(),
    commentSaga(),
    NotificationSaga(),
    projectSaga(),
    projectChecklistSaga(),
    journalSaga(),
    JournalBoardSaga(),
    scheduleSaga(),
    scheduleItemSaga(),
    teamSaga(),
    taskListSaga(),
    listItemSaga(),
    timeSaga(),
    scheduleTimeSaga(),
    workStatusSaga(),
    calendarSaga(),
    assignMembersSaga(),
    calendarTeamViewSaga(),
    objectSaga(),
    emailSaga(),
    emailServiceTypeSaga(),
    textMessageSaga(),
    attachmentSaga(),
    databaseBackupSaga(),
    notebookSaga(),
    notebookBoardSaga(),
  ]);
}
