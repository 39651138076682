import React, {useState, useEffect} from 'react'
import {
    Modal,
    Input,
    Button,
    Select,
    Tag,
    Row,
    Col,
    DatePicker
  } from "antd";
  import DatePickers from "react-datepicker";
import { AssigneeActiveUserAvatar, AssigneeUserAvatar } from 'views/app-views/project/scrumboard/utils';
import { getTimeInterval } from 'utils/duration';
import handleTimeError from 'utils/TimeError';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getTimeConflict } from 'redux/actions';
import moment from 'moment';

const { Option } = Select;

const memberTagRender = (props) => (
    <AssigneeUserAvatar id={props.value} size={25} />
  );

function AddTime(props) {
    
    const dispatch = useDispatch();

    const {timerScheduleCreateVisible, createTime, url, cardData, teams, user,setTimerScheduleCreateVisible, title} = props;

    
  const handleCreateScheduleTimerOk = () => {
    setTimerScheduleCreateVisible(false);
  };

  const handleCreateScheduleTimerCancel = () => {
    setTimerScheduleCreateVisible(false);
  };
  const [scheduleTimeDate, setTimeScheduleDate] = useState("");
  const [scheduleTimeTitle, setScheduleTimeTitle] = useState("");
  const [startScheduleTime, setStartScheduleTime] = useState("");
  const [endScheduleTime, setEndScheduleTime] = useState("");
  const [scheduleTimeUser, setScheduleTimeUser] = useState("");
  const [isTimeConflict, setIsTimeConflict] = useState(null);
  const calendar_store = useSelector((state) => state.CalendarStore);
  const time_conflict = calendar_store?.is_conflict;
  const calendar_success = calendar_store?.success;
  const user_state = useSelector((state) => state.AuthStore?.user);
  const calendar = user_state?.calendar;

  const handleUserChange = (value) => {
    setScheduleTimeUser(value);
  };

  function onChangeStartScheduleTime(time) {
    setStartScheduleTime(time);
    setEndScheduleTime(time);
  }

  function onChangeEndScheduleTime(time) {
    setEndScheduleTime(time);
    dispatch(getTimeConflict({start_time:moment(startScheduleTime).format('YYYY-MM-DD HH:mm:ss'), end_time:moment(time).format('YYYY-MM-DD HH:mm:ss'), calender_id:calendar?.id, user_id:user?.id}))    
  }


  React.useEffect(() => {
    if(calendar_success === true || isTimeConflict) {
      setIsTimeConflict(time_conflict);
    }

}, [calendar_success])


  const timeScheduleData = {
    user_id: scheduleTimeUser === "" ? user?.id : scheduleTimeUser,
    card_id: cardData?.id,
    team_id: cardData?.team_id === undefined ? null : cardData?.team_id,
    date: scheduleTimeDate,
    title: scheduleTimeTitle,
    start_time: startScheduleTime,
    end_time: endScheduleTime,
    object_type: 1,
    object_id: cardData?.id,
    color: "#1ccb9e",
    duration: getTimeInterval(startScheduleTime, endScheduleTime),
  };

  const addScheduleTime = () => {
    var errorCount = 0;
    
    let errorNum = handleTimeError(errorCount,scheduleTimeUser,scheduleTimeTitle, startScheduleTime, endScheduleTime);

    if(errorNum === 0) {
      if(isTimeConflict === false)
      {
      dispatch(createTime(timeScheduleData, url));
      handleCreateScheduleTimerCancel();
      setTimeScheduleDate("");
      setStartScheduleTime("");
      setEndScheduleTime("");
      setScheduleTimeTitle("");
      setScheduleTimeUser("");
      }
   }
  };

  useEffect(() => {
       if(cardData?.team_id === 0)
       {
        setScheduleTimeUser(user?.id);
       }else{
           setScheduleTimeUser("");
       }
    
  }, [cardData])

  useEffect(() => {
    setScheduleTimeTitle(cardData?.title);
  }, [cardData?.title]);


    return (
        <React.Fragment>
                   
        <Modal
          title={`${title} time to work on this task`}
          visible={timerScheduleCreateVisible}
          onOk={handleCreateScheduleTimerOk}
          onCancel={handleCreateScheduleTimerCancel}
          footer={null}
          width={700}
        >
          <Row gutter={16}>
            <Col span={12} className="mb-3">
              <div>
                <h5>User</h5>
                <Select
                  onChange={handleUserChange}
                  filterOption={false}
                  tagRender={memberTagRender}
                  // mode="tags"
                  removeIcon={null}
                  placeholder="None"
                  className="board-card-modal"
                  style={{ width: "100%" }}
                   defaultValue={cardData?.team_id === 0 ? user?.id : ""}
                >
                  {cardData?.team_id === 0 ? (
                    <Option key={user?.id} value={user?.id}>
                      <AssigneeActiveUserAvatar
                        id={user?.id}
                        name={user?.fullname}
                      />
                    </Option>
                  ) : (
                    teams
                      ?.filter((x) => x.id === cardData?.team_id)?.[0]
                      ?.team_members.map((elm) => (
                        <Option key={elm?.user_id} value={elm?.user_id}>
                          <AssigneeUserAvatar
                            id={elm?.user_id}
                            name={`${elm?.firstname} ${elm?.lastname}`}
                          />
                        </Option>
                      ))
                  )}
                </Select>
            
              </div>
            </Col>

            <Col span={12} className="mb-3">
              <div>
                <h5>Description</h5>
                <Input
                  value={scheduleTimeTitle}
                  onChange={(e) => setScheduleTimeTitle(e.target.value)}
                />
              </div>
            </Col>

            <Col span={12} className="mb-3">
              <div>
                <h5>Start time</h5>
                <DatePicker
                //  value={moment(startScheduleTime).tz(user?.timezone)}
                 format={`DD-MMM-YYYY hh:mm a z`}
                 onChange={onChangeStartScheduleTime}
                 bordered={true}
                 showTime={true}
                 minuteStep={5}
                 style={{ width: "100%" }}
               />
{/* 
                <DatePickers
                  selected={startScheduleTime}
                  onChange={(date) => onChangeStartScheduleTime(date)}
                  showTimeSelect
                  // showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="dd-MMMM-yyyy h:mm aa"
                /> */}
              </div>
            </Col>
            <Col span={12} className="mb-3">
              <div>
                <h5>End time</h5>
                <DatePicker
                //  value={moment(startScheduleTime).tz(user?.timezone)}
                 format={`DD-MMM-YYYY hh:mm a z`}
                 onChange={onChangeEndScheduleTime}
                 bordered={true}
                 showTime={true}
                 minuteStep={5}
                 style={{ width: "100%" }}
               />
                {/* <DatePickers
                  selected={endScheduleTime}
                  onChange={(date) => onChangeEndScheduleTime(date)}
                  showTimeSelect
                  // showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat=" dd-MMMM-yyyy h:mm aa"
                /> */}
              </div>
           
            </Col>
            <Col span={24}>
              {startScheduleTime === endScheduleTime ? "" : 
              <>
                 {isTimeConflict? <p className="text-danger text-center mt-0">
           time conflict - please check the calendar
                </p>: ""}
              </>
              }
         
            </Col>
      
            <Col span={12}>
              <h5>
                Duration:{" "}
                <Tag color="green">
                  {startScheduleTime === "" || endScheduleTime === ""
                    ? ""
                    : getTimeInterval(startScheduleTime, endScheduleTime)}
                </Tag>
              </h5>
            </Col>
          </Row>

          <div className="mt-4 d-flex justify-content-between">
            <div className="">
              <Button
                type="danger"
                htmlType="button"
                onClick={() => handleCreateScheduleTimerCancel()}
              >
                Cancel
              </Button>
            </div>

            <div className="">
              <Button
                type="default"
                className="bg-success text-white"
                htmlType="button"
                onClick={addScheduleTime}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
        </React.Fragment>
    )
}

export default AddTime
