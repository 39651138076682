import * as types from "./constants"

const initialStates = {
    email_service_types:[],
    singel_email_service_type:{},
    loading:false,
    error:null,
    success:false,
}

 const EmailServiceTypeStore = (state = initialStates, action) => {
    //setup journals reducers
   switch (action.type) {
       case types.GET_EMAIL_SERVICE_TYPE :
       return {...state, loading:true}
       case types.GET_EMAIL_SERVICE_TYPE_SUCCESS :
       return {...state, loading:false, email_service_types:action.email_service_types}
       case types.GET_EMAIL_SERVICE_TYPE_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_EMAIL_SERVICE_TYPE :
       return {...state}
       case types.SHOW_EMAIL_SERVICE_TYPE_SUCCESS :
       return {...state, singel_email_service_type:action.singel_email_service_type}
       case types.SHOW_EMAIL_SERVICE_TYPE_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_EMAIL_SERVICE_TYPE :
       return {...state, loading:true, success:false}
       case types.CREATE_EMAIL_SERVICE_TYPE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_EMAIL_SERVICE_TYPE_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_EMAIL_SERVICE_TYPE :
       return {...state, loading:true, success:false}
       case types.UPDATE_EMAIL_SERVICE_TYPE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_EMAIL_SERVICE_TYPE_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_EMAIL_SERVICE_TYPE :
       return {...state, loading:true, success:false}
       case types.DELETE_EMAIL_SERVICE_TYPE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_EMAIL_SERVICE_TYPE_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default EmailServiceTypeStore
