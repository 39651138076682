import * as types from "./constants"

export const getListItem = (list_items) => {
    return {
        type:types.GET_LIST_ITEM,
        payload:list_items
    }
}

export const showListItem = (single_list_item) => {
    return {
        type:types.SHOW_LIST_ITEM,
        payload:single_list_item
    }
}

export const createListItem = (list_items, history) => {
    return {
        type:types.CREATE_LIST_ITEM,
        payload:{list_items, history
    }}
}

export const updateListItem = (list_items) => {
    return {
        type:types.UPDATE_LIST_ITEM,
        payload:list_items
    }
}

export const deleteListItem = (list_items, history) => {
    return {
        type:types.DELETE_LIST_ITEM,
        payload:{list_items, history}
    }
}
