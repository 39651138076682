import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/AuthService';


// Login Redux States
import * as types from './constants';

function* registerUser(action) {
    const {response, error} = yield call(api.register, action.payload);
    if(response) 
    {
        yield put({type: types.REGISTER_USER_SUCCESS, user:response.data});
    } else {
        yield put({type: types.REGISTER_USER_FAILED, error:error.response.data})
    }
 }

function* loginUser(action) {
   const {response, error} = yield call(api.login, action.payload);
   if(response) 
   {
       yield put({type: types.LOGIN_SUCCESS, user:response.data});
   } else {
       yield put({type: types.LOGIN_FAILD, error:error.response.data})
   }
}

function* getUser(action) {
    const {response, error} = yield call(api.user, action.payload);
    if(response) 
    {
        yield put({type: types.GET_USER_SUCCESS, user:response.data});
    } else {
        yield put({type: types.GET_USER_FAILD, error:error.response.data})
    }
 }

 function* getAllUsers(action) {
    const {response, error} = yield call(api.allUsers, action.payload);
    if(response) 
    {
        yield put({type: types.GET_USERS_SUCCESS, users:response.data});
    } else {
        yield put({type: types.GET_USERS_FAILD, error:error.response.data})
    }
 }

 function* showUser(action) {
    const {response, error} = yield call(api.show, action.payload);
    if(response) 
    {
        yield put({type: types.SHOW_USER_SUCCESS, single_user:response.data});
    } else {
        yield put({type: types.SHOW_USER_FAILD, error:error.response.data})
    }
 }


function* forgetPassword(action) {
    const {response, error} = yield call(api.forgetPassword, action.payload);
    if(response) 
    {
        yield put({type: types.FORGET_PASSWORD_SUCCESS, user:response.data});
    } else {
        yield put({type: types.FORGET_PASSWORD_FAILED, error:error.response.data})
    }
 }

 function* resetPassword(action) {
    const {response, error} = yield call(api.resetPassword, action.payload);
    if(response) 
    {
        yield put({type: types.RESET_PASSWORD_SUCCESS, user:response.data});
    } else {
        yield put({type: types.RESET_PASSWORD_FAILED, error:error.response.data})
    }
 }

function* logoutUser(action) {
    const {response, error} = yield call(api.logout, action.payload);
    if(response) 
    {
        yield put({type: types.LOGOUT_USER_SUCCESS, user:response.data});
    } else {
        yield put({type: types.LOGOUT_FAILD, error:error.response.data})
    }
}

function* updateUserProfile(action) {
    const {response, error} = yield call(api.update, action.payload);
    if(response) 
    {
        yield put({type: types.UPDATE_USER_PROFILE_SUCCESS});
    } else {
        yield put({type: types.UPDATE_USER_PROFILE_FAILED, error:error.response.data})
    }
}

function* deleteUser(action) {
    const {response, error} = yield call(api.destroy, action.payload);
    if(response) 
    {
        yield put({type: types.DELETE_USER_SUCCESS});
    } else {
        yield put({type: types.DELETE_USER_FAILD, error:error.response.data})
    }
}

export function* watchUserRegister() {
    yield takeEvery(types.REGISTER_USER, registerUser)
}

export function* watchUserLogin() {
    yield takeEvery(types.LOGIN_USER, loginUser)
}

export function* watchGetUser() {
    yield takeEvery(types.GET_USER, getUser)
}

export function* watchShowUser() {
    yield takeEvery(types.SHOW_USER, showUser)
}

export function* watchGetAllUsers() {
    yield takeEvery(types.GET_USERS, getAllUsers)
}

export function* watchUserLogout() {
    yield takeEvery(types.LOGOUT_USER, logoutUser)
}

export function* watchForgetPassword() {
    yield takeEvery(types.FORGET_PASSWORD, forgetPassword)
}

export function* watchResetPassword() {
    yield takeEvery(types.RESET_PASSWORD, resetPassword)
}

export function* watchUpdateUserProfile() {
    yield takeEvery(types.UPDATE_USER_PROFILE, updateUserProfile)
}

export function* watchDeleteUser() {
    yield takeEvery(types.DELETE_USER, deleteUser)
}

function* AuthSaga() {
    yield all([
        fork(watchUserRegister),
        fork(watchUserLogin),
        fork(watchGetUser),
        fork(watchUserLogout),
        fork(watchResetPassword),
        fork(watchForgetPassword),
        fork(watchUpdateUserProfile),
        fork(watchGetAllUsers),
        fork(watchDeleteUser),
        fork(watchShowUser)
    ]);
}

export default AuthSaga;