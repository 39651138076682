import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/WorkStatusService';


// Board Redux States
import * as types from './constants';

function* getWorkStatus(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_WORK_STATUS_SUCCESS, work_status:response.data});
    } else {
        yield put({type: types.GET_WORK_STATUS_FAILED, error:error});
    }
}

function* showWorkStatus(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_WORK_STATUS_SUCCESS, single_work_status:response.data});
    } else {
        yield put({type: types.SHOW_WORK_STATUS_FAILED, error:error});
    }
}

function* createWorkStatus(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_WORK_STATUS_SUCCESS,});
    } else {
        yield put({type: types.CREATE_WORK_STATUS_FAILED, error:error});
    }
}

function* updateWorkStatus(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_WORK_STATUS_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_WORK_STATUS_FAILED, error:error});
    }
}

function* deleteWorkStatus(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_WORK_STATUS_SUCCESS, });
    } else {
        yield put({type: types.DELETE_WORK_STATUS_FAILED, error:error});
    }
}




function* watchGetWorkStatus() 
{
    yield takeLatest(types.GET_WORK_STATUS, getWorkStatus)
}

function* watchShowWorkStatus() 
{
    yield takeEvery(types.SHOW_WORK_STATUS, showWorkStatus)
}

function* watchCreateWorkStatus() 
{
    yield takeEvery(types.CREATE_WORK_STATUS, createWorkStatus)
}

function* watchUpdateWorkStatus() 
{
    yield takeEvery(types.UPDATE_WORK_STATUS, updateWorkStatus)
}

function* watchDeleteWorkStatus() 
{
    yield takeEvery(types.DELETE_WORK_STATUS, deleteWorkStatus)
}

function* workStatusSaga(){
    yield all([
        fork(watchGetWorkStatus),
        fork(watchShowWorkStatus),
        fork(watchCreateWorkStatus),
        fork(watchUpdateWorkStatus),
        fork(watchDeleteWorkStatus),
    ])
}

export default workStatusSaga;