import * as types from "./constants"

const initialStates = {
    notebook_board:[],
    single_notebook_board:{},
    notebook_notes:[],
    single_notebook_note:{},
    loading:false,
    error:null,
    success:false,
}

export const NotebookBoardStore = (state = initialStates, action) => {
    //setup notebook_board reducers
   switch (action.type) {
       case types.GET_NOTEBOOK_BOARD :
       return {...state, loading:true}
       case types.GET_NOTEBOOK_BOARD_SUCCESS :
       return {...state, loading:false, notebook_board:action.notebook_board}
       case types.GET_NOTEBOOK_BOARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_NOTEBOOK_BOARD :
       return {...state}
       case types.SHOW_NOTEBOOK_BOARD_SUCCESS :
       return {...state, single_notebook_board:action.single_notebook_board}
       case types.SHOW_NOTEBOOK_BOARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_NOTEBOOK_BOARD :
       return {...state, loading:true, success:false}
       case types.CREATE_NOTEBOOK_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_NOTEBOOK_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_NOTEBOOK_BOARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_NOTEBOOK_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_NOTEBOOK_BOARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_NOTEBOOK_BOARD_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_NOTEBOOK_BOARD_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_NOTEBOOK_BOARD_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_NOTEBOOK_BOARD :
       return {...state, loading:true, success:false}
       case types.DELETE_NOTEBOOK_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_NOTEBOOK_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}


//setup notebook_notes reducers
       case types.GET_NOTEBOOK_NOTE :
       return {...state, loading:true}
       case types.GET_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, notebook_notes:action.notebook_notes}
       case types.GET_NOTEBOOK_NOTE_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_NOTEBOOK_NOTE :
       return {...state}
       case types.SHOW_NOTEBOOK_NOTE_SUCCESS :
       return {...state, single_notebook_note:action.single_notebook_note}
       case types.SHOW_NOTEBOOK_NOTE_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_NOTEBOOK_NOTE :
       return {...state, loading:true, success:false}
       case types.CREATE_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_NOTEBOOK_NOTE_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_NOTEBOOK_NOTE :
       return {...state, loading:true, success:false}
       case types.UPDATE_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_NOTEBOOK_NOTE_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_NOTEBOOK_NOTE :
       return {...state, loading:true, success:false}
       case types.DELETE_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_NOTEBOOK_NOTE_FAILED :
       return {...state, success:true, loading:false, error:action.error}
 
       default:
           return state;
   }
}


export const NotebookNoteStore = (state = initialStates, action) => {
    //setup notebook_board reducers
   switch (action.type) {
//setup notebook_notes reducers
       case types.GET_NOTEBOOK_NOTE :
       return {...state, loading:true}
       case types.GET_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, notebook_notes:action.notebook_notes}
       case types.GET_NOTEBOOK_NOTE_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_NOTEBOOK_NOTE :
       return {...state}
       case types.SHOW_NOTEBOOK_NOTE_SUCCESS :
       return {...state, single_notebook_note:action.single_notebook_note}
       case types.SHOW_NOTEBOOK_NOTE_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_NOTEBOOK_NOTE :
       return {...state, loading:true, success:false}
       case types.CREATE_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_NOTEBOOK_NOTE_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_NOTEBOOK_NOTE :
       return {...state, loading:true, success:false}
       case types.UPDATE_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_NOTEBOOK_NOTE_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_NOTEBOOK_NOTE_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_NOTEBOOK_NOTE_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_NOTEBOOK_NOTE_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_NOTEBOOK_NOTE_NEXT_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_NOTEBOOK_NOTE_NEXT_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_NOTEBOOK_NOTE :
       return {...state, loading:true, success:false}
       case types.DELETE_NOTEBOOK_NOTE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_NOTEBOOK_NOTE_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       default:
           return state;
   }
}
