import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";

export const api = {
  registerDevice(data) {
    axios.defaults.withCredentials = true;
    console.log(data);
    return authAxios
      .post("/device", data)

      .then((response) => {
        // successNotify(response.data.message);

        message.success(response.data.message.message, 4);
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }

        return { error };
      });
  },
  show(data) {
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/device/${data}`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }

        return { error };
      });
  },

  getTextMessage(data) {
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/text-message/${data}`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }

        return { error };
      });
  },

  showTextMessage(data) {
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/show-text-message/${data}`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message === "Unauthenticated.");
        // if(error.response.status === 401){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear();
        //   window.location = "/auth/login";
        // }

        return { error };
      });
  },
  sendTextMessage(data){
 
    axios.defaults.withCredentials = true;
        return authAxios
          .post("/text-message",data)
          .then((response) => {
            // successNotify(response.data.message);
      
            // message.success(response.data.message.message, 4);
            // window.open(`/project/${response.data.data.id}`, "_blank", "titlebar=no,toolbar=no,fullscreen=yes")
            // data.history.push(``);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
  }
};
