export const GET_DATABASE_BACKUP = "GET_DATABASE_BACKUP"
export const GET_DATABASE_BACKUP_SUCCESS = "GET_DATABASE_BACKUP_SUCCESS"
export const GET_DATABASE_BACKUP_FAILED = "GET_DATABASE_BACKUP_FAILED"

export const GET_RESTORE_DATABASE_BACKUP = "GET_RESTORE_DATABASE_BACKUP"
export const GET_RESTORE_DATABASE_BACKUP_SUCCESS = "GET_RESTORE_DATABASE_BACKUP_SUCCESS"
export const GET_RESTORE_DATABASE_BACKUP_FAILED = "GET_RESTORE_DATABASE_BACKUP_FAILED"

export const RESTORE_DATABASE_BACKUP = "RESTORE_DATABASE_BACKUP"
export const RESTORE_DATABASE_BACKUP_SUCCESS = "RESTORE_DATABASE_BACKUP_SUCCESS"
export const RESTORE_DATABASE_BACKUP_FAILED = "RESTORE_DATABASE_BACKUP_FAILED"

export const MAKE_DATABASE_BACKUP = "MAKE_DATABASE_BACKUP"
export const MAKE_DATABASE_BACKUP_SUCCESS = "MAKE_DATABASE_BACKUP_SUCCESS"
export const MAKE_DATABASE_BACKUP_FAILED = "MAKE_DATABASE_BACKUP_FAILED"

export const ENABLE_MAINTENANCE = "ENABLE_MAINTENANCE"
export const ENABLE_MAINTENANCE_SUCCESS = "ENABLE_MAINTENANCE_SUCCESS"
export const ENABLE_MAINTENANCE_FAILED = "ENABLE_MAINTENANCE_FAILED"