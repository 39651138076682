import * as types from "./constants"

export const getBoard = (board) => {
    return {
        type:types.GET_BOARD,
        payload:board
    }
}

export const showBoard = (single_board, url) => {
    return {
        type:types.SHOW_BOARD,
        payload:single_board, url
    }
}

export const createBoard = (board,url) => {
    return {
        type:types.CREATE_BOARD,
        payload:{board,url}
    }
}

export const updateBoard = (board,url) => {
    return {
        type:types.UPDATE_BOARD,
        payload:{board,url}
    }
}


export const updateBoardIndex = (index, url) => {
    return {
        type:types.UPDATE_BOARD_INDEX,
        payload:{index, url}
    }
}

export const updateCardIndex = (index, url) => {
    return {
        type:types.UPDATE_CARD_INDEX,
        payload:{index, url}
    }
}

export const updateCardNextIndex = (index, url) => {
    return {
        type:types.UPDATE_CARD_NEXT_INDEX,
        payload:{index, url}
    }
}

export const deleteBoard = (board,url) => {
    return {
        type:types.DELETE_BOARD,
        payload:{board,url}
    }
}



export const getCard = (card, url, query) => {
    return {
        type:types.GET_CARD,
        payload:{card, url, query}
    }
}

export const showCard = (single_card, url) => {
    return {
        type:types.SHOW_CARD,
        payload:{single_card, url}
    }
}

export const createCard = (card, url) => {
    return {
        type:types.CREATE_CARD,
        payload:{card, url}
    }
}

export const updateCard = (card, url) => {
    return {
        type:types.UPDATE_CARD,
        payload:{card, url}
    }
}

export const deleteCard = (card, url) => {
    return {
        type:types.DELETE_CARD,
        payload:{card, url}
    }
}