import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/teams`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message);
            // if(error.response.data.message === "Unauthenticated."){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear(); 
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    teamUser(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .get(`/team-users`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message);
          // if(error.response.data.message === "Unauthenticated."){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear(); 
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },

    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/teams/${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    store(data){
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/teams",data.teams)
          .then((response) => {
            // successNotify(response.data.message);
      
            message.success(response.data.message.message,1);
            // data.history.push(`/app/team/${response.data.data.id}`);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/teams/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    updateActiveTeam(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .post(`/sessions`, data)
        .then((response) => {
          // message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },

    destroy(data){
     
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/teams/${data}`)
          .then((response) => {
            message.success(response.data.message, 2);
            
            // if(response.status === 200){
            //   data.history.push('/app/home');
            // }
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
}





export const  members_api = {
  index(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .get(`/team-members?team_leader_id=${data}`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message);
          // if(error.response.data.message === "Unauthenticated."){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear(); 
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  teamAdmin(data){
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/team-admin`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        // errorNotify(error.response.data.message);
        // if(error.response.data.message === "Unauthenticated."){
        //   alert("Session Expire Login Again!")
        //   localStorage.clear(); 
        //   window.location = "/auth/login";
        // }
        
        return { error };
      });
},
  
  store(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .post("/team-members", data)
        .then((response) => {
          // successNotify(response.data.message);
    
          message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  update(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .put(`/team-members/${data.id}`, data)
        .then((response) => {
          message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  destroy(data){
    
      axios.defaults.withCredentials = false;
      return authAxios
        .delete(`/team-members/${data}`)
        .then((response) => {
          message.success(response.data.message, 2);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
}

export const  team_role_api = {
  index(data){
      axios.defaults.withCredentials = false;

      return authAxios
        .get(`/team-roles`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message);
          // if(error.response.data.message === "Unauthenticated."){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear(); 
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  
  store(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .post("/team-roles", data)
        .then((response) => {
          // successNotify(response.data.message);
    
          // message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
         
          if(error.response.status === 404){
            message.error(error.response.data.error);
          }
          
          return { error };
        });
  },
  update(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .put(`/team-roles/${data.id}`, data)
        .then((response) => {
          message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  destroy(data){
    
      axios.defaults.withCredentials = false;
      return authAxios
        .delete(`/team-roles/${data}`)
        .then((response) => {
          message.success(response.data.message, 2);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
}
