import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/ScheduleService';


// Board Redux States
import * as types from './constants';

function* getSchedule(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_SCHEDULE_SUCCESS, schedules:response.data});
    } else {
        yield put({type: types.GET_SCHEDULE_FAILED, error:error});
    }
}

function* getRangeSchedule(action)
{
    const {response, error} = yield call(api.range, action.payload)
    if(response)
    {
        yield put({type: types.GET_SCHEDULE_RANGE_SUCCESS, schedules_range:response.data});
    } else {
        yield put({type: types.GET_SCHEDULE_RANGE_FAILED, error:error});
    }
}

function* showSchedule(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_SCHEDULE_SUCCESS, single_schedule:response.data});
    } else {
        yield put({type: types.SHOW_SCHEDULE_FAILED, error:error});
    }
}

function* createSchedule(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_SCHEDULE_SUCCESS, list:response.data});
    } else {
        yield put({type: types.CREATE_SCHEDULE_FAILED, error:error});
    }
}

function* updateSchedule(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_SCHEDULE_SUCCESS, list:response.data});
    } else {
        yield put({type: types.UPDATE_SCHEDULE_FAILED, error:error});
    }
}

function* deleteSchedule(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_SCHEDULE_SUCCESS, list:response.data});
    } else {
        yield put({type: types.DELETE_SCHEDULE_FAILED, error:error});
    }
}




function* watchGetSchedule() 
{
    yield takeLatest(types.GET_SCHEDULE, getSchedule)
}

function* watchGetRangeSchedule() 
{
    yield takeEvery(types.GET_SCHEDULE_RANGE, getRangeSchedule)
}

function* watchShowSchedule() 
{
    yield takeEvery(types.SHOW_SCHEDULE, showSchedule)
}

function* watchCreateSchedule() 
{
    yield takeEvery(types.CREATE_SCHEDULE, createSchedule)
}

function* watchUpdateSchedule() 
{
    yield takeEvery(types.UPDATE_SCHEDULE, updateSchedule)
}

function* watchDeleteSchedule() 
{
    yield takeEvery(types.DELETE_SCHEDULE, deleteSchedule)
}




function* scheduleSaga(){
    yield all([
        fork(watchGetSchedule),
        fork(watchGetRangeSchedule),
        fork(watchShowSchedule),
        fork(watchCreateSchedule),
        fork(watchUpdateSchedule),
        fork(watchDeleteSchedule),

    ])
}

export default scheduleSaga;