export const GET_SCHEDULE = "GET_SCHEDULE"
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS"
export const GET_SCHEDULE_FAILED = "GET_SCHEDULE_FAILED"

export const GET_SCHEDULE_RANGE = "GET_SCHEDULE_RANGE"
export const GET_SCHEDULE_RANGE_SUCCESS = "GET_SCHEDULE_RANGE_SUCCESS"
export const GET_SCHEDULE_RANGE_FAILED = "GET_SCHEDULE_RANGE_FAILED"

export const SHOW_SCHEDULE = "SHOW_SCHEDULE"
export const SHOW_SCHEDULE_SUCCESS = "SHOW_SCHEDULE_SUCCESS"
export const SHOW_SCHEDULE_FAILED = "SHOW_SCHEDULE_FAILED"

export const CREATE_SCHEDULE = "CREATE_SCHEDULE"
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS"
export const CREATE_SCHEDULE_FAILED = "CREATE_SCHEDULE_FAILED"

export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE"
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS"
export const UPDATE_SCHEDULE_FAILED = "UPDATE_SCHEDULE_FAILED"

export const DELETE_SCHEDULE = "DELETE_SCHEDULE"
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS"
export const DELETE_SCHEDULE_FAILED = "DELETE_SCHEDULE_FAILED"
