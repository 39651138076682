import React, {useRef, useCallback, useEffect, useState} from 'react'
import {
    Input,
    Avatar,
    Spin,
  } from "antd";
  import {
    CommentOutlined,
    UserOutlined,
  } from "@ant-design/icons";
import AvatarStatus from '../AvatarStatus';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import { createComment, getComment } from 'redux/actions';

function Comments(props) {
    const dispatch = useDispatch();
    const [isCancelled, setIsCancelled] = useState(false);
    const {user, team_users, cardData, url, cardIdString} = props;

    const commentInput = useRef();

    const getData = useCallback(() => {
      if (cardData?.id !== undefined) {
            
        const getCommentData = async() => {
          await dispatch(getComment(cardData?.id, url, cardIdString));
        }
        getCommentData();
      }
    }, [cardData?.id])

      useEffect(() => {
       
        if(!isCancelled){
          getData();
        }else{
          getData();
        }
      
        return () => {
          setIsCancelled(true);
        }
      }, [getData]);
    
      const comments = useSelector((state) => state.CommentStore);
      const comments_data = comments?.comments;
      const comment_success = comments?.success;
      const loading = comments?.loading;
    
      useEffect(() => {
        if(!isCancelled){
          if (comment_success === true) {
            const getCommentData = async() => {
              await dispatch(getComment(cardData?.id, url, cardIdString));
            }

            getCommentData();
            
          }
        }else{
          if (comment_success === true) {
            const getCommentData = async() => {
              await dispatch(getComment(cardData?.id, url, cardIdString));
            }

            getCommentData();
            
          }
        }
       
      }, [comment_success]);

    const submitComment = () => {
        const message = commentInput.current.state.value;
        const comment = {
          user_id: user?.id,
          photo: user?.photo,
          card_id: cardData?.id,
          comments: message,
          username: user?.username,
        };
    
        dispatch(createComment(comment, url));
        commentInput.current.state.value = "";
      };

    return (
        <React.Fragment>
                  {comments_data?.length >= 0 ? (
        <div className="d-flex">
          <div className="mr-3 font-size-md">
            <CommentOutlined />
          </div>
          <div className="w-100">
            <h4 className="mb-3">Comments ({comments_data.length})</h4>
            {loading ? (
              <div className="text-center mt-4">
                <Spin />
              </div>
            ) : (
              <>
                {comments_data?.map((elm) => (
                  <div className="mb-3 d-flex" key={elm.id}>
                    <div className="mt-2">
                      {user?.id === elm?.user_id ? (
                        <>
                          {user?.photo !== null ? (
                            <AvatarStatus
                              src={user?.photo}
                              // subTitle="Kofi Boakye"
                              size={30}
                            />
                          ) : (
                            <AvatarStatus
                              // icon={<UserOutlined />}
                              text={user?.initials}
                              type="blue"
                              // subTitle="Kofi Boakye"
                              size={30}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {team_users?.filter((x) => x.id === elm?.user_id)?.[0]
                            ?.photo !== null ? (
                            <AvatarStatus
                              src={
                                team_users?.filter(
                                  (x) => x.id === elm?.user_id
                                )?.[0]?.photo
                              }
                              // subTitle="Kofi Boakye"
                              size={30}
                            />
                          ) : (
                            <AvatarStatus
                              //  icon={<UserOutlined />}
                              text={
                                team_users?.filter(
                                  (x) => x.id === elm?.user_id
                                )?.[0]?.initials
                              }
                              type="blue"
                              // subTitle="Kofi Boakye"
                              size={30}
                            />
                          )}
                        </>
                      )}

                      {/* {elm?.photo ? (
                        <Avatar src={elm?.photo} />
                      ) : (
                        <AvatarStatus icon={<UserOutlined />} type="cyan" />
                      )} */}

                      {/* <Avatar src={elm.src}/> */}
                    </div>
                    <div className="ml-2 bg-gray-lightest p-3 rounded w-100">
                      <div className="d-flex align-items-center mb-2">
                        <h4 className="mb-0">
                          {elm.user_id === user?.id
                            ? user?.fullname
                            : team_users?.filter(
                                (x) => x.id === elm?.user_id
                              )?.[0]?.fullname}
                        </h4>
                        <span className="mx-1"> | </span>
                        <span className="font-size-sm text-dark">
                          {moment(elm.date).format("DD-MMM-YYYY, h:mm a")}
                        </span>
                      </div>
                      <p className="mb-0">{elm.comments}</p>
                    </div>
                  </div>
                ))}
              </>
            )}

            <div className="mb-3 d-flex">
              {user?.photo ? (
                <Avatar src={user?.photo} />
              ) : (
                <AvatarStatus icon={<UserOutlined />} type="cyan" />
              )}
              {/* <Avatar src="/img/avatars/thumb-1.jpg"/> */}
              <div className="ml-2 bg-gray-lightest p-3 rounded w-100">
                <Input
                  ref={commentInput}
                  placeholder="Write comment"
                  suffix={
                    <div
                      onClick={() => submitComment()}
                      className="cursor-pointer font-weight-semibold text-primary"
                    >
                      post
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
        </React.Fragment>
    )
}

export default Comments
