import * as types from "./constants"

const initialStates = {
    list_board:[],
    single_list_board:{},
    list_card:[],
    single_list_card:{},
    loading:false,
    error:null,
    success:false,
}

export const ListBoardStore = (state = initialStates, action) => {
    //setup list_board reducers
   switch (action.type) {
       case types.GET_LIST_BOARD :
       return {...state, loading:true}
       case types.GET_LIST_BOARD_SUCCESS :
       return {...state, loading:false, list_board:action.list_board}
       case types.GET_LIST_BOARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_LIST_BOARD :
       return {...state}
       case types.SHOW_LIST_BOARD_SUCCESS :
       return {...state, single_list_board:action.single_list_board}
       case types.SHOW_LIST_BOARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_LIST_BOARD :
       return {...state, loading:true, success:false}
       case types.CREATE_LIST_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_LIST_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_LIST_BOARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_LIST_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_LIST_BOARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_LIST_BOARD_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_LIST_BOARD_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_LIST_BOARD_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_LIST_BOARD :
       return {...state, loading:true, success:false}
       case types.DELETE_LIST_BOARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_LIST_BOARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}


//setup list_card reducers
       case types.GET_LIST_CARD :
       return {...state, loading:true}
       case types.GET_LIST_CARD_SUCCESS :
       return {...state, loading:false, list_card:action.list_card}
       case types.GET_LIST_CARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_LIST_CARD :
       return {...state}
       case types.SHOW_LIST_CARD_SUCCESS :
       return {...state, single_list_card:action.single_list_card}
       case types.SHOW_LIST_CARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_LIST_CARD :
       return {...state, loading:true, success:false}
       case types.CREATE_LIST_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_LIST_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_LIST_CARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_LIST_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_LIST_CARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_LIST_CARD :
       return {...state, loading:true, success:false}
       case types.DELETE_LIST_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_LIST_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

           
          
       default:
           return state;
   }
}


export const ListCardStore = (state = initialStates, action) => {
    //setup list_board reducers
   switch (action.type) {
//setup list_card reducers
       case types.GET_LIST_CARD :
       return {...state, loading:true}
       case types.GET_LIST_CARD_SUCCESS :
       return {...state, loading:false, list_card:action.list_card}
       case types.GET_LIST_CARD_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_LIST_CARD :
       return {...state}
       case types.SHOW_LIST_CARD_SUCCESS :
       return {...state, single_list_card:action.single_list_card}
       case types.SHOW_LIST_CARD_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_LIST_CARD :
       return {...state, loading:true, success:false}
       case types.CREATE_LIST_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_LIST_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_LIST_CARD :
       return {...state, loading:true, success:false}
       case types.UPDATE_LIST_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_LIST_CARD_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_LIST_CARD_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_LIST_CARD_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_LIST_CARD_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_LIST_CARD_NEXT_INDEX :
       return {...state, loading:true, success:false}
       case types.UPDATE_LIST_CARD_NEXT_INDEX_SUCCESS:
       return {...state, loading:false, success:true}
       case types.UPDATE_LIST_CARD_NEXT_INDEX_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_LIST_CARD :
       return {...state, loading:true, success:false}
       case types.DELETE_LIST_CARD_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_LIST_CARD_FAILED :
       return {...state, success:true, loading:false, error:action.error}

           
          
       default:
           return state;
   }
}
