export const GET_JOURNAL = "GET_JOURNAL"
export const GET_JOURNAL_SUCCESS = "GET_JOURNAL_SUCCESS"
export const GET_JOURNAL_FAILED = "GET_JOURNAL_FAILED"

export const SHOW_JOURNAL = "SHOW_JOURNAL"
export const SHOW_JOURNAL_SUCCESS = "SHOW_JOURNAL_SUCCESS"
export const SHOW_JOURNAL_FAILED = "SHOW_JOURNAL_FAILED"

export const CREATE_JOURNAL = "CREATE_JOURNAL"
export const CREATE_JOURNAL_SUCCESS = "CREATE_JOURNAL_SUCCESS"
export const CREATE_JOURNAL_FAILED = "CREATE_JOURNAL_FAILED"

export const UPDATE_JOURNAL = "UPDATE_JOURNAL"
export const UPDATE_JOURNAL_SUCCESS = "UPDATE_JOURNAL_SUCCESS"
export const UPDATE_JOURNAL_FAILED = "UPDATE_JOURNAL_FAILED"

export const DELETE_JOURNAL = "DELETE_JOURNAL"
export const DELETE_JOURNAL_SUCCESS = "DELETE_JOURNAL_SUCCESS"
export const DELETE_JOURNAL_FAILED = "DELETE_JOURNAL_FAILED"
