import React, {useState} from 'react'
import AvatarStatus from '../AvatarStatus';
import {Divider, Row, Col, Checkbox, Tooltip} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    showProjectCard,
    createAssignedMembers,
    showProject,
    getTeam,
  } from "redux/actions";
  import {
    CloseOutlined,
    PlusOutlined,
  } from "@ant-design/icons";

function AssignTeamMembers(props) {

    const dispatch = useDispatch();
    const [isCancelled, setIsCancelled] = useState(false);

    const {cardData, card_data, listItem} = props;
    const project_card = useSelector((state) => state.ProjectStore);
    const assign_users = project_card?.project_card?.assign_users;
    
    const team_item = useSelector((state) => state.TeamStore?.teams);
  
    const teams = team_item?.all_teams;
    const team_role = team_item?.team_role;
    const user = team_item?.user;

    const project_id = listItem;

  const [enableAddTeamMember, setEnableAddTeamMember] = useState(false);
  const [assignUsers, setAssignUser] = useState([]);

  
  const onChangeTeamMember = (data) => {
    // assign team members
    // dispatch(createAssignedMembers(data));

    if(data.checked === true)
    {
          setAssignUser((assignUsers) => [...assignUsers, data]);
          dispatch(createAssignedMembers(data));

    }else if(data.checked === false){
        let newAssignUsers = assignUsers?.filter(x => x.user_id !== data?.user_id);
         setAssignUser(newAssignUsers);
         dispatch(createAssignedMembers(data));
    }

  };

  React.useEffect(() => {
    setAssignUser([]);
    if(card_data?.assign_user?.length > 0){
      setAssignUser(card_data?.assign_user);
    }
  }, [card_data])


  const assign_user_success = useSelector((state) => state.AssignUserStore.success);
  const getData = React.useMemo(() => {
    if(assign_user_success !== true) return;
    dispatch(getTeam());
    dispatch(showProjectCard(cardData?.id));
  }, [assign_user_success])

  React.useEffect(() => {
  if(!getData) return;
  getData();
   
  }, [getData])


  const team_owner = teams?.filter((x) => x.id === cardData?.team_id)?.[0]?.team_owner;

    return (
        <React.Fragment>
              <div className="assign__user__menu__box">
            <h5 className="mt-5">Assigned Team Members</h5>

            <div className="assign__user__main__box">
              {/* {console.log(card_data?.assign_user)} */}

              {assignUsers?.map((elm, index) => (
                <Tooltip placement="top" title={elm?.fullname} key={index}>
                  <div
                    className="avatar__box__view"
                    onClick={() =>
                      team_role === 1 || team_owner === user?.id ? setEnableAddTeamMember(true) : ""
                    }
                  >
                    {elm?.photo !== null ? (
                      <AvatarStatus src={elm?.photo} size={30} />
                    ) : (
                      <AvatarStatus text={elm.initials} type="blue" size={30} />
                    )}
                  </div>
                </Tooltip>
              ))}
           
              {team_role === 1 || team_owner === user?.id ? (
                <Tooltip
                  placement="top"
                  title={`Add members`}
                  onClick={() =>
                    team_role === 1 || team_owner === user?.id ? setEnableAddTeamMember(true) : ""
                  }
                >
                  <div className="avatar__box__view">
                    <AvatarStatus
                      icon={<PlusOutlined />}
                      name=""
                      type="blue"
                      subTitle=""
                      size={30}
                    />
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            {enableAddTeamMember === true ? (
              <div className="dropdown__box ">
                <div className="heading__box">
                  <span>Team Member</span>
                  <CloseOutlined
                    onClick={() => {
                      setEnableAddTeamMember(false);
                    }}
                  />
                </div>

                <Divider className="dropdown__divider" />
                <Row>
          
                  {teams
                    ?.filter((x) => x.id === cardData?.team_id)?.[0]
                    ?.team_members?.map((elm, index) => (
                      <Col span={24} key={index} className="mb-2">
                        <Checkbox

                          defaultChecked={
                            assign_users?.filter((x) => x.user_id === elm?.user_id)?.[0]?.checked === 1 ? true : false
                          }
                          onChange={(e) => {
                            onChangeTeamMember({
                              id: elm?.id,
                              member_id: elm?.member_id,
                              team_id: elm?.team_id,
                              firstname: elm?.firstname,
                              lastname: elm?.lastname,
                              initials: elm?.initials,
                              photo: elm?.photo,
                              fullname: elm?.fullname,
                              project_card_id: cardData?.id,
                              project_id: project_id?.id,
                              user_id: elm?.user_id,
                              isCheckedItem:
                              assign_users?.filter((x) => x.user_id === elm?.user_id)?.[0]?.id === undefined
                                  ? null
                                  : assign_users?.filter((x) => x.user_id === elm?.user_id)?.[0]?.id,
                              checked: e.target.checked,
                            });
                          }}
                          // value={index}
                        >
                          {elm.photo !== null ? (
                            <AvatarStatus
                              src={elm?.photo}
                              name={elm?.fullname}
                              // subTitle="Kofi Boakye"
                              size={25}
                            />
                          ) : (
                            <AvatarStatus
                              //  icon={<UserOutlined />}
                              text={elm?.initials}
                              name={elm?.fullname}
                              type="blue"
                              // subTitle="Kofi Boakye"
                              size={25}
                            />
                          )}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </div>
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
    )
}

export default AssignTeamMembers
