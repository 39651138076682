import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/task-lists?${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message);
            // if(error.response.data.message === "Unauthenticated."){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear(); 
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/${data.url}?object_id=${data.single_task_list?.object_id}&user_id=${data.single_task_list?.user_id}&token_id=${data.single_task_list?.token_id}`)
          .then((response) => {          
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    showTaskListCard(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .get(`/tasklist-cards/${data}`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
    store(data){
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/task-lists",data.task_list)
          .then((response) => {
            // successNotify(response.data.message);
      
            message.success(response.data.message.message, 4);
            data.history.push(`/app/task-list/${response.data.data.id}`);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/task-lists/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    destroy(data){
    
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/task-lists/${data.task_list}`)
          .then((response) => {
            message.success(response.data.message, 2);
            
            if(response.status === 200){
              data.history.push('/app/home');
            }
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },

    checkListIndex(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .get(`/project-checklist`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message);
          // if(error.response.data.message === "Unauthenticated."){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear(); 
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  checkListShow(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .get(`/tasklist-checklist/${data}`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  checkListStore(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .post("/tasklist-checklist",data.task_list_check_list)
        .then((response) => {
          // successNotify(response.data.message);
    
          message.success(response.data.message.message, 4);
          data.history.push(`/app/project/${response.data.data.id}`);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  checkListUpdate(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .put(`/tasklist-checklist/${data.id}`, data)
        .then((response) => {
          message.success(response.data.message, 4);
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
  checkListDestroy(data){
  
      axios.defaults.withCredentials = false;
      return authAxios
        .delete(`/tasklist-checklist/${data.task_list_check_list}`)
        .then((response) => {
          message.success(response.data.message, 2);
          
          if(response.status === 200){
            data.history.push('/app/project');
          }
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },
}

