import React, { Component } from 'react'
import MailData from 'assets/data/mail.data.json';
import { ReplySVG } from 'assets/svg/icon';
import { labels, getFileType } from './MailLabels';
import { BsForward } from "react-icons/bs";
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { Tooltip, Popconfirm } from 'antd';
import { 
	LeftCircleOutlined, 
	StarOutlined, 
	DeleteOutlined, 
	StarFilled, 
	DownloadOutlined,
	MailOutlined ,
	QuestionCircleOutlined
} from '@ant-design/icons';
import CustomIcon from 'components/util-components/CustomIcon'
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { deleteSendEmail, showSendEmail, updateSendEmail } from "redux/actions";
import FowardEmail from './FowardEmail';


const SentEmailDetail = (props) => {

	// var pathArray = window.location.pathname.split("/");
	// var object_id = pathArray[3];

	const {handleSwitchPage, id} = props;

	const [details, setDetail] = React.useState({});
	const [starred, setStarred] = React.useState(false);
	const [attachment, setAttachment] = React.useState([]);
	const getId = localStorage.getItem("send_email_id");

	  //   get dispatch function
	const dispatch = useDispatch();

	  //   create function to get emails from api
	  var getEmails = React.useCallback(() => {
		if(getId === undefined) return;
		dispatch(showSendEmail(getId));
	  }, [dispatch, getId]);
	
	    // run get email function anytime email component render
	  React.useEffect(() => {
		getEmails();
	  }, [getEmails]);
	
	const emails = useSelector(state => state.EmailStore?.send_emails?.data);

	React.useEffect(() => {
		if(emails !== undefined) {
			// const { category, id } = props.match.params
			const currentId = parseInt(getId)
			let data = []
			// if(labels.includes(category)) {
			// 	data =emails?.filter(elm => elm.id === currentId)
			// } else {
				
			// }
			data = emails?.filter(elm => elm.id === currentId)
			const res = data[0]
			setDetail(res);
			setStarred(res?.e_star === 0 ? false : true);
			setAttachment(res?.email_message_attachments);
		}
	}, [emails, getId])


	const [enableSaveStar, setEnableStarEmail] = React.useState(false);
	const starEmail = React.useMemo(() => {
		if(enableSaveStar === false) return;
		if(starred === undefined) return;
		let data = details;
			data.e_star = starred;
		dispatch(updateSendEmail(data));
		
	}, [starred])

	React.useEffect(() => {
		if(starEmail === undefined) return;
		(async() => {
			
		   await starEmail();
		  
		})()
	}, [starEmail])
	const tick = () => {
	setEnableStarEmail(true);
	setStarred(!starred);
	}

	const back = () => {
		handleSwitchPage('sent')
		// props.history.goBack()
	}

	const [isModalVisible, setIsModalVisible] = React.useState(false);

	const showModal = () => {
	setIsModalVisible(true);
	};

	function deleteEmailConfirm (id)
	{
	  dispatch(deleteSendEmail(id))
	  handleSwitchPage("sent");
	}
	
	function cancel(e) {
	  // console.log(e);
	  // message.error('Click on No');
	}

		return (
			<div className="mail-detail">
					<FowardEmail id={id} email={details} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>
				<div className="d-lg-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center mb-3">
						<div className="font-size-md mr-3" onClick={()=> {back()}}>
							<LeftCircleOutlined className="mail-detail-action-icon font-size-md ml-0" />
						</div>
						<AvatarStatus icon={<MailOutlined />} name={details?.e_personal} subTitle={`To: ${details?.e_to}`}/>
					</div>
					<div className="mail-detail-action mb-3">
						<span className="mr-2 font-size-md">{moment(details?.e_date).format('MMMM Do YYYY, h:mm a')}</span>
						{/* <Tooltip title="Reply">
							<CustomIcon className="mail-detail-action-icon" svg={ReplySVG} />
						</Tooltip> */}
						<Tooltip title="Star" onClick={()=>{tick()}}>
							{starred? <StarFilled className="mail-detail-action-icon star checked" /> : <StarOutlined className="mail-detail-action-icon star" />}
						</Tooltip>
						{details?.attachments?.length > 0 ? <Tooltip title="Download Attachment"><DownloadOutlined className="mail-detail-action-icon"/></Tooltip> : null}
						<Tooltip title="Delete">
						<Popconfirm title="Are you sure？"
            cancelText="No"
            okText="Yes"
            onConfirm={() => deleteEmailConfirm(details?.id)}
            onCancel={cancel}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
							<DeleteOutlined className="mail-detail-action-icon"/>
							</Popconfirm>
						</Tooltip>

						<Tooltip title="Forward" onClick={showModal}>
						<div className="mail-detail-action-icon">
						<BsForward />
						</div>	
						</Tooltip>
					</div>
				</div>
				<div className="mail-detail-content">
					<h3 className="mb-4">{details?.e_subject}</h3>

					<div dangerouslySetInnerHTML={{ __html: details?.e_body }} />
					
					<div className="mail-detail-attactment">
						{
							details?.attachments?.map( (elm, i) => (
								<div className="mail-detail-attactment-item" key={`attachment-file-${i}`}>
									<span>{getFileType(elm.a_type)}</span>
									<div className="ml-2">
										<div>{elm.a_name}</div>
										<div className="text-muted font-size-sm">{elm.size}</div>
									</div>
								</div>
							))
						}
					</div>
				</div>
			</div>
		)
	}


export default SentEmailDetail
