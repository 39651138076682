export const GET_JOURNAL_BOARD = "GET_JOURNAL_BOARD"
export const GET_JOURNAL_BOARD_SUCCESS = "GET_JOURNAL_BOARD_SUCCESS"
export const GET_JOURNAL_BOARD_FAILED = "GET_JOURNAL_BOARD_FAILED"

export const SHOW_JOURNAL_BOARD = "SHOW_JOURNAL_BOARD"
export const SHOW_JOURNAL_BOARD_SUCCESS = "SHOW_JOURNAL_BOARD_SUCCESS"
export const SHOW_JOURNAL_BOARD_FAILED = "SHOW_JOURNAL_BOARD_FAILED"

export const CREATE_JOURNAL_BOARD = "CREATE_JOURNAL_BOARD"
export const CREATE_JOURNAL_BOARD_SUCCESS = "CREATE_JOURNAL_BOARD_SUCCESS"
export const CREATE_JOURNAL_BOARD_FAILED = "CREATE_JOURNAL_BOARD_FAILED"

export const UPDATE_JOURNAL_BOARD = "UPDATE_JOURNAL_BOARD"
export const UPDATE_JOURNAL_BOARD_SUCCESS = "UPDATE_JOURNAL_BOARD_SUCCESS"
export const UPDATE_JOURNAL_BOARD_FAILED = "UPDATE_JOURNAL_BOARD_FAILED"

export const UPDATE_JOURNAL_BOARD_INDEX = "UPDATE_JOURNAL_BOARD_INDEX"
export const UPDATE_JOURNAL_BOARD_INDEX_SUCCESS = "UPDATE_JOURNAL_BOARD_INDEX_SUCCESS"
export const UPDATE_JOURNAL_BOARD_INDEX_FAILED = "UPDATE_JOURNAL_BOARD_INDEX_FAILED"


export const DELETE_JOURNAL_BOARD = "DELETE_JOURNAL_BOARD"
export const DELETE_JOURNAL_BOARD_SUCCESS = "DELETE_JOURNAL_BOARD_SUCCESS"
export const DELETE_JOURNAL_BOARD_FAILED = "DELETE_JOURNAL_BOARD_FAILED"


export const GET_JOURNAL_ENTRIES = "GET_JOURNAL_ENTRIES"
export const GET_JOURNAL_ENTRIES_SUCCESS = "GET_JOURNAL_ENTRIES_SUCCESS"
export const GET_JOURNAL_ENTRIES_FAILED = "GET_JOURNAL_ENTRIES_FAILED"

export const SHOW_JOURNAL_ENTRIES = "SHOW_JOURNAL_ENTRIES"
export const SHOW_JOURNAL_ENTRIES_SUCCESS = "SHOW_JOURNAL_ENTRIES_SUCCESS"
export const SHOW_JOURNAL_ENTRIES_FAILED = "SHOW_JOURNAL_ENTRIES_FAILED"

export const CREATE_JOURNAL_ENTRIES = "CREATE_JOURNAL_ENTRIES"
export const CREATE_JOURNAL_ENTRIES_SUCCESS = "CREATE_JOURNAL_ENTRIES_SUCCESS"
export const CREATE_JOURNAL_ENTRIES_FAILED = "CREATE_JOURNAL_ENTRIES_FAILED"

export const UPDATE_JOURNAL_ENTRIES = "UPDATE_JOURNAL_ENTRIES"
export const UPDATE_JOURNAL_ENTRIES_SUCCESS = "UPDATE_JOURNAL_ENTRIES_SUCCESS"
export const UPDATE_JOURNAL_ENTRIES_FAILED = "UPDATE_JOURNAL_ENTRIES_FAILED"

export const DELETE_JOURNAL_ENTRIES = "DELETE_JOURNAL_ENTRIES"
export const DELETE_JOURNAL_ENTRIES_SUCCESS = "DELETE_JOURNAL_ENTRIES_SUCCESS"
export const DELETE_JOURNAL_ENTRIES_FAILED = "DELETE_JOURNAL_ENTRIES_FAILED"


export const UPDATE_JOURNAL_ENTRIES_INDEX = "UPDATE_JOURNAL_ENTRIES_INDEX"
export const UPDATE_JOURNAL_ENTRIES_INDEX_SUCCESS = "UPDATE_JOURNAL_ENTRIES_INDEX_SUCCESS"
export const UPDATE_JOURNAL_ENTRIES_INDEX_FAILED = "UPDATE_JOURNAL_ENTRIES_INDEX_FAILED"

export const UPDATE_JOURNAL_ENTRIES_NEXT_INDEX = "UPDATE_JOURNAL_ENTRIES_NEXT_INDEX"
export const UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_SUCCESS = "UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_SUCCESS"
export const UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_FAILED = "UPDATE_JOURNAL_ENTRIES_NEXT_INDEX_FAILED"