import React, { useEffect} from "react";
import {
  Modal,
  Form,
  Row,
  Col,
} from "antd";

import { useSelector } from "react-redux";

import DueDate from "components/shared-components/DraggableCard/DueDate";
import WorkStatus from "components/shared-components/DraggableCard/WorkStatus";
import Priority from "components/shared-components/DraggableCard/Priority";
import AssignTeamMembers from "components/shared-components/DraggableCard/AssignTeamMembers";
import MarkAsComplete from "components/shared-components/DraggableCard/MarkAsComplete";
import DeleteCard from "components/shared-components/DraggableCard/DeleteCard";
import UpdateCardForm from "components/shared-components/DraggableCard/UpdateCardForm";
import { modalModeTypes } from "views/app-views/project/scrumboard/utils";
import Draggable from 'react-draggable';

const getModalTitle = (type) => {
  switch (type) {
    case modalModeTypes(0):
      return "New card";
    case modalModeTypes(2):
      return "New board";
    default:
      return;
  }
};

const ModalForm = ({
  visible,
  modalMode,
  cardData,
  listId,
  onClose,
  onModalSubmit,
  listItem,
  object_team_members,
}) => {
  const [form] = Form.useForm();

  const project_card = useSelector((state) => state.ProjectStore);
  const card_data = project_card?.project_card?.card;

  const submit = (values, mode) => {
    onModalSubmit(values, mode);
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [cardData, form]);

  const [disable, setDisable] = React.useState(true);
  const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
 
  return (
    <Modal
    title={
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (disable) {
            setDisable(false);
          }
        }}
        onMouseOut={() => {
         setDisable(true);
        }}
        // fix eslintjsx-a11y/mouse-events-have-key-events
        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
        onFocus={() => {}}
        onBlur={() => {}}
        // end
      >
     Edit
      </div>
    }
      visible={visible}
      footer={null}
      width={1220}
      style={modalMode === modalModeTypes(1) ? { top: 20 } : null}
      // destroyOnClose
      maskClosable={false}
      onCancel={() => onClose()}
      className="editModal"
      modalRender={modal => (
        <Draggable
          disabled={disable}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Row>
        <Col md={17}>
          <div
            className="pt-2 pb-4 pr-4"
            style={
              modalMode === modalModeTypes(1)
                ? { maxHeight: "85vh", overflowY: "auto", overflowX: "hidden" }
                : null
            }
          >
            <div
              className={modalMode === modalModeTypes(1) ? "mr-2 ml-2" : null}
            >
              <UpdateCardForm
                cardData={cardData}
                listId={listId}
                onSubmit={(values) => submit(values, modalModeTypes(1))}
                listItem={listItem}
                card_data={card_data}
                object_team_members={object_team_members}
              />
            </div>
          </div>
        </Col>

        <Col md={7} className="card__right p-3 ">
          <div className="pt-3">
            <h5>Settings</h5>

            {/* due date component start */}
            <DueDate card_data={card_data} url="project-cards" />
            {/* due date component end */}

            {/* work status component start */}
            <WorkStatus
              card_data={card_data}
              cardData={cardData}
              url="project-cards"
            />
            {/* work status component end */}

            {/* priority component start */}
            <Priority
              card_data={card_data}
              cardData={cardData}
              url="project-cards"
            />
            {/* priority component end */}
          </div>

          {/* Assign team members component start */}
          <AssignTeamMembers
            cardData={cardData}
            card_data={card_data}
            listItem={listItem}
          />
          {/* Assign team members component end */}
          <div>
            <h5 className="mt-5">Action</h5>

            {/* Delete card component start */}
            <DeleteCard url="project-cards" cardData={cardData} onClose={onClose} />
            {/* Delete card component end */}

            {/* mark as complete component start */}
            <MarkAsComplete
              url="project-cards"
              card_data={card_data}
              cardData={cardData}
            />
            {/* mark as complete component end */}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default React.memo(ModalForm);
