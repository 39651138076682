import * as types from "./constants"

const initialStates = {
    calendar:[],
    single_calendar:{},
    loading:false,
    error:null,
    success:false,
    is_conflict:null,
}

 const CalendarStore = (state = initialStates, action) => {
    //setup list_items reducers
   switch (action.type) {
       case types.GET_CALENDAR :
       return {...state, loading:true}
       case types.GET_CALENDAR_SUCCESS :
       return {...state, loading:false, calendar:action.calendar}
       case types.GET_CALENDAR_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_CALENDAR :
       return {...state}
       case types.SHOW_CALENDAR_SUCCESS :
       return {...state, single_calendar:action.single_calendar}
       case types.SHOW_CALENDAR_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_CALENDAR :
       return {...state, loading:true, success:false}
       case types.CREATE_CALENDAR_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_CALENDAR_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_CALENDAR :
       return {...state, loading:true, success:false}
       case types.UPDATE_CALENDAR_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_CALENDAR_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.DELETE_CALENDAR :
       return {...state, loading:true, success:false}
       case types.DELETE_CALENDAR_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_CALENDAR_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.GET_TIME_CONFLICT :
        return {...state, loading:true, success:false}
        case types.GET_TIME_CONFLICT_SUCCESS :
        return {...state, loading:false,success:true,  is_conflict:action.is_conflict}
        case types.GET_TIME_CONFLICT_FAILED :
        return {...state, loading:false, success:true, error:action.error, }
          
       default:
           return state;
   }
}

export default CalendarStore
