import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'services/EmailService';


// Board Redux States
import * as types from './constants';

function* getEmail(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_EMAIL_SUCCESS, emails:response.data});
    } else {
        yield put({type: types.GET_EMAIL_FAILED, error:error});
    }
}

function* showEmail(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_EMAIL_SUCCESS, single_email:response.data});
    } else {
        yield put({type: types.SHOW_EMAIL_FAILED, error:error});
    }
}

function* createEmail(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_EMAIL_SUCCESS,});
    } else {
        yield put({type: types.CREATE_EMAIL_FAILED, error:error});
    }
}

function* updateEmail(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_EMAIL_FAILED, error:error});
    }
}

function* deleteEmail(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.DELETE_EMAIL_FAILED, error:error});
    }
}

function* searchEmail(action)
{
    const {response, error} = yield call(api.search, action.payload)
    if(response)
    {
        yield put({type: types.SEARCH_EMAIL_SUCCESS, emails:response.data});
    } else {
        yield put({type: types.SEARCH_EMAIL_FAILED, error:error});
    }
}

function* getSendEmail(action)
{
    const {response, error} = yield call(api.sentEmail, action.payload)
    if(response)
    {
        yield put({type: types.GET_SEND_EMAIL_SUCCESS, send_emails:response.data});
    } else {
        yield put({type: types.GET_SEND_EMAIL_FAILED, error:error});
    }
}

function* showSendEmail(action)
{
    const {response, error} = yield call(api.showSentEmail, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_SEND_EMAIL_SUCCESS, single_send_email:response.data});
    } else {
        yield put({type: types.SHOW_SEND_EMAIL_FAILED, error:error});
    }
}


function* updateSendEmail(action)
{
    const {response, error} = yield call(api.updateSentEmail, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_EMAIL_FAILED, error:error});
    }
}

function* deleteSendEmail(action)
{
    const {response, error} = yield call(api.destroySentEmail, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.DELETE_EMAIL_FAILED, error:error});
    }
}



function* createDraftEmail(action)
{
    const {response, error} = yield call(api.storeDraftEmail, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_DRAFT_EMAIL_SUCCESS,});
    } else {
        yield put({type: types.CREATE_DRAFT_EMAIL_FAILED, error:error});
    }
}

function* updateDraftEmail(action)
{
    const {response, error} = yield call(api.updateDraftEmail, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_DRAFT_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_DRAFT_EMAIL_FAILED, error:error});
    }
}

function* getDraftEmail(action)
{
    const {response, error} = yield call(api.getDraftEmail, action.payload)
    if(response)
    {
        yield put({type: types.GET_DRAFT_EMAIL_SUCCESS, draft_emails:response.data});
    } else {
        yield put({type: types.GET_DRAFT_EMAIL_FAILED, error:error});
    }
}

function* showDraftEmail(action)
{
    const {response, error} = yield call(api.showDraftEmail, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_DRAFT_EMAIL_SUCCESS, single_draft_email:response.data});
    } else {
        yield put({type: types.SHOW_DRAFT_EMAIL_FAILED, error:error});
    }
}

function* deleteDraftEmail(action)
{
    const {response, error} = yield call(api.deleteDraftEmail, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_DRAFT_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.DELETE_DRAFT_EMAIL_FAILED, error:error});
    }
}


function* replyEmail(action)
{
    const {response, error} = yield call(api.replyEmail, action.payload)
    if(response)
    {
        yield put({type: types.REPLY_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.REPLY_EMAIL_FAILED, error:error});
    }
}

function* deleteReplyEmail(action)
{
    const {response, error} = yield call(api.deleteReplyEmail, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_REPLY_EMAIL_SUCCESS, });
    } else {
        yield put({type: types.DELETE_REPLY_EMAIL_FAILED, error:error});
    }
}


function* watchGetEmail() 
{
    yield takeLatest(types.GET_EMAIL, getEmail)
}

function* watchShowEmail() 
{
    yield takeEvery(types.SHOW_EMAIL, showEmail)
}

function* watchCreateEmail() 
{
    yield takeEvery(types.CREATE_EMAIL, createEmail)
}

function* watchUpdateEmail() 
{
    yield takeEvery(types.UPDATE_EMAIL, updateEmail)
}

function* watchDeleteEmail() 
{
    yield takeEvery(types.DELETE_EMAIL, deleteEmail)
}

function* watchSearchEmail() 
{
    yield takeLatest(types.SEARCH_EMAIL, searchEmail)
}


function* watchGetSendEmail() 
{
    yield takeLatest(types.GET_SEND_EMAIL, getSendEmail)
}

function* watchShowSendEmail() 
{
    yield takeLatest(types.SHOW_SEND_EMAIL, showSendEmail)
}

function* watchCreateDraftEmail() 
{
    yield takeEvery(types.CREATE_DRAFT_EMAIL, createDraftEmail)
}

function* watchUpdateDraftEmail() 
{
    yield takeEvery(types.UPDATE_DRAFT_EMAIL, updateDraftEmail)
}

function* watchGetDraftEmail() 
{
    yield takeLatest(types.GET_DRAFT_EMAIL, getDraftEmail)
}

function* watchShowDraftEmail() 
{
    yield takeEvery(types.SHOW_DRAFT_EMAIL, showDraftEmail)
}

function* watchDeleteDraftEmail() 
{
    yield takeEvery(types.DELETE_DRAFT_EMAIL, deleteDraftEmail)
}

function* watchReplyEmail() 
{
    yield takeEvery(types.REPLY_EMAIL, replyEmail)
}

function* watchDeleteReplyEmail() 
{
    yield takeEvery(types.DELETE_REPLY_EMAIL, deleteReplyEmail)
}

function* watchUpdateSendEmail() 
{
    yield takeEvery(types.UPDATE_SEND_EMAIL, updateSendEmail)
}

function* watchDeleteSendEmail() 
{
    yield takeEvery(types.DELETE_SEND_EMAIL, deleteSendEmail)
}

function* emailSaga(){
    yield all([
        fork(watchGetEmail),
        fork(watchShowEmail),
        fork(watchCreateEmail),
        fork(watchUpdateEmail),
        fork(watchDeleteEmail),
        fork(watchSearchEmail),
        fork(watchGetSendEmail),
        fork(watchShowSendEmail),
        fork(watchCreateDraftEmail),
        fork(watchUpdateDraftEmail),
        fork(watchUpdateSendEmail),
        fork(watchDeleteSendEmail),
        fork(watchGetDraftEmail),
        fork(watchShowDraftEmail),
        fork(watchDeleteDraftEmail),
        fork(watchReplyEmail),
        fork(watchDeleteReplyEmail)
    ])
}

export default emailSaga;