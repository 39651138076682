import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from "react-redux";
import moment from "moment-timezone";

export const AppViews = () => {
  const user = useSelector((state) => state.AuthStore?.user?.user);
  moment.tz.setDefault(user?.timezone);

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
      <Route path={`${APP_PREFIX_PATH}/project/:id`} component={lazy(() => import(`./project/scrumboard`))} />
      <Route path={`${APP_PREFIX_PATH}/project`} component={lazy(() => import(`./project`))} />
      <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))} />
      <Route path={`${APP_PREFIX_PATH}/list/:id`} component={lazy(() => import(`./list`))} />
      <Route path={`${APP_PREFIX_PATH}/list`} component={lazy(() => import(`./list`))} />
      <Route path={`${APP_PREFIX_PATH}/work-status/:id`} component={lazy(() => import(`./work-status/scrumboard`))} />
      <Route path={`${APP_PREFIX_PATH}/personal-tasklist/:id`} component={lazy(() => import(`./personal_tasklist_view/scrumboard`))} />
      <Route path={`${APP_PREFIX_PATH}/team`} component={lazy(() => import(`./team`))} />
      <Route path={`${APP_PREFIX_PATH}/journal/:id`} component={lazy(() => import(`./journal/scrumboard`))} />
      <Route path={`${APP_PREFIX_PATH}/journal`} component={lazy(() => import(`./journal`))} />
      <Route path={`${APP_PREFIX_PATH}/schedule/:id`} component={lazy(() => import(`./schedule/scrumboard`))} />
      <Route path={`${APP_PREFIX_PATH}/schedule`} component={lazy(() => import(`./schedule`))} />
      <Route path={`${APP_PREFIX_PATH}/task-list/:id`} component={lazy(() => import(`./tasklist/scrumboard`))} />
      <Route path={`${APP_PREFIX_PATH}/task-list`} component={lazy(() => import(`./tasklist`))} />
      <Route path={`${APP_PREFIX_PATH}/board`} component={lazy(() => import(`./draggable`))} />
      <Route path={`${APP_PREFIX_PATH}/calendar/:id`} component={lazy(() => import(`./calendar`))} />
      <Route path={`${APP_PREFIX_PATH}/team-calendar/:id`} component={lazy(() => import(`./calendar_team_view`))} />
      <Route path={`${APP_PREFIX_PATH}/email/:id`} component={lazy(() => import(`./email`))} />
      <Route path={`${APP_PREFIX_PATH}/email-accounts`} component={lazy(() => import(`./email_account`))} />
      <Route path={`${APP_PREFIX_PATH}/backup`} component={lazy(() => import(`./admin/backup`))} />
      {user?.user_role_id === 1 ? 
      <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./admin`))} />
      
      : <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />}
        
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);