export const GET_EMAIL_SERVICE_TYPE = "GET_EMAIL_SERVICE_TYPE"
export const GET_EMAIL_SERVICE_TYPE_SUCCESS = "GET_EMAIL_SERVICE_TYPE_SUCCESS"
export const GET_EMAIL_SERVICE_TYPE_FAILED = "GET_EMAIL_SERVICE_TYPE_FAILED"

export const SHOW_EMAIL_SERVICE_TYPE = "SHOW_EMAIL_SERVICE_TYPE"
export const SHOW_EMAIL_SERVICE_TYPE_SUCCESS = "SHOW_EMAIL_SERVICE_TYPE_SUCCESS"
export const SHOW_EMAIL_SERVICE_TYPE_FAILED = "SHOW_EMAIL_SERVICE_TYPE_FAILED"

export const CREATE_EMAIL_SERVICE_TYPE = "CREATE_EMAIL_SERVICE_TYPE"
export const CREATE_EMAIL_SERVICE_TYPE_SUCCESS = "CREATE_EMAIL_SERVICE_TYPE_SUCCESS"
export const CREATE_EMAIL_SERVICE_TYPE_FAILED = "CREATE_EMAIL_SERVICE_TYPE_FAILED"

export const UPDATE_EMAIL_SERVICE_TYPE = "UPDATE_EMAIL_SERVICE_TYPE"
export const UPDATE_EMAIL_SERVICE_TYPE_SUCCESS = "UPDATE_EMAIL_SERVICE_TYPE_SUCCESS"
export const UPDATE_EMAIL_SERVICE_TYPE_FAILED = "UPDATE_EMAIL_SERVICE_TYPE_FAILED"

export const DELETE_EMAIL_SERVICE_TYPE = "DELETE_EMAIL_SERVICE_TYPE"
export const DELETE_EMAIL_SERVICE_TYPE_SUCCESS = "DELETE_EMAIL_SERVICE_TYPE_SUCCESS"
export const DELETE_EMAIL_SERVICE_TYPE_FAILED = "DELETE_EMAIL_SERVICE_TYPE_FAILED"
