import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/inbox-messages?page=${data?.page}&object_id=${data?.object_id}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            return { error };
          });
    },
    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/show-email-messages/${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    store(data){
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/compose-email",data)
          .then((response) => {
            // successNotify(response.data.message);
            message.success("Email sent successfully", 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/update-email/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    destroy(data){
     
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/delete-email/${data}`)
          .then((response) => {
            message.success(response.data.message, 2);
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    search(data){
      axios.defaults.withCredentials = true;
      return authAxios
        .get(`/search-emails?object_id=${data.object_id}&search=${data.search.toString()}`)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message === "Unauthenticated.");
          // if(error.response.status === 401){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear();
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },


  sentEmail(data){
    axios.defaults.withCredentials = false;
    return authAxios
      .get(`/get-sent-email?page=${data?.page}&account_id=${data?.object_id}`)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
},

showSentEmail(data){
  axios.defaults.withCredentials = false;
  return authAxios
    .get(`/show-sent-email/${data}`)
    .then((response) => {
      return { response };
    })
    .catch((error) => {
      return { error };
    });
},
getDraftEmail(data){
  axios.defaults.withCredentials = false;
  return authAxios
    .get(`/drafts?page=${data?.page}&account_id=${data?.object_id}`)
    .then((response) => {
      return { response };
    })
    .catch((error) => {
      return { error };
    });
},
storeDraftEmail(data){
  axios.defaults.withCredentials = true;
  return authAxios
    .post("/drafts",data)
    .then((response) => {
      // successNotify(response.data.message);
      message.success("email save to draft", 4);
      localStorage.setItem("draft_id", response.data.id)
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},
updateDraftEmail(data){
  axios.defaults.withCredentials = false;
  return authAxios
    .put(`/drafts/${data.id}`, data)
    .then((response) => {
      message.success("email save to draft", 4);
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},

showDraftEmail(data){
  axios.defaults.withCredentials = false;
  return authAxios
    .get(`/drafts/${data}`)
    .then((response) => {
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},

deleteDraftEmail(data){
     
  axios.defaults.withCredentials = false;
  return authAxios
    .delete(`/drafts/${data}`)
    .then((response) => {
      message.success(response.data.message, 2);
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},

replyEmail(data){
  axios.defaults.withCredentials = true;
  return authAxios
    .post("/reply-email",data)
    .then((response) => {
      // successNotify(response.data.message);
      message.success("reply send successfully", 4);
      localStorage.setItem("draft_id", response.data.id)
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},

deleteReplyEmail(data){
     
  axios.defaults.withCredentials = false;
  return authAxios
    .delete(`/reply-email/${data}`)
    .then((response) => {
      message.success(response.data.message, 2);
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},

updateSentEmail(data){
  axios.defaults.withCredentials = false;
  return authAxios
    .put(`/update-sent-email/${data.id}`, data)
    .then((response) => {
      message.success(response.data.message, 4);
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},
destroySentEmail(data){

  axios.defaults.withCredentials = false;
  return authAxios
    .delete(`/delete-sent-email/${data}`)
    .then((response) => {
      message.success(response.data.message, 2);
      return { response };
    })
    .catch((error) => {
      // errorNotify(error.response.data.message === "Unauthenticated.");
      // if(error.response.status === 401){
      //   alert("Session Expire Login Again!")
      //   localStorage.clear();
      //   window.location = "/auth/login";
      // }
      
      return { error };
    });
},

}

