import { message } from "antd";
import axios from "axios";
import { authAxios } from "configs/EnvironmentConfig";
export const  api = {
    index(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .post(`/filters`, data)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message);
            // if(error.response.data.message === "Unauthenticated."){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear(); 
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    range(data){
      axios.defaults.withCredentials = false;
      return authAxios
        .post(`/ranges`, data)
        .then((response) => {
          return { response };
        })
        .catch((error) => {
          // errorNotify(error.response.data.message);
          // if(error.response.data.message === "Unauthenticated."){
          //   alert("Session Expire Login Again!")
          //   localStorage.clear(); 
          //   window.location = "/auth/login";
          // }
          
          return { error };
        });
  },

    show(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .get(`/schedule-cards/${data}`)
          .then((response) => {
            return { response };
          })
          .catch((error) => {
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    store(data){
        axios.defaults.withCredentials = true;
        return authAxios
          .post("/schedule-cards",data)
          .then((response) => {
            // successNotify(response.data.message);
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            message.error(error.response.data.message, 4);
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    update(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .put(`/schedule-cards/${data.id}`, data)
          .then((response) => {
            message.success(response.data.message, 4);
            return { response };
          })
          .catch((error) => {
            message.error(error.response.data.message, 4);
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },
    destroy(data){
        axios.defaults.withCredentials = false;
        return authAxios
          .delete(`/schedule-cards/${data}`)
          .then((response) => {
            message.success(response.data.message, 2);
            return { response };
          })
          .catch((error) => {
            message.error(error.response.data.message, 4);
            // errorNotify(error.response.data.message === "Unauthenticated.");
            // if(error.response.status === 401){
            //   alert("Session Expire Login Again!")
            //   localStorage.clear();
            //   window.location = "/auth/login";
            // }
            
            return { error };
          });
    },

    
}

