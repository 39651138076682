import * as types from "./constants"

const initialStates = {
    journals:[],
    single_journal:{},
    loading:false,
    error:null,
    success:false,
}

 const JournalStore = (state = initialStates, action) => {
    //setup journals reducers
   switch (action.type) {
       case types.GET_JOURNAL :
       return {...state, loading:true}
       case types.GET_JOURNAL_SUCCESS :
       return {...state, loading:false, journals:action.journals}
       case types.GET_JOURNAL_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_JOURNAL :
       return {...state}
       case types.SHOW_JOURNAL_SUCCESS :
       return {...state, single_journal:action.single_journal}
       case types.SHOW_JOURNAL_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_JOURNAL :
       return {...state, loading:true, success:false}
       case types.CREATE_JOURNAL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_JOURNAL_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_JOURNAL :
       return {...state, loading:true, success:false}
       case types.UPDATE_JOURNAL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_JOURNAL_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_JOURNAL :
       return {...state, loading:true, success:false}
       case types.DELETE_JOURNAL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_JOURNAL_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default JournalStore
