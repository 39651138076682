import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import {
  MailOutlined,
  BellOutlined,
  FileSyncOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
// import notificationData from "assets/data/notification.data.json";
import Flex from "components/shared-components/Flex";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { echo } from "configs/Socket";
import useSound from "use-sound";
import boopSfx from "../../assets/sound/alert.mp3";
import { getTeam, getUser } from "redux/actions";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

const getNotificationBody = (list) => {
  return list?.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              {item?.data?.user?.photo || item?.user?.photo ? (
                <Avatar src={item?.data?.user?.photo || item?.user?.photo} />
              ) : (
                <Avatar
                  className={`ant-avatar-${item.type}`}
                  icon={getIcon(item.icon)}
                />
              )}
            </div>
            <div className="mr-3">
              <span className="font-weight-bold text-dark">
                {item.data?.user?.username || item?.user?.username}{" "}
              </span>
              <span className="text-gray-light">
                {item.data?.text || item?.text}
              </span>
            </div>
            <small className="ml-auto">
              {moment(item?.created_at).format("LT")}
            </small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const ActivityNotification = () => {
  const dispatch = useDispatch();

  const [playActive] = useSound(boopSfx, { volume: 0.25 });
  const [playbackRate, setPlaybackRate] = React.useState(0.75);
  const [play] = useSound(boopSfx, playbackRate);

  const [visible, setVisible] = useState(false);

  const [notificationData, setNotificationData] = useState([]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const notification = useSelector(
    (state) => state.NotificationStore?.notification?.data
  );
  const state = useSelector((state) => state.AuthStore?.user);
  const user = state?.user;

  useEffect(() => {
    setNotificationData(notification);
  }, [notification]);

  const playSound = () => {
    <div onClick={playActive()}></div>;
  };


  const handleNotificationReceived = React.useMemo(() => {
    if(!user?.id) return;
  
    echo.private(`App.Models.User.${user?.id}`).notification((messages) => {
      if (messages !== undefined || messages !== null) {
        playSound();
        setNotificationData((data) => [messages, ...data]);
        if (messages?.admin_notification === 1 || messages?.admin_notification === true) {
          dispatch(getUser());
          dispatch(getTeam());
        }
      }
    });
  }, [user?.id])


  React.useEffect(() => {
        
    if(!handleNotificationReceived) return;
    (async() => {
      await handleNotificationReceived();
    })()
      
   }, [handleNotificationReceived]);

  const data = notificationData?.filter(
    (x) =>
      x.type === "App\\Notifications\\UserActivityNotification" ||
      x.type === "activity-message"
  );

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Activity</h4>
        <Button
          type="link"
          onClick={() => setNotificationData([])}
          size="small"
        >
          Clear{" "}
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
      {data?.length > 0 ? (
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">
            View all
          </a>
        </div>
      ) : null}
      {/* <button onMouseDown={playActive}>play sound</button> */}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={data?.length}>
            <FileSyncOutlined className="nav-icon mx-auto" type="bell" />
            {/* <BellOutlined className="nav-icon mx-auto" type="bell" /> */}
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default ActivityNotification;
