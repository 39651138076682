import React, { Component } from "react";
import { Table, Avatar, Badge, Tooltip, Dropdown, Menu, Input } from "antd";
import {
  StarOutlined,
  StarFilled,
  DeleteOutlined,
  TagOutlined,
  MailOutlined,
  UserOutlined
} from "@ant-design/icons";
import MailData from "../../../assets/data/mail.data.json";
import { labels, getLabelColor } from "./MailLabels";
import { useDispatch, useSelector } from "react-redux";
import { getDraftEmail, getSendEmail, searchEmail } from "redux/actions";
import moment from "moment";
import AvatarStatus from "components/shared-components/AvatarStatus";



function DraftEmails(props) {
  //   get object id
// var pathArray = window.location.pathname.split("/");
// var object_id = pathArray[3];
const object = useSelector((state) => state.ObjectStore?.single_object)
  // destructure props data
  const { id, handleSwitchPage } = props;

  //   get dispatch function
  const dispatch = useDispatch();

  //   create mails default state as an array data.
  const [mails, setMails] = React.useState([]);

  //   create select row key state as an array data.
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);



  const getEmails = React.useMemo(() => {
    if(object?.email_account_id === undefined) return;
    dispatch(getDraftEmail(object?.email_account_id, 1));
  }, [object?.email_account_id])

  //   run get email function anytime email component render
  React.useEffect(() => {
    if(!getEmails) return;
    getEmails();
  }, [getEmails]);

  const getPaginationData = (page) =>{
    dispatch(getDraftEmail(object?.email_account_id, page));
  }


  //   select email data from email store.
  const emails = useSelector((state) => state.EmailStore?.draft_emails?.data);
  const totalPage = useSelector((state) => state.EmailStore?.draft_emails?.total);
  const loading = useSelector((state) => state.EmailStore?.loading);

  //   on select checkbox function
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys({ selectedRowKeys });
  };

  //   create function to check handle starred. this check if the starred icon is enable or disabled
  const onStarTicked = (elm) => {
    const { id, starred } = elm;

    setMails(
      mails.map((item) => {
        if (item.id === id) {
          item.starred = !starred;
          return item;
        }
        return item;
      })
    );

    setSelectedRowKeys([]);
  };

  // create method to formart email body
  const formatBody = (body) => {
    var t = document.createElement('template');
    var content = body?.replace(/<(?:.|\n)*?>/gm, " ");
    t.innerHTML = content;
    return t.content;
    
  };

  // run load emails funcntion anytime page loads
  React.useEffect(() => {
    loadMail();
  }, []);

  // run load emails if pathname is not equal to previous path
  // React.useEffect(() => {
  //   if (props.location.pathname !== history.location.pathname) {
  //     loadMail();
  //   }
  // }, [history.location.pathname, props.location.pathname]);

  //   create load email function
  const loadMail = () => {
    const data = getCurrentCategory();

    setMails(data);
    setSelectedRowKeys([]);
  };

  //   create mass delete emails function
  const massDeleted = (selectedKey) => {
    let data = mails;
    selectedKey.forEach((num) => {
      data = data.filter((elm) => elm.id !== num);
    });

    setMails(data);
    setSelectedRowKeys([]);
  };

  //   create mass stared function
  const massStar = (selectedKey) => {
    let data = mails;
    selectedKey.forEach((num) => {
      data = data.map((elm) => {
        if (elm.id === num) {
          elm.starred = true;
          return elm;
        } else return elm;
      });
    });

    setMails(data);
    setSelectedRowKeys([]);
  };

  //   create mass categories
  const massCategorize = (label, selectedKey) => {
    let data = mails;
    selectedKey.forEach((num) => {
      data = data.map((elm) => {
        if (elm.id === num) {
          elm.label = label;
          return elm;
        } else return elm;
      });
    });
    setMails(data);
    setSelectedRowKeys([]);
  };

  // const onSelectEmail = (elm) => {
  //   const { match, history } = props;
  //   return {
  //     onClick: (e) => {
  //       e.preventDefault();
  //       history.push(`${match.url}/${elm.id}`);
  //     },
  //   };
  // };

  //
  const search = (e) => {
    let search = e.target.value;
    dispatch(searchEmail(id, search))
  
  };

  const getCurrentCategory = () => {
    // const { category } = id;

    // switch (category) {
    //   case "inbox":
    //     return emails;
    //   case "sent":
    //     return MailData.sent;
    //   case "draft":
    //     return MailData.draft;
    //   case "starred":
    //     return emails.filter((elm) => elm.starred);
    //   case "deleted":
    //     return MailData.deleted;
    //   default:
    //     break;
    // }
  };

  const rowSelection = {
    // selectedRowKeys,
    // onChange: onSelectChange,
  };

  const locale = {
    emptyText: (
      <div className="text-center my-5">
        <img src="/img/others/img-10.png" alt="Add credit card" />
        <h3 className="mt-3 font-weight-light">There is no draft mail!</h3>
      </div>
    ),
  };

  const tableColumns = [
    {
      title: () => (
        <div className="mail-list-action">
          <div>
            {hasSelected ? (
              <div>
                <Dropdown
                  overlay={
                    <Menu>
                      {labels.map((elm) => (
                        <Menu.Item
                          key={`key-${elm}`}
                          onClick={() => {
                            massCategorize(elm, selectedRowKeys);
                          }}
                        >
                          <Badge color={getLabelColor(elm)} />
                          <span className="text-capitalize">{elm}</span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <span
                    className="mail-list-action-icon ml-0"
                    onClick={(e) => e.preventDefault()}
                  >
                    <TagOutlined />
                  </span>
                </Dropdown>
                <span
                  className="mail-list-action-icon"
                  onClick={() => {
                    massDeleted(selectedRowKeys);
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteOutlined />
                  </Tooltip>
                </span>
                <span
                  className="mail-list-action-icon"
                  onClick={() => {
                    massStar(selectedRowKeys);
                  }}
                >
                  <Tooltip title="Star">
                    <StarOutlined />
                  </Tooltip>
                </span>
              </div>
            ) : null}
          </div>
          <div>
            <Input
              size="small"
              placeholder="Search emails"
			  style={{ width: "450px" }}
              onChange={(e) => {
                search(e);
              }}
            />
          </div>
        </div>
      ),
      colSpan: 4,
      dataIndex: "name",
      className: "mail-list-sender",
      render: (_, elm) => (
        <div className="d-flex align-items-center">
          <div
            onClick={(e) => {
              e.stopPropagation();
              onStarTicked(elm);
            }}
            className={`mail-list-star font-size-md ${
              elm.starred ? "checked" : "uncheck"
            }`}
          >
            {elm.starred ? <StarFilled /> : <StarOutlined />}
          </div>
          <div className="d-flex align-items-center">
            <AvatarStatus icon={<UserOutlined />} type="cyan" size={30} />
            {/* <Avatar icon={<MailOutlined />} size={30} /> */}
            <h4 className="mb-0 ml-2">{elm.e_personal}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "",
      colSpan: 0,
      className: "mail-list-content",
      render: (_, elm) => (
        <div className=" mail-list-content-msg">
          <Badge color={getLabelColor(elm.label)} />
          <span className="font-weight-semibold text-dark ml-1">
            {elm.e_subject}
          </span>
          {/* <span className="mx-2"> - </span> */}
          {/* <span className="p mb-0">
            {formatBody(elm.e_body)}
          </span> */}
          {/* <div dangerouslySetInnerHTML={{__html:elm.e_body }} /> */}
        </div>
      ),
    },
    {
      title: "",
      colSpan: 0,
      className: "mail-list-date",
      render: (_, elm) => (
        <div>{moment(elm.e_date).format("DD-MMM-YYYY, h:mm a")}</div>
      ),
      width: "12%",
    },

  ];

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="mail-list">
      <Table
        rowSelection={rowSelection}
        columns={tableColumns}
        dataSource={emails}
        locale={locale}
        onRow={(elm) => {
          return {
            onClick: (e) => {
              e.preventDefault();
              handleSwitchPage('show_draft');
              localStorage.setItem("draft_id", elm?.id);
            },
          };
        }}
        rowKey="id"
		loading={loading}
    pagination={{ 
      pageSize:15,
      total:totalPage,
      onChange:(page) => {
        getPaginationData(page);
      }
     }}
      />
    </div>
  );
}

export default React.memo(DraftEmails);
