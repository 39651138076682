export const  HandleOpenModal = {
    handleOpenModal(values, id, modalData, minimumData, setMinimumData, setModalData, dispatch, MinimizeModal){
        let modalItem = modalData.filter((x) => x.id === id);
    
        if (modalItem?.length > 0) return;
  
        // removeminimumData
        const arrIndex = minimumData.filter((x) => x.id !== id);
        setMinimumData(arrIndex);
        setModalData((elm) => [...elm, values]);
        dispatch(MinimizeModal(arrIndex))
    }, 
 }
