import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal, Layout, Select } from "antd";
import { PlusCircleOutlined, TeamOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
import {
  getUser,
  enableMenu,
  disableMenu,
  updateActiveTeam,
  getObjects,
  getTeam,
  logoutUser,
} from "redux/actions";
import { useHistory } from "react-router-dom";
import ActivityNotification from "./ActivityNotification";

import { echo } from "configs/Socket";
import Draggable from "react-draggable";
import useSound from "use-sound";
import boopSfx from "../../assets/sound/alert.mp3";
import moment from 'moment';
import 'moment-timezone';

const { Header } = Layout;
const { Option } = Select;

export const HeaderNav = (props) => {
  const dispatch = useDispatch();
  const {
    navCollapsed,
    navType,
    headerNavColor,
    isMobile,
    currentTheme,
    direction,
  } = props;
  const [isCancelled, setIsCancelled] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [enableTeam, setEnableTeam] = useState(false);
  const history = useHistory();

  const state = useSelector((state) => state.AuthStore?.user);
  const user = state?.user;
  const organization = state?.organization;
  const teams = state?.all_teams;
  const active = state?.active_workspace;
  const success = useSelector((state) => state.AuthStore.success);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  // const onToggle = () => {
  //   if (!isMobile) {
  //     toggleCollapsedNav(!navCollapsed);
  //   } else {
  //     onMobileNavToggle(!mobileNav);
  //   }
  // };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  const [team, setTeam] = useState(1);

  const [defaultActiveTeam, setDefaultActiveTeam] =
    useState("Personal Workspace");

  const onChangeWorkSpace = React.useCallback((value, id) => {
    let valueItem = value < 2 ? "Personal" : value;
    setTeam(valueItem);
    handleWorkSpaceCancel();
    dispatch(updateActiveTeam({ id: id, team_id: value }));
    setEnableTeam(false);
    dispatch(getUser());
    dispatch(getTeam());
  }, [dispatch]);

  const handleShowObjectByPersonal = React.useCallback(
    (user) => {
      if (user !== undefined) {
        dispatch(getUser());
        dispatch(getTeam());
        dispatch(getObjects(`user_id=${user}`));
      } else return false;
    },
    [dispatch]
  );

  const handleShowObjectByTeam = React.useCallback((value) => {
    dispatch(getUser());
    dispatch(getObjects(`team_id=${value}`));
  }, [dispatch]);

  useEffect(() => {
   
    if (active?.team_id !== undefined || active?.team_id !== 0) {
      setTeam(active?.team_id);
    } else {
      setTeam(1);
    }
    setDefaultActiveTeam("Personal Workspace");
  }, [active?.team_id]);

  const object_success = useSelector((state) => state.ObjectStore?.success);
  const project_success = useSelector((state) => state.ProjectStore?.success);
  const list_success = useSelector((state) => state.ListItemStore?.success);
  const journal_success = useSelector((state) => state.JournalStore?.success);
  const notebook_success = useSelector((state) => state.NotebookStore?.success);
  const text_message_success = useSelector((state) => state.TextMessageStore?.success);
  const schedule_success = useSelector(
    (state) => state.ScheduleItemStore?.success
  );

  //  const fetchTeamObjects = React.useCallback(
  //   () =>{ return dispatch(getObjects(`team_id=${active?.team_id}`))},
  //   []
  // );

  // const fetchPersonalObjects = React.useCallback(
  //   () => {return dispatch(getObjects(`user_id=${user?.id}`))},
  //   []
  // );


  React.useEffect(() => {
    if (!isCancelled) {
      if (active?.team_id !== 0 && active?.team_id !== undefined) {
        const fetchTeamObjects = async () => {
          await dispatch(getObjects(`team_id=${active?.team_id}`));
        };
        fetchTeamObjects();
      } else if (active?.team_id === 0 && active?.team_id !== undefined) {
        const fetchPersonalObjects = async () => {
          await dispatch(getObjects(`user_id=${user?.id}`));
        };
        fetchPersonalObjects();
      }
    }

    return () => {
      setIsCancelled(true);
    };
  }, [active?.team_id]);

  // React.useEffect(() => {
  //   let isCancelled = false;

  //   if(!isCancelled) {
  //     if(active?.team_id !== 0 && active?.team_id !== undefined) {
  //       return fetchTeamObjects();
  //     }else if(active?.team_id === 0 && active?.team_id !== undefined){
  //       if(active?.team_id === 0) return fetchPersonalObjects();
  //     }
  //   }

  //   return () => {
  //     isCancelled = true;
  //   };
  // }, [
  //   project_success,
  //   list_success,
  //   journal_success,
  //   schedule_success,
  // ]);


  const handleObjectReload = React.useMemo(() => {
    if(object_success === true || project_success === true || list_success === true || journal_success === true || schedule_success === true 
      || notebook_success === true || text_message_success === true) {
      if (active?.team_id !== 0 && active?.team_id !== undefined) {
        
        const fetchTeamObjects = async () => {
          await dispatch(getObjects(`team_id=${active?.team_id}`));
        };
        fetchTeamObjects();
      } else if (active?.team_id === 0 && active?.team_id !== undefined) {
        
        const fetchPersonalObjects = async () => {
          await dispatch(getObjects(`user_id=${user?.id}`));
        };
        fetchPersonalObjects();
      }
    }
  }, [object_success, journal_success, list_success, project_success, schedule_success, notebook_success, text_message_success])
  // React.useEffect(() => {
  //   if(project_success === true || list_success === true || journal_success === true || schedule_success === true) {
  //   if (!isCancelled) {
      
  //       if (active?.team_id !== 0 && active?.team_id !== undefined) {
        
  //         const fetchTeamObjects = async () => {
  //           await dispatch(getObjects(`team_id=${active?.team_id}`));
  //         };
  //         fetchTeamObjects();
  //       } else if (active?.team_id === 0 && active?.team_id !== undefined) {
          
  //         const fetchPersonalObjects = async () => {
  //           await dispatch(getObjects(`user_id=${user?.id}`));
  //         };
  //         fetchPersonalObjects();
  //       }
  //     }

  //   }

  //   return () => {
  //     setIsCancelled(true);
  //   };
  // }, [project_success, list_success, journal_success, schedule_success]);

  
  React.useEffect(() => {
    if (!handleObjectReload) return;
    (async () => {
      await handleObjectReload();
    })();
  }, [handleObjectReload]);

  const showModal = () => {
    dispatch(enableMenu());
  };

  const [isModalWorkSpaceVisible, setIsModalWorkSpaceVisible] = useState(false);

  const showWorkSpaceModal = () => {
    setIsModalWorkSpaceVisible(true);
  };

  const handleOk = () => {
    setIsModalWorkSpaceVisible(false);
  };

  const handleWorkSpaceCancel = () => {
    setIsModalWorkSpaceVisible(false);
  };

  const handleEnableTeam = () => {
    showWorkSpaceModal();
  };

  const [disable, setDisable] = React.useState(true);
  const [bounds, setBounds] = React.useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const [data, setData] = useState();

  const [playActive] = useSound(boopSfx, { volume: 0.25 });
  const [playbackRate, setPlaybackRate] = React.useState(0.75);
  const [play] = useSound(boopSfx, playbackRate);

  const playSound = () => {
    <div onClick={playActive()}></div>;
  };

  const handleNotificationReceived = React.useMemo(() => {
    if (!user?.id) return;

    echo.private(`App.Models.User.${user?.id}`).notification((messages) => {
      if (messages !== undefined || messages !== null) {
        if (messages?.message_type === "maintainance") {
          playSound();
          setData(messages);

          if (
            messages?.admin_notification === 1 ||
            messages?.admin_notification === true
          ) {
            dispatch(getUser());
            dispatch(getTeam());
          }
        }
      }
    });
  }, [user?.id]);
  //  const hanldeNotificationReviced = React.useCallback(async() => {
  //   if (user?.id !== undefined) {

  //   }

  // }, [user?.id]);

  React.useEffect(() => {
    if (!handleNotificationReceived) return;
    (async () => {
      await handleNotificationReceived();
    })();
  }, [handleNotificationReceived]);

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disable) {
                setDisable(false);
              }
            }}
            onMouseOut={() => {
              setDisable(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            WorkSpace
          </div>
        }
        modalRender={(modal) => (
          <Draggable
            disabled={disable}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={null}
        maskClosable={false}
        visible={isModalWorkSpaceVisible}
        onOk={handleOk}
        onCancel={handleWorkSpaceCancel}
        className="draggable__modal_item"
      >
        <span>Select Workspace</span>
        <Select
          className="mt-2"
          placeholder="Select workspace"
          style={{ width: "100%" }}
          onChange={(e) => {
            
            onChangeWorkSpace(e, active?.id);
            e === 0
              ? handleShowObjectByPersonal(user?.id)
              : handleShowObjectByTeam(e);
          }}
          defaultValue={team === 1 ? "Personal Workspace" : team}
          onSelect={(e) => setEnableTeam(false)}
          onFocus={(e) => setEnableTeam(true)}
        >
          {teams?.map((item, index) => (
            <Option value={item?.id === 1 ? 0 : item?.id} key={index}>
              {item?.team_name === "None" ? "Personal" : item?.team_name}{" "}
              Workspace
            </Option>
          ))}
        </Select>
      </Modal>
      {data?.message_type === "maintainance" ? (
        <div className="alert__box">
          <div className="alert__message">
            <h4>{data?.text}</h4>
            <Button onClick={handleLogout}>Logout</Button>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {/* {isNavTop && !isMobile ? null : (
                // <li className="ant-menu-item ant-menu-item-only-child">
                //   <HomeOutlined className="nav-icon" onClick={handleHome}/>
                // </li>
                // <li
                //   className="ant-menu-item ant-menu-item-only-child"
                //   onClick={() => {
                //     onToggle();
                //   }}
                // >
                //   {navCollapsed || isMobile ? (
                //     <MenuUnfoldOutlined className="nav-icon" />
                //   ) : (
                //     <MenuFoldOutlined className="nav-icon" />
                //   )}
                // </li>
              )} */}
            </ul>

            <div className="mr-5">
              <Button
                type=""
                className="nav-bar-btn"
                icon={<PlusCircleOutlined />}
                onClick={showModal}
              >
                New
              </Button>
            </div>
          </div>

          <div
            className="nav-middle text-center d-flex justify-content-center align-items-center"
            style={{ height: "100%", flex: 1 }}
          >
            <h4>{organization?.name} / {user?.fullname} / {moment.tz(user?.timezone).format('z')}</h4>

            <Button
              onClick={handleEnableTeam}
              className="ml-5 mr-3 nav-bar-btn-grey"
              icon={<TeamOutlined />}
            >
              {team === 0 || team === "Personal"
                ? defaultActiveTeam
                : teams?.filter((elm) => elm?.id === team)?.[0]?.team_name
                ? teams?.filter((elm) => elm?.id === team)?.[0]?.team_name
                : teams?.filter((elm) => elm?.id === team)?.[0]?.team_name}
            </Button>

            {/* {active?.user_id !== active_team?.[0]?.team_owner ? (
              ""
            ) : (
              <Button
                onClick={handleEditTeam}
                type=""
                className=""
                icon={<EyeOutlined />}
              >
                Edit Team
              </Button>
            )} */}
          </div>
          <div className="nav-right">
            <ActivityNotification />
            <NavNotification />
            <NavProfile showWorkSpaceModal={showWorkSpaceModal} user={user} />
            <NavPanel direction={direction} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(React.memo(HeaderNav));
