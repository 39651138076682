import React from 'react'
import {FileDoneOutlined} from "@ant-design/icons";
import {DatePicker} from "antd";
import moment from "moment";
import { updateCard } from 'redux/actions';
import { useDispatch } from 'react-redux';

function DueDate(props) {
    const dispatch = useDispatch();

    const {card_data, url} = props;

    const changeEndDate = (dueDate) => {
        let data = card_data;
        data.due_date = dueDate;
        data.attachments = [];
        data.isDueDateChange = true;
        dispatch(updateCard(data, url));
      };

    return (
        <React.Fragment>
            
            <div className="item__box mb-4">
              <div class="icon__box">
                <FileDoneOutlined />{" "}
                <span className="icon__text">Due Date: </span>
              </div>
              <div>
                <DatePicker
                  bordered={false}
                  value={moment(card_data?.due_date ? card_data?.due_date : card_data?.entry_date)}
                  format="DD-MMM-YYYY"
                  onChange={(date) => changeEndDate(date)}
                />
              </div>
            </div>
        </React.Fragment>
    )
}

export default DueDate
