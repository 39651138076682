import React, {useState} from 'react'
import Draggable from 'react-draggable';
import { Tooltip, Modal, Button,
    Form, Input, message, Select, Upload,
    Divider, Popconfirm } from 'antd';
    // import {useIdleTimer } from 'react-idle-timer'

import SunEditor from "suneditor-react";
import moment from "moment";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import {
	align,
	font,
	fontColor,
	fontSize,
	formatBlock,
	hiliteColor,
	horizontalRule,
	lineHeight,
	list,
	paragraphStyle,
	table,
	template,
	textStyle,
	image,
	link,
  } from "suneditor/src/plugins";
  import { 
	UploadOutlined,
	CloudUploadOutlined,

} from '@ant-design/icons';

import { useDispatch, useSelector } from "react-redux";
import { createDraftEmail, createEmail, updateDraftEmail } from "redux/actions";

function FowardEmail(props) {
    const  {email, id, isModalVisible, setIsModalVisible, setEnableModal } = props;
    const dispatch = useDispatch();


    const [form] = Form.useForm();
    const back = () => {
      form.resetFields();
    };
  
    const [cc, setCC] = useState("");
    const [subject, setSubject] = useState("");
    const [bcc, setBcc] = useState("");
    const [to, setTo] = useState([]);
  
    const [uploadImage, setUploadImage] = React.useState([]);
    const [base64, setBase64] = React.useState([]);
    function uploadCallback(file) {
      let uploadedImages = uploadImage;
      const imageObject = {
        file: file,
        localSrc: URL.createObjectURL(file),
      };
  
      uploadedImages.push(imageObject);
  
      return new Promise((resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      });
    }
  
   
  
    const user = useSelector((state) => state.AuthStore?.user);
    const object = useSelector((state) => state.ObjectStore?.single_object)
  
  
    const onFinish = (values) => {
      let datas = values;
      datas.object_id = id;
      datas.account = object?.email_account_id;
      datas.full_name = user?.fullname;
      datas.attachments = base64;
      datas.date = moment();
      dispatch(createEmail(datas))
      setBase64([]);
    //   setDescription("");
      form.resetFields();
      handleOk();
    };
  
    function handleChange(value) {
      setTo(value);
    }
  
     //   get default html data from database.
 

    const [editorState, setDescription] = React.useState("");
  
    const handleDescription = (editorState) => {
      setDescription(editorState)
    };
  
  
    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
  
    const handleUpload = (info) => {
      let fileList = [...info.fileList];
      // Accept 5 files only
      fileList = fileList.slice(-5);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = (e) => {
          file.base64 = e.target.result;
        };
        reader.readAsDataURL(file.originFileObj);
      });
      setBase64(fileList);
    };
  
  
    const onIdle = () => {
      // Close Modal Prompt
      // Do some idle action like log out your user
      const getId = localStorage.getItem("draft_id");
      if(editorState === "") return;
      let datas = {
        id:getId,
        object_id: id,
        e_account_id:object?.email_account_id,
        e_personal:user?.user?.fullname,
        e_date:moment(),
        attachments:base64,
        e_subject:subject,
        e_cc:cc,
        e_bcc:bcc,
        e_body:editorState,
        //  e_to:to,
  
      }; 
      if(getId === undefined || getId === null){
      
        // dispatch(createDraftEmail(datas));
      }else{
        // dispatch(updateDraftEmail(datas));
      }
  
  
      // setBase64([]);
      // form.resetFields();
      // dispatch(createEmail(datas))
    }
  
    const onActive = (event) => {
      // Close Modal Prompt
      // Do some active action
    }



  const handleOk = () => {
    setIsModalVisible(false);
    setEnableModal(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEnableModal(false);
  };

  const [disable, setDisable] = React.useState(true);
  const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const initialValues = {
    subject:email?.e_subject,
    message:editorState,
  };

  React.useEffect(() => {
     
    form.resetFields();

  }, [email, form]);


  React.useEffect(() => {
    if(email !== undefined) {
        const html =
        email?.e_body === null || email?.e_body === null? "<p></p>" : `${email?.e_body || email?.e_body}`;
        setSubject(email?.e_subject)
        setDescription(html);
      }
  
  }, [props])



  // const idleTimer = useIdleTimer({ onIdle, onActive, timeout:8 * 1000 })

  return (
    <>
     <Modal
        width={800}
		maskClosable={false}
		footer={null}
		visible={isModalVisible}
		onOk={handleOk}
		onCancel={handleCancel}
		className="draggable__modal_item"
		title={
		  <div
			style={{
			  width: '100%',
			  cursor: 'move',
			}}
			onMouseOver={() => {
			  if (disable) {
				setDisable(false);
			  }
			}}
			onMouseOut={() => {
			 setDisable(true);
			}}
			onFocus={() => {}}
			onBlur={() => {}}
			// end
		  >
		Forward Email
		  </div>
		}

		modalRender={modal => (
		  <Draggable
			disabled={disable}
			bounds={bounds}
		   
			onStart={(event, uiData) => onStart(event, uiData)}
		  >
			<div ref={draggleRef}>{modal}</div>
		  </Draggable>
		)}
        >
       
       <Form onFinish={onFinish} form={form} initialValues={initialValues}>
        <Form.Item name="to" className="email_to">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="To"
            onChange={handleChange}
            className="container-dropdown"
          >
            {/* {emailAddress} */}
          </Select>
        </Form.Item>
        <Form.Item name="cc">
          <Input placeholder="Cc:" onChange={(e) => setCC(e.target.value)}/>
        </Form.Item>
        <Form.Item name="subject">
          <Input placeholder="Subject:" onChange={(e) => setSubject(e.target.value)}/>
        </Form.Item>
        <Form.Item name="message">
          <SunEditor
            width="100%"
            onChange={handleDescription}
            autoFocus={true}
            defaultValue={editorState}
            lang="en"
            setOptions={{
              showPathLabel: false,
              minHeight: "30vh",
              maxHeight: "40vh",
              placeholder: "Enter your text here!!!",
              popupDisplay: "local",
              resizingBar: true,
              plugins: [
                align,
                // font,
                // fontColor,
                // fontSize,
                formatBlock,
                hiliteColor,
                horizontalRule,
                // lineHeight,
                list,
                // paragraphStyle,
                table,
                template,
                textStyle,
                image,
                link,
              ],
              buttonList: [
                ["undo", "redo"],
                // ["font", "fontSize", "formatBlock"],
                // ["paragraphStyle"],
                [
                  "bold",
                  //   "underline",
                  //   "italic",
                  //   "strike",
                  //   "subscript",
                  //   "superscript"
                ],
                // ["fontColor", "hiliteColor"],
                ["removeFormat"],
                // Line break
                ["outdent", "indent"],
                [
                  "align",
                  "horizontalRule",
                  "list",
                  //  "lineHeight"
                ],
                ["table", "link", "image"],
              ],
              formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
              // font: [
              //   "Arial",
              //   "Calibri",
              //   "Comic Sans",
              //   "Courier",
              //   "Garamond",
              //   "Georgia",
              //   "Impact",
              //   "Lucida Console",
              //   "Palatino Linotype",
              //   "Segoe UI",
              //   "Tahoma",
              //   "Times New Roman",
              //   "Trebuchet MS"
              // ]
            }}
          />
        </Form.Item>

        <div className="mt-5 mb-5">
			  <h4 className="mb-3"><CloudUploadOutlined /> Attachments</h4>
		   <Form.Item
        name="attachments"
        label=""
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
		
      >
        <Upload name="logo" listType="picture" onChange={handleUpload}>
          <Button>
            <UploadOutlined /> Click to upload
          </Button>
        </Upload>
      </Form.Item>

		  </div>
        <Form.Item>
          <div className="reply__button">
            <Button danger className="mr-2" onClick={back}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </div>
        </Form.Item>
      </Form>
        </Modal>
    </>
  )
}

export default FowardEmail


