export const GET_CALENDAR_TEAM_VIEW = "GET_CALENDAR_TEAM_VIEW"
export const GET_CALENDAR_TEAM_VIEW_SUCCESS = "GET_CALENDAR_TEAM_VIEW_SUCCESS"
export const GET_CALENDAR_TEAM_VIEW_FAILED = "GET_CALENDAR_TEAM_VIEW_FAILED"

export const SHOW_CALENDAR_TEAM_VIEW = "SHOW_CALENDAR_TEAM_VIEW"
export const SHOW_CALENDAR_TEAM_VIEW_SUCCESS = "SHOW_CALENDAR_TEAM_VIEW_SUCCESS"
export const SHOW_CALENDAR_TEAM_VIEW_FAILED = "SHOW_CALENDAR_TEAM_VIEW_FAILED"

export const CREATE_CALENDAR_TEAM_VIEW = "CREATE_CALENDAR_TEAM_VIEW"
export const CREATE_CALENDAR_TEAM_VIEW_SUCCESS = "CREATE_CALENDAR_TEAM_VIEW_SUCCESS"
export const CREATE_CALENDAR_TEAM_VIEW_FAILED = "CREATE_CALENDAR_TEAM_VIEW_FAILED"

export const UPDATE_CALENDAR_TEAM_VIEW = "UPDATE_CALENDAR_TEAM_VIEW"
export const UPDATE_CALENDAR_TEAM_VIEW_SUCCESS = "UPDATE_CALENDAR_TEAM_VIEW_SUCCESS"
export const UPDATE_CALENDAR_TEAM_VIEW_FAILED = "UPDATE_CALENDAR_TEAM_VIEW_FAILED"

export const DELETE_CALENDAR_TEAM_VIEW = "DELETE_CALENDAR_TEAM_VIEW"
export const DELETE_CALENDAR_TEAM_VIEW_SUCCESS = "DELETE_CALENDAR_TEAM_VIEW_SUCCESS"
export const DELETE_CALENDAR_TEAM_VIEW_FAILED = "DELETE_CALENDAR_TEAM_VIEW_FAILED"

export const FILTER_CALENDAR_EVENT = "FILTER_CALENDAR_EVENT"
export const FILTER_CALENDAR_EVENT_SUCCESS = "FILTER_CALENDAR_EVENT_SUCCESS"
export const FILTER_CALENDAR_EVENT_FAILED = "FILTER_CALENDAR_EVENT_FAILED"
