import * as types from "./constants";

const initialStates = {
  project_check_list:[],
  single_project_check_list:{},
  loading: false,
  error: null,
  success: false,
};

const ProjectChecklistStore = (state = initialStates, action) => {
  //setup object reducers
  switch (action.type) {
    case types.GET_PROJECT_CHECK_LIST:
      return { ...state, loading: true };
    case types.GET_PROJECT_CHECK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        project_check_list: action.project_check_list,
      };
    case types.GET_PROJECT_CHECK_LIST_FAILED:
      return { ...state, loading: false, error: action.error };

    case types.SHOW_PROJECT_CHECK_LIST:
      return { ...state };
    case types.SHOW_PROJECT_CHECK_LIST_SUCCESS:
      return {
        ...state,
        single_project_check_list: action.single_Project_check_list,
      };
    case types.SHOW_PROJECT_CHECK_LIST_FAILED:
      return { ...state, error: action.error, loading: false };

    case types.CREATE_PROJECT_CHECK_LIST:
      return { ...state, loading: true, success: false };
    case types.CREATE_PROJECT_CHECK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        single_project_check_list: action.single_Project_check_list,
      };
    case types.CREATE_PROJECT_CHECK_LIST_FAILED:
      return { ...state, success: true, loading: false, error: action.error };

    case types.UPDATE_PROJECT_CHECK_LIST:
      return { ...state, loading: true, success: false };
    case types.UPDATE_PROJECT_CHECK_LIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case types.UPDATE_PROJECT_CHECK_LIST_FAILED:
      return { ...state, success: true, loading: false, error: action.error };

    case types.DELETE_PROJECT_CHECK_LIST:
      return { ...state, loading: true, success: false };
    case types.DELETE_PROJECT_CHECK_LIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case types.DELETE_PROJECT_CHECK_LIST_FAILED:
      return { ...state, success: true, loading: false, error: action.error };
    default:
      return state;
  }
};

export default ProjectChecklistStore;
