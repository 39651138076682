import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
} from "antd";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePickers from "react-datepicker"
import { getTimeInterval } from "../../../../../utils/duration";
import { createSchedule, getTimeConflict } from "redux/actions";
import '../../styles.css';
import Checkbox from "antd/lib/checkbox/Checkbox";
import { AppointmentUtiles } from "./utils";
const { TextArea } = Input;
function AddCard(props) {
  // select ant design form data to handle form events
  const [form] = Form.useForm();

  // destructure props datas.
  const { onCancel, listId, data } = props;

  // select team data from redux store
  const team_item = useSelector((state) => state.TeamStore?.teams);
  const teams = team_item?.all_teams;
  const active_team = team_item?.active_workspace;

  const user_state = useSelector((state) => state.AuthStore?.user);
  const user = user_state?.user;
  const calendar = user_state?.calendar;
  const calendar_store = useSelector((state) => state.CalendarStore);
  const time_conflict = calendar_store?.is_conflict;
  const calendar_success = calendar_store?.success;
  const [isTimeConflict, setIsTimeConflict] = useState(null);

  // create event type state
  const [eventType, setEventType] = useState("");

  // create start time and end time state
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  // handle on start time change - This function set start to the select time by the user
  function onChangeStartTime(time) {
    setStartTime(time);
    setEndTime(time);
  }

  // handle on start time change - This set start to the select time by the user
  function onChangeEndTime(time) {
    setEndTime(time);
    dispatch(getTimeConflict({start_time:moment(startTime).format('YYYY-MM-DD HH:mm:ss'), end_time:moment(time).format('YYYY-MM-DD HH:mm:ss'), calender_id:calendar?.id, user_id:user?.id}))    
  }

  // create duration state
  const [duration, setDuration] = useState("");

  // create personal state - This will use to handle the event type. Whether the user select personal or private
  const [personal, setPersonal] = useState(false);
  const [privates, setPrivates] = useState(false);

// create dispatch function to dispatch redux actions. 
  const dispatch = useDispatch();


  React.useEffect(() => {
    if(calendar_success === true || isTimeConflict) {
      setIsTimeConflict(time_conflict);
    }

}, [calendar_success])

// handle on finish event submission to the api endpoint. - This function send the data to the api end point.
const onFinish = (values) => {
 
 if(isTimeConflict === false)
 {
   let data = values;
   data.schedule_id = props?.listItem.id;
   data.calendar_id = props?.listItem?.calendar_id;
   data.duration = getTimeInterval(startTime, endTime);
   data.start_time = startTime;
   data.end_time = endTime;
   data.board_id = listId;
   data.private = privates;
   data.personal = personal;
   data.color = "#ff8acc";
   data.object_type = 2;
   dispatch(createSchedule(data));
   form.resetFields();
   onCancel();
 }

};

  // handle on form submission failed
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // set default form values
  const defaultValues = {
    date: moment(props.dateItem),
    duration: duration,
    schedule_id: props.itemId,
    event_type: "Regular Event",
    owner : data?.id,
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={defaultValues}
      >

        {/* form container start */}
        <div className="form-box">
          <div className="main-flex">
            <div className="double-form-box">
              <span>
                {eventType === "Travel Event" ? "Start Date" : "Date"}
              </span>

              <Form.Item
                rules={[
                  { required: true, message: "Please select event date !" },
                ]}
                label=""
                name="date"
                className="main_form__item"
              >
                <DatePicker
                  placeholder="Select event date"
                  className="date-picker"
                  style={{ width: "230px" }}
                  format={"dddd D-MMM-YYYY"}
                />
              </Form.Item>
            </div>
           <div>
           <div className="double-form-box">
              <span>Time</span>
              <div className="time__box">
      <DatePickers
      selected={startTime}
      onChange={(date) => onChangeStartTime(date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm a z"
      timeZoneOffsetInSeconds={0}
    />
    
      <h5>To</h5>

    <DatePickers
      selected={endTime}
      onChange={(date) => onChangeEndTime(date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm a z"
      timeZoneOffsetInSeconds={0}
    />
              </div>

             
            </div>
            {startTime === endTime ? "" : <>
            {isTimeConflict? <p className="text-danger conflict__message">
           time conflict - please check the calendar
                </p>: ""}
            </>}
        
           </div>
       

            
          </div>
         
          {/* form container start */}

          {/* form container start */}
          <div className="main-flex">
            <div className="double-form-box">
            <span>
                {/* {eventType === "Travel Event" ? "Travel Details" : "Why"} */}
              </span>
              <Checkbox onChange={() => AppointmentUtiles.onChangePersonal(personal, setPersonal)}>Personal</Checkbox>
              {personal ? 
              <Checkbox onChange={() => AppointmentUtiles.onChangePrivate(privates, setPrivates)}>Private</Checkbox>        
              : ""}
                 
            </div>
     
          {/* form container end */}

            <div className="double-form-box">
              <span>Duration</span>
              {endTime === moment() ? "" : getTimeInterval(startTime, endTime)}
            </div>
          </div>
          {/* form container end */}

          <div className="main-flex">
            <div className="double-form-box">
              <span>What</span>
              <Form.Item
                  label=""
                  name="what"
                  className="main_form__item"
                  rules={[
                    {
                      required: eventType === "Travel Event" ? false : true,
                      message: "Input your event appointment title !",
                    },
                  ]}
                >
                  <Input placeholder="What is the reason for the appointment?" />
                </Form.Item>
            </div>

            
          </div>
          {/* form container end */}
          

          {/* form container start */}
          <div className="main-flex">
          
            
          </div>
          {/* form container end */}
 
          {/* form container start */}
          <div className="main-flex">
          <div className="double-form-box">
              <span>
                 Why
              </span>
              <Form.Item
                  label=""
                  name="why"
                  className="main_form__item"
                  rules={[
                    {
                      required: eventType === "Travel Event" ? false : true,
                      message: "Input the reason for the event !",
                    },
                  ]}
                >
                  <Input placeholder="What is the benefit of the appointment?" />
                </Form.Item>
            </div>
            
              <div className="double-form-box">
                <span>Who</span>

                <Form.Item label="" name="who" className="main_form__item">
                  <Input placeholder="who is the appointment with?" />
                </Form.Item>
              </div>
              
          </div>
          {/* form container end */}

          {/* form container start */}
         
            <div className="main-flex">
              <div className="double-form-box">
                <span>Where</span>

                <Form.Item
                  className="main_form__item"
                  label=""
                  name="where"
                  rules={[
                    {
                      required: eventType === "Travel Event" ? false : true,
                      message: "Input the location for the event !",
                    },
                  ]}
                >
                  <Input placeholder="Location of the meeting" />
                </Form.Item>
              </div>

              <div className="double-form-box">
                <span>Team</span>
                <Form.Item
                    label=""
                    name="team_id"
                    className="main_form__item"
                    rules={[
                      {
                        required: true,
                        message: "Please select a team!",
                      },
                    ]}
                  >
                    <Select placeholder="Select team">
                      {teams?.map((elm, i) => (
                        <Select.Option key={i} value={elm?.id === 1 ? 0 : elm.id}>
                          {elm?.team_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                
              </div>
            </div>
     
          {/* form container end */}

          {/* form container start */}
          <div className="main-flex">
            <div className="double-form-box">
              <span>Note</span>
              <Form.Item label="" name="notes" className="main_form__item">
                <TextArea
                  placeholder="Add event not here"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </div>
          </div>
          {/* form container end */}
        </div>
        {/* form box end */}

        <Form.Item className="blockform-col col-12 mt-5 mb-3">
            <Button type="" className="float-left bg-gray-lighter" htmlType="button" style={{ width:"120px" }} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="success" className="float-right bg-success text-white" style={{ width:"120px" }} htmlType="submit">
              Save
            </Button>
          </Form.Item>
      </Form>
    </>
  );
}

export default AddCard;
