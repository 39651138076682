import * as types from "./constants"

const initialStates = {
    emails:[],
    draft_emails:[],
    single_emails:{},
    send_email:[],
    single_send_email:{},
    single_draft_email:{},
    loading:false,
    error:null,
    success:false,
}

 const EmailStore = (state = initialStates, action) => {
    //setup journals reducers
   switch (action.type) {
       case types.GET_EMAIL :
       return {...state, loading:true}
       case types.GET_EMAIL_SUCCESS :
       return {...state, loading:false, emails:action.emails}
       case types.GET_EMAIL_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_EMAIL :
       return {...state}
       case types.SHOW_EMAIL_SUCCESS :
       return {...state, single_email:action.single_email}
       case types.SHOW_EMAIL_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_EMAIL :
       return {...state, loading:true, success:false}
       case types.CREATE_EMAIL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_EMAIL_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_EMAIL :
       return {...state, loading:true, success:false}
       case types.UPDATE_EMAIL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_EMAIL_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_EMAIL :
       return {...state, loading:true, success:false}
       case types.DELETE_EMAIL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_EMAIL_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.SEARCH_EMAIL :
        return {...state, loading:true}
        case types.SEARCH_EMAIL_SUCCESS :
        return {...state, loading:false, emails:action.emails}
        case types.SEARCH_EMAIL_FAILED :
        return {...state, loading:false, error:action.error, }

        case types.GET_SEND_EMAIL :
        return {...state, loading:true}
        case types.GET_SEND_EMAIL_SUCCESS :
        return {...state, loading:false, send_emails:action.send_emails}
        case types.GET_SEND_EMAIL_FAILED :
        return {...state, loading:false, error:action.error, }
          
        case types.SHOW_SEND_EMAIL :
        return {...state, loading:true}
        case types.SHOW_SEND_EMAIL_SUCCESS :
        return {...state, loading:false, single_send_email:action.singe_send_email}
        case types.SHOW_SEND_EMAIL_FAILED :
        return {...state, loading:false, error:action.error, }

        case types.UPDATE_SEND_EMAIL :
        return {...state, loading:true, success:false}
        case types.UPDATE_SEND_EMAIL_SUCCESS :
        return {...state, loading:false, success:true}
        case types.UPDATE_SEND_EMAIL_FAILED:
        return {...state, success:true, loading:false, error:action.error}
 
 
        case types.DELETE_SEND_EMAIL :
        return {...state, loading:true, success:false}
        case types.DELETE_SEND_EMAIL_SUCCESS :
        return {...state, loading:false, success:true}
        case types.DELETE_SEND_EMAIL_FAILED :
        return {...state, success:true, loading:false, error:action.error}


        case types.GET_DRAFT_EMAIL :
        return {...state, loading:true}
        case types.GET_DRAFT_EMAIL_SUCCESS :
        return {...state, loading:false, draft_emails:action.draft_emails}
        case types.GET_DRAFT_EMAIL_FAILED :
        return {...state, loading:false, error:action.error, }

       case types.CREATE_DRAFT_EMAIL :
       return {...state, loading:true, success:false}
       case types.CREATE_DRAFT_EMAIL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_DRAFT_EMAIL_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_DRAFT_EMAIL :
       return {...state, loading:true, success:false}
       case types.UPDATE_DRAFT_EMAIL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_DRAFT_EMAIL_FAILED:
       return {...state, success:true, loading:false, error:action.error}

       case types.SHOW_DRAFT_EMAIL :
       return {...state, loading:true}
       case types.SHOW_DRAFT_EMAIL_SUCCESS :
       return {...state, loading:false, single_draft_email:action.single_draft_email}
       case types.SHOW_DRAFT_EMAIL_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.DELETE_DRAFT_EMAIL :
       return {...state, loading:true, success:false}
       case types.DELETE_DRAFT_EMAIL_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_DRAFT_EMAIL_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.REPLY_EMAIL :
       return {...state, loading:true}
       case types.REPLY_EMAIL_SUCCESS :
       return {...state, loading:false}
       case types.REPLY_EMAIL_FAILED :
       return {...state, loading:false, error:action.error, }


       case types.DELETE_REPLY_EMAIL :
       return {...state, loading:true}
       case types.DELETE_REPLY_EMAIL_SUCCESS :
       return {...state, loading:false}
       case types.DELETE_REPLY_EMAIL_FAILED :
       return {...state, loading:false, error:action.error, }
       default:
           return state;
   }
}

export default EmailStore
