import * as types from "./constants"

export const getSchedule = (schedule) => {
    return {
        type:types.GET_SCHEDULE,
        payload:schedule
    }
}

export const getScheduleRange = (schedule_range) => {
    return {
        type:types.GET_SCHEDULE_RANGE,
        payload:schedule_range
    }
}

export const showSchedule = (single_schedule) => {
    return {
        type:types.SHOW_SCHEDULE,
        payload:single_schedule
    }
}

export const createSchedule = (schedule) => {
    return {
        type:types.CREATE_SCHEDULE,
        payload:schedule
    }
}

export const updateSchedule = (schedule) => {
    return {
        type:types.UPDATE_SCHEDULE,
        payload:schedule
    }
}

export const deleteSchedule = (schedule) => {
    return {
        type:types.DELETE_SCHEDULE,
        payload:schedule
    }
}
