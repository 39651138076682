import * as types from "./constants"

const initialStates = {
    schedules:[],
    schedules_range:[],
    single_schedule:{},
    loading:false,
    error:null,
    success:false,
}

 const ScheduleStore = (state = initialStates, action) => {
    //setup schedules reducers
   switch (action.type) {
       case types.GET_SCHEDULE :
       return {...state, loading:true}
       case types.GET_SCHEDULE_SUCCESS :
       return {...state, loading:false, schedules:action.schedules}
       case types.GET_SCHEDULE_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.GET_SCHEDULE_RANGE :
       return {...state, loading:true}
       case types.GET_SCHEDULE_RANGE_SUCCESS :
       return {...state, loading:false, schedules_range:action.schedules_range}
       case types.GET_SCHEDULE_RANGE_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_SCHEDULE :
       return {...state}
       case types.SHOW_SCHEDULE_SUCCESS :
       return {...state, single_schedule:action.single_schedule}
       case types.SHOW_SCHEDULE_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_SCHEDULE :
       return {...state, loading:true, success:false}
       case types.CREATE_SCHEDULE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_SCHEDULE_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_SCHEDULE :
       return {...state, loading:true, success:false}
       case types.UPDATE_SCHEDULE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_SCHEDULE_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_SCHEDULE :
       return {...state, loading:true, success:false}
       case types.DELETE_SCHEDULE_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_SCHEDULE_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default ScheduleStore
