import * as types from "./constants"

export const getListBoard = (list_board) => {
    return {
        type:types.GET_LIST_BOARD,
        payload:list_board
    }
}

export const showListBoard = (single_list) => {
    return {
        type:types.SHOW_LIST_BOARD,
        payload:single_list
    }
}

export const createListBoard = (list_board) => {
    return {
        type:types.CREATE_LIST_BOARD,
        payload:list_board
    }
}

export const updateListBoard = (list_board) => {
    return {
        type:types.UPDATE_LIST_BOARD,
        payload:list_board
    }
}

export const updateListBoardIndex = (index) => {
    return {
        type:types.UPDATE_LIST_BOARD_INDEX,
        payload:index
    }
}

export const updateListCardIndex = (index) => {
    return {
        type:types.UPDATE_LIST_CARD_INDEX,
        payload:index
    }
}

export const updateListCardNextIndex = (index) => {
    return {
        type:types.UPDATE_LIST_CARD_NEXT_INDEX,
        payload:index
    }
}

export const deleteListBoard = (list_board) => {
    return {
        type:types.DELETE_LIST_BOARD,
        payload:list_board
    }
}



export const getListCard = (list_card) => {
    return {
        type:types.GET_LIST_CARD,
        payload:list_card
    }
}

export const showListCard = (single_card) => {
    return {
        type:types.SHOW_LIST_CARD,
        payload:single_card
    }
}

export const createListCard = (list_card) => {
    return {
        type:types.CREATE_LIST_CARD,
        payload:list_card
    }
}

export const updateListCard = (list_card) => {
    return {
        type:types.UPDATE_LIST_CARD,
        payload:list_card
    }
}

export const deleteListCard = (list_card) => {
    return {
        type:types.DELETE_LIST_CARD,
        payload:list_card
    }
}