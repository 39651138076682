import React, { useContext, useState } from 'react'
import { ScrumboardContext } from './ScrumboardContext'
import { Draggable } from 'react-beautiful-dnd';
import BoardCard from './BoardCard';
import { modalModeTypes } from './utils';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import { Menu, Form, Input, Modal, Button, Badge } from 'antd'
import { CloseOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBoard, updateBoard } from 'redux/actions';
import AddCard from './actions/AddCard';

const RenameForm = ({ title, finish,  id, setRenameActive}) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const onRenameSubmit = values => {
	  dispatch(updateBoard(values));
	};

	const onClose = () => {
		setRenameActive('');
  };
 
	return (
		<Form 
			form={form}
			name="renameField" 
			onFinish={onRenameSubmit} 
			layout="vertical"
			autoComplete="off"
			className="w-100"
			initialValues={{
				title: title,
				id:id,
			}}
		>
			<Form.Item name="title" className="mb-0">
				<Input 
					autoFocus 
					value={title} 
					suffix={
						<div className="cursor-pointer" onClick={() => onClose()}>
							<CloseOutlined />
						</div>
					}
				/>
			</Form.Item>

			<Form.Item name="id" className="mb-0 mt-3" style={{ display:"none" }}>
				<Input 
					autoFocus 
					value={title} 
				/>
			</Form.Item>

			<Form.Item>
				<Button className="mb-0 mt-2" type="primary" htmlType="submit">Save Changes</Button>
			</Form.Item>
		</Form>
	)
}

const Board = ({ title, contents, index,listItem, id,  isScrollable, isCombineEnabled, useClone }) => {
	const { updateModal, updateModalMode, updateCurrentListId, updateCardData, columns, updateColumns, ordered, updateOrdered } = useContext(ScrumboardContext)
	const [renameActive, setRenameActive] = useState('')
	const [form] = Form.useForm();
	const newCard = (listId)=> {
		updateModal(true)
		updateModalMode(modalModeTypes(0))
		updateCurrentListId(listId)
	}	

	const onUpdateCardModal = (obj, listId) => {
		updateModal(true)
		updateModalMode(modalModeTypes(1))
		updateCurrentListId(listId)
		updateCardData(obj)
	}
	
	const onTitleClick = (e, title) => {
		switch (e.detail) {
			case 1:
			  break;
			case 2:
			setRenameActive(title);
			  break;
			case 3:
			  break;
			default:
		  }
		
	}

	const onFinish = newTitle => {
		if(newTitle) {
			const newColumns = {};
			delete Object.assign(newColumns, columns, {[newTitle]: columns[title] })[title];
			const newOrder = ordered.map(elm => {
				if(elm === title) {
					return newTitle
				}
				return elm
			})
			updateColumns(newColumns)
			updateOrdered(newOrder)
		}
		setRenameActive('')
	};

	const dispatch = useDispatch();

	const onBoardDelete = id => {
		Modal.confirm({
			title: 'Do you want to delete this board?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			cancelText: 'Cancel',
			onOk() {
			dispatch(deleteBoard(id))
			},
		});
	}
	

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
	  setIsModalVisible(true);
	};
  
	const handleOk = () => {
	  setIsModalVisible(false);
	};
  
	const [enableFocus, setEnableFocus] = useState(false); 
	const textInput = React.useRef(null);

	const handleCancel = () => {
	  setIsModalVisible(false);
	  setEnableFocus(false);
		form.resetFields()
	};

	const team_admin = useSelector(state => state.TeamStore?.team_admin);

	return (
		<React.Fragment>

<Modal   
	 	className="editModal" maskClosable={false} width={1100}  header={null} footer={null} title={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
       <AddCard form={form} enableFocus={enableFocus} setEnableFocus={setEnableFocus} textInput={textInput}  title={title} listId={id} onCancel={handleCancel} setIsModalVisible={setIsModalVisible}/>
      </Modal>
		<Draggable draggableId={title} index={index}>
			{
				(provided, snapshot) => (
					<div className="board-column project__view__board__column" ref={provided.innerRef} {...provided.draggableProps}>
						<div className="board-title" {...provided.dragHandleProps}>
							{
								renameActive === title ? (
									<RenameForm id={id} title={title} finish={onFinish} setRenameActive={setRenameActive}/>
								)
								:
								(
									<>
										<h4 className="mb-0" >{title}</h4>
										<Badge
        className="site-badge-count-109"

        count={contents?.length === 0 ? "" : contents?.length }
        style={{ backgroundColor: '#fff8a2',color:"#455560",fontSize:"10px", border:'1px solid rgba(0, 0, 0, 0.137)' }}
        
      />
										{/* {team_admin?.primary_role === 'Leader' && team_admin?.team_id === listItem?.team_id  ? 
										 <EllipsisDropdown menu={
											<Menu>
												<Menu.Item onClick={() => onTitleClick(title)}>
													<EditOutlined />
													<span>Rename Board</span>
												</Menu.Item>
												<Menu.Item onClick={() => onBoardDelete(id)}>
													<DeleteOutlined />
													<span>Delete Board</span>
												</Menu.Item>
											</Menu>
										}/>: ""
									    } */}
										
									</>
								)
							}
						</div>
						<BoardCard
							listId={title}
							listType="CONTENT"
							className={snapshot.isDragging ? 'is-dragging' : ''}
							contents={contents}
							internalScroll={isScrollable}
							isCombineEnabled={isCombineEnabled}
							useClone={useClone}
							cardData={onUpdateCardModal}
						/>
						{/* <div className="board-add" onClick={() => showModal()}>
							<div>Add task</div>
						</div> */}
					</div>
				)
			}
		</Draggable>
		</React.Fragment>
	)
}

export default Board
