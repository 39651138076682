import moment from "moment";

export const getTimeInterval = (date1, date2) => 
{
    var __startTime = moment(date1);
    var __endTime = moment(date2);

    var __duration = moment.duration(moment(__endTime).diff(__startTime));
    var __hours = __duration.asHours();

    var decimalTimeString = __hours;
    var decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    var hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    var minutes = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;
    if (minutes < 10) {
      minutes = 0 + minutes;
    }
    const time = `${hours} ${minutes <= 0 ? ":" : ":"}${
      minutes === 0 ? "00" : minutes
    }`;

    return time;
   
}