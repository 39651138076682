import * as types from "./constants"

const initialStates = {
    time:[],
    single_time:{},
    loading:false,
    error:null,
    success:false,
}

 const timeStore = (state = initialStates, action) => {
    //setup list_items reducers
   switch (action.type) {
       case types.GET_TIME :
       return {...state, loading:true}
       case types.GET_TIME_SUCCESS :
       return {...state, loading:false, time:action.time}
       case types.GET_TIME_FAILED :
       return {...state, loading:false, error:action.error, }

       case types.SHOW_TIME :
       return {...state}
       case types.SHOW_TIME_SUCCESS :
       return {...state, single_time:action.single_time}
       case types.SHOW_TIME_FAILED :
       return {...state, error:action.error, loading:false}

       case types.CREATE_TIME :
       return {...state, loading:true, success:false}
       case types.CREATE_TIME_SUCCESS :
       return {...state, loading:false, success:true}
       case types.CREATE_TIME_FAILED :
       return {...state, success:true, loading:false, error:action.error}

       case types.UPDATE_TIME :
       return {...state, loading:true, success:false}
       case types.UPDATE_TIME_SUCCESS :
       return {...state, loading:false, success:true}
       case types.UPDATE_TIME_FAILED:
       return {...state, success:true, loading:false, error:action.error}


       case types.DELETE_TIME :
       return {...state, loading:true, success:false}
       case types.DELETE_TIME_SUCCESS :
       return {...state, loading:false, success:true}
       case types.DELETE_TIME_FAILED :
       return {...state, success:true, loading:false, error:action.error}
          
       default:
           return state;
   }
}

export default timeStore
