import React, { useState, useEffect } from "react";
import {
  createTime,
  getTime,
  showProjectCard,
  updateCard,
  deleteTime,
  updateTime,
  updateScheduleTime,
  createScheduleTime,
  getScheduleTime,
  deleteScheduleTime,
  getTeam,
  getAssignedMembers,
} from "redux/actions";

import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Divider,
  Spin,
  Popconfirm,
  Tooltip,
} from "antd";

import Header from "components/shared-components/DraggableCard/Header";
import Description from "components/shared-components/DraggableCard/Description";
import Checklist from "components/shared-components/DraggableCard/Checklist";
import Attachments from "components/shared-components/DraggableCard/Attachments";
import WorkTime from "components/shared-components/DraggableCard/WorkTime";
import Comments from "components/shared-components/DraggableCard/Comments";
import { useDispatch, useSelector } from "react-redux";
function UpdateCardForm({ listId, listItem, card_data }) {
  const dispatch = useDispatch();
  const [isCancelled, setIsCancelled]= useState(false);
  const [form] = Form.useForm();
  const project_card = useSelector((state) => state.ProjectStore);
  const project_item = listItem;
  const board = useSelector((state) => state.BoardStore);
  const cardData = project_card?.project_card?.card;
  // const success = project_card?.success;
  const board_success = board.success;
  const attachment_success = useSelector((state) => state.AttachmentStore?.success);



  const user_item = useSelector((state) => state.AuthStore?.user);
  const user = user_item?.user;
  const teams = user_item?.all_teams;

  let lists = [];
  cardData?.card_labels?.forEach((r) => {
    const item = r?.name;
    lists.push(item);
  });

  const initialValues = {
    id: cardData?.id,
    title: cardData?.title,
    members: cardData?.members,
    labels: lists,
    attachments: [],
  };

  // reset form
  useEffect(() => {
    form.resetFields();
  }, [cardData, form]);

  const [enableTime, setEnableTime] = useState(false);

  React.useEffect(() => { 
    if(!isCancelled){
      if (cardData?.id !== undefined) {
        const fetchTeamData = async() => {
          await  dispatch(getTeam());
        }
        fetchTeamData();
      }
    } else{
      if (cardData?.id !== undefined) {
        const fetchTeamData = async() => {
          await  dispatch(getTeam());
        }
        fetchTeamData();
      }
    }  

    return () => {
      setIsCancelled(true);
    };

  }, [cardData?.id]);



// get assign Team members



  useEffect(() => {
    setIsCancelled(false);
    if(!isCancelled){
      if(cardData?.id !== undefined){
        const getTimerData = async() =>{
          dispatch(getTime(cardData?.id, "project_card_id", "project-time"));
        }
        getTimerData();
      }
    }else{
      if(cardData?.id !== undefined){
        const getTimerData = async() =>{
          dispatch(getTime(cardData?.id, "project_card_id", "project-time"));
        }
        getTimerData();
      }
    }

    return () => {
      setIsCancelled(true);
    }
    
  }, [cardData?.id]);

  const time_data = useSelector((state) => state.TimeStore);
  const time = time_data?.time;
  const time_success = time_data?.success;

  const team_state = useSelector((state) => state.TeamStore);
  const team_users = team_state?.users;

  const [enableScheduleTime, setEnableScheduleTime] = useState(false);

  useEffect(() => {
    setIsCancelled(false);
    if(!isCancelled){
      if(time_success === true){
        const getTimerData = async() =>{
          dispatch(getTime(cardData?.id, "project_card_id", "project-time"));
        }
        getTimerData();
      }
    }else{
      if(time_success){
        const getTimerData = async() =>{
          dispatch(getTime(cardData?.id, "project_card_id", "project-time"));
        }
        getTimerData();
      }
    }

    return () => {
      setIsCancelled(true);
    }
    
  }, [time_success]);




  // select schedule_time_data from api.
  const schedule_time_data = useSelector((state) => state.ScheduleTimeStore);
  const schedule_time = schedule_time_data?.schedule_time;
  const schedule_time_success = schedule_time_data?.success;

  // // reload work time scheduled data from api
  // ReloadScheduleTime(
  //   dispatch,
  //   useCallback,
  //   useEffect,
  //   getScheduleTime,
  //   cardData,
  //   project_item,
  //   schedule_time_success,
  //   showProjectCard
  // );



    
  // // end of schedule timer
  useEffect(() => {
    setIsCancelled(false);
    if(!isCancelled){
      if (cardData?.id !== undefined) {
        const getScheduleTimeData = async() => {
          await dispatch(getScheduleTime(
            `object_item_id=${cardData?.id}&object_type=${
              cardData?.team_id === 0 ? 1 : 2
            }`,
            "calendar-event"
          )); 
        }
        getScheduleTimeData();
      }
    }else{
      if (cardData?.id !== undefined) {
        const getScheduleTimeData = async() => {
          await dispatch(getScheduleTime(
            `object_item_id=${cardData?.id}&object_type=${
              cardData?.team_id === 0 ? 1 : 2
            }`,
            "calendar-event"
          )); 
        }
        getScheduleTimeData();
      }
    }
   
    return () => {
      setIsCancelled(true);
    }
  }, [cardData?.id]);


    // // end of schedule timer
    useEffect(() => {
      setIsCancelled(false);
      if(!isCancelled){
        if (schedule_time_success === true) {
          const getScheduleTimeData = async() => {
            await dispatch(getScheduleTime(
              `object_item_id=${cardData?.id}&object_type=${
                cardData?.team_id === 0 ? 1 : 2
              }`,
              "calendar-event"
            )); 
          }
          getScheduleTimeData();
        }
      }else{
        if (schedule_time_success === true) {
          const getScheduleTimeData = async() => {
            await dispatch(getScheduleTime(
              `object_item_id=${cardData?.id}&object_type=${
                cardData?.team_id === 0 ? 1 : 2
              }`,
              "calendar-event"
            )); 
          }
          getScheduleTimeData();
        }
      }
     
      return () => {
        setIsCancelled(true);
      }
    }, [schedule_time_success]);


  
  useEffect(() => {
    if(!isCancelled){
      if ( time_success === true || schedule_time_success === true || attachment_success) {
        const showProjectCardData = async() => {
          await  dispatch(showProjectCard(cardData?.id));
        }
        showProjectCardData();
      }
    }else{
      if (time_success === true || schedule_time_success === true || attachment_success) {
        const showProjectCardData = async() => {
          await  dispatch(showProjectCard(cardData?.id));
        }
        showProjectCardData();
      }
    }
 
    return () => {
      setIsCancelled(true);
    }

  }, [schedule_time_success, time_success, attachment_success]);



  return (
    <React.Fragment>
      <Form
        name="edit-card-ref"
        layout="vertical"
        onFinish={""}
        initialValues={initialValues}
        form={form}
      >
        <div className="text-center mt-2">
          <h4 className="text-gray-light">Task Details</h4>
        </div>
        {/* edit for component start */}
        <Header
          cardData={cardData}
          updateCard={updateCard}
          url="project-cards"
        />
        {/* edit for component start */}

        {/* display object component start */}
        <Form.Item style={{ display: "none" }} name="id" className="mb-0">
          <Input className="board-card-modal input" />
        </Form.Item>
        <Form.Item className="mb-3 mt-2">
          <p>
            Objective:{" "}
            <span className="font-weight-semibold">
              {cardData?.objective === undefined || cardData?.objective === null
                ? listId
                : cardData?.objective}
            </span>
          </p>
        </Form.Item>

        {/* display objctive component end */}

        <Divider className="mt-0" />

        {/* display description text editor start */}
        <Description
          cardData={cardData}
          updateCard={updateCard}
          url="project-cards"
        />
        {/*display description text editor end  */}

        {/* checklist container */}
        <Checklist cardData={cardData} />
        {/* end of the checklist container */}
        <br />
        {/* attachment header */}

        {/* end of attachment header */}
        <Attachments
          cardData={card_data}
          updateCard={updateCard}
          url="project-cards"
          atttachment_url="project-attachments"
        />

        {/* end of attachment list view */}
        {/* time tracker start */}

        <Divider className="mt-0" />
        {/* Schedule time */}

        <div className="mb-3 mt-4">
          {/* schedule work time component start */}
          <WorkTime
            createScheduleTime={createScheduleTime}
            user={user}
            team={teams}
            cardData={cardData}
            showProjectCard={showProjectCard}
            project_item={project_item}
            time={schedule_time}
            url="calendar-event"
            updateTime={updateScheduleTime}
            deleteTime={deleteScheduleTime}
            title="Schedule"
            work_status={2}
            enableTime={enableScheduleTime}
            setEnableTime={setEnableScheduleTime}
            message="Assign a team member to this task before you can schedule time to work on it."
          />
          {/* schedule work time component end */}
        </div>

        <div className="mb-3">
          {/* log work time component start */}
          <WorkTime
            createScheduleTime={createTime}
            user={user}
            team={teams}
            cardData={cardData}
            showProjectCard={showProjectCard}
            project_item={project_item}
            time={time}
            url="project-time"
            updateTime={updateTime}
            deleteTime={deleteTime}
            title="Log"
            work_status={4}
            enableTime={enableTime}
            setEnableTime={setEnableTime}
            message="Change work status to doing before you can log time"
          />
          {/* log work time component end */}
        </div>

        {/* end of time tracker */}

        <Divider className="mt-0" />
        <Comments
          user={user}
          cardData={cardData}
          team_users={team_users}
          url="project-comments"
          cardIdString="project_card_id"
        />
      </Form>
    </React.Fragment>
  );
}

export default UpdateCardForm;
