import { takeEvery, fork, put, all, call, takeLatest, throttle } from 'redux-saga/effects';
import { api } from 'services/ProjectService';


// Board Redux States
import * as types from './constants';

function* getProject(action)
{
    const {response, error} = yield call(api.index, action.payload)
    if(response)
    {
        yield put({type: types.GET_PROJECTS_SUCCESS, projects:response.data});

        // yield cancel(response);
    } else {
        yield put({type: types.GET_PROJECTS_FAILED, error:error});
    }
}

function* showProject(action)
{
    const {response, error} = yield call(api.show, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_PROJECTS_SUCCESS, single_project:response.data});
    } else {
        yield put({type: types.SHOW_PROJECTS_FAILED, error:error});
    }
}

function* showProjectCard(action)
{
    const {response, error} = yield call(api.showProjectCard, action.payload)
    if(response)
    {
        yield put({type: types.SHOW_PROJECT_CARD_SUCCESS, project_card:response.data});
    } else {
        yield put({type: types.SHOW_PROJECT_CARD_FAILED, error:error});
    }
}

function* createProject(action)
{
    const {response, error} = yield call(api.store, action.payload)
    if(response)
    {
        yield put({type: types.CREATE_PROJECTS_SUCCESS,});
    } else {
        yield put({type: types.CREATE_PROJECTS_FAILED, error:error});
    }
}

function* updateProject(action)
{
    const {response, error} = yield call(api.update, action.payload)
    if(response)
    {
        yield put({type: types.UPDATE_PROJECTS_SUCCESS, });
    } else {
        yield put({type: types.UPDATE_PROJECTS_FAILED, error:error});
    }
}

function* deleteProject(action)
{
    const {response, error} = yield call(api.destroy, action.payload)
    if(response)
    {
        yield put({type: types.DELETE_PROJECTS_SUCCESS, });
    } else {
        yield put({type: types.DELETE_PROJECTS_FAILED, error:error});
    }
}



function* watchGetProject() 
{
    yield takeLatest(types.GET_PROJECTS, getProject)
}

function* watchShowProject() 
{
    yield takeEvery(types.SHOW_PROJECTS, showProject)
}

function* watchShowProjectCard() 
{
    yield takeLatest(types.SHOW_PROJECT_CARD, showProjectCard)
}

function* watchCreateProject() 
{
    yield takeEvery(types.CREATE_PROJECTS, createProject)
}

function* watchUpdateProject() 
{
    yield takeEvery(types.UPDATE_PROJECTS, updateProject)
}

function* watchDeleteProject() 
{
    yield takeEvery(types.DELETE_PROJECTS, deleteProject)
}





function* projectSaga(){
    yield all([
        fork(watchGetProject),
        fork(watchShowProject),
        fork(watchCreateProject),
        fork(watchUpdateProject),
        fork(watchDeleteProject),
        fork(watchShowProjectCard),
    ])
}

export default projectSaga;