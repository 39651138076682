import * as types from "./constants"

export const getProject = (projects) => {
    return {
        type:types.GET_PROJECTS,
        payload:projects
    }
}

export const showProject = (single_project) => {
    return {
        type:types.SHOW_PROJECTS,
        payload:single_project
    }
}

export const showProjectCard = (single_project_card) => {
    return {
        type:types.SHOW_PROJECT_CARD,
        payload:single_project_card
    }
}

export const createProject = (projects, history) => {
    return {
        type:types.CREATE_PROJECTS,
        payload:{projects, history
    }}
}

export const updateProject = (projects) => {
    return {
        type:types.UPDATE_PROJECTS,
        payload:projects
    }
}

export const deleteProject = (projects, history) => {
    return {
        type:types.DELETE_PROJECTS,
        payload:{projects, history}
    }
}

