import React, {useState, useEffect} from 'react'
import {SafetyOutlined, CloseOutlined} from "@ant-design/icons";
import { updateCard } from 'redux/actions';
import { useDispatch } from 'react-redux';
import {Divider} from "antd";

function Priority(props) {

 const dispatch = useDispatch();
 const {card_data,cardData, url} = props;
 const [priority, setPriority] = useState("");
 const [priorityData, setPriorityData] = useState([
    { value: "Low" },
    { value: "Normal" },
    { value: "High" },
    { value: "Urgent" },
  ]);

  useEffect(() => {
    if (card_data !== undefined) {
      setPriority(card_data?.priority);
    }
  }, [card_data]);


  const changePiriority = (priority) => {
    let data = card_data;
    data.priority = priority;
    data.attachments = [];
    data.isPriorityChange = true;
    dispatch(updateCard(data, url));
  };

  const [priorityBox, setPriorityBox] = useState(false);

    return (
        <React.Fragment>
            
            <div style={{ position: "relative" }}>
              <div className="item__box" onClick={() => setPriorityBox(true)}>
                <span>
                  <SafetyOutlined />{" "}
                  <span className="icon__text">Priority:</span>{" "}
                  <span>{priority}</span>
                </span>
              </div>

              {priorityBox === true ? (
                <div className="dropdown__box">
                  <div className="heading__box">
                    <span>Priority</span>
                    <CloseOutlined
                      onClick={() => {
                        setPriorityBox(false);
                      }}
                    />
                  </div>
                  <Divider className="dropdown__divider" />
                  {priorityData.map((elm, index) => (
                    <div
                      className="dropdown__box_menu"
                      key={index}
                      onClick={() => {
                        setPriority(elm?.value);
                        setPriorityBox(false);
                        changePiriority(elm?.value);
                      }}
                    >
                      {elm?.value}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
        </React.Fragment>
    )
}

export default Priority
