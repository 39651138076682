import * as types from "./constants"

export const updateAttachments = (attachment, url) => {
    return {
        type:types.UPDATE_ATTACHMENTS,
        payload:{attachment, url} 
    }
}

export const deleteAttachments = (attachment, url) => {
    return {
        type:types.DELETE_ATTACHMENTS,
        payload:{attachment,url} 
    }
}

export const updateAttachmentFromCard = (attachment, url) => {
    return {
        type:types.UPDATE_ATTACHMENT_FROM_CARD,
        payload:{attachment, url}
    }
}