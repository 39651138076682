import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { api } from 'services/NotificationService';


// Board Redux States
import * as types from './constants';

function* allNotification(action) 
{
    const {response, error} = yield call(api.index)

    if(response){
        yield put({type:types.GET_NOTIFICATION_SUCCESS, notification:response.data})
    } else {
        yield put({type:types.GET_NOTIFICATION_FAILED, error:error})
    }
}

function* readNotification(action) 
{
    const {response, error} = yield call(api.read, action.payload)

    if(response){
        yield put({type:types.READ_NOTIFICATION_SUCCESS, notification:response.data})
    } else {
        yield put({type:types.READ_NOTIFICATION_FAILED, error:error})
    }
}


function* readAllNotification() 
{
    const {response, error} = yield call(api.readAll)

    if(response){
        yield put({type:types.READ_ALL_NOTIFICATION_SUCCESS, notification:response.data})
    } else {
        yield put({type:types.READ_ALL_NOTIFICATION_FAILED, error:error})
    }
}

export function* watchAllNotification()
{
    yield takeEvery(types.GET_NOTIFICATION, allNotification)
}
export function* watchReadNotification()
{
    yield takeEvery(types.READ_NOTIFICATION, readNotification)
}
export function* watchReadAllNotification()
{
    yield takeEvery(types.READ_ALL_NOTIFICATION, readAllNotification)
}

function* NotificationSaga(){
    yield all([
        fork(watchAllNotification),
        fork(watchReadNotification),
        fork(watchReadAllNotification),
    ])
}

export default NotificationSaga;